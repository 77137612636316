export default {
  info: {
    header: 'Server Info',
    env: 'Environment',
    system: 'System',
  },
  logs: {
    header: 'Logs',
  },
  jobs: {
    header: 'Jobs',
    run: {
      header: 'Run Jobs',
      system: {
        title: 'Run Backend Jobs',
        clearCache: {
          title: 'Clear System Cache',
        },
      },
      notification: {
        title: 'Notifications',
        clearQueue: {
          title: 'Clear Notification Queue',
        },
      },
      elastic: {
        title: 'ElasticSearch',
        requirements: 'Import Requirements',
        patients: 'Import Patients',
        doctors: 'Import Doctors',
        doctorTopRequirements: 'Top Doctors Requirements',
      },
      runNow: 'Run now',
      notifications: {
        success: 'Job completed',
      },
    },
    dashboard: {
      header: 'Dashboard',
    },
  },

};
