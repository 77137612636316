import { Guard, SubRoute } from 'containers';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { createRandomString } from 'utils/helpers';
import { Icon } from 'components/Icon';
import { Translate } from 'providers';
import cx from 'classnames';
import styles from 'components/SideBar/SideBar.module.less';
import { Collapse } from 'components/Collapse';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export type SideBarItemProps = SubRoute & {
  onClick: () => void;
};

export const SideBarItem: React.FC<SideBarItemProps> = React.memo((item) => {

  const { pathname } = useLocation();

  const isActive = useCallback((item: SubRoute) => (pathname + '/').indexOf(item.path.replace('/*', '') + '/') === 0, [pathname]);
  const parentActive = useMemo(() => isActive(item) || filter(item.subRoutes, child => isActive(child)).length > 0, [isActive, item.children]);

  const parentWrapper = (content: any) => item.path
    ? (
      <Link to={{ pathname: item.path.replace('/*', '') }} state={{ key: createRandomString() }} onClick={item.onClick}>
        {content}
      </Link>
    )
    : (
      <a>{content}</a>
    );

  const header = parentWrapper((
    <>
      {item.icon && <span className={'control-icon'}><Icon {...item.icon}/></span>}
      {item.title && <span className={'control-label'}><Translate message={item.title}/></span>}
    </>
  ));

  const hasChildren = item?.subRoutes?.length > 0;

  const className = cx(item.navClassName, { [styles.isActive]: parentActive, [styles.hasChildren]: hasChildren });

  return (
    <Guard {...item.guard}>
      <li className={className}>
        {hasChildren
          ? (
            <Collapse
              activeKey={parentActive ? ['1'] : []}
              items={[{
                key: '1',
                label: header,
                showArrow: false,
                children: (
                  <ul>
                    {item.subRoutes.map(child => (
                      <Guard key={child.path} {...child.guard}>
                        <li className={cx({ [styles.isActive]: isActive(child) })}>
                          <Link to={`${(child.path).replace('/*', '')}`} state={{ key: createRandomString() }} onClick={item.onClick} relative="path">
                            <Icon icon={faChevronRight}/>
                            <Translate message={child.title}/>
                          </Link>
                        </li>
                      </Guard>
                    ))}
                  </ul>
                ),
              }]}
            />
          )
          : (
            <>{header}</>
          )}
      </li>
    </Guard>
  );

});
