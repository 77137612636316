/**
 * ATTENTION!!!
 *
 * DO NOT MODIFY THIS FILE BECAUSE IT WAS GENERATED AUTOMATICALLY,
 * Instead, run 'yarn api' to regenerate this file.
 */

export const ErrorSchema = {
  "status": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "message": {
    "type": [
      "string",
      "object"
    ]
  }
};

export const ArticleSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "maxLength": 100,
    "type": "string"
  },
  "articleName": {
    "type": "string"
  },
  "articleRef": {
    "type": "integer"
  },
  "displayContent": {
    "type": "string"
  },
  "mainContent": {
    "type": "string"
  },
  "doctorCustomContent": {
    "type": "string"
  },
  "patientCustomContent": {
    "type": "string"
  }
};

export const ArticleMainContentSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "maxLength": 100,
    "type": "string"
  },
  "htmlContent": {
    "type": "string"
  },
  "articleRef": {
    "type": "integer"
  }
};

export const ArticleCustomContentSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "articleRef": {
    "type": "integer"
  },
  "targetUser": {
    "maxLength": 10,
    "type": "string"
  },
  "htmlContent": {
    "type": "string"
  }
};

export const ArticleAnalysesSchema = {
  "id": {
    "type": "integer"
  },
  "analysisKey": {
    "maxLength": 100,
    "type": "string"
  },
  "articleRef": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  }
};

export const HintSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "importPreanalyticId": {
    "maxLength": 25,
    "type": "string"
  },
  "hintName": {
    "maxLength": 100,
    "type": "string"
  },
  "htmlContent": {
    "type": "string"
  },
  "htmlColor": {
    "maxLength": 7,
    "type": "string"
  },
  "onlyForAid": {
    "type": "integer"
  }
};

export const HintRequirementsSchema = {
  "id": {
    "type": "integer"
  },
  "hintId": {
    "type": "integer"
  },
  "requirementKey": {
    "maxLength": 100,
    "type": "string"
  },
  "submitter": {
    "maxLength": 50,
    "type": "string"
  },
  "lid": {
    "type": "integer"
  }
};

export const PushNotificationSchema = {
  "puid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "tnr": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "processed_at": {
    "type": "string"
  },
  "pushToken": {
    "type": "string"
  },
  "deviceType": {
    "type": "string"
  },
  "sent_at": {
    "type": "string"
  },
  "notification_id": {
    "type": "integer"
  },
  "target": {
    "type": "string"
  },
  "title": {
    "type": "string"
  },
  "body": {
    "type": "string"
  },
  "data": {
    "type": "object"
  },
  "result": {}
};

export const GuidReportNotificationSchema = {
  "guid": {
    "type": "string"
  },
  "deviceId": {
    "type": "string"
  },
  "mailTemplateGroupId": {
    "type": "number"
  }
};

export const SendMailSchema = {
  "smid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "receiverMail": {
    "type": "string"
  },
  "receiverName": {
    "type": "string"
  },
  "subject": {
    "type": "string"
  },
  "body": {
    "type": "string"
  },
  "type": {},
  "encoding": {},
  "attachment": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "sent": {
    "type": "boolean"
  },
  "reply": {
    "type": "string"
  },
  "printId": {
    "type": "number"
  },
  "mailTemplateGroupId": {
    "type": "number"
  },
  "notification_id": {
    "type": [
      "integer",
      "null"
    ]
  }
};

export const SendmailSettingsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "number"
  },
  "mtgid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "default_sender": {
    "type": "string"
  },
  "server": {
    "maxLength": 200,
    "type": "string"
  },
  "user": {
    "maxLength": 50,
    "type": "string"
  },
  "password": {
    "maxLength": 200,
    "type": "string"
  },
  "port": {
    "type": "integer"
  },
  "use_ssl": {
    "type": "boolean"
  },
  "bcc": {
    "maxLength": 200,
    "type": [
      "string",
      "null"
    ]
  },
  "domain": {
    "maxLength": 200,
    "type": [
      "string",
      "null"
    ]
  },
  "mailTemplateGroup": {
    "mtgid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "reply": {
      "type": "string"
    },
    "target": {
      "type": [
        "string",
        "null"
      ]
    },
    "www_url": {
      "maxLength": 50,
      "pattern": "^https?://",
      "type": [
        "string",
        "null"
      ]
    },
    "logo": {
      "type": [
        "string",
        "null"
      ]
    },
    "fontColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "backgroundColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    }
  }
};

export const MailTemplateGroupSchema = {
  "mtgid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "reply": {
    "type": "string"
  },
  "target": {
    "type": [
      "string",
      "null"
    ]
  },
  "www_url": {
    "maxLength": 50,
    "pattern": "^https?://",
    "type": [
      "string",
      "null"
    ]
  },
  "logo": {
    "type": [
      "string",
      "null"
    ]
  },
  "fontColor": {
    "type": [
      "string",
      "null"
    ]
  },
  "backgroundColor": {
    "type": [
      "string",
      "null"
    ]
  },
  "highlightColor": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const MailLanguageEntrySchema = {
  "lid": {
    "type": "integer"
  },
  "mtgid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "locale": {},
  "type": {},
  "subject": {
    "type": "string"
  },
  "body": {
    "type": "string"
  },
  "mailData": {
    "type": "object"
  }
};

export const MailTemplateSchema = {
  "mtid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "type": {},
  "groups": {
    "type": "string"
  },
  "is_html": {
    "type": "boolean"
  },
  "has_bg": {
    "type": "boolean"
  }
};

export const QuickAccessNotificationSchema = {
  "bid": {
    "type": "integer"
  },
  "email": {
    "type": "string"
  },
  "target": {
    "type": "string"
  },
  "mailTemplateGroupId": {
    "type": "number"
  }
};

export const OrderPoolSchema = {
  "id": {
    "type": "integer"
  },
  "tnr": {
    "type": "string"
  },
  "displayName": {
    "type": "string"
  },
  "status": {
    "type": "array"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "orders": {
    "type": "array"
  },
  "logs": {
    "type": "array"
  },
  "created_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "materialsString": {
    "type": "string"
  },
  "requirementsString": {
    "type": "string"
  },
  "costUnitsString": {
    "type": "string"
  },
  "journalString": {
    "type": "string"
  },
  "orderFormNamesString": {
    "type": "string"
  },
  "meta_strings": {
    "materials": {
      "type": "string"
    },
    "requirements": {
      "type": "string"
    },
    "journal": {
      "type": "string"
    },
    "costUnits": {
      "type": "string"
    },
    "orderFormNames": {
      "type": "string"
    }
  },
  "isSoftDeleted": {
    "type": "boolean"
  }
};

export const OrderSchema = {
  "lid": {
    "type": "integer"
  },
  "isPool": {
    "type": "boolean"
  },
  "isPoct": {
    "type": "boolean"
  },
  "isSoftDeleted": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeText": {
    "maxLength": 60,
    "type": "string"
  },
  "message": {
    "maxLength": 100,
    "type": "string"
  },
  "infectious": {
    "type": "boolean"
  },
  "urgent": {
    "type": "string"
  },
  "pregnancy": {
    "type": "boolean"
  },
  "checkup": {
    "type": "boolean"
  },
  "costUnit": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "controlCheckupKnownInfection": {
    "type": "boolean"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "dz16b": {
    "type": "boolean"
  },
  "accident": {
    "type": "boolean"
  },
  "accidentTime": {
    "maxLength": 20,
    "type": "string"
  },
  "documentReferenceNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "anamnesticInfo": {
    "maxLength": 60,
    "type": "string"
  },
  "printedMaterials": {
    "type": [
      "string",
      "null"
    ]
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "urgentNotificationPhone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationFax": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationMail": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "id": {
    "type": "integer"
  },
  "status": {},
  "executionStatus": {},
  "poolId": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "tnr": {
    "type": "string"
  },
  "externalId2": {
    "type": "string"
  },
  "lanr": {
    "type": "string"
  },
  "bsnr": {
    "type": "string"
  },
  "selectedDoctor": {
    "type": "string"
  },
  "apid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "patient": {
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "suffix": {
      "maxLength": 50,
      "type": "string"
    },
    "title": {
      "maxLength": 50,
      "type": "string"
    },
    "gender": {},
    "birthday": {
      "maxLength": 50,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 150,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "addressAdditional": {
      "maxLength": 50,
      "type": "string"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "phone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "costUnit": {
      "type": "string"
    },
    "medication": {
      "maxLength": 100,
      "type": "string"
    },
    "cycleDay": {
      "maxLength": 10,
      "type": "string"
    },
    "weight": {
      "maxLength": 5,
      "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "diagnosis": {
      "maxLength": 150,
      "type": "string"
    },
    "exttnr": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "primaryCarePhysicianLanr": {
      "maxLength": 20,
      "type": "string"
    },
    "primaryCarePhysicianBsnr": {
      "maxLength": 20,
      "type": "string"
    },
    "diseaseManagementProgram": {
      "maxLength": 50,
      "type": "string"
    },
    "dmpSpecialGroup": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceStart": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceEnd": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceType": {
      "maxLength": 50,
      "type": "string"
    },
    "exceptionalIndication": {
      "maxLength": 20,
      "type": "string"
    },
    "sktIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "wopIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "accountingArea": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "hvcode": {
      "maxLength": 20,
      "type": "string"
    },
    "vkat": {
      "maxLength": 20,
      "type": "string"
    },
    "secondName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "canton": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "ahvNr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "aisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "insurance": {
      "type": [
        "number",
        "null"
      ]
    },
    "bag": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "cardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "patientIdentification": {
      "maxLength": 20,
      "type": "string"
    },
    "globalLocationNumber": {
      "maxLength": 13,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "hospitalBedNumber": {
      "type": "string"
    },
    "hospitalRoomNumber": {
      "type": "string"
    },
    "hospitalProfession": {
      "type": [
        "string",
        "null"
      ]
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseNumber": {
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "invoiceTo": {
      "maxLength": 25,
      "type": "string"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "autInsuranceTitle": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceFirstName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceLastName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceInsuranceNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceBirthday": {
      "type": "string"
    },
    "autInsuranceAddress": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceHouseNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCountry": {
      "maxLength": 10,
      "type": "string"
    },
    "autInsuranceZip": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCity": {
      "maxLength": 50,
      "type": "string"
    },
    "pid": {
      "type": "integer"
    },
    "apgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "displayName": {
      "type": "string"
    },
    "prefix": {
      "maxLength": 50,
      "type": "string"
    },
    "height": {
      "maxLength": 5,
      "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "pregnancyWeek": {
      "maxLength": 2,
      "pattern": "^(\\d{1,2})?$",
      "type": "string"
    },
    "pregnancyWeekDay": {
      "maxLength": 1,
      "pattern": "^(\\d{1})?$",
      "type": "string"
    },
    "cycleLength": {
      "maxLength": 3,
      "type": [
        "string",
        "null"
      ]
    },
    "dayOfCycle": {
      "maxLength": 2,
      "type": "string"
    },
    "fetalCrownRumpLength": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "fetalNuchalTranslucency": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "hasInsuranceNumber": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "sampleAmount": {
      "maxLength": 10,
      "type": "string"
    },
    "samplePeriod": {
      "maxLength": 10,
      "type": "string"
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "passportNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "idcardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "orderReason": {
      "maxLength": 1,
      "type": "string"
    },
    "costUnitIdentification": {
      "maxLength": 25,
      "type": "string"
    },
    "autInsuranceEmployer": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceEmployerCity": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceAddressAdditional": {
      "maxLength": 50,
      "type": "string"
    }
  },
  "requirements": {
    "type": "array"
  },
  "anamnesis": {
    "type": "array"
  },
  "animal": {
    "name": {
      "maxLength": 50,
      "type": "string"
    },
    "birthday": {
      "maxLength": 8,
      "type": "string"
    },
    "species": {
      "maxLength": 2,
      "type": "string"
    },
    "speciesManual": {
      "maxLength": 50,
      "type": "string"
    },
    "chipId": {
      "maxLength": 50,
      "type": "string"
    },
    "height": {
      "maxLength": 3,
      "type": "string"
    },
    "weight": {
      "maxLength": 3,
      "type": "string"
    },
    "sex": {
      "type": "number"
    },
    "breed": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerName": {
      "maxLength": 100,
      "type": "string"
    },
    "ownerAmendment": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerStreet": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerHouseNumber": {
      "maxLength": 10,
      "type": "string"
    },
    "ownerZipCode": {
      "maxLength": 10,
      "type": "string"
    },
    "ownerCity": {
      "maxLength": 50,
      "type": "string"
    }
  },
  "switzerland": {
    "billToOther1": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther2": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyTo": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToEmail": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToSalutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToFirstName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToLastName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToStreet": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToZip": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToCity": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "notes": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "accidentDate": {
      "maxLength": 8,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "hungary": {
    "motherLastName": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "motherFirstName": {
      "maxLength": 15,
      "type": [
        "string",
        "null"
      ]
    },
    "treatmentReason": {
      "maxLength": 1,
      "type": [
        "string",
        "null"
      ]
    },
    "invoice": {
      "maxLength": 2,
      "type": [
        "string",
        "null"
      ]
    },
    "icd1": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "icd2": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "icd3": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "insuranceNumber": {
      "maxLength": 18,
      "type": [
        "string",
        "null"
      ]
    },
    "citizen": {
      "maxLength": 3,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "executed_at": {
    "type": "string"
  },
  "scheduled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "sampleDate": {
    "type": "string"
  },
  "sampleTime": {
    "maxLength": 8,
    "type": "string"
  },
  "covid": {
    "testNumber": {},
    "testReason": {},
    "agreed": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "riskCharacteristicsLocation": {},
    "riskCharacteristicsLocationType": {},
    "legalReason": {},
    "healthServiceZipCode": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "healthService": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "legalOrderBasis": {},
    "kvSpecial": {
      "type": [
        "string",
        "null"
      ]
    },
    "confirmationDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "variantDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "oegdInvoiceTo": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "covidWarnAppBarcode": {
      "type": [
        "boolean",
        "null"
      ]
    }
  },
  "mustValidate": {
    "type": "boolean"
  },
  "hospitalStation": {
    "type": "string"
  },
  "requirementDiagnosisFreeText": {
    "maxLength": 150,
    "type": "string"
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "selectedDiagnoses": {
    "type": "object"
  },
  "selectedDiagnosesChecked": {
    "type": "boolean"
  },
  "workstationChecked": {
    "type": "boolean"
  },
  "splitProperties": {
    "type": "array"
  },
  "sample_in_lab": {
    "type": "boolean"
  },
  "clientname": {
    "type": "string"
  },
  "printerAliasName": {
    "type": "string"
  },
  "overridePrinterName": {
    "type": [
      "string",
      "null"
    ]
  },
  "overrideBarcodePrinterName": {
    "type": [
      "string",
      "null"
    ]
  },
  "beforeAfterDialysis": {},
  "reportCopyAid": {
    "type": [
      "number",
      "null"
    ]
  },
  "reportCopyDoctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "aisIdentifier": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "patientAisNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "bloodCollectionType": {},
  "transported_at": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "digitalSignature": {},
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "report": {
    "bid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "parent_bid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "status": {},
    "tnr": {
      "type": "string"
    },
    "pathological": {},
    "mibi": {},
    "created_at": {
      "type": "string"
    },
    "imported_at": {
      "type": "string"
    },
    "registered_at": {
      "type": "string"
    },
    "validated_at": {
      "type": "string"
    },
    "sampled_at": {
      "type": "string"
    },
    "diagnosis": {
      "maxLength": 100,
      "type": "string"
    },
    "validatingDoctor": {
      "maxLength": 100,
      "type": "string"
    },
    "validatingDoctorAddition": {
      "maxLength": 100,
      "type": "string"
    },
    "patientName": {
      "type": "string"
    },
    "is_locked": {
      "type": "boolean"
    },
    "is_unread": {
      "type": "boolean"
    },
    "is_pinned": {
      "type": "boolean"
    },
    "has_base64": {
      "type": "boolean"
    },
    "has_elpho": {
      "type": "boolean"
    },
    "has_subreports": {
      "type": "boolean"
    },
    "has_history": {
      "type": "boolean"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "patient": {
      "pid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 200,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 100,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 50,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "phone": {
        "maxLength": 50,
        "type": "string"
      },
      "costUnit": {
        "type": "string"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "sonstiges1": {
        "maxLength": 50,
        "type": "string"
      },
      "sonstiges2": {
        "maxLength": 50,
        "type": "string"
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "smsNotificationEnabled": {
        "type": "boolean"
      },
      "smsNumber": {
        "type": "string"
      },
      "canViewPartialResults": {
        "type": "boolean"
      },
      "canViewPreliminaryResults": {
        "type": "boolean"
      },
      "resultLanguage": {
        "maxLength": 5,
        "type": "string"
      },
      "oa": {
        "type": "boolean"
      },
      "aids": {
        "type": "array"
      },
      "latest_report_date": {
        "type": "string"
      },
      "dateScore": {
        "type": "number"
      },
      "passportNumber": {
        "type": "string"
      },
      "idCardNumber": {
        "type": "string"
      }
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "laboratoryGroupFilter": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 32,
        "type": "string"
      },
      "color": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      },
      "group": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "values": {
      "type": "array"
    },
    "subreports": {
      "type": "array"
    },
    "attachments": {
      "type": "array"
    },
    "attachment_count": {
      "type": "integer"
    },
    "comments": {
      "type": "array"
    },
    "comment_count": {
      "type": "integer"
    },
    "arzname": {
      "maxLength": 300,
      "type": "string"
    },
    "labname": {
      "type": "string"
    },
    "accountingType": {
      "type": "string"
    },
    "laborgruppe": {
      "type": "string"
    },
    "exttnr": {
      "type": "string"
    },
    "lebensnummer": {
      "type": "string"
    },
    "zimmer": {
      "type": "string"
    },
    "lastupdate": {
      "type": "string"
    },
    "orderComment": {
      "type": "string"
    },
    "overwritecolor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "pdfSrc": {
      "type": "string"
    },
    "material": {
      "type": "string"
    },
    "caseNumber": {
      "type": "string"
    },
    "insuranceNumber": {
      "type": "string"
    },
    "showBdw": {
      "type": "boolean"
    },
    "showCumulative": {
      "type": "boolean"
    },
    "showPdf": {
      "type": "boolean"
    },
    "showOriginal": {
      "type": "boolean"
    },
    "showOriginalExclusive": {
      "type": "boolean"
    },
    "showCumulativePdf": {
      "type": "boolean"
    },
    "showCumulativeAllPdf": {
      "type": "boolean"
    },
    "showSend": {
      "type": "boolean"
    },
    "showRerequest": {
      "type": "boolean"
    },
    "originalPdfIds": {
      "type": "array"
    },
    "guid": {
      "type": "string"
    },
    "externalGuid": {
      "type": "string"
    },
    "manualCode": {
      "type": "string"
    },
    "uuid": {
      "type": "string"
    },
    "settlementType": {}
  },
  "pool": {
    "id": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "displayName": {
      "type": "string"
    },
    "status": {
      "type": "array"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "orders": {
      "type": "array"
    },
    "logs": {
      "type": "array"
    },
    "created_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isSoftDeleted": {
      "type": "boolean"
    }
  },
  "samples": {
    "type": "array"
  },
  "workstation": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "location": {
      "type": "string"
    },
    "default": {
      "type": "boolean"
    },
    "status": {},
    "clientVersion": {
      "type": "string"
    },
    "printers": {
      "type": "array"
    }
  },
  "manufacturer": {
    "type": "string"
  },
  "initialWorkplaceId": {
    "type": "number"
  },
  "lastWorkplaceId": {
    "type": "number"
  },
  "materialsString": {
    "type": "string"
  },
  "requirementsString": {
    "type": "string"
  },
  "costUnitsString": {
    "type": "string"
  },
  "journalString": {
    "type": "string"
  },
  "orderFormNamesString": {
    "type": "string"
  },
  "splitId": {
    "type": "integer"
  },
  "quickScanPdfPassword": {
    "type": [
      "string",
      "null"
    ]
  },
  "hasAttachment": {
    "type": "boolean"
  },
  "case_id": {
    "type": "integer"
  },
  "caseNumber": {
    "type": "string"
  },
  "realReRequestConditions": {
    "allowed": {
      "type": "boolean"
    },
    "formIds": {
      "type": "array"
    },
    "requirementIds": {
      "type": "array"
    },
    "profileIds": {
      "type": "array"
    }
  },
  "exportFlag": {
    "type": "boolean"
  },
  "statusExport": {
    "type": "integer"
  },
  "statusStornoExport": {
    "type": "integer"
  },
  "comment": {
    "type": "string"
  },
  "meta_strings": {
    "materials": {
      "type": "string"
    },
    "requirements": {
      "type": "string"
    },
    "journal": {
      "type": "string"
    },
    "costUnits": {
      "type": "string"
    },
    "orderFormNames": {
      "type": "string"
    }
  },
  "isCancelable": {
    "type": "boolean"
  },
  "actuallyPrintedMaterials": {
    "type": "string"
  },
  "hasReportCopyInfo": {
    "type": "boolean"
  }
};

export const RequirementCategorySchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const RequirementFieldSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "type": "string"
  },
  "name": {},
  "layout": {},
  "withPrice": {
    "type": "boolean"
  },
  "withSubRequirements": {
    "type": "boolean"
  },
  "renderTarget": {
    "type": "boolean"
  },
  "requirementAssigned": {
    "type": "boolean"
  }
};

export const RequirementFieldSettingSchema = {
  "id": {
    "type": "integer"
  },
  "fieldId": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "renderMode": {
    "type": "boolean"
  },
  "fieldKey": {
    "type": "string"
  },
  "fieldName": {
    "type": "string"
  }
};

export const IndicationSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const RequirementProfileSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "order": {
    "type": "integer"
  },
  "costUnit": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "color": {
    "maxLength": 6,
    "type": [
      "string",
      "null"
    ]
  },
  "diagnosis": {
    "maxLength": 60,
    "type": [
      "string",
      "null"
    ]
  },
  "freeText": {
    "maxLength": 60,
    "type": [
      "string",
      "null"
    ]
  },
  "isMicrobiological": {
    "type": "boolean"
  },
  "orderReason": {
    "maxLength": 1,
    "type": "string"
  },
  "selectedDiagnoses": {
    "type": "object"
  }
};

export const RequirementGlobalProfileSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "public": {
    "type": "boolean"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "order": {
    "type": "integer"
  },
  "costUnit": {
    "maxLength": 20,
    "type": "string"
  },
  "orderReason": {
    "maxLength": 1,
    "type": "string"
  }
};

export const PinnedRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "type": {},
  "aid": {
    "type": "integer"
  },
  "requirement": {
    "id": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    },
    "shortName": {
      "type": "string"
    },
    "externalName": {
      "type": "string"
    },
    "alias": {
      "type": "string"
    },
    "longName": {
      "type": "string"
    },
    "limit": {
      "type": [
        "integer",
        "null"
      ]
    },
    "materialLimitOverrule": {
      "type": [
        "integer",
        "null"
      ]
    },
    "superRequirement": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "subRequirements": {
      "type": "string"
    },
    "infoUrl": {
      "type": [
        "string",
        "null"
      ]
    },
    "additionalText": {
      "type": [
        "string",
        "null"
      ]
    },
    "hint": {
      "type": [
        "string",
        "null"
      ]
    },
    "analysesString": {
      "type": "string"
    },
    "analyses": {
      "type": "array"
    },
    "occurrence": {
      "type": "integer"
    },
    "samples": {
      "type": "array"
    },
    "materials": {
      "type": "array"
    },
    "indicationsString": {
      "type": "array"
    },
    "forms": {
      "type": "array"
    },
    "categories": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "requirementFieldSettings": {
      "type": "array"
    },
    "pinnedByDoctors": {
      "type": "array"
    },
    "topFromDoctors": {
      "type": "array"
    },
    "firstImportDate": {
      "type": "string"
    },
    "laboratoryGroup": {
      "type": "string"
    },
    "submitter": {
      "type": "string"
    },
    "duration": {
      "type": "string"
    },
    "selfDefined": {
      "type": "boolean"
    },
    "deletable": {
      "type": "boolean"
    },
    "blocked": {
      "type": "boolean"
    },
    "shortNameEditable": {
      "type": "boolean"
    },
    "ebmPrice": {
      "type": "number"
    },
    "priority": {
      "type": [
        "integer",
        "null"
      ]
    },
    "lisExport": {
      "type": "boolean"
    },
    "isCharged": {
      "type": "boolean"
    },
    "isEbmCharged": {
      "type": "boolean"
    },
    "freeTextAllowed": {
      "type": "boolean"
    },
    "freeTextMandatory": {
      "type": "boolean"
    },
    "freeTextAsDate": {
      "type": "boolean"
    },
    "freeTextKey": {
      "type": [
        "integer",
        "null"
      ]
    },
    "freeTextPreappendValue": {
      "type": [
        "string",
        "null"
      ]
    },
    "examinationDuration": {
      "type": [
        "integer",
        "null"
      ]
    },
    "examinationDurationInterval": {},
    "priceGroup1": {
      "type": "number"
    },
    "maxPriceGroup1": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup1": {
      "type": "string"
    },
    "priceGroup2": {
      "type": "number"
    },
    "maxPriceGroup2": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup2": {
      "type": "string"
    },
    "priceGroup3": {
      "type": "number"
    },
    "maxPriceGroup3": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup3": {
      "type": "string"
    },
    "priceGroup4": {
      "type": "number"
    },
    "maxPriceGroup4": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup4": {
      "type": "string"
    },
    "priceGroup5": {
      "type": "number"
    },
    "maxPriceGroup5": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup5": {
      "type": "string"
    }
  }
};

export const ClinicalMaterialSchema = {
  "id": {
    "type": "string"
  },
  "name": {
    "type": "string"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "sampleType": {
    "type": "string"
  },
  "analyses": {
    "type": "array"
  }
};

export const ClinicalAnalysisSchema = {
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "amount": {
    "type": "number"
  },
  "isDummy": {
    "type": "boolean"
  },
  "materialId": {
    "type": "string"
  },
  "sampleType": {
    "type": "string"
  }
};

export const ClinicalOrderRequirementSchema = {
  "orderId": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "requirement": {
    "id": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    },
    "shortName": {
      "type": "string"
    },
    "externalName": {
      "type": "string"
    },
    "alias": {
      "type": "string"
    },
    "longName": {
      "type": "string"
    },
    "limit": {
      "type": [
        "integer",
        "null"
      ]
    },
    "materialLimitOverrule": {
      "type": [
        "integer",
        "null"
      ]
    },
    "superRequirement": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "subRequirements": {
      "type": "string"
    },
    "infoUrl": {
      "type": [
        "string",
        "null"
      ]
    },
    "additionalText": {
      "type": [
        "string",
        "null"
      ]
    },
    "hint": {
      "type": [
        "string",
        "null"
      ]
    },
    "analysesString": {
      "type": "string"
    },
    "analyses": {
      "type": "array"
    },
    "occurrence": {
      "type": "integer"
    },
    "samples": {
      "type": "array"
    },
    "materials": {
      "type": "array"
    },
    "indicationsString": {
      "type": "array"
    },
    "forms": {
      "type": "array"
    },
    "categories": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "requirementFieldSettings": {
      "type": "array"
    },
    "pinnedByDoctors": {
      "type": "array"
    },
    "topFromDoctors": {
      "type": "array"
    },
    "firstImportDate": {
      "type": "string"
    },
    "laboratoryGroup": {
      "type": "string"
    },
    "submitter": {
      "type": "string"
    },
    "duration": {
      "type": "string"
    },
    "selfDefined": {
      "type": "boolean"
    },
    "deletable": {
      "type": "boolean"
    },
    "blocked": {
      "type": "boolean"
    },
    "shortNameEditable": {
      "type": "boolean"
    },
    "ebmPrice": {
      "type": "number"
    },
    "priority": {
      "type": [
        "integer",
        "null"
      ]
    },
    "lisExport": {
      "type": "boolean"
    },
    "isCharged": {
      "type": "boolean"
    },
    "isEbmCharged": {
      "type": "boolean"
    },
    "freeTextAllowed": {
      "type": "boolean"
    },
    "freeTextMandatory": {
      "type": "boolean"
    },
    "freeTextAsDate": {
      "type": "boolean"
    },
    "freeTextKey": {
      "type": [
        "integer",
        "null"
      ]
    },
    "freeTextPreappendValue": {
      "type": [
        "string",
        "null"
      ]
    },
    "examinationDuration": {
      "type": [
        "integer",
        "null"
      ]
    },
    "examinationDurationInterval": {},
    "priceGroup1": {
      "type": "number"
    },
    "maxPriceGroup1": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup1": {
      "type": "string"
    },
    "priceGroup2": {
      "type": "number"
    },
    "maxPriceGroup2": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup2": {
      "type": "string"
    },
    "priceGroup3": {
      "type": "number"
    },
    "maxPriceGroup3": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup3": {
      "type": "string"
    },
    "priceGroup4": {
      "type": "number"
    },
    "maxPriceGroup4": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup4": {
      "type": "string"
    },
    "priceGroup5": {
      "type": "number"
    },
    "maxPriceGroup5": {
      "type": [
        "number",
        "null"
      ]
    },
    "maxPriceCommentGroup5": {
      "type": "string"
    }
  },
  "selectedDiagnoses": {
    "type": "array"
  },
  "profileId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "profileType": {},
  "profile": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "order": {
      "type": "integer"
    },
    "costUnit": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "color": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "diagnosis": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "freeText": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "isMicrobiological": {
      "type": "boolean"
    },
    "orderReason": {
      "maxLength": 1,
      "type": "string"
    },
    "selectedDiagnoses": {
      "type": "object"
    }
  },
  "shortName": {
    "type": "string"
  },
  "externalName": {
    "maxLength": 100,
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "price": {
    "type": [
      "string",
      "null"
    ]
  },
  "isCharged": {
    "type": "boolean"
  },
  "invoiceTo": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "laboratoryGroup": {
    "maxLength": 20,
    "type": "string"
  },
  "freeText": {
    "maxLength": 100,
    "type": "string"
  },
  "freeTextDate": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextAllowed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextMandatory": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextAsDate": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "byRealReRequest": {
    "type": "boolean"
  },
  "dynamicMaterials": {
    "type": "array"
  },
  "cancelled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "missingAggregation": {
    "type": "boolean"
  }
};

export const RequirementAdministrativePropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "externalName": {
    "type": "string"
  },
  "duration": {
    "type": "string"
  },
  "alias": {
    "type": "string"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "priority": {
    "type": [
      "integer",
      "null"
    ]
  },
  "lisExport": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "isEbmCharged": {
    "type": "boolean"
  },
  "freeTextAllowed": {
    "type": "boolean"
  },
  "freeTextMandatory": {
    "type": "boolean"
  },
  "freeTextAsDate": {
    "type": "boolean"
  },
  "freeTextKey": {
    "type": [
      "integer",
      "null"
    ]
  },
  "freeTextPreappendValue": {
    "type": [
      "string",
      "null"
    ]
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "selfDefined": {
    "type": "boolean"
  },
  "shortNameEditable": {
    "type": "boolean"
  },
  "deletable": {
    "type": "boolean"
  },
  "priceGroup1": {
    "type": "number"
  },
  "priceGroup2": {
    "type": "number"
  },
  "priceGroup3": {
    "type": "number"
  },
  "priceGroup4": {
    "type": "number"
  },
  "priceGroup5": {
    "type": "number"
  },
  "maxPriceGroup1": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup2": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup3": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup4": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup5": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup1": {
    "type": "string"
  },
  "maxPriceCommentGroup2": {
    "type": "string"
  },
  "maxPriceCommentGroup3": {
    "type": "string"
  },
  "maxPriceCommentGroup4": {
    "type": "string"
  },
  "maxPriceCommentGroup5": {
    "type": "string"
  },
  "ebmPrice": {
    "type": "number"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "requirementFieldSettings": {
    "type": "array"
  },
  "examinationDuration": {
    "type": [
      "integer",
      "null"
    ]
  },
  "examinationDurationInterval": {}
};

export const OrderAdminRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "externalName": {
    "type": "string"
  },
  "duration": {
    "type": "string"
  },
  "alias": {
    "type": "string"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "priority": {
    "type": [
      "integer",
      "null"
    ]
  },
  "lisExport": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "isEbmCharged": {
    "type": "boolean"
  },
  "freeTextAllowed": {
    "type": "boolean"
  },
  "freeTextMandatory": {
    "type": "boolean"
  },
  "freeTextAsDate": {
    "type": "boolean"
  },
  "freeTextKey": {
    "type": [
      "integer",
      "null"
    ]
  },
  "freeTextPreappendValue": {
    "type": [
      "string",
      "null"
    ]
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "selfDefined": {
    "type": "boolean"
  },
  "shortNameEditable": {
    "type": "boolean"
  },
  "deletable": {
    "type": "boolean"
  },
  "priceGroup1": {
    "type": "number"
  },
  "priceGroup2": {
    "type": "number"
  },
  "priceGroup3": {
    "type": "number"
  },
  "priceGroup4": {
    "type": "number"
  },
  "priceGroup5": {
    "type": "number"
  },
  "maxPriceGroup1": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup2": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup3": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup4": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceGroup5": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup1": {
    "type": "string"
  },
  "maxPriceCommentGroup2": {
    "type": "string"
  },
  "maxPriceCommentGroup3": {
    "type": "string"
  },
  "maxPriceCommentGroup4": {
    "type": "string"
  },
  "maxPriceCommentGroup5": {
    "type": "string"
  },
  "ebmPrice": {
    "type": "number"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "requirementFieldSettings": {
    "type": "array"
  },
  "examinationDuration": {
    "type": [
      "integer",
      "null"
    ]
  },
  "examinationDurationInterval": {}
};

export const ClinicalRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "externalName": {
    "type": "string"
  },
  "alias": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "materialLimitOverrule": {
    "type": [
      "integer",
      "null"
    ]
  },
  "superRequirement": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "subRequirements": {
    "type": "string"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "analysesString": {
    "type": "string"
  },
  "analyses": {
    "type": "array"
  },
  "occurrence": {
    "type": "integer"
  },
  "samples": {
    "type": "array"
  },
  "materials": {
    "type": "array"
  },
  "indicationsString": {
    "type": "array"
  },
  "forms": {
    "type": "array"
  },
  "categories": {
    "type": "array"
  },
  "profiles": {
    "type": "array"
  },
  "globalProfiles": {
    "type": "array"
  },
  "requirementFieldSettings": {
    "type": "array"
  },
  "pinnedByDoctors": {
    "type": "array"
  },
  "topFromDoctors": {
    "type": "array"
  },
  "firstImportDate": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "submitter": {
    "type": "string"
  },
  "duration": {
    "type": "string"
  },
  "selfDefined": {
    "type": "boolean"
  },
  "deletable": {
    "type": "boolean"
  },
  "blocked": {
    "type": "boolean"
  },
  "shortNameEditable": {
    "type": "boolean"
  },
  "ebmPrice": {
    "type": "number"
  },
  "priority": {
    "type": [
      "integer",
      "null"
    ]
  },
  "lisExport": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "isEbmCharged": {
    "type": "boolean"
  },
  "freeTextAllowed": {
    "type": "boolean"
  },
  "freeTextMandatory": {
    "type": "boolean"
  },
  "freeTextAsDate": {
    "type": "boolean"
  },
  "freeTextKey": {
    "type": [
      "integer",
      "null"
    ]
  },
  "freeTextPreappendValue": {
    "type": [
      "string",
      "null"
    ]
  },
  "examinationDuration": {
    "type": [
      "integer",
      "null"
    ]
  },
  "examinationDurationInterval": {},
  "priceGroup1": {
    "type": "number"
  },
  "maxPriceGroup1": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup1": {
    "type": "string"
  },
  "priceGroup2": {
    "type": "number"
  },
  "maxPriceGroup2": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup2": {
    "type": "string"
  },
  "priceGroup3": {
    "type": "number"
  },
  "maxPriceGroup3": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup3": {
    "type": "string"
  },
  "priceGroup4": {
    "type": "number"
  },
  "maxPriceGroup4": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup4": {
    "type": "string"
  },
  "priceGroup5": {
    "type": "number"
  },
  "maxPriceGroup5": {
    "type": [
      "number",
      "null"
    ]
  },
  "maxPriceCommentGroup5": {
    "type": "string"
  }
};

export const MicrobiologicalMaterialSchema = {
  "matid": {
    "type": "string"
  },
  "externalMaterialId": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "name": {
    "type": "string"
  },
  "origin": {
    "type": "string"
  },
  "order": {
    "type": "integer"
  },
  "sampleType": {
    "type": "string"
  },
  "barcodeCount": {
    "type": "integer"
  },
  "askIntraoperative": {
    "type": "boolean"
  },
  "askLeftRight": {
    "type": "boolean"
  },
  "askOrigin": {
    "type": "integer"
  },
  "analyses": {
    "type": "array"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "profiles": {
    "type": "array"
  },
  "globalProfiles": {
    "type": "array"
  },
  "localizations": {
    "type": "array"
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "alias": {
    "maxLength": 255,
    "type": [
      "string",
      "null"
    ]
  }
};

export const MicrobiologicalAnalysisSchema = {
  "asid": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "externalId": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "order": {
    "type": "integer"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  }
};

export const MicrobiologicalOrderRequirementSchema = {
  "orderId": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "materialId": {
    "type": "string"
  },
  "profileId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "profileType": {},
  "leftRight": {},
  "origin": {
    "type": "string"
  },
  "intraoperative": {
    "type": "boolean"
  },
  "selectedAnalyses": {
    "type": "array"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "externalId": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "externalMaterialId": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "material": {
    "type": "string"
  },
  "herkunft": {
    "type": "string"
  },
  "sampleType": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  },
  "missingAggregation": {
    "type": "boolean"
  },
  "requirement": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "asid": {
      "type": "integer"
    },
    "matid": {
      "type": "string"
    },
    "pinnedByDoctors": {
      "type": "array"
    },
    "occurrence": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    }
  },
  "invoiceTo": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "cancelled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "selectedLocalizations": {
    "type": "array"
  },
  "dynamicMaterials": {
    "type": "array"
  }
};

export const MicrobiologicalRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "asid": {
    "type": "integer"
  },
  "matid": {
    "type": "string"
  },
  "pinnedByDoctors": {
    "type": "array"
  },
  "occurrence": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  }
};

export const MicrobiologicalLocalizationSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "lokid": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "kuerzel": {
    "type": "string"
  },
  "bereich": {
    "type": "string"
  },
  "name1": {
    "type": "string"
  },
  "name2": {
    "type": "string"
  },
  "sort": {
    "type": "number"
  },
  "level": {
    "type": "number"
  },
  "exportId": {
    "type": "string"
  }
};

export const MicrobiologicalTextSchema = {
  "lid": {
    "type": "integer"
  },
  "matid": {
    "type": "string"
  },
  "untid": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "type": {}
};

export const RequirementRulesSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "requirementId": {
    "maxLength": 100,
    "type": "string"
  },
  "excReqs": {
    "maxLength": 400,
    "type": "string"
  },
  "reqReqs": {
    "maxLength": 400,
    "type": "string"
  },
  "timeFrameMax": {
    "maxLength": 3,
    "type": "string"
  },
  "timeFrameUnit": {},
  "timeFrameCount": {
    "maxLength": 3,
    "type": "string"
  },
  "infoText": {
    "maxLength": 500,
    "type": "string"
  },
  "formId": {
    "type": "integer"
  },
  "insteadReqs": {
    "maxLength": 400,
    "type": "string"
  },
  "materialMandatory": {
    "type": "boolean"
  },
  "zuseitdruck": {
    "type": "integer"
  },
  "diagnosisMandatory": {
    "type": "boolean"
  },
  "confirmationMandatory": {
    "type": "boolean"
  },
  "ein": {
    "maxLength": 50,
    "type": "string"
  },
  "additionalPageID": {
    "type": "integer"
  },
  "patGender": {},
  "patAgeOp": {
    "maxLength": 3,
    "type": "string"
  },
  "patAge": {
    "maxLength": 3,
    "type": "string"
  },
  "pregnancyWeekMandatory": {
    "type": "boolean"
  },
  "patHeightMandatory": {
    "type": "boolean"
  },
  "patWeightMandatory": {
    "type": "boolean"
  },
  "customFields": {
    "maxLength": 400,
    "type": "string"
  },
  "dzOrderReasons": {
    "maxLength": 100,
    "type": "string"
  },
  "sampleAmountMandatory": {
    "type": "boolean"
  },
  "samplePeriodMandatory": {
    "type": "boolean"
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "printQuickReportBarcodeCovid": {
    "type": "boolean"
  }
};

export const RequirementBlacklistSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  }
};

export const RequirementWhitelistSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  }
};

export const DiagnosisSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "onlyAdultFemale": {
    "type": "boolean"
  }
};

export const OrderSampleSchema = {
  "sample_id": {
    "type": "integer"
  },
  "count": {
    "type": "number"
  },
  "originalCount": {
    "type": [
      "number",
      "null"
    ]
  },
  "storedCount": {
    "type": "number"
  },
  "dynamic": {
    "type": "boolean"
  },
  "originalSampleType": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "reprinted": {
    "type": "boolean"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "imageLarge": {
    "type": "string"
  },
  "imageSmall": {
    "type": "string"
  },
  "unit": {
    "maxLength": 10,
    "type": "string"
  },
  "quantity": {
    "type": "integer"
  },
  "sortingOrder": {
    "type": "integer"
  },
  "laboratoryGroupShortCode": {
    "maxLength": 50,
    "type": "string"
  },
  "sampleType": {
    "maxLength": 20,
    "type": "string"
  },
  "sampleTypeLabel": {
    "maxLength": 4,
    "type": "string"
  },
  "requirements": {
    "type": "array"
  },
  "comment": {
    "type": "string"
  },
  "notice": {
    "type": "string"
  }
};

export const SampleSchema = {
  "id": {
    "type": "integer"
  },
  "manufacturer": {
    "maxLength": 100,
    "type": "string"
  },
  "laboratoryGroup": {
    "grpid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "kurz": {
      "maxLength": 20,
      "pattern": "^(?![Ll][Aa]$).*$",
      "type": "string"
    },
    "name": {
      "maxLength": 200,
      "type": "string"
    },
    "telefon": {
      "maxLength": 20,
      "type": "string"
    },
    "fax": {
      "maxLength": 20,
      "type": "string"
    },
    "website": {
      "maxLength": 50,
      "type": "string"
    },
    "adresse": {
      "maxLength": 50,
      "type": "string"
    },
    "stadt": {
      "maxLength": 50,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": "string"
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": "string"
    },
    "zusatz1": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz2": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz3": {
      "maxLength": 200,
      "type": "string"
    },
    "plz": {
      "maxLength": 25,
      "type": "string"
    },
    "showPdfExport": {
      "type": "boolean"
    },
    "laboratoryGroupBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "printPosition": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMin": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMax": {
        "maxLength": 40,
        "type": "string"
      }
    },
    "advancedAlphanumericBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPosition1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax1": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax2": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax3": {
        "maxLength": 3,
        "type": "string"
      },
      "useState": {}
    }
  },
  "comment": {
    "maxLength": 50,
    "type": "string"
  },
  "notice": {
    "maxLength": 50,
    "type": "string"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "imageLarge": {
    "type": "string"
  },
  "imageSmall": {
    "type": "string"
  },
  "unit": {
    "maxLength": 10,
    "type": "string"
  },
  "quantity": {
    "type": "integer"
  },
  "sortingOrder": {
    "type": "integer"
  },
  "laboratoryGroupShortCode": {
    "maxLength": 50,
    "type": "string"
  },
  "sampleType": {
    "maxLength": 20,
    "type": "string"
  },
  "sampleTypeLabel": {
    "maxLength": 4,
    "type": "string"
  },
  "imageLargeFile": {
    "type": [
      "object",
      "null"
    ]
  },
  "imageSmallFile": {
    "type": [
      "object",
      "null"
    ]
  },
  "substituteSampleTypes": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderAustriaFieldsSchema = {
  "title": {
    "maxLength": 4,
    "type": "string"
  },
  "firstName": {
    "maxLength": 4,
    "type": "string"
  },
  "lastName": {
    "maxLength": 4,
    "type": "string"
  },
  "birthday": {
    "maxLength": 4,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 4,
    "type": "string"
  },
  "country": {
    "maxLength": 4,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 4,
    "type": "string"
  },
  "city": {
    "maxLength": 4,
    "type": "string"
  },
  "street": {
    "maxLength": 4,
    "type": "string"
  },
  "houseNumber": {
    "maxLength": 4,
    "type": "string"
  },
  "addressAdditional": {
    "maxLength": 4,
    "type": "string"
  },
  "employer": {
    "maxLength": 4,
    "type": "string"
  },
  "employerPlace": {
    "maxLength": 4,
    "type": "string"
  },
  "chargeType": {
    "maxLength": 4,
    "type": "string"
  }
};

export const OrderExportSettingsSchema = {
  "path": {
    "type": "string"
  },
  "delay": {
    "type": "number"
  },
  "shortCodeMode": {},
  "shortCodeOAAnswerMode": {},
  "exportTrigger": {},
  "ch_bags": {
    "type": "object"
  }
};

export const OrderFreeTextFieldsSchema = {
  "fieldId": {
    "maxLength": 4,
    "type": "string"
  },
  "preValue": {
    "maxLength": 100,
    "type": "string"
  },
  "defaultRequest": {
    "type": "string"
  }
};

export const OrderFurtherFieldsSchema = {
  "patientPhone": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "patientEmail": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "crownRumpLength": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "nuchalTranslucency": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "cycleLength": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "anamnesticInfo": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "bmi": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "cycleDay": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  }
};

export const OrderMicrobiologyFieldsSchema = {
  "leftRight": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "operative": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "notes": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "examination": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "examinationId": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "examinationText": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "material": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "materialId": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "materialText": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "origin": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "originText": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  },
  "originSite": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    }
  }
};

export const OrderOptionalFieldsSchema = {
  "fieldId": {
    "maxLength": 4,
    "type": "string"
  },
  "export": {
    "type": "boolean"
  }
};

export const CentralBarcodeSchema = {
  "cbid": {
    "type": "integer"
  },
  "orderId": {
    "type": "number"
  },
  "barcode": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  }
};

export const OrderCostUnitMandatoryFieldsSchema = {
  "id": {
    "type": "integer"
  },
  "costUnit": {
    "type": "string"
  },
  "formType": {},
  "orderFormID": {
    "type": "integer"
  }
};

export const PrinterMappingSchema = {
  "aid": {
    "type": "integer"
  },
  "alias": {
    "maxLength": 255,
    "type": "string"
  },
  "override_printer_name": {
    "maxLength": 255,
    "type": "string"
  },
  "override_barcode_printer_name": {
    "maxLength": 255,
    "type": "string"
  },
  "is_default": {
    "type": "boolean"
  }
};

export const FormLabelPrinterMappingSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "formId": {
    "type": "integer"
  },
  "workstationId": {
    "type": "integer"
  },
  "printerName": {
    "type": "string"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  }
};

export const TransportationHistorySchema = {
  "tid": {
    "type": "integer"
  },
  "created_at": {
    "type": "string"
  }
};

export const PatientGDTSchema = {
  "apgid": {
    "type": "integer"
  },
  "lastName": {
    "type": "string"
  },
  "firstName": {
    "type": "string"
  },
  "title": {
    "maxLength": 100,
    "type": "string"
  },
  "prefix": {
    "maxLength": 50,
    "type": "string"
  },
  "suffix": {
    "maxLength": 50,
    "type": "string"
  },
  "displayName": {
    "type": "string"
  },
  "address": {
    "maxLength": 100,
    "type": "string"
  },
  "houseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "addressAdditional": {
    "maxLength": 100,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 100,
    "type": "string"
  },
  "city": {
    "maxLength": 100,
    "type": "string"
  },
  "country": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "birthday": {
    "maxLength": 100,
    "type": "string"
  },
  "gender": {},
  "phone": {
    "maxLength": 100,
    "type": "string"
  },
  "email": {
    "maxLength": 255,
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "costUnit": {
    "maxLength": 100,
    "type": "string"
  },
  "height": {
    "maxLength": 5,
    "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
    "type": "string"
  },
  "pregnancyWeek": {
    "maxLength": 5,
    "type": "string"
  },
  "pregnancyWeekDay": {
    "type": "string"
  },
  "orderReason": {},
  "costUnitIdentification": {
    "maxLength": 100,
    "type": "string"
  },
  "medication": {
    "maxLength": 100,
    "type": "string"
  },
  "cycleDay": {
    "maxLength": 10,
    "type": "string"
  },
  "weight": {
    "maxLength": 5,
    "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
    "type": "string"
  },
  "diagnosis": {
    "maxLength": 150,
    "type": "string"
  },
  "exttnr": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "primaryCarePhysicianLanr": {
    "maxLength": 20,
    "type": "string"
  },
  "primaryCarePhysicianBsnr": {
    "maxLength": 50,
    "type": "string"
  },
  "diseaseManagementProgram": {
    "maxLength": 50,
    "type": "string"
  },
  "dmpSpecialGroup": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceStart": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceEnd": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceType": {
    "maxLength": 50,
    "type": "string"
  },
  "exceptionalIndication": {
    "maxLength": 20,
    "type": "string"
  },
  "sktIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "wopIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "accountingArea": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "hvcode": {
    "maxLength": 20,
    "type": "string"
  },
  "vkat": {
    "maxLength": 20,
    "type": "string"
  },
  "secondName": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "canton": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "ahvNr": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "aisNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "insurance": {
    "type": [
      "number",
      "null"
    ]
  },
  "bag": {
    "maxLength": 5,
    "type": [
      "string",
      "null"
    ]
  },
  "cardNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "patientIdentification": {
    "maxLength": 20,
    "type": "string"
  },
  "globalLocationNumber": {
    "maxLength": 13,
    "type": [
      "string",
      "null"
    ]
  },
  "chTreatmentReason": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "hospitalBedNumber": {
    "type": "string"
  },
  "hospitalRoomNumber": {
    "type": "string"
  },
  "hospitalProfession": {
    "type": [
      "string",
      "null"
    ]
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "caseNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 100,
    "type": "string"
  },
  "invoiceTo": {
    "maxLength": 25,
    "type": "string"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "autInsuranceTitle": {
    "maxLength": 100,
    "type": "string"
  },
  "autInsuranceFirstName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceLastName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceInsuranceNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceBirthday": {
    "type": "string"
  },
  "autInsuranceAddress": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceHouseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCountry": {
    "maxLength": 10,
    "type": "string"
  },
  "autInsuranceZip": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCity": {
    "maxLength": 50,
    "type": "string"
  },
  "caseEntity": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "pid": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "diagnosis": {
      "type": "string"
    },
    "room": {
      "type": "string"
    },
    "status": {},
    "msh3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "msh4": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "created_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "patient": {
      "pid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 200,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 100,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 50,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "phone": {
        "maxLength": 50,
        "type": "string"
      },
      "costUnit": {
        "type": "string"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "sonstiges1": {
        "maxLength": 50,
        "type": "string"
      },
      "sonstiges2": {
        "maxLength": 50,
        "type": "string"
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "smsNotificationEnabled": {
        "type": "boolean"
      },
      "smsNumber": {
        "type": "string"
      },
      "canViewPartialResults": {
        "type": "boolean"
      },
      "canViewPreliminaryResults": {
        "type": "boolean"
      },
      "resultLanguage": {
        "maxLength": 5,
        "type": "string"
      },
      "oa": {
        "type": "boolean"
      },
      "aids": {
        "type": "array"
      },
      "latest_report_date": {
        "type": "string"
      },
      "dateScore": {
        "type": "number"
      },
      "passportNumber": {
        "type": "string"
      },
      "idCardNumber": {
        "type": "string"
      }
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "events": {
      "type": "array"
    },
    "reports": {
      "type": "array"
    },
    "orders": {
      "type": "array"
    }
  }
};

export const CustomFieldFormSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "orderFormID": {
    "type": "integer"
  },
  "formCustomFieldID": {
    "type": "integer"
  },
  "reqCustomFieldID": {
    "type": "integer"
  }
};

export const CustomFieldsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "customFieldName": {
    "maxLength": 100,
    "type": "string"
  },
  "customFieldType": {},
  "ldtCode": {
    "maxLength": 100,
    "type": "string"
  },
  "defaultText": {
    "type": "string"
  },
  "useState": {
    "maxLength": 10,
    "type": "string"
  }
};

export const CustomFieldValuesSchema = {
  "id": {
    "type": "integer"
  },
  "orderID": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "customFieldID": {
    "type": "integer"
  },
  "value": {
    "maxLength": 250,
    "type": "string"
  },
  "origin": {
    "maxLength": 2,
    "type": "string"
  },
  "orderFormID": {
    "type": "integer"
  }
};

export const OrderFormSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "array"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 20,
    "type": "string"
  },
  "costUnit": {
    "maxLength": 20,
    "type": "string"
  },
  "invoiceToChangeable": {
    "type": "boolean"
  },
  "defaultInvoiceTo": {},
  "isPrivate": {
    "type": "boolean"
  },
  "isDynamicMaterial": {
    "type": "boolean"
  },
  "isPoct": {
    "type": "boolean"
  },
  "isDefault": {
    "type": "boolean"
  },
  "isFilterHidden": {
    "type": "boolean"
  },
  "formNote": {
    "maxLength": 150,
    "type": "string"
  },
  "isGraphic": {
    "type": "boolean"
  },
  "barcodeComment": {
    "maxLength": 15,
    "type": "string"
  },
  "exportInfo": {
    "maxLength": 150,
    "type": "string"
  },
  "isBak": {
    "type": "boolean"
  },
  "satzart": {
    "maxLength": 4,
    "type": "string"
  },
  "directscheindruckmode": {
    "type": "integer"
  },
  "lastChanged": {
    "maxLength": 14,
    "type": "string"
  },
  "isPool": {
    "type": "boolean"
  },
  "maximumRequestCount": {
    "type": [
      "integer",
      "null"
    ]
  },
  "datamatrix": {
    "maxLength": 20,
    "type": "string"
  },
  "splitMode": {},
  "basePrice": {
    "type": "string"
  },
  "hasDynProbeType": {
    "type": "boolean"
  },
  "isDeleted": {
    "type": "boolean"
  },
  "priceFactor": {
    "type": "string"
  },
  "formGroupID": {
    "type": [
      "integer",
      "null"
    ]
  },
  "bcPrefix": {
    "maxLength": 10,
    "type": "string"
  },
  "exportAsFile": {
    "maxLength": 10,
    "type": "string"
  },
  "digitalSignatureMode": {},
  "exportType": {
    "type": "integer"
  },
  "laboratoryGroupShortCode": {
    "maxLength": 50,
    "type": "string"
  },
  "zplID": {
    "type": [
      "integer",
      "null"
    ]
  },
  "formType": {},
  "laboratoryGroup": {
    "grpid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "kurz": {
      "maxLength": 20,
      "pattern": "^(?![Ll][Aa]$).*$",
      "type": "string"
    },
    "name": {
      "maxLength": 200,
      "type": "string"
    },
    "telefon": {
      "maxLength": 20,
      "type": "string"
    },
    "fax": {
      "maxLength": 20,
      "type": "string"
    },
    "website": {
      "maxLength": 50,
      "type": "string"
    },
    "adresse": {
      "maxLength": 50,
      "type": "string"
    },
    "stadt": {
      "maxLength": 50,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": "string"
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": "string"
    },
    "zusatz1": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz2": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz3": {
      "maxLength": 200,
      "type": "string"
    },
    "plz": {
      "maxLength": 25,
      "type": "string"
    },
    "showPdfExport": {
      "type": "boolean"
    },
    "laboratoryGroupBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "printPosition": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMin": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMax": {
        "maxLength": 40,
        "type": "string"
      }
    },
    "advancedAlphanumericBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPosition1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax1": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax2": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax3": {
        "maxLength": 3,
        "type": "string"
      },
      "useState": {}
    }
  },
  "requirements": {
    "type": "array"
  }
};

export const OrderFormGroupSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "orderFormGroupName": {
    "maxLength": 150,
    "type": "string"
  },
  "export": {
    "maxLength": 150,
    "type": "string"
  },
  "comment": {
    "maxLength": 10,
    "type": "string"
  },
  "sids": {
    "maxLength": 250,
    "type": "string"
  }
};

export const OrderFormPageSchema = {
  "id": {
    "type": "integer"
  },
  "orderFormID": {
    "type": "integer"
  },
  "img": {
    "maxLength": 255,
    "type": "string"
  },
  "orientation": {
    "type": "integer"
  },
  "height": {
    "type": "integer"
  },
  "width": {
    "type": "integer"
  },
  "pageNumber": {
    "type": "integer"
  },
  "mode": {
    "type": "integer"
  },
  "isAdditionalPage": {
    "type": "boolean"
  },
  "pageName": {
    "maxLength": 32,
    "type": "string"
  },
  "digitalModel": {
    "type": "string"
  },
  "imageID": {
    "type": "integer"
  }
};

export const OrderFormRequirementSchema = {
  "requirementId": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "counter": {
    "type": "integer"
  },
  "price": {
    "type": "number"
  }
};

export const OrderRuleSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "group": {
    "type": [
      "string",
      "null"
    ]
  },
  "description": {
    "type": "string"
  },
  "conditions": {
    "type": "array"
  },
  "actions": {
    "type": "array"
  },
  "created_at": {
    "type": "string"
  },
  "autoGenerated": {
    "type": "boolean"
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "printQuickReportBarcodeCovid": {
    "type": "boolean"
  }
};

export const OrderFileSchema = {
  "stoid": {
    "type": "integer"
  },
  "content": {
    "type": "string"
  },
  "filename": {
    "type": "string"
  }
};

export const OrderFileInfoSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "stoid": {
    "type": "integer"
  },
  "filename": {
    "type": "string"
  },
  "importedAt": {
    "type": "string"
  },
  "status": {
    "type": "string"
  },
  "type": {
    "type": "string"
  }
};

export const OrderImageDataSchema = {
  "id": {
    "type": "integer"
  },
  "laboratoryGroup": {
    "grpid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "kurz": {
      "maxLength": 20,
      "pattern": "^(?![Ll][Aa]$).*$",
      "type": "string"
    },
    "name": {
      "maxLength": 200,
      "type": "string"
    },
    "telefon": {
      "maxLength": 20,
      "type": "string"
    },
    "fax": {
      "maxLength": 20,
      "type": "string"
    },
    "website": {
      "maxLength": 50,
      "type": "string"
    },
    "adresse": {
      "maxLength": 50,
      "type": "string"
    },
    "stadt": {
      "maxLength": 50,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": "string"
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": "string"
    },
    "zusatz1": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz2": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz3": {
      "maxLength": 200,
      "type": "string"
    },
    "plz": {
      "maxLength": 25,
      "type": "string"
    },
    "showPdfExport": {
      "type": "boolean"
    },
    "laboratoryGroupBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "printPosition": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMin": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMax": {
        "maxLength": 40,
        "type": "string"
      }
    },
    "advancedAlphanumericBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPosition1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax1": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax2": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax3": {
        "maxLength": 3,
        "type": "string"
      },
      "useState": {}
    }
  },
  "filename": {
    "type": "string"
  },
  "type": {
    "type": "string"
  },
  "size": {
    "type": "integer"
  },
  "width": {
    "type": "number"
  },
  "height": {
    "type": "number"
  },
  "useType": {
    "type": "string"
  },
  "content": {
    "type": [
      "string",
      "null"
    ]
  },
  "thumbnail": {
    "type": [
      "string",
      "null"
    ]
  },
  "lastModified": {
    "type": "string"
  }
};

export const OrderAttachmentSchema = {
  "anid": {
    "type": "integer"
  },
  "orderId": {
    "type": "integer"
  },
  "content": {
    "type": "string"
  },
  "fileName": {
    "type": "string"
  },
  "filePath": {
    "type": "string"
  },
  "fileExtension": {
    "type": "string"
  },
  "thumbnailContent": {
    "type": "string"
  },
  "description": {
    "type": "string"
  }
};

export const GroupFormSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "categories": {
    "type": "array"
  },
  "columns": {
    "type": [
      "integer",
      "null"
    ]
  },
  "order": {
    "type": "integer"
  }
};

export const GroupFormCategorySchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "groupFormId": {
    "type": "integer"
  },
  "order": {
    "type": "number"
  },
  "subCategories": {
    "type": "array"
  }
};

export const GroupFormSubCategorySchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "groupCategoryId": {
    "type": "integer"
  },
  "bgColor": {
    "type": "string"
  },
  "requirements": {
    "type": "array"
  },
  "order": {
    "type": "number"
  },
  "doubleWidth": {
    "type": "boolean"
  },
  "withTitle": {
    "type": "boolean"
  }
};

export const GroupFormRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "subCategoryId": {
    "type": "integer"
  },
  "order": {
    "type": "number"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "mibiRequirement": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "asid": {
      "type": "integer"
    },
    "matid": {
      "type": "string"
    },
    "pinnedByDoctors": {
      "type": "array"
    },
    "occurrence": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    }
  },
  "selectedAnalyses": {
    "type": "array"
  }
};

export const GroupFormOrderSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "groupFormId": {
    "type": "integer"
  },
  "order": {
    "type": "number"
  }
};

export const OrderAustriaHvCodeSchema = {
  "id": {
    "type": "integer"
  },
  "code": {
    "maxLength": 32,
    "type": "string"
  },
  "costUnit": {
    "maxLength": 32,
    "type": "string"
  },
  "title": {
    "maxLength": 100,
    "type": "string"
  }
};

export const OrderAustriaInsuranceCategorySchema = {
  "id": {
    "type": "integer"
  },
  "code": {
    "maxLength": 32,
    "type": "string"
  },
  "title": {
    "maxLength": 100,
    "type": "string"
  }
};

export const OrderCoronaWarnAppAssignmentSchema = {
  "orderId": {
    "type": "integer"
  },
  "reportId": {
    "type": "integer"
  },
  "guid": {
    "type": "string"
  },
  "result": {
    "type": "boolean"
  },
  "submitted": {
    "type": "boolean"
  },
  "submitted_at": {
    "type": "string"
  },
  "salt": {
    "maxLength": 32,
    "type": "string"
  },
  "prefix": {
    "maxLength": 6,
    "type": "string"
  },
  "cwaHash": {
    "type": "string"
  },
  "dccCompleted": {
    "type": "boolean"
  },
  "testIdHash": {
    "type": "string"
  }
};

export const ReRequestHistorySchema = {
  "id": {
    "type": "integer"
  },
  "openedAt": {
    "type": "string"
  },
  "reRequestInfo": {
    "type": "string"
  },
  "requirementInfo": {
    "type": "string"
  },
  "closedAt": {
    "type": "string"
  },
  "appliedAt": {
    "type": "string"
  },
  "order": {
    "lid": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "freeText": {
      "maxLength": 60,
      "type": "string"
    },
    "message": {
      "maxLength": 100,
      "type": "string"
    },
    "infectious": {
      "type": "boolean"
    },
    "urgent": {
      "type": "string"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "costUnit": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "controlCheckupKnownInfection": {
      "type": "boolean"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "dz16b": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    },
    "accidentTime": {
      "maxLength": 20,
      "type": "string"
    },
    "documentReferenceNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "anamnesticInfo": {
      "maxLength": 60,
      "type": "string"
    },
    "printedMaterials": {
      "type": [
        "string",
        "null"
      ]
    },
    "printQuickReportBarcode": {
      "type": "boolean"
    },
    "urgentNotificationPhone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationFax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationMail": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "id": {
      "type": "integer"
    },
    "status": {},
    "executionStatus": {},
    "poolId": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "type": "string"
    },
    "bsnr": {
      "type": "string"
    },
    "selectedDoctor": {
      "type": "string"
    },
    "apid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "patient": {
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 50,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 150,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "costUnit": {
        "type": "string"
      },
      "medication": {
        "maxLength": 100,
        "type": "string"
      },
      "cycleDay": {
        "maxLength": 10,
        "type": "string"
      },
      "weight": {
        "maxLength": 5,
        "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 150,
        "type": "string"
      },
      "exttnr": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "primaryCarePhysicianLanr": {
        "maxLength": 20,
        "type": "string"
      },
      "primaryCarePhysicianBsnr": {
        "maxLength": 20,
        "type": "string"
      },
      "diseaseManagementProgram": {
        "maxLength": 50,
        "type": "string"
      },
      "dmpSpecialGroup": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceStart": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceEnd": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceType": {
        "maxLength": 50,
        "type": "string"
      },
      "exceptionalIndication": {
        "maxLength": 20,
        "type": "string"
      },
      "sktIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "wopIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "accountingArea": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "hvcode": {
        "maxLength": 20,
        "type": "string"
      },
      "vkat": {
        "maxLength": 20,
        "type": "string"
      },
      "secondName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "canton": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "ahvNr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "aisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "insurance": {
        "type": [
          "number",
          "null"
        ]
      },
      "bag": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "cardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "globalLocationNumber": {
        "maxLength": 13,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "hospitalBedNumber": {
        "type": "string"
      },
      "hospitalRoomNumber": {
        "type": "string"
      },
      "hospitalProfession": {
        "type": [
          "string",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "invoiceTo": {
        "maxLength": 25,
        "type": "string"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "autInsuranceTitle": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceFirstName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceLastName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceInsuranceNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceBirthday": {
        "type": "string"
      },
      "autInsuranceAddress": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceHouseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCountry": {
        "maxLength": 10,
        "type": "string"
      },
      "autInsuranceZip": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCity": {
        "maxLength": 50,
        "type": "string"
      },
      "pid": {
        "type": "integer"
      },
      "apgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "displayName": {
        "type": "string"
      },
      "prefix": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 5,
        "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "pregnancyWeek": {
        "maxLength": 2,
        "pattern": "^(\\d{1,2})?$",
        "type": "string"
      },
      "pregnancyWeekDay": {
        "maxLength": 1,
        "pattern": "^(\\d{1})?$",
        "type": "string"
      },
      "cycleLength": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      },
      "dayOfCycle": {
        "maxLength": 2,
        "type": "string"
      },
      "fetalCrownRumpLength": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "fetalNuchalTranslucency": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "hasInsuranceNumber": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "sampleAmount": {
        "maxLength": 10,
        "type": "string"
      },
      "samplePeriod": {
        "maxLength": 10,
        "type": "string"
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "passportNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "idcardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "orderReason": {
        "maxLength": 1,
        "type": "string"
      },
      "costUnitIdentification": {
        "maxLength": 25,
        "type": "string"
      },
      "autInsuranceEmployer": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceEmployerCity": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceAddressAdditional": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "requirements": {
      "type": "array"
    },
    "anamnesis": {
      "type": "array"
    },
    "animal": {
      "name": {
        "maxLength": 50,
        "type": "string"
      },
      "birthday": {
        "maxLength": 8,
        "type": "string"
      },
      "species": {
        "maxLength": 2,
        "type": "string"
      },
      "speciesManual": {
        "maxLength": 50,
        "type": "string"
      },
      "chipId": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 3,
        "type": "string"
      },
      "weight": {
        "maxLength": 3,
        "type": "string"
      },
      "sex": {
        "type": "number"
      },
      "breed": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerName": {
        "maxLength": 100,
        "type": "string"
      },
      "ownerAmendment": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerStreet": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerHouseNumber": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerZipCode": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerCity": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "hungary": {
      "motherLastName": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "motherFirstName": {
        "maxLength": 15,
        "type": [
          "string",
          "null"
        ]
      },
      "treatmentReason": {
        "maxLength": 1,
        "type": [
          "string",
          "null"
        ]
      },
      "invoice": {
        "maxLength": 2,
        "type": [
          "string",
          "null"
        ]
      },
      "icd1": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd2": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd3": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "insuranceNumber": {
        "maxLength": 18,
        "type": [
          "string",
          "null"
        ]
      },
      "citizen": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "executed_at": {
      "type": "string"
    },
    "scheduled_at": {
      "type": [
        "string",
        "null"
      ]
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "covid": {
      "testNumber": {},
      "testReason": {},
      "agreed": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "riskCharacteristicsLocation": {},
      "riskCharacteristicsLocationType": {},
      "legalReason": {},
      "healthServiceZipCode": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "healthService": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "legalOrderBasis": {},
      "kvSpecial": {
        "type": [
          "string",
          "null"
        ]
      },
      "confirmationDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "variantDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "oegdInvoiceTo": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "covidWarnAppBarcode": {
        "type": [
          "boolean",
          "null"
        ]
      }
    },
    "mustValidate": {
      "type": "boolean"
    },
    "hospitalStation": {
      "type": "string"
    },
    "requirementDiagnosisFreeText": {
      "maxLength": 150,
      "type": "string"
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "selectedDiagnoses": {
      "type": "object"
    },
    "selectedDiagnosesChecked": {
      "type": "boolean"
    },
    "workstationChecked": {
      "type": "boolean"
    },
    "splitProperties": {
      "type": "array"
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "clientname": {
      "type": "string"
    },
    "printerAliasName": {
      "type": "string"
    },
    "overridePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "overrideBarcodePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "beforeAfterDialysis": {},
    "reportCopyAid": {
      "type": [
        "number",
        "null"
      ]
    },
    "reportCopyDoctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aisIdentifier": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "patientAisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "bloodCollectionType": {},
    "transported_at": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "digitalSignature": {},
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "report": {
      "bid": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "parent_bid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "status": {},
      "tnr": {
        "type": "string"
      },
      "pathological": {},
      "mibi": {},
      "created_at": {
        "type": "string"
      },
      "imported_at": {
        "type": "string"
      },
      "registered_at": {
        "type": "string"
      },
      "validated_at": {
        "type": "string"
      },
      "sampled_at": {
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctor": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctorAddition": {
        "maxLength": 100,
        "type": "string"
      },
      "patientName": {
        "type": "string"
      },
      "is_locked": {
        "type": "boolean"
      },
      "is_unread": {
        "type": "boolean"
      },
      "is_pinned": {
        "type": "boolean"
      },
      "has_base64": {
        "type": "boolean"
      },
      "has_elpho": {
        "type": "boolean"
      },
      "has_subreports": {
        "type": "boolean"
      },
      "has_history": {
        "type": "boolean"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "patient": {
        "pid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 200,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 100,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 50,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "phone": {
          "maxLength": 50,
          "type": "string"
        },
        "costUnit": {
          "type": "string"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "sonstiges1": {
          "maxLength": 50,
          "type": "string"
        },
        "sonstiges2": {
          "maxLength": 50,
          "type": "string"
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "smsNotificationEnabled": {
          "type": "boolean"
        },
        "smsNumber": {
          "type": "string"
        },
        "canViewPartialResults": {
          "type": "boolean"
        },
        "canViewPreliminaryResults": {
          "type": "boolean"
        },
        "resultLanguage": {
          "maxLength": 5,
          "type": "string"
        },
        "oa": {
          "type": "boolean"
        },
        "aids": {
          "type": "array"
        },
        "latest_report_date": {
          "type": "string"
        },
        "dateScore": {
          "type": "number"
        },
        "passportNumber": {
          "type": "string"
        },
        "idCardNumber": {
          "type": "string"
        }
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "laboratoryGroupFilter": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "maxLength": 32,
          "type": "string"
        },
        "color": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        },
        "group": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "values": {
        "type": "array"
      },
      "subreports": {
        "type": "array"
      },
      "attachments": {
        "type": "array"
      },
      "attachment_count": {
        "type": "integer"
      },
      "comments": {
        "type": "array"
      },
      "comment_count": {
        "type": "integer"
      },
      "arzname": {
        "maxLength": 300,
        "type": "string"
      },
      "labname": {
        "type": "string"
      },
      "accountingType": {
        "type": "string"
      },
      "laborgruppe": {
        "type": "string"
      },
      "exttnr": {
        "type": "string"
      },
      "lebensnummer": {
        "type": "string"
      },
      "zimmer": {
        "type": "string"
      },
      "lastupdate": {
        "type": "string"
      },
      "orderComment": {
        "type": "string"
      },
      "overwritecolor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "pdfSrc": {
        "type": "string"
      },
      "material": {
        "type": "string"
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceNumber": {
        "type": "string"
      },
      "showBdw": {
        "type": "boolean"
      },
      "showCumulative": {
        "type": "boolean"
      },
      "showPdf": {
        "type": "boolean"
      },
      "showOriginal": {
        "type": "boolean"
      },
      "showOriginalExclusive": {
        "type": "boolean"
      },
      "showCumulativePdf": {
        "type": "boolean"
      },
      "showCumulativeAllPdf": {
        "type": "boolean"
      },
      "showSend": {
        "type": "boolean"
      },
      "showRerequest": {
        "type": "boolean"
      },
      "originalPdfIds": {
        "type": "array"
      },
      "guid": {
        "type": "string"
      },
      "externalGuid": {
        "type": "string"
      },
      "manualCode": {
        "type": "string"
      },
      "uuid": {
        "type": "string"
      },
      "settlementType": {}
    },
    "pool": {
      "id": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "displayName": {
        "type": "string"
      },
      "status": {
        "type": "array"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "orders": {
        "type": "array"
      },
      "logs": {
        "type": "array"
      },
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isSoftDeleted": {
        "type": "boolean"
      }
    },
    "samples": {
      "type": "array"
    },
    "workstation": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "location": {
        "type": "string"
      },
      "default": {
        "type": "boolean"
      },
      "status": {},
      "clientVersion": {
        "type": "string"
      },
      "printers": {
        "type": "array"
      }
    },
    "manufacturer": {
      "type": "string"
    },
    "initialWorkplaceId": {
      "type": "number"
    },
    "lastWorkplaceId": {
      "type": "number"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "splitId": {
      "type": "integer"
    },
    "quickScanPdfPassword": {
      "type": [
        "string",
        "null"
      ]
    },
    "hasAttachment": {
      "type": "boolean"
    },
    "case_id": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "realReRequestConditions": {
      "allowed": {
        "type": "boolean"
      },
      "formIds": {
        "type": "array"
      },
      "requirementIds": {
        "type": "array"
      },
      "profileIds": {
        "type": "array"
      }
    },
    "exportFlag": {
      "type": "boolean"
    },
    "statusExport": {
      "type": "integer"
    },
    "statusStornoExport": {
      "type": "integer"
    },
    "comment": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isCancelable": {
      "type": "boolean"
    },
    "actuallyPrintedMaterials": {
      "type": "string"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    }
  }
};

export const ReRequestOrderRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "orderId": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "requirementId": {
    "type": "integer"
  },
  "formName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "externalName": {
    "maxLength": 100,
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "insurance": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "price": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeText": {
    "type": "string"
  },
  "freeTextAllowed": {
    "type": "boolean"
  },
  "freeTextMandatory": {
    "type": "boolean"
  },
  "state": {},
  "data": {
    "type": "string"
  },
  "comment": {
    "type": "string"
  },
  "historyId": {
    "type": "integer"
  },
  "accept": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "order": {
    "lid": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "freeText": {
      "maxLength": 60,
      "type": "string"
    },
    "message": {
      "maxLength": 100,
      "type": "string"
    },
    "infectious": {
      "type": "boolean"
    },
    "urgent": {
      "type": "string"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "costUnit": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "controlCheckupKnownInfection": {
      "type": "boolean"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "dz16b": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    },
    "accidentTime": {
      "maxLength": 20,
      "type": "string"
    },
    "documentReferenceNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "anamnesticInfo": {
      "maxLength": 60,
      "type": "string"
    },
    "printedMaterials": {
      "type": [
        "string",
        "null"
      ]
    },
    "printQuickReportBarcode": {
      "type": "boolean"
    },
    "urgentNotificationPhone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationFax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationMail": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "id": {
      "type": "integer"
    },
    "status": {},
    "executionStatus": {},
    "poolId": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "type": "string"
    },
    "bsnr": {
      "type": "string"
    },
    "selectedDoctor": {
      "type": "string"
    },
    "apid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "patient": {
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 50,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 150,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "costUnit": {
        "type": "string"
      },
      "medication": {
        "maxLength": 100,
        "type": "string"
      },
      "cycleDay": {
        "maxLength": 10,
        "type": "string"
      },
      "weight": {
        "maxLength": 5,
        "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 150,
        "type": "string"
      },
      "exttnr": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "primaryCarePhysicianLanr": {
        "maxLength": 20,
        "type": "string"
      },
      "primaryCarePhysicianBsnr": {
        "maxLength": 20,
        "type": "string"
      },
      "diseaseManagementProgram": {
        "maxLength": 50,
        "type": "string"
      },
      "dmpSpecialGroup": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceStart": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceEnd": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceType": {
        "maxLength": 50,
        "type": "string"
      },
      "exceptionalIndication": {
        "maxLength": 20,
        "type": "string"
      },
      "sktIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "wopIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "accountingArea": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "hvcode": {
        "maxLength": 20,
        "type": "string"
      },
      "vkat": {
        "maxLength": 20,
        "type": "string"
      },
      "secondName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "canton": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "ahvNr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "aisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "insurance": {
        "type": [
          "number",
          "null"
        ]
      },
      "bag": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "cardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "globalLocationNumber": {
        "maxLength": 13,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "hospitalBedNumber": {
        "type": "string"
      },
      "hospitalRoomNumber": {
        "type": "string"
      },
      "hospitalProfession": {
        "type": [
          "string",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "invoiceTo": {
        "maxLength": 25,
        "type": "string"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "autInsuranceTitle": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceFirstName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceLastName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceInsuranceNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceBirthday": {
        "type": "string"
      },
      "autInsuranceAddress": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceHouseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCountry": {
        "maxLength": 10,
        "type": "string"
      },
      "autInsuranceZip": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCity": {
        "maxLength": 50,
        "type": "string"
      },
      "pid": {
        "type": "integer"
      },
      "apgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "displayName": {
        "type": "string"
      },
      "prefix": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 5,
        "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "pregnancyWeek": {
        "maxLength": 2,
        "pattern": "^(\\d{1,2})?$",
        "type": "string"
      },
      "pregnancyWeekDay": {
        "maxLength": 1,
        "pattern": "^(\\d{1})?$",
        "type": "string"
      },
      "cycleLength": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      },
      "dayOfCycle": {
        "maxLength": 2,
        "type": "string"
      },
      "fetalCrownRumpLength": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "fetalNuchalTranslucency": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "hasInsuranceNumber": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "sampleAmount": {
        "maxLength": 10,
        "type": "string"
      },
      "samplePeriod": {
        "maxLength": 10,
        "type": "string"
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "passportNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "idcardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "orderReason": {
        "maxLength": 1,
        "type": "string"
      },
      "costUnitIdentification": {
        "maxLength": 25,
        "type": "string"
      },
      "autInsuranceEmployer": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceEmployerCity": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceAddressAdditional": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "requirements": {
      "type": "array"
    },
    "anamnesis": {
      "type": "array"
    },
    "animal": {
      "name": {
        "maxLength": 50,
        "type": "string"
      },
      "birthday": {
        "maxLength": 8,
        "type": "string"
      },
      "species": {
        "maxLength": 2,
        "type": "string"
      },
      "speciesManual": {
        "maxLength": 50,
        "type": "string"
      },
      "chipId": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 3,
        "type": "string"
      },
      "weight": {
        "maxLength": 3,
        "type": "string"
      },
      "sex": {
        "type": "number"
      },
      "breed": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerName": {
        "maxLength": 100,
        "type": "string"
      },
      "ownerAmendment": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerStreet": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerHouseNumber": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerZipCode": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerCity": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "hungary": {
      "motherLastName": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "motherFirstName": {
        "maxLength": 15,
        "type": [
          "string",
          "null"
        ]
      },
      "treatmentReason": {
        "maxLength": 1,
        "type": [
          "string",
          "null"
        ]
      },
      "invoice": {
        "maxLength": 2,
        "type": [
          "string",
          "null"
        ]
      },
      "icd1": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd2": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd3": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "insuranceNumber": {
        "maxLength": 18,
        "type": [
          "string",
          "null"
        ]
      },
      "citizen": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "executed_at": {
      "type": "string"
    },
    "scheduled_at": {
      "type": [
        "string",
        "null"
      ]
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "covid": {
      "testNumber": {},
      "testReason": {},
      "agreed": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "riskCharacteristicsLocation": {},
      "riskCharacteristicsLocationType": {},
      "legalReason": {},
      "healthServiceZipCode": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "healthService": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "legalOrderBasis": {},
      "kvSpecial": {
        "type": [
          "string",
          "null"
        ]
      },
      "confirmationDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "variantDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "oegdInvoiceTo": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "covidWarnAppBarcode": {
        "type": [
          "boolean",
          "null"
        ]
      }
    },
    "mustValidate": {
      "type": "boolean"
    },
    "hospitalStation": {
      "type": "string"
    },
    "requirementDiagnosisFreeText": {
      "maxLength": 150,
      "type": "string"
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "selectedDiagnoses": {
      "type": "object"
    },
    "selectedDiagnosesChecked": {
      "type": "boolean"
    },
    "workstationChecked": {
      "type": "boolean"
    },
    "splitProperties": {
      "type": "array"
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "clientname": {
      "type": "string"
    },
    "printerAliasName": {
      "type": "string"
    },
    "overridePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "overrideBarcodePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "beforeAfterDialysis": {},
    "reportCopyAid": {
      "type": [
        "number",
        "null"
      ]
    },
    "reportCopyDoctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aisIdentifier": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "patientAisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "bloodCollectionType": {},
    "transported_at": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "digitalSignature": {},
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "report": {
      "bid": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "parent_bid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "status": {},
      "tnr": {
        "type": "string"
      },
      "pathological": {},
      "mibi": {},
      "created_at": {
        "type": "string"
      },
      "imported_at": {
        "type": "string"
      },
      "registered_at": {
        "type": "string"
      },
      "validated_at": {
        "type": "string"
      },
      "sampled_at": {
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctor": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctorAddition": {
        "maxLength": 100,
        "type": "string"
      },
      "patientName": {
        "type": "string"
      },
      "is_locked": {
        "type": "boolean"
      },
      "is_unread": {
        "type": "boolean"
      },
      "is_pinned": {
        "type": "boolean"
      },
      "has_base64": {
        "type": "boolean"
      },
      "has_elpho": {
        "type": "boolean"
      },
      "has_subreports": {
        "type": "boolean"
      },
      "has_history": {
        "type": "boolean"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "patient": {
        "pid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 200,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 100,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 50,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "phone": {
          "maxLength": 50,
          "type": "string"
        },
        "costUnit": {
          "type": "string"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "sonstiges1": {
          "maxLength": 50,
          "type": "string"
        },
        "sonstiges2": {
          "maxLength": 50,
          "type": "string"
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "smsNotificationEnabled": {
          "type": "boolean"
        },
        "smsNumber": {
          "type": "string"
        },
        "canViewPartialResults": {
          "type": "boolean"
        },
        "canViewPreliminaryResults": {
          "type": "boolean"
        },
        "resultLanguage": {
          "maxLength": 5,
          "type": "string"
        },
        "oa": {
          "type": "boolean"
        },
        "aids": {
          "type": "array"
        },
        "latest_report_date": {
          "type": "string"
        },
        "dateScore": {
          "type": "number"
        },
        "passportNumber": {
          "type": "string"
        },
        "idCardNumber": {
          "type": "string"
        }
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "laboratoryGroupFilter": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "maxLength": 32,
          "type": "string"
        },
        "color": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        },
        "group": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "values": {
        "type": "array"
      },
      "subreports": {
        "type": "array"
      },
      "attachments": {
        "type": "array"
      },
      "attachment_count": {
        "type": "integer"
      },
      "comments": {
        "type": "array"
      },
      "comment_count": {
        "type": "integer"
      },
      "arzname": {
        "maxLength": 300,
        "type": "string"
      },
      "labname": {
        "type": "string"
      },
      "accountingType": {
        "type": "string"
      },
      "laborgruppe": {
        "type": "string"
      },
      "exttnr": {
        "type": "string"
      },
      "lebensnummer": {
        "type": "string"
      },
      "zimmer": {
        "type": "string"
      },
      "lastupdate": {
        "type": "string"
      },
      "orderComment": {
        "type": "string"
      },
      "overwritecolor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "pdfSrc": {
        "type": "string"
      },
      "material": {
        "type": "string"
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceNumber": {
        "type": "string"
      },
      "showBdw": {
        "type": "boolean"
      },
      "showCumulative": {
        "type": "boolean"
      },
      "showPdf": {
        "type": "boolean"
      },
      "showOriginal": {
        "type": "boolean"
      },
      "showOriginalExclusive": {
        "type": "boolean"
      },
      "showCumulativePdf": {
        "type": "boolean"
      },
      "showCumulativeAllPdf": {
        "type": "boolean"
      },
      "showSend": {
        "type": "boolean"
      },
      "showRerequest": {
        "type": "boolean"
      },
      "originalPdfIds": {
        "type": "array"
      },
      "guid": {
        "type": "string"
      },
      "externalGuid": {
        "type": "string"
      },
      "manualCode": {
        "type": "string"
      },
      "uuid": {
        "type": "string"
      },
      "settlementType": {}
    },
    "pool": {
      "id": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "displayName": {
        "type": "string"
      },
      "status": {
        "type": "array"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "orders": {
        "type": "array"
      },
      "logs": {
        "type": "array"
      },
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isSoftDeleted": {
        "type": "boolean"
      }
    },
    "samples": {
      "type": "array"
    },
    "workstation": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "location": {
        "type": "string"
      },
      "default": {
        "type": "boolean"
      },
      "status": {},
      "clientVersion": {
        "type": "string"
      },
      "printers": {
        "type": "array"
      }
    },
    "manufacturer": {
      "type": "string"
    },
    "initialWorkplaceId": {
      "type": "number"
    },
    "lastWorkplaceId": {
      "type": "number"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "splitId": {
      "type": "integer"
    },
    "quickScanPdfPassword": {
      "type": [
        "string",
        "null"
      ]
    },
    "hasAttachment": {
      "type": "boolean"
    },
    "case_id": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "realReRequestConditions": {
      "allowed": {
        "type": "boolean"
      },
      "formIds": {
        "type": "array"
      },
      "requirementIds": {
        "type": "array"
      },
      "profileIds": {
        "type": "array"
      }
    },
    "exportFlag": {
      "type": "boolean"
    },
    "statusExport": {
      "type": "integer"
    },
    "statusStornoExport": {
      "type": "integer"
    },
    "comment": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isCancelable": {
      "type": "boolean"
    },
    "actuallyPrintedMaterials": {
      "type": "string"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    }
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "history": {
    "id": {
      "type": "integer"
    },
    "openedAt": {
      "type": "string"
    },
    "reRequestInfo": {
      "type": "string"
    },
    "requirementInfo": {
      "type": "string"
    },
    "closedAt": {
      "type": "string"
    },
    "appliedAt": {
      "type": "string"
    },
    "order": {
      "lid": {
        "type": "integer"
      },
      "isPool": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isSoftDeleted": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "freeText": {
        "maxLength": 60,
        "type": "string"
      },
      "message": {
        "maxLength": 100,
        "type": "string"
      },
      "infectious": {
        "type": "boolean"
      },
      "urgent": {
        "type": "string"
      },
      "pregnancy": {
        "type": "boolean"
      },
      "checkup": {
        "type": "boolean"
      },
      "costUnit": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "controlCheckupKnownInfection": {
        "type": "boolean"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "dz16b": {
        "type": "boolean"
      },
      "accident": {
        "type": "boolean"
      },
      "accidentTime": {
        "maxLength": 20,
        "type": "string"
      },
      "documentReferenceNumber": {
        "maxLength": 20,
        "type": "string"
      },
      "anamnesticInfo": {
        "maxLength": 60,
        "type": "string"
      },
      "printedMaterials": {
        "type": [
          "string",
          "null"
        ]
      },
      "printQuickReportBarcode": {
        "type": "boolean"
      },
      "urgentNotificationPhone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationFax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationMail": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "id": {
        "type": "integer"
      },
      "status": {},
      "executionStatus": {},
      "poolId": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "type": "string"
      },
      "bsnr": {
        "type": "string"
      },
      "selectedDoctor": {
        "type": "string"
      },
      "apid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "patient": {
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 50,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 150,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "costUnit": {
          "type": "string"
        },
        "medication": {
          "maxLength": 100,
          "type": "string"
        },
        "cycleDay": {
          "maxLength": 10,
          "type": "string"
        },
        "weight": {
          "maxLength": 5,
          "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 150,
          "type": "string"
        },
        "exttnr": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "primaryCarePhysicianLanr": {
          "maxLength": 20,
          "type": "string"
        },
        "primaryCarePhysicianBsnr": {
          "maxLength": 20,
          "type": "string"
        },
        "diseaseManagementProgram": {
          "maxLength": 50,
          "type": "string"
        },
        "dmpSpecialGroup": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceStart": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceEnd": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceType": {
          "maxLength": 50,
          "type": "string"
        },
        "exceptionalIndication": {
          "maxLength": 20,
          "type": "string"
        },
        "sktIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "wopIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "accountingArea": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "hvcode": {
          "maxLength": 20,
          "type": "string"
        },
        "vkat": {
          "maxLength": 20,
          "type": "string"
        },
        "secondName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "canton": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "ahvNr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "aisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "insurance": {
          "type": [
            "number",
            "null"
          ]
        },
        "bag": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "cardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "globalLocationNumber": {
          "maxLength": 13,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "hospitalBedNumber": {
          "type": "string"
        },
        "hospitalRoomNumber": {
          "type": "string"
        },
        "hospitalProfession": {
          "type": [
            "string",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "invoiceTo": {
          "maxLength": 25,
          "type": "string"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "autInsuranceTitle": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceFirstName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceLastName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceInsuranceNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceBirthday": {
          "type": "string"
        },
        "autInsuranceAddress": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceHouseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCountry": {
          "maxLength": 10,
          "type": "string"
        },
        "autInsuranceZip": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCity": {
          "maxLength": 50,
          "type": "string"
        },
        "pid": {
          "type": "integer"
        },
        "apgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "displayName": {
          "type": "string"
        },
        "prefix": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 5,
          "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "pregnancyWeek": {
          "maxLength": 2,
          "pattern": "^(\\d{1,2})?$",
          "type": "string"
        },
        "pregnancyWeekDay": {
          "maxLength": 1,
          "pattern": "^(\\d{1})?$",
          "type": "string"
        },
        "cycleLength": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        },
        "dayOfCycle": {
          "maxLength": 2,
          "type": "string"
        },
        "fetalCrownRumpLength": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "fetalNuchalTranslucency": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "hasInsuranceNumber": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "sampleAmount": {
          "maxLength": 10,
          "type": "string"
        },
        "samplePeriod": {
          "maxLength": 10,
          "type": "string"
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "passportNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "idcardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "orderReason": {
          "maxLength": 1,
          "type": "string"
        },
        "costUnitIdentification": {
          "maxLength": 25,
          "type": "string"
        },
        "autInsuranceEmployer": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceEmployerCity": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceAddressAdditional": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "requirements": {
        "type": "array"
      },
      "anamnesis": {
        "type": "array"
      },
      "animal": {
        "name": {
          "maxLength": 50,
          "type": "string"
        },
        "birthday": {
          "maxLength": 8,
          "type": "string"
        },
        "species": {
          "maxLength": 2,
          "type": "string"
        },
        "speciesManual": {
          "maxLength": 50,
          "type": "string"
        },
        "chipId": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 3,
          "type": "string"
        },
        "weight": {
          "maxLength": 3,
          "type": "string"
        },
        "sex": {
          "type": "number"
        },
        "breed": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerName": {
          "maxLength": 100,
          "type": "string"
        },
        "ownerAmendment": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerStreet": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerHouseNumber": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerZipCode": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerCity": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "switzerland": {
        "billToOther1": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther2": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther3": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyTo": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToEmail": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToSalutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToFirstName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToLastName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToStreet": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToZip": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToCity": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "notes": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "accidentDate": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "hungary": {
        "motherLastName": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "motherFirstName": {
          "maxLength": 15,
          "type": [
            "string",
            "null"
          ]
        },
        "treatmentReason": {
          "maxLength": 1,
          "type": [
            "string",
            "null"
          ]
        },
        "invoice": {
          "maxLength": 2,
          "type": [
            "string",
            "null"
          ]
        },
        "icd1": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd2": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd3": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "insuranceNumber": {
          "maxLength": 18,
          "type": [
            "string",
            "null"
          ]
        },
        "citizen": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "executed_at": {
        "type": "string"
      },
      "scheduled_at": {
        "type": [
          "string",
          "null"
        ]
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "covid": {
        "testNumber": {},
        "testReason": {},
        "agreed": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "riskCharacteristicsLocation": {},
        "riskCharacteristicsLocationType": {},
        "legalReason": {},
        "healthServiceZipCode": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "healthService": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "legalOrderBasis": {},
        "kvSpecial": {
          "type": [
            "string",
            "null"
          ]
        },
        "confirmationDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "variantDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "oegdInvoiceTo": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "covidWarnAppBarcode": {
          "type": [
            "boolean",
            "null"
          ]
        }
      },
      "mustValidate": {
        "type": "boolean"
      },
      "hospitalStation": {
        "type": "string"
      },
      "requirementDiagnosisFreeText": {
        "maxLength": 150,
        "type": "string"
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "selectedDiagnoses": {
        "type": "object"
      },
      "selectedDiagnosesChecked": {
        "type": "boolean"
      },
      "workstationChecked": {
        "type": "boolean"
      },
      "splitProperties": {
        "type": "array"
      },
      "sample_in_lab": {
        "type": "boolean"
      },
      "clientname": {
        "type": "string"
      },
      "printerAliasName": {
        "type": "string"
      },
      "overridePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "overrideBarcodePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "beforeAfterDialysis": {},
      "reportCopyAid": {
        "type": [
          "number",
          "null"
        ]
      },
      "reportCopyDoctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "aisIdentifier": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "patientAisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "bloodCollectionType": {},
      "transported_at": {
        "type": "string"
      },
      "created_at": {
        "type": "string"
      },
      "digitalSignature": {},
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "report": {
        "bid": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "parent_bid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "status": {},
        "tnr": {
          "type": "string"
        },
        "pathological": {},
        "mibi": {},
        "created_at": {
          "type": "string"
        },
        "imported_at": {
          "type": "string"
        },
        "registered_at": {
          "type": "string"
        },
        "validated_at": {
          "type": "string"
        },
        "sampled_at": {
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctor": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctorAddition": {
          "maxLength": 100,
          "type": "string"
        },
        "patientName": {
          "type": "string"
        },
        "is_locked": {
          "type": "boolean"
        },
        "is_unread": {
          "type": "boolean"
        },
        "is_pinned": {
          "type": "boolean"
        },
        "has_base64": {
          "type": "boolean"
        },
        "has_elpho": {
          "type": "boolean"
        },
        "has_subreports": {
          "type": "boolean"
        },
        "has_history": {
          "type": "boolean"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "patient": {
          "pid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 200,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 100,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 50,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "phone": {
            "maxLength": 50,
            "type": "string"
          },
          "costUnit": {
            "type": "string"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "sonstiges1": {
            "maxLength": 50,
            "type": "string"
          },
          "sonstiges2": {
            "maxLength": 50,
            "type": "string"
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "smsNotificationEnabled": {
            "type": "boolean"
          },
          "smsNumber": {
            "type": "string"
          },
          "canViewPartialResults": {
            "type": "boolean"
          },
          "canViewPreliminaryResults": {
            "type": "boolean"
          },
          "resultLanguage": {
            "maxLength": 5,
            "type": "string"
          },
          "oa": {
            "type": "boolean"
          },
          "aids": {
            "type": "array"
          },
          "latest_report_date": {
            "type": "string"
          },
          "dateScore": {
            "type": "number"
          },
          "passportNumber": {
            "type": "string"
          },
          "idCardNumber": {
            "type": "string"
          }
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "laboratoryGroup": {
          "grpid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "kurz": {
            "maxLength": 20,
            "pattern": "^(?![Ll][Aa]$).*$",
            "type": "string"
          },
          "name": {
            "maxLength": 200,
            "type": "string"
          },
          "telefon": {
            "maxLength": 20,
            "type": "string"
          },
          "fax": {
            "maxLength": 20,
            "type": "string"
          },
          "website": {
            "maxLength": 50,
            "type": "string"
          },
          "adresse": {
            "maxLength": 50,
            "type": "string"
          },
          "stadt": {
            "maxLength": 50,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": "string"
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": "string"
          },
          "zusatz1": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz2": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz3": {
            "maxLength": 200,
            "type": "string"
          },
          "plz": {
            "maxLength": 25,
            "type": "string"
          },
          "showPdfExport": {
            "type": "boolean"
          },
          "laboratoryGroupBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPrefix": {
              "maxLength": 10,
              "type": "string"
            },
            "printPosition": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMin": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMax": {
              "maxLength": 40,
              "type": "string"
            }
          },
          "advancedAlphanumericBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPosition1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax1": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax2": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax3": {
              "maxLength": 3,
              "type": "string"
            },
            "useState": {
              "type": "object"
            }
          }
        },
        "laboratoryGroupFilter": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "maxLength": 32,
            "type": "string"
          },
          "color": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          },
          "group": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "values": {
          "type": "array"
        },
        "subreports": {
          "type": "array"
        },
        "attachments": {
          "type": "array"
        },
        "attachment_count": {
          "type": "integer"
        },
        "comments": {
          "type": "array"
        },
        "comment_count": {
          "type": "integer"
        },
        "arzname": {
          "maxLength": 300,
          "type": "string"
        },
        "labname": {
          "type": "string"
        },
        "accountingType": {
          "type": "string"
        },
        "laborgruppe": {
          "type": "string"
        },
        "exttnr": {
          "type": "string"
        },
        "lebensnummer": {
          "type": "string"
        },
        "zimmer": {
          "type": "string"
        },
        "lastupdate": {
          "type": "string"
        },
        "orderComment": {
          "type": "string"
        },
        "overwritecolor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "pdfSrc": {
          "type": "string"
        },
        "material": {
          "type": "string"
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceNumber": {
          "type": "string"
        },
        "showBdw": {
          "type": "boolean"
        },
        "showCumulative": {
          "type": "boolean"
        },
        "showPdf": {
          "type": "boolean"
        },
        "showOriginal": {
          "type": "boolean"
        },
        "showOriginalExclusive": {
          "type": "boolean"
        },
        "showCumulativePdf": {
          "type": "boolean"
        },
        "showCumulativeAllPdf": {
          "type": "boolean"
        },
        "showSend": {
          "type": "boolean"
        },
        "showRerequest": {
          "type": "boolean"
        },
        "originalPdfIds": {
          "type": "array"
        },
        "guid": {
          "type": "string"
        },
        "externalGuid": {
          "type": "string"
        },
        "manualCode": {
          "type": "string"
        },
        "uuid": {
          "type": "string"
        },
        "settlementType": {}
      },
      "pool": {
        "id": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "displayName": {
          "type": "string"
        },
        "status": {
          "type": "array"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "orders": {
          "type": "array"
        },
        "logs": {
          "type": "array"
        },
        "created_at": {
          "type": "string"
        },
        "updated_at": {
          "type": "string"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isSoftDeleted": {
          "type": "boolean"
        }
      },
      "samples": {
        "type": "array"
      },
      "workstation": {
        "id": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "location": {
          "type": "string"
        },
        "default": {
          "type": "boolean"
        },
        "status": {},
        "clientVersion": {
          "type": "string"
        },
        "printers": {
          "type": "array"
        }
      },
      "manufacturer": {
        "type": "string"
      },
      "initialWorkplaceId": {
        "type": "number"
      },
      "lastWorkplaceId": {
        "type": "number"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "splitId": {
        "type": "integer"
      },
      "quickScanPdfPassword": {
        "type": [
          "string",
          "null"
        ]
      },
      "hasAttachment": {
        "type": "boolean"
      },
      "case_id": {
        "type": "integer"
      },
      "caseNumber": {
        "type": "string"
      },
      "realReRequestConditions": {
        "allowed": {
          "type": "boolean"
        },
        "formIds": {
          "type": "array"
        },
        "requirementIds": {
          "type": "array"
        },
        "profileIds": {
          "type": "array"
        }
      },
      "exportFlag": {
        "type": "boolean"
      },
      "statusExport": {
        "type": "integer"
      },
      "statusStornoExport": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isCancelable": {
        "type": "boolean"
      },
      "actuallyPrintedMaterials": {
        "type": "string"
      },
      "hasReportCopyInfo": {
        "type": "boolean"
      }
    }
  }
};

export const AnamnesisAnswerSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "questions": {
    "type": "array"
  }
};

export const AnamnesisFieldSchema = {
  "id": {
    "type": "integer"
  },
  "maxlength": {
    "type": "integer"
  },
  "gridSize": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "placeholder": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "complist": {
    "type": "array"
  }
};

export const AnamnesisHeaderSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "horizontal": {
    "type": "boolean"
  },
  "backgroundColor": {
    "type": "string"
  },
  "weight": {
    "type": "integer"
  }
};

export const AnamnesisQuestionSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "weight": {
    "type": "integer"
  },
  "type": {},
  "ldt": {
    "type": "string"
  },
  "mandatory": {
    "type": "boolean"
  },
  "backgroundColor": {
    "type": "string"
  },
  "header": {
    "id": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "text": {
      "type": "string"
    },
    "horizontal": {
      "type": "boolean"
    },
    "backgroundColor": {
      "type": "string"
    },
    "weight": {
      "type": "integer"
    }
  },
  "answers": {
    "type": "array"
  },
  "field": {
    "id": {
      "type": "integer"
    },
    "maxlength": {
      "type": "integer"
    },
    "gridSize": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "placeholder": {
      "type": "string"
    },
    "text": {
      "type": "string"
    },
    "complist": {
      "type": "array"
    }
  },
  "usePreviousAnswer": {
    "type": "boolean"
  },
  "previous": {
    "id": {
      "type": "integer"
    },
    "orderId": {
      "type": "integer"
    },
    "formId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "shortName": {
      "type": [
        "string",
        "null"
      ]
    },
    "ldt": {
      "type": "string"
    },
    "questionId": {
      "type": "integer"
    },
    "parentQuestionId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "answerId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "fieldId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "fieldValue": {
      "type": [
        "string",
        "null"
      ]
    },
    "questionText": {
      "type": "string"
    },
    "answerText": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "infoText": {
    "type": "string"
  },
  "infoTextStyle": {
    "type": "string"
  }
};

export const AnamnesisQuestionResponseSchema = {
  "id": {
    "type": "integer"
  },
  "questionId": {
    "type": "integer"
  },
  "answerId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "fieldId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "order": {
    "type": "integer"
  },
  "checked": {
    "type": "boolean"
  }
};

export const AnamnesisRequirementTriggerSchema = {
  "id": {
    "type": "integer"
  },
  "shortName": {
    "type": [
      "string",
      "null"
    ]
  },
  "laboratoryGroup": {
    "type": [
      "string",
      "null"
    ]
  },
  "formId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "questionId": {
    "type": "integer"
  },
  "groupFormId": {
    "type": "integer"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "question": {
    "id": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "text": {
      "type": "string"
    },
    "weight": {
      "type": "integer"
    },
    "type": {},
    "ldt": {
      "type": "string"
    },
    "mandatory": {
      "type": "boolean"
    },
    "backgroundColor": {
      "type": "string"
    },
    "header": {
      "id": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "text": {
        "type": "string"
      },
      "horizontal": {
        "type": "boolean"
      },
      "backgroundColor": {
        "type": "string"
      },
      "weight": {
        "type": "integer"
      }
    },
    "answers": {
      "type": "array"
    },
    "field": {
      "id": {
        "type": "integer"
      },
      "maxlength": {
        "type": "integer"
      },
      "gridSize": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "placeholder": {
        "type": "string"
      },
      "text": {
        "type": "string"
      },
      "complist": {
        "type": "array"
      }
    },
    "usePreviousAnswer": {
      "type": "boolean"
    },
    "previous": {
      "id": {
        "type": "integer"
      },
      "orderId": {
        "type": "integer"
      },
      "formId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "shortName": {
        "type": [
          "string",
          "null"
        ]
      },
      "ldt": {
        "type": "string"
      },
      "questionId": {
        "type": "integer"
      },
      "parentQuestionId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "answerId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "fieldId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "fieldValue": {
        "type": [
          "string",
          "null"
        ]
      },
      "questionText": {
        "type": "string"
      },
      "answerText": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "infoText": {
      "type": "string"
    },
    "infoTextStyle": {
      "type": "string"
    }
  }
};

export const AnamnesisTriggerQuestionSchema = {
  "id": {
    "type": "integer"
  },
  "answerId": {
    "type": "integer"
  },
  "questionId": {
    "type": "integer"
  },
  "order": {
    "type": "integer"
  }
};

export const OrderAnamnesisSchema = {
  "id": {
    "type": "integer"
  },
  "orderId": {
    "type": "integer"
  },
  "formId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "shortName": {
    "type": [
      "string",
      "null"
    ]
  },
  "ldt": {
    "type": "string"
  },
  "questionId": {
    "type": "integer"
  },
  "parentQuestionId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "answerId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "fieldId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "fieldValue": {
    "type": [
      "string",
      "null"
    ]
  },
  "questionText": {
    "type": "string"
  },
  "answerText": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderChangeLogSchema = {
  "id": {
    "type": "integer"
  },
  "xuser_id": {
    "type": "integer"
  },
  "user_agent": {
    "type": "string"
  },
  "user_name": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "info": {
    "type": "string"
  },
  "ip": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "pool_id": {
    "type": "integer"
  },
  "data": {
    "type": "object"
  },
  "action": {}
};

export const OrderGuidAssignmentSchema = {
  "id": {
    "type": "integer"
  },
  "orderId": {
    "type": "integer"
  },
  "created_at": {
    "type": "string"
  },
  "guid": {
    "type": "string"
  },
  "tnr": {
    "type": "string"
  },
  "laboratoryId": {
    "type": "number"
  },
  "manualCode": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderSwitzerlandBagSchema = {
  "id": {
    "type": "integer"
  },
  "code": {
    "maxLength": 5,
    "type": "string"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "abbreviation": {
    "maxLength": 10,
    "type": "string"
  }
};

export const OrderSwitzerlandBagToGlnSchema = {
  "id": {
    "type": "integer"
  },
  "bag_code": {
    "maxLength": 5,
    "type": "string"
  },
  "gln": {
    "maxLength": 20,
    "type": "string"
  }
};

export const WebshopOrderSchema = {
  "id": {
    "type": "integer"
  },
  "email": {
    "maxLength": 50,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "lastName": {
    "maxLength": 50,
    "type": "string"
  },
  "dob": {
    "maxLength": 8,
    "type": "string"
  },
  "address": {
    "maxLength": 150,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 5,
    "type": "string"
  },
  "city": {
    "maxLength": 150,
    "type": "string"
  },
  "sex": {
    "type": "integer"
  },
  "order": {
    "lid": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "freeText": {
      "maxLength": 60,
      "type": "string"
    },
    "message": {
      "maxLength": 100,
      "type": "string"
    },
    "infectious": {
      "type": "boolean"
    },
    "urgent": {
      "type": "string"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "costUnit": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "controlCheckupKnownInfection": {
      "type": "boolean"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "dz16b": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    },
    "accidentTime": {
      "maxLength": 20,
      "type": "string"
    },
    "documentReferenceNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "anamnesticInfo": {
      "maxLength": 60,
      "type": "string"
    },
    "printedMaterials": {
      "type": [
        "string",
        "null"
      ]
    },
    "printQuickReportBarcode": {
      "type": "boolean"
    },
    "urgentNotificationPhone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationFax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationMail": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "id": {
      "type": "integer"
    },
    "status": {},
    "executionStatus": {},
    "poolId": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "type": "string"
    },
    "bsnr": {
      "type": "string"
    },
    "selectedDoctor": {
      "type": "string"
    },
    "apid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "patient": {
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 50,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 150,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "costUnit": {
        "type": "string"
      },
      "medication": {
        "maxLength": 100,
        "type": "string"
      },
      "cycleDay": {
        "maxLength": 10,
        "type": "string"
      },
      "weight": {
        "maxLength": 5,
        "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 150,
        "type": "string"
      },
      "exttnr": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "primaryCarePhysicianLanr": {
        "maxLength": 20,
        "type": "string"
      },
      "primaryCarePhysicianBsnr": {
        "maxLength": 20,
        "type": "string"
      },
      "diseaseManagementProgram": {
        "maxLength": 50,
        "type": "string"
      },
      "dmpSpecialGroup": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceStart": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceEnd": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceType": {
        "maxLength": 50,
        "type": "string"
      },
      "exceptionalIndication": {
        "maxLength": 20,
        "type": "string"
      },
      "sktIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "wopIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "accountingArea": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "hvcode": {
        "maxLength": 20,
        "type": "string"
      },
      "vkat": {
        "maxLength": 20,
        "type": "string"
      },
      "secondName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "canton": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "ahvNr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "aisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "insurance": {
        "type": [
          "number",
          "null"
        ]
      },
      "bag": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "cardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "globalLocationNumber": {
        "maxLength": 13,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "hospitalBedNumber": {
        "type": "string"
      },
      "hospitalRoomNumber": {
        "type": "string"
      },
      "hospitalProfession": {
        "type": [
          "string",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "invoiceTo": {
        "maxLength": 25,
        "type": "string"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "autInsuranceTitle": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceFirstName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceLastName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceInsuranceNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceBirthday": {
        "type": "string"
      },
      "autInsuranceAddress": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceHouseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCountry": {
        "maxLength": 10,
        "type": "string"
      },
      "autInsuranceZip": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCity": {
        "maxLength": 50,
        "type": "string"
      },
      "pid": {
        "type": "integer"
      },
      "apgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "displayName": {
        "type": "string"
      },
      "prefix": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 5,
        "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "pregnancyWeek": {
        "maxLength": 2,
        "pattern": "^(\\d{1,2})?$",
        "type": "string"
      },
      "pregnancyWeekDay": {
        "maxLength": 1,
        "pattern": "^(\\d{1})?$",
        "type": "string"
      },
      "cycleLength": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      },
      "dayOfCycle": {
        "maxLength": 2,
        "type": "string"
      },
      "fetalCrownRumpLength": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "fetalNuchalTranslucency": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "hasInsuranceNumber": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "sampleAmount": {
        "maxLength": 10,
        "type": "string"
      },
      "samplePeriod": {
        "maxLength": 10,
        "type": "string"
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "passportNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "idcardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "orderReason": {
        "maxLength": 1,
        "type": "string"
      },
      "costUnitIdentification": {
        "maxLength": 25,
        "type": "string"
      },
      "autInsuranceEmployer": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceEmployerCity": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceAddressAdditional": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "requirements": {
      "type": "array"
    },
    "anamnesis": {
      "type": "array"
    },
    "animal": {
      "name": {
        "maxLength": 50,
        "type": "string"
      },
      "birthday": {
        "maxLength": 8,
        "type": "string"
      },
      "species": {
        "maxLength": 2,
        "type": "string"
      },
      "speciesManual": {
        "maxLength": 50,
        "type": "string"
      },
      "chipId": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 3,
        "type": "string"
      },
      "weight": {
        "maxLength": 3,
        "type": "string"
      },
      "sex": {
        "type": "number"
      },
      "breed": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerName": {
        "maxLength": 100,
        "type": "string"
      },
      "ownerAmendment": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerStreet": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerHouseNumber": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerZipCode": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerCity": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "hungary": {
      "motherLastName": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "motherFirstName": {
        "maxLength": 15,
        "type": [
          "string",
          "null"
        ]
      },
      "treatmentReason": {
        "maxLength": 1,
        "type": [
          "string",
          "null"
        ]
      },
      "invoice": {
        "maxLength": 2,
        "type": [
          "string",
          "null"
        ]
      },
      "icd1": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd2": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd3": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "insuranceNumber": {
        "maxLength": 18,
        "type": [
          "string",
          "null"
        ]
      },
      "citizen": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "executed_at": {
      "type": "string"
    },
    "scheduled_at": {
      "type": [
        "string",
        "null"
      ]
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "covid": {
      "testNumber": {},
      "testReason": {},
      "agreed": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "riskCharacteristicsLocation": {},
      "riskCharacteristicsLocationType": {},
      "legalReason": {},
      "healthServiceZipCode": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "healthService": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "legalOrderBasis": {},
      "kvSpecial": {
        "type": [
          "string",
          "null"
        ]
      },
      "confirmationDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "variantDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "oegdInvoiceTo": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "covidWarnAppBarcode": {
        "type": [
          "boolean",
          "null"
        ]
      }
    },
    "mustValidate": {
      "type": "boolean"
    },
    "hospitalStation": {
      "type": "string"
    },
    "requirementDiagnosisFreeText": {
      "maxLength": 150,
      "type": "string"
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "selectedDiagnoses": {
      "type": "object"
    },
    "selectedDiagnosesChecked": {
      "type": "boolean"
    },
    "workstationChecked": {
      "type": "boolean"
    },
    "splitProperties": {
      "type": "array"
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "clientname": {
      "type": "string"
    },
    "printerAliasName": {
      "type": "string"
    },
    "overridePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "overrideBarcodePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "beforeAfterDialysis": {},
    "reportCopyAid": {
      "type": [
        "number",
        "null"
      ]
    },
    "reportCopyDoctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aisIdentifier": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "patientAisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "bloodCollectionType": {},
    "transported_at": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "digitalSignature": {},
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "report": {
      "bid": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "parent_bid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "status": {},
      "tnr": {
        "type": "string"
      },
      "pathological": {},
      "mibi": {},
      "created_at": {
        "type": "string"
      },
      "imported_at": {
        "type": "string"
      },
      "registered_at": {
        "type": "string"
      },
      "validated_at": {
        "type": "string"
      },
      "sampled_at": {
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctor": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctorAddition": {
        "maxLength": 100,
        "type": "string"
      },
      "patientName": {
        "type": "string"
      },
      "is_locked": {
        "type": "boolean"
      },
      "is_unread": {
        "type": "boolean"
      },
      "is_pinned": {
        "type": "boolean"
      },
      "has_base64": {
        "type": "boolean"
      },
      "has_elpho": {
        "type": "boolean"
      },
      "has_subreports": {
        "type": "boolean"
      },
      "has_history": {
        "type": "boolean"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "patient": {
        "pid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 200,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 100,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 50,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "phone": {
          "maxLength": 50,
          "type": "string"
        },
        "costUnit": {
          "type": "string"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "sonstiges1": {
          "maxLength": 50,
          "type": "string"
        },
        "sonstiges2": {
          "maxLength": 50,
          "type": "string"
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "smsNotificationEnabled": {
          "type": "boolean"
        },
        "smsNumber": {
          "type": "string"
        },
        "canViewPartialResults": {
          "type": "boolean"
        },
        "canViewPreliminaryResults": {
          "type": "boolean"
        },
        "resultLanguage": {
          "maxLength": 5,
          "type": "string"
        },
        "oa": {
          "type": "boolean"
        },
        "aids": {
          "type": "array"
        },
        "latest_report_date": {
          "type": "string"
        },
        "dateScore": {
          "type": "number"
        },
        "passportNumber": {
          "type": "string"
        },
        "idCardNumber": {
          "type": "string"
        }
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "laboratoryGroupFilter": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "maxLength": 32,
          "type": "string"
        },
        "color": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        },
        "group": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "values": {
        "type": "array"
      },
      "subreports": {
        "type": "array"
      },
      "attachments": {
        "type": "array"
      },
      "attachment_count": {
        "type": "integer"
      },
      "comments": {
        "type": "array"
      },
      "comment_count": {
        "type": "integer"
      },
      "arzname": {
        "maxLength": 300,
        "type": "string"
      },
      "labname": {
        "type": "string"
      },
      "accountingType": {
        "type": "string"
      },
      "laborgruppe": {
        "type": "string"
      },
      "exttnr": {
        "type": "string"
      },
      "lebensnummer": {
        "type": "string"
      },
      "zimmer": {
        "type": "string"
      },
      "lastupdate": {
        "type": "string"
      },
      "orderComment": {
        "type": "string"
      },
      "overwritecolor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "pdfSrc": {
        "type": "string"
      },
      "material": {
        "type": "string"
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceNumber": {
        "type": "string"
      },
      "showBdw": {
        "type": "boolean"
      },
      "showCumulative": {
        "type": "boolean"
      },
      "showPdf": {
        "type": "boolean"
      },
      "showOriginal": {
        "type": "boolean"
      },
      "showOriginalExclusive": {
        "type": "boolean"
      },
      "showCumulativePdf": {
        "type": "boolean"
      },
      "showCumulativeAllPdf": {
        "type": "boolean"
      },
      "showSend": {
        "type": "boolean"
      },
      "showRerequest": {
        "type": "boolean"
      },
      "originalPdfIds": {
        "type": "array"
      },
      "guid": {
        "type": "string"
      },
      "externalGuid": {
        "type": "string"
      },
      "manualCode": {
        "type": "string"
      },
      "uuid": {
        "type": "string"
      },
      "settlementType": {}
    },
    "pool": {
      "id": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "displayName": {
        "type": "string"
      },
      "status": {
        "type": "array"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "orders": {
        "type": "array"
      },
      "logs": {
        "type": "array"
      },
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isSoftDeleted": {
        "type": "boolean"
      }
    },
    "samples": {
      "type": "array"
    },
    "workstation": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "location": {
        "type": "string"
      },
      "default": {
        "type": "boolean"
      },
      "status": {},
      "clientVersion": {
        "type": "string"
      },
      "printers": {
        "type": "array"
      }
    },
    "manufacturer": {
      "type": "string"
    },
    "initialWorkplaceId": {
      "type": "number"
    },
    "lastWorkplaceId": {
      "type": "number"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "splitId": {
      "type": "integer"
    },
    "quickScanPdfPassword": {
      "type": [
        "string",
        "null"
      ]
    },
    "hasAttachment": {
      "type": "boolean"
    },
    "case_id": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "realReRequestConditions": {
      "allowed": {
        "type": "boolean"
      },
      "formIds": {
        "type": "array"
      },
      "requirementIds": {
        "type": "array"
      },
      "profileIds": {
        "type": "array"
      }
    },
    "exportFlag": {
      "type": "boolean"
    },
    "statusExport": {
      "type": "integer"
    },
    "statusStornoExport": {
      "type": "integer"
    },
    "comment": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isCancelable": {
      "type": "boolean"
    },
    "actuallyPrintedMaterials": {
      "type": "string"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    }
  },
  "orderPool": {
    "id": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "displayName": {
      "type": "string"
    },
    "status": {
      "type": "array"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "orders": {
      "type": "array"
    },
    "logs": {
      "type": "array"
    },
    "created_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isSoftDeleted": {
      "type": "boolean"
    }
  },
  "phoneNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "paymentMethod": {},
  "linkId": {
    "type": "integer"
  },
  "country": {
    "maxLength": 50,
    "type": "string"
  },
  "billingAddress": {
    "maxLength": 50,
    "type": "string"
  },
  "billingZipCode": {
    "maxLength": 20,
    "type": "string"
  },
  "billingCity": {
    "maxLength": 50,
    "type": "string"
  },
  "billingCountry": {
    "maxLength": 50,
    "type": "string"
  },
  "orderDate": {
    "type": "string"
  },
  "billingNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "deviceId": {
    "maxLength": 255,
    "type": "string"
  },
  "productId": {
    "type": "integer"
  }
};

export const WebshopOrderValidationSchema = {
  "id": {
    "type": "integer"
  },
  "idcard": {
    "address": {
      "maxLength": 255,
      "type": "string"
    },
    "age": {
      "type": "integer"
    },
    "birthDate": {
      "type": "string"
    },
    "expiryDate": {
      "type": "string"
    },
    "issuingDate": {
      "type": "string"
    },
    "cardNumber": {
      "maxLength": 255,
      "type": "string"
    },
    "expired": {
      "type": "boolean"
    },
    "faceImage": {
      "type": "object"
    },
    "firstName": {
      "maxLength": 255,
      "type": "string"
    },
    "fullName": {
      "maxLength": 255,
      "type": "string"
    },
    "issuingAuthority": {
      "maxLength": 255,
      "type": "string"
    },
    "lastNamePart": {
      "maxLength": 255,
      "type": "string"
    },
    "lastName": {
      "maxLength": 255,
      "type": "string"
    },
    "nationality": {
      "maxLength": 255,
      "type": "string"
    },
    "placeOfBirth": {
      "maxLength": 255,
      "type": "string"
    },
    "sex": {
      "maxLength": 255,
      "type": "string"
    }
  },
  "blinkIdDocumentCountry": {
    "type": "integer"
  },
  "blinkIdDocumentRegion": {
    "type": "integer"
  },
  "blinkIdDocumentType": {
    "type": "integer"
  }
};

export const WebshopProductSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "maxLength": 255,
    "type": "string"
  },
  "dailyLimit": {
    "type": "integer"
  },
  "doctorId": {
    "type": "integer"
  },
  "formId": {
    "type": "integer"
  },
  "requirementId": {
    "type": "integer"
  },
  "privateFormId": {
    "type": "integer"
  },
  "privateRequirementId": {
    "type": "integer"
  },
  "priceInCents": {
    "type": "integer"
  },
  "privatePriceInCents": {
    "type": "integer"
  },
  "userSelectable": {
    "type": "boolean"
  }
};

export const PaymentVoucherSchema = {
  "voucherCode": {
    "maxLength": 255,
    "type": "string"
  },
  "consumed": {
    "type": "boolean"
  },
  "purpose": {},
  "option": {}
};

export const ReportSchema = {
  "bid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "parent_bid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "status": {},
  "tnr": {
    "type": "string"
  },
  "pathological": {},
  "mibi": {},
  "created_at": {
    "type": "string"
  },
  "imported_at": {
    "type": "string"
  },
  "registered_at": {
    "type": "string"
  },
  "validated_at": {
    "type": "string"
  },
  "sampled_at": {
    "type": "string"
  },
  "diagnosis": {
    "maxLength": 100,
    "type": "string"
  },
  "validatingDoctor": {
    "maxLength": 100,
    "type": "string"
  },
  "validatingDoctorAddition": {
    "maxLength": 100,
    "type": "string"
  },
  "patientName": {
    "type": "string"
  },
  "is_locked": {
    "type": "boolean"
  },
  "is_unread": {
    "type": "boolean"
  },
  "is_pinned": {
    "type": "boolean"
  },
  "has_base64": {
    "type": "boolean"
  },
  "has_elpho": {
    "type": "boolean"
  },
  "has_subreports": {
    "type": "boolean"
  },
  "has_history": {
    "type": "boolean"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "patient": {
    "pid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "maxLength": 200,
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "suffix": {
      "maxLength": 100,
      "type": "string"
    },
    "title": {
      "maxLength": 50,
      "type": "string"
    },
    "gender": {},
    "birthday": {
      "maxLength": 50,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 50,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "addressAdditional": {
      "maxLength": 50,
      "type": "string"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "phone": {
      "maxLength": 50,
      "type": "string"
    },
    "costUnit": {
      "type": "string"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "sonstiges1": {
      "maxLength": 50,
      "type": "string"
    },
    "sonstiges2": {
      "maxLength": 50,
      "type": "string"
    },
    "patientIdentification": {
      "maxLength": 20,
      "type": "string"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "smsNotificationEnabled": {
      "type": "boolean"
    },
    "smsNumber": {
      "type": "string"
    },
    "canViewPartialResults": {
      "type": "boolean"
    },
    "canViewPreliminaryResults": {
      "type": "boolean"
    },
    "resultLanguage": {
      "maxLength": 5,
      "type": "string"
    },
    "oa": {
      "type": "boolean"
    },
    "aids": {
      "type": "array"
    },
    "latest_report_date": {
      "type": "string"
    },
    "dateScore": {
      "type": "number"
    },
    "passportNumber": {
      "type": "string"
    },
    "idCardNumber": {
      "type": "string"
    }
  },
  "laboratory": {
    "lid": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "maxLength": 50,
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "maxLength": 100,
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "hl7Name": {
      "type": "string"
    },
    "target": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "companyName": {
      "type": "string"
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "phone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "whatsapp": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "fax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "autoLockReport": {
      "type": "boolean"
    },
    "class0": {
      "type": "number"
    },
    "class1": {
      "type": "number"
    },
    "class2": {
      "type": "number"
    },
    "class3": {
      "type": "number"
    },
    "class4": {
      "type": "number"
    },
    "class5": {
      "type": "number"
    },
    "showPdf": {},
    "showOriginal": {},
    "showBdw": {},
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "type": [
        "string",
        "null"
      ]
    },
    "suffix": {
      "type": [
        "string",
        "null"
      ]
    },
    "loginSite": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "additional1": {
      "type": [
        "string",
        "null"
      ]
    },
    "additional2": {
      "type": [
        "string",
        "null"
      ]
    },
    "additional3": {
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "contactInfo": {
      "type": [
        "string",
        "null"
      ]
    },
    "verifyIKs": {
      "type": "boolean"
    },
    "displayText": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "concurrentLoginLimit": {
      "type": "integer"
    },
    "barcodeAlgorithm": {
      "maxLength": 10,
      "type": "string"
    },
    "barcodeLength": {
      "type": "integer"
    },
    "labelWidth": {
      "type": "integer"
    },
    "labelHeight": {
      "type": "integer"
    },
    "barcodeWidth": {
      "type": "integer"
    },
    "barcodeHeight": {
      "type": "integer"
    },
    "dynamicProbeTypeBase": {
      "type": "integer"
    },
    "reportDisplay": {
      "lid": {
        "type": "integer"
      },
      "emailReports": {
        "type": "boolean"
      },
      "exportReports": {
        "type": "boolean"
      },
      "contactLaboratory": {
        "type": "boolean"
      },
      "reportAttachments": {
        "type": "boolean"
      },
      "rerequests": {
        "type": "boolean"
      },
      "printOrders": {
        "type": "boolean"
      },
      "showPathoColumn": {
        "type": "boolean"
      },
      "hidePathoColumnPat": {
        "type": "boolean"
      },
      "pathoHighlightType": {},
      "showLaboratoryGroup": {
        "type": "boolean"
      },
      "rerequestTimeFrame": {
        "type": "number"
      },
      "alwaysShowDateAndTimeInCumulativeDisplay": {
        "type": "boolean"
      }
    },
    "mailTemplateGroup": {
      "mtgid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "reply": {
        "type": "string"
      },
      "target": {
        "type": [
          "string",
          "null"
        ]
      },
      "www_url": {
        "maxLength": 50,
        "pattern": "^https?://",
        "type": [
          "string",
          "null"
        ]
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "defaultPatientCanViewPartialResults": {
      "type": "boolean"
    },
    "defaultPatientCanViewPreliminaryResults": {
      "type": "boolean"
    },
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "overwrite": {
      "type": "boolean"
    },
    "resetCentralBarcodes": {
      "type": "boolean"
    },
    "mtlMail": {
      "maxLength": 250,
      "type": "string"
    },
    "useLgMail": {
      "type": "boolean"
    },
    "orderTimeframe": {
      "type": "integer"
    },
    "reRequestLimit": {
      "type": "integer"
    },
    "reRequestBuffer": {
      "type": "integer"
    },
    "reRequestApprove": {
      "type": "boolean"
    },
    "reRequestNoExport": {
      "type": "boolean"
    },
    "reRequestLimitSampleDate": {
      "type": "boolean"
    },
    "emailsBlock": {
      "type": "array"
    },
    "orderLocalization": {},
    "quickScanPdfMode": {
      "type": "integer"
    },
    "quickScanWalletMode": {
      "type": "integer"
    },
    "quickScanAskFirstName": {
      "type": "boolean"
    },
    "quickScanAskPassport": {
      "type": "boolean"
    },
    "logo": {
      "type": [
        "string",
        "null"
      ]
    },
    "fontColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "backgroundColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "laboratoryGroup": {
    "grpid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "kurz": {
      "maxLength": 20,
      "pattern": "^(?![Ll][Aa]$).*$",
      "type": "string"
    },
    "name": {
      "maxLength": 200,
      "type": "string"
    },
    "telefon": {
      "maxLength": 20,
      "type": "string"
    },
    "fax": {
      "maxLength": 20,
      "type": "string"
    },
    "website": {
      "maxLength": 50,
      "type": "string"
    },
    "adresse": {
      "maxLength": 50,
      "type": "string"
    },
    "stadt": {
      "maxLength": 50,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": "string"
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": "string"
    },
    "zusatz1": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz2": {
      "maxLength": 200,
      "type": "string"
    },
    "zusatz3": {
      "maxLength": 200,
      "type": "string"
    },
    "plz": {
      "maxLength": 25,
      "type": "string"
    },
    "showPdfExport": {
      "type": "boolean"
    },
    "laboratoryGroupBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "printPosition": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMin": {
        "maxLength": 40,
        "type": "string"
      },
      "printRangeMax": {
        "maxLength": 40,
        "type": "string"
      }
    },
    "advancedAlphanumericBarcodeSettings": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "grpid": {
        "type": "integer"
      },
      "group": {
        "maxLength": 20,
        "type": "string"
      },
      "printPosition1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin1": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax1": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin2": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax2": {
        "maxLength": 3,
        "type": "string"
      },
      "printPosition3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMin3": {
        "maxLength": 3,
        "type": "string"
      },
      "printRangeMax3": {
        "maxLength": 3,
        "type": "string"
      },
      "useState": {}
    }
  },
  "laboratoryGroupFilter": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 32,
      "type": "string"
    },
    "color": {
      "maxLength": 8,
      "type": [
        "string",
        "null"
      ]
    },
    "group": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "values": {
    "type": "array"
  },
  "subreports": {
    "type": "array"
  },
  "attachments": {
    "type": "array"
  },
  "attachment_count": {
    "type": "integer"
  },
  "comments": {
    "type": "array"
  },
  "comment_count": {
    "type": "integer"
  },
  "arzname": {
    "maxLength": 300,
    "type": "string"
  },
  "labname": {
    "type": "string"
  },
  "accountingType": {
    "type": "string"
  },
  "laborgruppe": {
    "type": "string"
  },
  "exttnr": {
    "type": "string"
  },
  "lebensnummer": {
    "type": "string"
  },
  "zimmer": {
    "type": "string"
  },
  "lastupdate": {
    "type": "string"
  },
  "orderComment": {
    "type": "string"
  },
  "overwritecolor": {
    "type": [
      "string",
      "null"
    ]
  },
  "highlightColor": {
    "type": [
      "string",
      "null"
    ]
  },
  "pdfSrc": {
    "type": "string"
  },
  "material": {
    "type": "string"
  },
  "caseNumber": {
    "type": "string"
  },
  "insuranceNumber": {
    "type": "string"
  },
  "showBdw": {
    "type": "boolean"
  },
  "showCumulative": {
    "type": "boolean"
  },
  "showPdf": {
    "type": "boolean"
  },
  "showOriginal": {
    "type": "boolean"
  },
  "showOriginalExclusive": {
    "type": "boolean"
  },
  "showCumulativePdf": {
    "type": "boolean"
  },
  "showCumulativeAllPdf": {
    "type": "boolean"
  },
  "showSend": {
    "type": "boolean"
  },
  "showRerequest": {
    "type": "boolean"
  },
  "originalPdfIds": {
    "type": "array"
  },
  "guid": {
    "type": "string"
  },
  "externalGuid": {
    "type": "string"
  },
  "manualCode": {
    "type": "string"
  },
  "uuid": {
    "type": "string"
  },
  "settlementType": {}
};

export const ReportAttachmentSchema = {
  "anid": {
    "type": "number"
  },
  "bid": {
    "type": "number"
  },
  "blid": {
    "type": "integer"
  },
  "filename": {
    "type": "string"
  },
  "description": {
    "type": "string"
  },
  "banh": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "btnr": {
    "type": "string"
  }
};

export const ReportBase64Schema = {
  "id": {
    "type": "number"
  },
  "base64": {
    "type": "string"
  }
};

export const ReportValueContextSchema = {
  "withPrevious": {
    "type": "object"
  }
};

export const ReportValueSchema = {
  "blid": {
    "type": "integer"
  },
  "bpid": {
    "type": "integer"
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "name": {
    "type": "string"
  },
  "group": {
    "type": "string"
  },
  "description": {
    "type": "string"
  },
  "bid": {
    "type": "integer"
  },
  "caseNumber": {
    "type": [
      "string",
      "null"
    ]
  },
  "btnr": {
    "type": "string"
  },
  "blg": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "result": {
    "type": "string"
  },
  "textResult": {
    "maxLength": 150,
    "type": "string"
  },
  "unit": {
    "type": "string"
  },
  "reference": {
    "from": {
      "type": "string"
    },
    "to": {
      "type": "string"
    },
    "text": {
      "type": "string"
    }
  },
  "pathological": {
    "type": "string"
  },
  "poct": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "graphicFormat": {},
  "line": {
    "type": "number"
  },
  "format": {},
  "previous": {
    "bid": {
      "type": "integer"
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseNumber": {
      "type": [
        "string",
        "null"
      ]
    },
    "btnr": {
      "type": "string"
    },
    "blg": {
      "type": "string"
    },
    "date": {
      "type": "string"
    },
    "result": {
      "type": "string"
    },
    "textResult": {
      "maxLength": 150,
      "type": "string"
    },
    "unit": {
      "type": "string"
    },
    "reference": {
      "from": {
        "type": "string"
      },
      "to": {
        "type": "string"
      },
      "text": {
        "type": "string"
      }
    },
    "pathological": {
      "type": "string"
    },
    "poct": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "graphicFormat": {},
    "laboratoryGroup": {
      "type": "string"
    }
  },
  "case": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "pid": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "diagnosis": {
      "type": "string"
    },
    "room": {
      "type": "string"
    },
    "status": {},
    "msh3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "msh4": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "created_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "patient": {
      "pid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 200,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 100,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 50,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "phone": {
        "maxLength": 50,
        "type": "string"
      },
      "costUnit": {
        "type": "string"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "sonstiges1": {
        "maxLength": 50,
        "type": "string"
      },
      "sonstiges2": {
        "maxLength": 50,
        "type": "string"
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "smsNotificationEnabled": {
        "type": "boolean"
      },
      "smsNumber": {
        "type": "string"
      },
      "canViewPartialResults": {
        "type": "boolean"
      },
      "canViewPreliminaryResults": {
        "type": "boolean"
      },
      "resultLanguage": {
        "maxLength": 5,
        "type": "string"
      },
      "oa": {
        "type": "boolean"
      },
      "aids": {
        "type": "array"
      },
      "latest_report_date": {
        "type": "string"
      },
      "dateScore": {
        "type": "number"
      },
      "passportNumber": {
        "type": "string"
      },
      "idCardNumber": {
        "type": "string"
      }
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "events": {
      "type": "array"
    },
    "reports": {
      "type": "array"
    },
    "orders": {
      "type": "array"
    }
  },
  "catalog": {
    "type": "string"
  },
  "mibi": {},
  "material": {
    "type": "string"
  },
  "messgeraet": {
    "type": "string"
  },
  "arbeitsplatz": {
    "type": "string"
  },
  "meta": {
    "type": "array"
  },
  "showResultOnly": {
    "type": "boolean"
  },
  "comment": {
    "maxLength": 100,
    "type": "string"
  },
  "mibiTableIndex": {
    "type": "integer"
  },
  "commentReferenceResult": {
    "maxLength": 50,
    "type": "string"
  },
  "commentReferenceResultGroup": {
    "maxLength": 50,
    "type": "string"
  },
  "commentType": {},
  "materialId": {
    "maxLength": 50,
    "type": "string"
  },
  "status": {
    "maxLength": 10,
    "type": "string"
  },
  "methodology": {
    "type": "string"
  },
  "cancel": {
    "type": "boolean"
  },
  "addOn": {
    "type": "boolean"
  },
  "isRtfConverted": {
    "type": "boolean"
  }
};

export const ReportCommentSchema = {
  "id": {
    "type": "integer"
  },
  "bid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "xuser_id": {
    "type": [
      "integer",
      "null"
    ]
  },
  "user_name": {
    "type": "string"
  },
  "data": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  }
};

export const FilterProfileSchema = {
  "pid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "settings": {
    "status": {},
    "gender": {},
    "characteristic": {},
    "reportType": {},
    "advancedAnalysisFilter": {
      "type": "array"
    },
    "locked": {
      "type": "boolean"
    },
    "dateFrom": {
      "type": "string"
    },
    "dateTo": {
      "type": "string"
    },
    "unread": {},
    "doctorName": {
      "type": "string"
    }
  },
  "created_at": {
    "type": "string"
  },
  "id": {
    "type": "integer"
  },
  "typ": {
    "type": "string"
  }
};

export const ReportElphoSchema = {
  "tnr": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "lid": {
    "type": "number"
  },
  "bid": {
    "type": "number"
  },
  "elphoid": {
    "type": "number"
  },
  "x_value": {
    "type": "number"
  },
  "y_value": {
    "type": "number"
  },
  "line": {},
  "type": {}
};

export const LanrSearchSchema = {
  "alsid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "lanr": {
    "type": "string"
  },
  "lanrsynonym": {
    "type": "string"
  },
  "sort": {
    "type": "integer"
  },
  "default": {
    "type": "boolean"
  }
};

export const ReportPrintJobSchema = {
  "did": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "filepath": {
    "type": "string"
  },
  "drucktyp": {},
  "blid": {
    "type": "integer"
  },
  "bdatum": {
    "type": "string"
  },
  "btnr": {
    "type": "string"
  },
  "status": {},
  "daid": {
    "type": "number"
  },
  "drucker": {
    "type": "number"
  },
  "order": {
    "type": "number"
  },
  "sendername": {
    "type": "string"
  },
  "sprache": {
    "type": "string"
  },
  "pdfpass": {
    "type": "string"
  },
  "bid": {
    "type": "integer"
  }
};

export const BlacklistSchema = {
  "bbid": {
    "type": "integer"
  },
  "value": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "exact": {
    "type": "boolean"
  }
};

export const CustomPushSchema = {
  "cpid": {
    "type": "integer"
  },
  "value": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  }
};

export const CustomSortSchema = {
  "lid": {
    "type": "integer"
  },
  "methode": {
    "type": "string"
  },
  "description": {
    "type": "string"
  },
  "group": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "line": {
    "type": "number"
  }
};

export const ReportDetailsSchema = {
  "vname": {
    "type": "string"
  },
  "vvorname": {
    "type": "string"
  },
  "vvernnr": {
    "type": "string"
  },
  "vtitel": {
    "type": "string"
  }
};

export const ReportPinSchema = {
  "bid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "xuser_id": {
    "type": [
      "integer",
      "null"
    ]
  },
  "created_at": {
    "type": "string"
  }
};

export const ReportNoteSchema = {
  "tnr": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "line": {
    "type": "integer"
  },
  "textContent": {
    "type": "string"
  },
  "type": {},
  "referenceText": {
    "maxLength": 50,
    "type": "string"
  },
  "bid": {
    "type": "integer"
  }
};

export const ReportBudgetSchema = {
  "tnr": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "bid": {
    "type": "integer"
  },
  "line": {
    "type": "integer"
  },
  "billingPositionId": {
    "maxLength": 20,
    "type": "string"
  },
  "billingPositionName": {
    "maxLength": 100,
    "type": "string"
  },
  "billingPositionPrice": {
    "maxLength": 50,
    "type": "string"
  },
  "vatPercentage": {
    "maxLength": 20,
    "type": "string"
  },
  "billingSumExclVat": {
    "maxLength": 50,
    "type": "string"
  },
  "billingSumInclVat": {
    "maxLength": 100,
    "type": "string"
  },
  "billingVatSum": {
    "maxLength": 20,
    "type": "string"
  },
  "billingCurrency": {
    "maxLength": 20,
    "type": "string"
  }
};

export const ReportReferenceCommentSchema = {
  "bid": {
    "type": "integer"
  },
  "positionNumber": {
    "type": "number"
  },
  "content": {
    "type": "string"
  }
};

export const ReportTriggerActionSchema = {
  "sltaid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "tnr": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "standard": {
    "type": "integer"
  },
  "fax": {
    "type": "integer"
  },
  "mail": {
    "type": "integer"
  },
  "recipeFile": {
    "maxLength": 100,
    "type": "string"
  },
  "level": {
    "type": "integer"
  },
  "importTime": {
    "type": "string"
  },
  "uuid": {
    "type": "string"
  },
  "pdfMode": {},
  "ldtMode": {},
  "elphoMode": {}
};

export const ReportAccessLogSchema = {
  "id": {
    "type": "integer"
  },
  "xuser_id": {
    "type": [
      "integer",
      "null"
    ]
  },
  "user_agent": {
    "type": "string"
  },
  "user_name": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "info": {
    "type": "string"
  },
  "ip": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "bid": {
    "type": "integer"
  },
  "action": {}
};

export const ReportChangeLogSchema = {
  "id": {
    "type": "integer"
  },
  "xuser_id": {
    "type": "integer"
  },
  "user_agent": {
    "type": "string"
  },
  "user_name": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "info": {
    "type": "string"
  },
  "ip": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "bid": {
    "type": "integer"
  },
  "action": {},
  "data": {
    "type": "string"
  }
};

export const DesignerSettingsSchema = {
  "setting_id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "pat_pdf": {
    "type": "boolean"
  },
  "showCumulativePdf": {
    "type": "boolean"
  },
  "showCumulativeAllPdf": {
    "type": "boolean"
  }
};

export const DoctorGroupContextSchema = {
  "withMeta": {
    "type": "object"
  }
};

export const DoctorGroupSchema = {
  "gid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 200,
    "type": "string"
  },
  "oagrp": {
    "type": "boolean"
  },
  "lsgrp": {
    "type": "boolean"
  },
  "members": {
    "type": "array"
  },
  "members_count": {
    "type": "integer"
  },
  "owners_count": {
    "type": "integer"
  },
  "total_count": {
    "type": "integer"
  },
  "isAdmin": {
    "type": "boolean"
  },
  "isMember": {
    "type": "boolean"
  }
};

export const DoctorGroupMemberSchema = {
  "gid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "isAdmin": {
    "type": "boolean"
  },
  "isMember": {
    "type": "boolean"
  },
  "bengrp": {
    "type": "boolean"
  },
  "sharesPrinters": {
    "type": "boolean"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "group": {
    "gid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 200,
      "type": "string"
    },
    "oagrp": {
      "type": "boolean"
    },
    "lsgrp": {
      "type": "boolean"
    },
    "members": {
      "type": "array"
    },
    "members_count": {
      "type": "integer"
    },
    "owners_count": {
      "type": "integer"
    },
    "total_count": {
      "type": "integer"
    },
    "isAdmin": {
      "type": "boolean"
    },
    "isMember": {
      "type": "boolean"
    }
  }
};

export const DoctorGroupOfficeSchema = {
  "pid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 250,
    "type": "string"
  },
  "deletable": {
    "type": "boolean"
  },
  "lanr": {
    "maxLength": 100,
    "type": "string"
  },
  "bsnr": {
    "maxLength": 150,
    "type": "string"
  },
  "kurzform": {
    "maxLength": 60,
    "type": "string"
  }
};

export const LaboratoryGroupSchema = {
  "grpid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "kurz": {
    "maxLength": 20,
    "pattern": "^(?![Ll][Aa]$).*$",
    "type": "string"
  },
  "name": {
    "maxLength": 200,
    "type": "string"
  },
  "telefon": {
    "maxLength": 20,
    "type": "string"
  },
  "fax": {
    "maxLength": 20,
    "type": "string"
  },
  "website": {
    "maxLength": 50,
    "type": "string"
  },
  "adresse": {
    "maxLength": 50,
    "type": "string"
  },
  "stadt": {
    "maxLength": 50,
    "type": "string"
  },
  "email": {
    "maxLength": 50,
    "type": "string"
  },
  "rerequestMail": {
    "maxLength": 100,
    "type": "string"
  },
  "zusatz1": {
    "maxLength": 200,
    "type": "string"
  },
  "zusatz2": {
    "maxLength": 200,
    "type": "string"
  },
  "zusatz3": {
    "maxLength": 200,
    "type": "string"
  },
  "plz": {
    "maxLength": 25,
    "type": "string"
  },
  "showPdfExport": {
    "type": "boolean"
  },
  "laboratoryGroupBarcodeSettings": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "grpid": {
      "type": "integer"
    },
    "group": {
      "maxLength": 20,
      "type": "string"
    },
    "printPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "printPosition": {
      "maxLength": 40,
      "type": "string"
    },
    "printRangeMin": {
      "maxLength": 40,
      "type": "string"
    },
    "printRangeMax": {
      "maxLength": 40,
      "type": "string"
    }
  },
  "advancedAlphanumericBarcodeSettings": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "grpid": {
      "type": "integer"
    },
    "group": {
      "maxLength": 20,
      "type": "string"
    },
    "printPosition1": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMin1": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMax1": {
      "maxLength": 3,
      "type": "string"
    },
    "printPosition2": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMin2": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMax2": {
      "maxLength": 3,
      "type": "string"
    },
    "printPosition3": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMin3": {
      "maxLength": 3,
      "type": "string"
    },
    "printRangeMax3": {
      "maxLength": 3,
      "type": "string"
    },
    "useState": {}
  }
};

export const LaboratoryGroupFilterSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 32,
    "type": "string"
  },
  "color": {
    "maxLength": 8,
    "type": [
      "string",
      "null"
    ]
  },
  "group": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  }
};

export const LabFeatureSchema = {
  "labfeature_id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "feature": {},
  "product": {},
  "arz_meth": {},
  "pat_meth": {}
};

export const LabProductSchema = {
  "lpid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "product": {},
  "doctorsEnabled": {
    "type": "boolean"
  },
  "patientsEnabled": {
    "type": "boolean"
  },
  "appEnabled": {
    "type": "boolean"
  },
  "explicitLock": {
    "type": "boolean"
  }
};

export const LegalInfoSchema = {
  "lid": {
    "type": "integer"
  },
  "locale": {},
  "type": {},
  "text": {
    "type": "string"
  }
};

export const LinkSchema = {
  "xlinksid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "position": {
    "type": "integer"
  },
  "linkname": {
    "type": "string"
  },
  "linkhref": {
    "pattern": "^https?://",
    "type": "string"
  },
  "product": {},
  "platform": {}
};

export const ReportDisplaySettingsSchema = {
  "lid": {
    "type": "integer"
  },
  "emailReports": {
    "type": "boolean"
  },
  "exportReports": {
    "type": "boolean"
  },
  "contactLaboratory": {
    "type": "boolean"
  },
  "reportAttachments": {
    "type": "boolean"
  },
  "rerequests": {
    "type": "boolean"
  },
  "printOrders": {
    "type": "boolean"
  },
  "showPathoColumn": {
    "type": "boolean"
  },
  "hidePathoColumnPat": {
    "type": "boolean"
  },
  "pathoHighlightType": {},
  "showLaboratoryGroup": {
    "type": "boolean"
  },
  "rerequestTimeFrame": {
    "type": "number"
  },
  "alwaysShowDateAndTimeInCumulativeDisplay": {
    "type": "boolean"
  }
};

export const ServerConfigSchema = {
  "key": {},
  "value": {
    "type": "string"
  }
};

export const UserFeatureSchema = {
  "ufeature_id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "pid": {
    "type": "integer"
  },
  "xuser_id": {
    "type": "integer"
  },
  "feature": {},
  "product": {},
  "assign_type": {}
};

export const UserPreferenceSchema = {
  "id": {
    "type": "integer"
  },
  "user_id": {
    "type": "integer"
  },
  "user_type": {
    "type": "string"
  },
  "pref_name": {
    "type": "string"
  },
  "pref_value": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const AdvancedAlphanumericBarcodeSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "grpid": {
    "type": "integer"
  },
  "group": {
    "maxLength": 20,
    "type": "string"
  },
  "printPosition1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax1": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax2": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax3": {
    "maxLength": 3,
    "type": "string"
  },
  "useState": {}
};

export const LaboratoryGroupBarcodeSettingsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "grpid": {
    "type": "integer"
  },
  "group": {
    "maxLength": 20,
    "type": "string"
  },
  "printPrefix": {
    "maxLength": 10,
    "type": "string"
  },
  "printPosition": {
    "maxLength": 40,
    "type": "string"
  },
  "printRangeMin": {
    "maxLength": 40,
    "type": "string"
  },
  "printRangeMax": {
    "maxLength": 40,
    "type": "string"
  }
};

export const ContactInfoSchema = {
  "lid": {
    "type": "integer"
  },
  "title": {
    "type": "string"
  },
  "short": {
    "type": "string"
  },
  "phone": {
    "type": "string"
  },
  "whatsapp": {
    "type": "string"
  },
  "fax": {
    "type": "string"
  },
  "www": {
    "type": "string"
  },
  "address": {
    "type": "string"
  },
  "city": {
    "type": "string"
  },
  "email": {
    "type": "string"
  }
};

export const SitePreferenceSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "user_type": {
    "type": "string"
  },
  "pref_name": {
    "type": "string"
  },
  "pref_value": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const LaboratorySchema = {
  "lid": {
    "type": "integer"
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "maxLength": 50,
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "email": {
    "maxLength": 100,
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  },
  "oa": {
    "type": "boolean"
  },
  "ob": {
    "type": "boolean"
  },
  "of": {
    "type": "boolean"
  },
  "ll": {
    "type": "boolean"
  },
  "le": {
    "type": "boolean"
  },
  "ls": {
    "type": "boolean"
  },
  "lc": {
    "type": "boolean"
  },
  "dd": {
    "type": "boolean"
  },
  "mc": {
    "type": "boolean"
  },
  "nc": {
    "type": "boolean"
  },
  "lw": {
    "type": "boolean"
  },
  "admin": {
    "type": "boolean"
  },
  "dashboard": {
    "type": "boolean"
  },
  "features": {
    "type": "object"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "hl7Name": {
    "type": "string"
  },
  "target": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "companyName": {
    "type": "string"
  },
  "address": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "city": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "phone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "whatsapp": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "fax": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "www": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "rerequestMail": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "autoLockReport": {
    "type": "boolean"
  },
  "class0": {
    "type": "number"
  },
  "class1": {
    "type": "number"
  },
  "class2": {
    "type": "number"
  },
  "class3": {
    "type": "number"
  },
  "class4": {
    "type": "number"
  },
  "class5": {
    "type": "number"
  },
  "showPdf": {},
  "showOriginal": {},
  "showBdw": {},
  "firstName": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "title": {
    "type": [
      "string",
      "null"
    ]
  },
  "suffix": {
    "type": [
      "string",
      "null"
    ]
  },
  "loginSite": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "additional1": {
    "type": [
      "string",
      "null"
    ]
  },
  "additional2": {
    "type": [
      "string",
      "null"
    ]
  },
  "additional3": {
    "type": [
      "string",
      "null"
    ]
  },
  "zipCode": {
    "type": [
      "string",
      "null"
    ]
  },
  "contactInfo": {
    "type": [
      "string",
      "null"
    ]
  },
  "verifyIKs": {
    "type": "boolean"
  },
  "displayText": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "concurrentLoginLimit": {
    "type": "integer"
  },
  "barcodeAlgorithm": {
    "maxLength": 10,
    "type": "string"
  },
  "barcodeLength": {
    "type": "integer"
  },
  "labelWidth": {
    "type": "integer"
  },
  "labelHeight": {
    "type": "integer"
  },
  "barcodeWidth": {
    "type": "integer"
  },
  "barcodeHeight": {
    "type": "integer"
  },
  "dynamicProbeTypeBase": {
    "type": "integer"
  },
  "reportDisplay": {
    "lid": {
      "type": "integer"
    },
    "emailReports": {
      "type": "boolean"
    },
    "exportReports": {
      "type": "boolean"
    },
    "contactLaboratory": {
      "type": "boolean"
    },
    "reportAttachments": {
      "type": "boolean"
    },
    "rerequests": {
      "type": "boolean"
    },
    "printOrders": {
      "type": "boolean"
    },
    "showPathoColumn": {
      "type": "boolean"
    },
    "hidePathoColumnPat": {
      "type": "boolean"
    },
    "pathoHighlightType": {},
    "showLaboratoryGroup": {
      "type": "boolean"
    },
    "rerequestTimeFrame": {
      "type": "number"
    },
    "alwaysShowDateAndTimeInCumulativeDisplay": {
      "type": "boolean"
    }
  },
  "mailTemplateGroup": {
    "mtgid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "reply": {
      "type": "string"
    },
    "target": {
      "type": [
        "string",
        "null"
      ]
    },
    "www_url": {
      "maxLength": 50,
      "pattern": "^https?://",
      "type": [
        "string",
        "null"
      ]
    },
    "logo": {
      "type": [
        "string",
        "null"
      ]
    },
    "fontColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "backgroundColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "defaultPatientCanViewPartialResults": {
    "type": "boolean"
  },
  "defaultPatientCanViewPreliminaryResults": {
    "type": "boolean"
  },
  "printPosition": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMin": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMax": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printPrefix": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "overwrite": {
    "type": "boolean"
  },
  "resetCentralBarcodes": {
    "type": "boolean"
  },
  "mtlMail": {
    "maxLength": 250,
    "type": "string"
  },
  "useLgMail": {
    "type": "boolean"
  },
  "orderTimeframe": {
    "type": "integer"
  },
  "reRequestLimit": {
    "type": "integer"
  },
  "reRequestBuffer": {
    "type": "integer"
  },
  "reRequestApprove": {
    "type": "boolean"
  },
  "reRequestNoExport": {
    "type": "boolean"
  },
  "reRequestLimitSampleDate": {
    "type": "boolean"
  },
  "emailsBlock": {
    "type": "array"
  },
  "orderLocalization": {},
  "quickScanPdfMode": {
    "type": "integer"
  },
  "quickScanWalletMode": {
    "type": "integer"
  },
  "quickScanAskFirstName": {
    "type": "boolean"
  },
  "quickScanAskPassport": {
    "type": "boolean"
  },
  "logo": {
    "type": [
      "string",
      "null"
    ]
  },
  "fontColor": {
    "type": [
      "string",
      "null"
    ]
  },
  "backgroundColor": {
    "type": [
      "string",
      "null"
    ]
  },
  "highlightColor": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const DoctorSchema = {
  "aid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "mtgid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "type": "string"
  },
  "salutation": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "email": {
    "maxLength": 200,
    "type": [
      "string",
      "null"
    ]
  },
  "userDebugEnabled": {
    "type": "boolean"
  },
  "oa": {
    "type": "boolean"
  },
  "ob": {
    "type": "boolean"
  },
  "of": {
    "type": "boolean"
  },
  "ll": {
    "type": "boolean"
  },
  "le": {
    "type": "boolean"
  },
  "ls": {
    "type": "boolean"
  },
  "lc": {
    "type": "boolean"
  },
  "dd": {
    "type": "boolean"
  },
  "mc": {
    "type": "boolean"
  },
  "nc": {
    "type": "boolean"
  },
  "lw": {
    "type": "boolean"
  },
  "admin": {
    "type": "boolean"
  },
  "dashboard": {
    "type": "boolean"
  },
  "features": {
    "type": "object"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "externalId": {
    "maxLength": 60,
    "type": [
      "string",
      "null"
    ]
  },
  "externalId2": {
    "type": "string"
  },
  "lanr": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "title": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "lastName": {
    "maxLength": 250,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "address": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "zipCode": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "city": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "houseNumber": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "countryCode": {
    "type": [
      "string",
      "null"
    ]
  },
  "phoneNumber": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "faxNumber": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "smsNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "www": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "welcomeLabel": {
    "maxLength": 200,
    "type": [
      "string",
      "null"
    ]
  },
  "logoutLabel": {
    "maxLength": 200,
    "type": [
      "string",
      "null"
    ]
  },
  "hierarchyStatus": {},
  "hierarchyAssignments": {
    "type": "array"
  },
  "hierarchyTree": {
    "type": "array"
  },
  "canActivatePatients": {
    "type": "boolean"
  },
  "isSuperDoctor": {
    "type": "boolean"
  },
  "disableViewDiagnosis": {
    "type": "boolean"
  },
  "emailNotificationEnabled": {
    "type": "boolean"
  },
  "emailNotificationsCharacteristics": {
    "type": "string"
  },
  "emailNotificationsStatus": {
    "type": "string"
  },
  "emailReports": {
    "type": "boolean"
  },
  "officeName": {
    "maxLength": 200,
    "type": "string"
  },
  "bsnr": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "localisation": {},
  "laboratoryLocalisation": {},
  "manufacturer": {
    "type": "string"
  },
  "urgent": {
    "type": "boolean"
  },
  "emergency": {
    "type": "boolean"
  },
  "infectious": {
    "type": "boolean"
  },
  "oaanswertrigger": {
    "type": "string"
  },
  "oaSoftDelete": {
    "type": "boolean"
  },
  "printMode": {
    "type": "integer"
  },
  "barcodePrintSettings": {
    "type": "integer"
  },
  "printername": {
    "maxLength": 200,
    "type": "string"
  },
  "scheinPrintSettings": {
    "type": "integer"
  },
  "printernameSchein": {
    "maxLength": 200,
    "type": "string"
  },
  "socketPrint": {
    "type": "boolean"
  },
  "internalPrintUser": {
    "type": "boolean"
  },
  "customDoctorHeading": {
    "type": "boolean"
  },
  "customDoctorHeadingLine1": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine2": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine3": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine4": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine5": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine6": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine7": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine8": {
    "maxLength": 50,
    "type": "string"
  },
  "customDoctorHeadingLine9": {
    "maxLength": 50,
    "type": "string"
  },
  "graphicalRequestChooser": {
    "type": "boolean"
  },
  "ipConstraint": {
    "type": "boolean"
  },
  "autoprint": {
    "type": "boolean"
  },
  "speciality": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "reportsLanguageSetting": {},
  "reportCount": {
    "type": "integer"
  },
  "forms": {
    "type": "array"
  },
  "profiles": {
    "type": "array"
  },
  "globalProfiles": {
    "type": "array"
  },
  "groups": {
    "type": "array"
  },
  "groupForms": {
    "type": "array"
  },
  "devices": {
    "type": "array"
  },
  "doctorGroupOffices": {
    "type": "array"
  },
  "doctorGroupOfficeName": {
    "type": "string"
  },
  "pid": {
    "type": "number"
  },
  "editKisMode": {
    "type": "boolean"
  },
  "laboratory": {
    "lid": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "maxLength": 50,
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "maxLength": 100,
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "hl7Name": {
      "type": "string"
    },
    "target": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "companyName": {
      "type": "string"
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "phone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "whatsapp": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "fax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "rerequestMail": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "autoLockReport": {
      "type": "boolean"
    },
    "class0": {
      "type": "number"
    },
    "class1": {
      "type": "number"
    },
    "class2": {
      "type": "number"
    },
    "class3": {
      "type": "number"
    },
    "class4": {
      "type": "number"
    },
    "class5": {
      "type": "number"
    },
    "showPdf": {},
    "showOriginal": {},
    "showBdw": {},
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "type": [
        "string",
        "null"
      ]
    },
    "suffix": {
      "type": [
        "string",
        "null"
      ]
    },
    "loginSite": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "additional1": {
      "type": [
        "string",
        "null"
      ]
    },
    "additional2": {
      "type": [
        "string",
        "null"
      ]
    },
    "additional3": {
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "contactInfo": {
      "type": [
        "string",
        "null"
      ]
    },
    "verifyIKs": {
      "type": "boolean"
    },
    "displayText": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "concurrentLoginLimit": {
      "type": "integer"
    },
    "barcodeAlgorithm": {
      "maxLength": 10,
      "type": "string"
    },
    "barcodeLength": {
      "type": "integer"
    },
    "labelWidth": {
      "type": "integer"
    },
    "labelHeight": {
      "type": "integer"
    },
    "barcodeWidth": {
      "type": "integer"
    },
    "barcodeHeight": {
      "type": "integer"
    },
    "dynamicProbeTypeBase": {
      "type": "integer"
    },
    "reportDisplay": {
      "lid": {
        "type": "integer"
      },
      "emailReports": {
        "type": "boolean"
      },
      "exportReports": {
        "type": "boolean"
      },
      "contactLaboratory": {
        "type": "boolean"
      },
      "reportAttachments": {
        "type": "boolean"
      },
      "rerequests": {
        "type": "boolean"
      },
      "printOrders": {
        "type": "boolean"
      },
      "showPathoColumn": {
        "type": "boolean"
      },
      "hidePathoColumnPat": {
        "type": "boolean"
      },
      "pathoHighlightType": {},
      "showLaboratoryGroup": {
        "type": "boolean"
      },
      "rerequestTimeFrame": {
        "type": "number"
      },
      "alwaysShowDateAndTimeInCumulativeDisplay": {
        "type": "boolean"
      }
    },
    "mailTemplateGroup": {
      "mtgid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "reply": {
        "type": "string"
      },
      "target": {
        "type": [
          "string",
          "null"
        ]
      },
      "www_url": {
        "maxLength": 50,
        "pattern": "^https?://",
        "type": [
          "string",
          "null"
        ]
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "defaultPatientCanViewPartialResults": {
      "type": "boolean"
    },
    "defaultPatientCanViewPreliminaryResults": {
      "type": "boolean"
    },
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "overwrite": {
      "type": "boolean"
    },
    "resetCentralBarcodes": {
      "type": "boolean"
    },
    "mtlMail": {
      "maxLength": 250,
      "type": "string"
    },
    "useLgMail": {
      "type": "boolean"
    },
    "orderTimeframe": {
      "type": "integer"
    },
    "reRequestLimit": {
      "type": "integer"
    },
    "reRequestBuffer": {
      "type": "integer"
    },
    "reRequestApprove": {
      "type": "boolean"
    },
    "reRequestNoExport": {
      "type": "boolean"
    },
    "reRequestLimitSampleDate": {
      "type": "boolean"
    },
    "emailsBlock": {
      "type": "array"
    },
    "orderLocalization": {},
    "quickScanPdfMode": {
      "type": "integer"
    },
    "quickScanWalletMode": {
      "type": "integer"
    },
    "quickScanAskFirstName": {
      "type": "boolean"
    },
    "quickScanAskPassport": {
      "type": "boolean"
    },
    "logo": {
      "type": [
        "string",
        "null"
      ]
    },
    "fontColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "backgroundColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "barcodeMode": {},
  "printPosition": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMin": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMax": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printPrefix": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "useOwnNummernkreis": {
    "type": "boolean"
  },
  "useLgNummernkreis": {
    "type": "boolean"
  },
  "exportMe": {
    "type": "boolean"
  },
  "exportMeReceiver": {
    "type": [
      "string",
      "null"
    ]
  },
  "exportMeStatusString": {
    "type": "string"
  },
  "exportMeCharacteristicString": {
    "type": "string"
  },
  "exportMeSettingsStatusFlags": {
    "preliminary": {
      "type": "boolean"
    },
    "partial": {
      "type": "boolean"
    },
    "final": {
      "type": "boolean"
    }
  },
  "exportMeSettingsCharacteristicFlags": {
    "notPathological": {
      "type": "boolean"
    },
    "pathological": {
      "type": "boolean"
    },
    "extreme": {
      "type": "boolean"
    },
    "deltaCheck": {
      "type": "boolean"
    }
  },
  "pinnedRequirements": {
    "type": "array"
  }
};

export const LaboratoryUserSchema = {
  "luid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "maxLength": 50,
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "email": {
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "privilegeMode": {},
  "courierService": {
    "type": "boolean"
  }
};

export const AdministratorSchema = {
  "suid": {
    "type": "integer"
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  },
  "type": {},
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "email": {
    "type": "string"
  }
};

export const PatientSchema = {
  "pid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "mtgid": {
    "type": "integer"
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "email": {
    "maxLength": 200,
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "lastName": {
    "maxLength": 50,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "suffix": {
    "maxLength": 100,
    "type": "string"
  },
  "title": {
    "maxLength": 50,
    "type": "string"
  },
  "gender": {},
  "birthday": {
    "maxLength": 50,
    "type": "string"
  },
  "city": {
    "maxLength": 50,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 50,
    "type": "string"
  },
  "address": {
    "maxLength": 50,
    "type": "string"
  },
  "houseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "addressAdditional": {
    "maxLength": 50,
    "type": "string"
  },
  "country": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "phone": {
    "maxLength": 50,
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "ob": {
    "type": "boolean"
  },
  "of": {
    "type": "boolean"
  },
  "ll": {
    "type": "boolean"
  },
  "le": {
    "type": "boolean"
  },
  "ls": {
    "type": "boolean"
  },
  "mc": {
    "type": "boolean"
  },
  "nc": {
    "type": "boolean"
  },
  "lw": {
    "type": "boolean"
  },
  "dashboard": {
    "type": "boolean"
  },
  "features": {
    "type": "object"
  },
  "sonstiges1": {
    "maxLength": 50,
    "type": "string"
  },
  "sonstiges2": {
    "maxLength": 50,
    "type": "string"
  },
  "patientIdentification": {
    "maxLength": 20,
    "type": "string"
  },
  "emailNotificationEnabled": {
    "type": "boolean"
  },
  "smsNotificationEnabled": {
    "type": "boolean"
  },
  "smsNumber": {
    "type": "string"
  },
  "canViewPartialResults": {
    "type": "boolean"
  },
  "canViewPreliminaryResults": {
    "type": "boolean"
  },
  "resultLanguage": {
    "maxLength": 5,
    "type": "string"
  },
  "oa": {
    "type": "boolean"
  },
  "aids": {
    "type": "array"
  },
  "latest_report_date": {
    "type": "string"
  },
  "dateScore": {
    "type": "number"
  },
  "passportNumber": {
    "type": "string"
  },
  "idCardNumber": {
    "type": "string"
  }
};

export const UserSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "type": {},
  "entityId": {
    "type": "integer"
  },
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "email": {
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  },
  "alternative_kennung": {
    "type": [
      "string",
      "null"
    ]
  },
  "acceptedTocAt": {
    "type": [
      "string",
      "null"
    ]
  },
  "acceptedMaintenanceAt": {
    "type": [
      "string",
      "null"
    ]
  },
  "enabled2fa": {
    "type": "boolean"
  },
  "rejected2faAt": {
    "type": [
      "string",
      "null"
    ]
  },
  "apiDocs": {
    "type": "boolean"
  }
};

export const UserLogSchema = {
  "id": {
    "type": "integer"
  },
  "xuser_id": {
    "type": "integer"
  },
  "user_agent": {
    "type": "string"
  },
  "user_name": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "info": {
    "type": "string"
  },
  "ip": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "initiator": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "initiatorId": {
    "type": "integer"
  },
  "action": {}
};

export const UserAgentSchema = {
  "id": {
    "type": "integer"
  },
  "hash": {
    "type": "string"
  },
  "agent": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  }
};

export const DeviceSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "userId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "uuid": {
    "type": "string"
  },
  "platform": {},
  "target": {
    "type": "string"
  },
  "note": {
    "maxLength": 255,
    "type": "string"
  },
  "status": {},
  "userAgentId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "createdAt": {
    "type": "string"
  },
  "approvedAt": {
    "type": "string"
  },
  "revokedAt": {
    "type": [
      "string",
      "null"
    ]
  },
  "deniedAt": {
    "type": "string"
  },
  "lastUsedAt": {
    "type": "string"
  },
  "token": {
    "type": "string"
  },
  "shortToken": {
    "type": "string"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  }
};

export const DoctorDeviceSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "deviceId": {
    "maxLength": 100,
    "type": "string"
  },
  "active": {
    "type": "boolean"
  },
  "registeredAt": {
    "type": "string"
  },
  "note": {
    "maxLength": 150,
    "type": "string"
  },
  "user_agent_id": {
    "type": "integer"
  }
};

export const DoctorHierarchySchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "parent": {
    "type": [
      "integer",
      "null"
    ]
  },
  "type": {},
  "created_at": {
    "type": "string"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "owner": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "name": {
    "type": "string"
  }
};

export const PatientExtraSchema = {
  "id": {
    "type": "integer"
  },
  "pid": {
    "type": "integer"
  },
  "zs1": {
    "maxLength": 50,
    "type": "string"
  },
  "zs2": {
    "maxLength": 50,
    "type": "string"
  },
  "zs3": {
    "maxLength": 50,
    "type": "string"
  },
  "zs4": {
    "maxLength": 50,
    "type": "string"
  },
  "zs5": {
    "maxLength": 50,
    "type": "string"
  },
  "zs6": {
    "maxLength": 50,
    "type": "string"
  },
  "zs7": {
    "maxLength": 50,
    "type": "string"
  },
  "zs8": {
    "maxLength": 50,
    "type": "string"
  }
};

export const AppointmentSchema = {
  "id": {
    "type": "integer"
  },
  "message_id": {
    "type": "integer"
  },
  "date": {
    "type": "string"
  },
  "time": {
    "type": "string"
  },
  "recipient_id": {
    "type": "number"
  },
  "is_confirmed": {},
  "created_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  }
};

export const MessageThreadSchema = {
  "id": {
    "type": "integer"
  },
  "subject": {
    "type": "string"
  },
  "type": {},
  "created_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "lastMessage": {
    "id": {
      "type": "integer"
    },
    "thread_id": {
      "type": "integer"
    },
    "text": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "is_read": {
      "type": "boolean"
    },
    "readBy": {
      "type": "array"
    },
    "sender_id": {
      "type": "number"
    },
    "sender_avatar": {
      "type": "string"
    },
    "appointment": {
      "id": {
        "type": "integer"
      },
      "message_id": {
        "type": "integer"
      },
      "date": {
        "type": "string"
      },
      "time": {
        "type": "string"
      },
      "recipient_id": {
        "type": "number"
      },
      "is_confirmed": {},
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      }
    },
    "context_read_by": {
      "type": "array"
    }
  },
  "report": {
    "bid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "parent_bid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "status": {},
    "tnr": {
      "type": "string"
    },
    "pathological": {},
    "mibi": {},
    "created_at": {
      "type": "string"
    },
    "imported_at": {
      "type": "string"
    },
    "registered_at": {
      "type": "string"
    },
    "validated_at": {
      "type": "string"
    },
    "sampled_at": {
      "type": "string"
    },
    "diagnosis": {
      "maxLength": 100,
      "type": "string"
    },
    "validatingDoctor": {
      "maxLength": 100,
      "type": "string"
    },
    "validatingDoctorAddition": {
      "maxLength": 100,
      "type": "string"
    },
    "patientName": {
      "type": "string"
    },
    "is_locked": {
      "type": "boolean"
    },
    "is_unread": {
      "type": "boolean"
    },
    "is_pinned": {
      "type": "boolean"
    },
    "has_base64": {
      "type": "boolean"
    },
    "has_elpho": {
      "type": "boolean"
    },
    "has_subreports": {
      "type": "boolean"
    },
    "has_history": {
      "type": "boolean"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "patient": {
      "pid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 200,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 100,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 50,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "phone": {
        "maxLength": 50,
        "type": "string"
      },
      "costUnit": {
        "type": "string"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "sonstiges1": {
        "maxLength": 50,
        "type": "string"
      },
      "sonstiges2": {
        "maxLength": 50,
        "type": "string"
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "smsNotificationEnabled": {
        "type": "boolean"
      },
      "smsNumber": {
        "type": "string"
      },
      "canViewPartialResults": {
        "type": "boolean"
      },
      "canViewPreliminaryResults": {
        "type": "boolean"
      },
      "resultLanguage": {
        "maxLength": 5,
        "type": "string"
      },
      "oa": {
        "type": "boolean"
      },
      "aids": {
        "type": "array"
      },
      "latest_report_date": {
        "type": "string"
      },
      "dateScore": {
        "type": "number"
      },
      "passportNumber": {
        "type": "string"
      },
      "idCardNumber": {
        "type": "string"
      }
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "laboratoryGroupFilter": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 32,
        "type": "string"
      },
      "color": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      },
      "group": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "values": {
      "type": "array"
    },
    "subreports": {
      "type": "array"
    },
    "attachments": {
      "type": "array"
    },
    "attachment_count": {
      "type": "integer"
    },
    "comments": {
      "type": "array"
    },
    "comment_count": {
      "type": "integer"
    },
    "arzname": {
      "maxLength": 300,
      "type": "string"
    },
    "labname": {
      "type": "string"
    },
    "accountingType": {
      "type": "string"
    },
    "laborgruppe": {
      "type": "string"
    },
    "exttnr": {
      "type": "string"
    },
    "lebensnummer": {
      "type": "string"
    },
    "zimmer": {
      "type": "string"
    },
    "lastupdate": {
      "type": "string"
    },
    "orderComment": {
      "type": "string"
    },
    "overwritecolor": {
      "type": [
        "string",
        "null"
      ]
    },
    "highlightColor": {
      "type": [
        "string",
        "null"
      ]
    },
    "pdfSrc": {
      "type": "string"
    },
    "material": {
      "type": "string"
    },
    "caseNumber": {
      "type": "string"
    },
    "insuranceNumber": {
      "type": "string"
    },
    "showBdw": {
      "type": "boolean"
    },
    "showCumulative": {
      "type": "boolean"
    },
    "showPdf": {
      "type": "boolean"
    },
    "showOriginal": {
      "type": "boolean"
    },
    "showOriginalExclusive": {
      "type": "boolean"
    },
    "showCumulativePdf": {
      "type": "boolean"
    },
    "showCumulativeAllPdf": {
      "type": "boolean"
    },
    "showSend": {
      "type": "boolean"
    },
    "showRerequest": {
      "type": "boolean"
    },
    "originalPdfIds": {
      "type": "array"
    },
    "guid": {
      "type": "string"
    },
    "externalGuid": {
      "type": "string"
    },
    "manualCode": {
      "type": "string"
    },
    "uuid": {
      "type": "string"
    },
    "settlementType": {}
  },
  "order": {
    "lid": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "freeText": {
      "maxLength": 60,
      "type": "string"
    },
    "message": {
      "maxLength": 100,
      "type": "string"
    },
    "infectious": {
      "type": "boolean"
    },
    "urgent": {
      "type": "string"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "costUnit": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "controlCheckupKnownInfection": {
      "type": "boolean"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "dz16b": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    },
    "accidentTime": {
      "maxLength": 20,
      "type": "string"
    },
    "documentReferenceNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "anamnesticInfo": {
      "maxLength": 60,
      "type": "string"
    },
    "printedMaterials": {
      "type": [
        "string",
        "null"
      ]
    },
    "printQuickReportBarcode": {
      "type": "boolean"
    },
    "urgentNotificationPhone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationFax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationMail": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "id": {
      "type": "integer"
    },
    "status": {},
    "executionStatus": {},
    "poolId": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "type": "string"
    },
    "bsnr": {
      "type": "string"
    },
    "selectedDoctor": {
      "type": "string"
    },
    "apid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "patient": {
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 50,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 150,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "costUnit": {
        "type": "string"
      },
      "medication": {
        "maxLength": 100,
        "type": "string"
      },
      "cycleDay": {
        "maxLength": 10,
        "type": "string"
      },
      "weight": {
        "maxLength": 5,
        "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 150,
        "type": "string"
      },
      "exttnr": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "primaryCarePhysicianLanr": {
        "maxLength": 20,
        "type": "string"
      },
      "primaryCarePhysicianBsnr": {
        "maxLength": 20,
        "type": "string"
      },
      "diseaseManagementProgram": {
        "maxLength": 50,
        "type": "string"
      },
      "dmpSpecialGroup": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceStart": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceEnd": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceType": {
        "maxLength": 50,
        "type": "string"
      },
      "exceptionalIndication": {
        "maxLength": 20,
        "type": "string"
      },
      "sktIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "wopIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "accountingArea": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "hvcode": {
        "maxLength": 20,
        "type": "string"
      },
      "vkat": {
        "maxLength": 20,
        "type": "string"
      },
      "secondName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "canton": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "ahvNr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "aisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "insurance": {
        "type": [
          "number",
          "null"
        ]
      },
      "bag": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "cardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "globalLocationNumber": {
        "maxLength": 13,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "hospitalBedNumber": {
        "type": "string"
      },
      "hospitalRoomNumber": {
        "type": "string"
      },
      "hospitalProfession": {
        "type": [
          "string",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "invoiceTo": {
        "maxLength": 25,
        "type": "string"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "autInsuranceTitle": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceFirstName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceLastName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceInsuranceNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceBirthday": {
        "type": "string"
      },
      "autInsuranceAddress": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceHouseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCountry": {
        "maxLength": 10,
        "type": "string"
      },
      "autInsuranceZip": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCity": {
        "maxLength": 50,
        "type": "string"
      },
      "pid": {
        "type": "integer"
      },
      "apgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "displayName": {
        "type": "string"
      },
      "prefix": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 5,
        "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "pregnancyWeek": {
        "maxLength": 2,
        "pattern": "^(\\d{1,2})?$",
        "type": "string"
      },
      "pregnancyWeekDay": {
        "maxLength": 1,
        "pattern": "^(\\d{1})?$",
        "type": "string"
      },
      "cycleLength": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      },
      "dayOfCycle": {
        "maxLength": 2,
        "type": "string"
      },
      "fetalCrownRumpLength": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "fetalNuchalTranslucency": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "hasInsuranceNumber": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "sampleAmount": {
        "maxLength": 10,
        "type": "string"
      },
      "samplePeriod": {
        "maxLength": 10,
        "type": "string"
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "passportNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "idcardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "orderReason": {
        "maxLength": 1,
        "type": "string"
      },
      "costUnitIdentification": {
        "maxLength": 25,
        "type": "string"
      },
      "autInsuranceEmployer": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceEmployerCity": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceAddressAdditional": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "requirements": {
      "type": "array"
    },
    "anamnesis": {
      "type": "array"
    },
    "animal": {
      "name": {
        "maxLength": 50,
        "type": "string"
      },
      "birthday": {
        "maxLength": 8,
        "type": "string"
      },
      "species": {
        "maxLength": 2,
        "type": "string"
      },
      "speciesManual": {
        "maxLength": 50,
        "type": "string"
      },
      "chipId": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 3,
        "type": "string"
      },
      "weight": {
        "maxLength": 3,
        "type": "string"
      },
      "sex": {
        "type": "number"
      },
      "breed": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerName": {
        "maxLength": 100,
        "type": "string"
      },
      "ownerAmendment": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerStreet": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerHouseNumber": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerZipCode": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerCity": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "hungary": {
      "motherLastName": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "motherFirstName": {
        "maxLength": 15,
        "type": [
          "string",
          "null"
        ]
      },
      "treatmentReason": {
        "maxLength": 1,
        "type": [
          "string",
          "null"
        ]
      },
      "invoice": {
        "maxLength": 2,
        "type": [
          "string",
          "null"
        ]
      },
      "icd1": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd2": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd3": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "insuranceNumber": {
        "maxLength": 18,
        "type": [
          "string",
          "null"
        ]
      },
      "citizen": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "executed_at": {
      "type": "string"
    },
    "scheduled_at": {
      "type": [
        "string",
        "null"
      ]
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "covid": {
      "testNumber": {},
      "testReason": {},
      "agreed": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "riskCharacteristicsLocation": {},
      "riskCharacteristicsLocationType": {},
      "legalReason": {},
      "healthServiceZipCode": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "healthService": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "legalOrderBasis": {},
      "kvSpecial": {
        "type": [
          "string",
          "null"
        ]
      },
      "confirmationDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "variantDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "oegdInvoiceTo": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "covidWarnAppBarcode": {
        "type": [
          "boolean",
          "null"
        ]
      }
    },
    "mustValidate": {
      "type": "boolean"
    },
    "hospitalStation": {
      "type": "string"
    },
    "requirementDiagnosisFreeText": {
      "maxLength": 150,
      "type": "string"
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "selectedDiagnoses": {
      "type": "object"
    },
    "selectedDiagnosesChecked": {
      "type": "boolean"
    },
    "workstationChecked": {
      "type": "boolean"
    },
    "splitProperties": {
      "type": "array"
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "clientname": {
      "type": "string"
    },
    "printerAliasName": {
      "type": "string"
    },
    "overridePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "overrideBarcodePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "beforeAfterDialysis": {},
    "reportCopyAid": {
      "type": [
        "number",
        "null"
      ]
    },
    "reportCopyDoctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aisIdentifier": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "patientAisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "bloodCollectionType": {},
    "transported_at": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "digitalSignature": {},
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "report": {
      "bid": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "parent_bid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "status": {},
      "tnr": {
        "type": "string"
      },
      "pathological": {},
      "mibi": {},
      "created_at": {
        "type": "string"
      },
      "imported_at": {
        "type": "string"
      },
      "registered_at": {
        "type": "string"
      },
      "validated_at": {
        "type": "string"
      },
      "sampled_at": {
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctor": {
        "maxLength": 100,
        "type": "string"
      },
      "validatingDoctorAddition": {
        "maxLength": 100,
        "type": "string"
      },
      "patientName": {
        "type": "string"
      },
      "is_locked": {
        "type": "boolean"
      },
      "is_unread": {
        "type": "boolean"
      },
      "is_pinned": {
        "type": "boolean"
      },
      "has_base64": {
        "type": "boolean"
      },
      "has_elpho": {
        "type": "boolean"
      },
      "has_subreports": {
        "type": "boolean"
      },
      "has_history": {
        "type": "boolean"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "patient": {
        "pid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 200,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 100,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 50,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "phone": {
          "maxLength": 50,
          "type": "string"
        },
        "costUnit": {
          "type": "string"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "sonstiges1": {
          "maxLength": 50,
          "type": "string"
        },
        "sonstiges2": {
          "maxLength": 50,
          "type": "string"
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "smsNotificationEnabled": {
          "type": "boolean"
        },
        "smsNumber": {
          "type": "string"
        },
        "canViewPartialResults": {
          "type": "boolean"
        },
        "canViewPreliminaryResults": {
          "type": "boolean"
        },
        "resultLanguage": {
          "maxLength": 5,
          "type": "string"
        },
        "oa": {
          "type": "boolean"
        },
        "aids": {
          "type": "array"
        },
        "latest_report_date": {
          "type": "string"
        },
        "dateScore": {
          "type": "number"
        },
        "passportNumber": {
          "type": "string"
        },
        "idCardNumber": {
          "type": "string"
        }
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "laboratoryGroupFilter": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "maxLength": 32,
          "type": "string"
        },
        "color": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        },
        "group": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "values": {
        "type": "array"
      },
      "subreports": {
        "type": "array"
      },
      "attachments": {
        "type": "array"
      },
      "attachment_count": {
        "type": "integer"
      },
      "comments": {
        "type": "array"
      },
      "comment_count": {
        "type": "integer"
      },
      "arzname": {
        "maxLength": 300,
        "type": "string"
      },
      "labname": {
        "type": "string"
      },
      "accountingType": {
        "type": "string"
      },
      "laborgruppe": {
        "type": "string"
      },
      "exttnr": {
        "type": "string"
      },
      "lebensnummer": {
        "type": "string"
      },
      "zimmer": {
        "type": "string"
      },
      "lastupdate": {
        "type": "string"
      },
      "orderComment": {
        "type": "string"
      },
      "overwritecolor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "pdfSrc": {
        "type": "string"
      },
      "material": {
        "type": "string"
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceNumber": {
        "type": "string"
      },
      "showBdw": {
        "type": "boolean"
      },
      "showCumulative": {
        "type": "boolean"
      },
      "showPdf": {
        "type": "boolean"
      },
      "showOriginal": {
        "type": "boolean"
      },
      "showOriginalExclusive": {
        "type": "boolean"
      },
      "showCumulativePdf": {
        "type": "boolean"
      },
      "showCumulativeAllPdf": {
        "type": "boolean"
      },
      "showSend": {
        "type": "boolean"
      },
      "showRerequest": {
        "type": "boolean"
      },
      "originalPdfIds": {
        "type": "array"
      },
      "guid": {
        "type": "string"
      },
      "externalGuid": {
        "type": "string"
      },
      "manualCode": {
        "type": "string"
      },
      "uuid": {
        "type": "string"
      },
      "settlementType": {}
    },
    "pool": {
      "id": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "displayName": {
        "type": "string"
      },
      "status": {
        "type": "array"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "orders": {
        "type": "array"
      },
      "logs": {
        "type": "array"
      },
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isSoftDeleted": {
        "type": "boolean"
      }
    },
    "samples": {
      "type": "array"
    },
    "workstation": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "name": {
        "type": "string"
      },
      "location": {
        "type": "string"
      },
      "default": {
        "type": "boolean"
      },
      "status": {},
      "clientVersion": {
        "type": "string"
      },
      "printers": {
        "type": "array"
      }
    },
    "manufacturer": {
      "type": "string"
    },
    "initialWorkplaceId": {
      "type": "number"
    },
    "lastWorkplaceId": {
      "type": "number"
    },
    "materialsString": {
      "type": "string"
    },
    "requirementsString": {
      "type": "string"
    },
    "costUnitsString": {
      "type": "string"
    },
    "journalString": {
      "type": "string"
    },
    "orderFormNamesString": {
      "type": "string"
    },
    "splitId": {
      "type": "integer"
    },
    "quickScanPdfPassword": {
      "type": [
        "string",
        "null"
      ]
    },
    "hasAttachment": {
      "type": "boolean"
    },
    "case_id": {
      "type": "integer"
    },
    "caseNumber": {
      "type": "string"
    },
    "realReRequestConditions": {
      "allowed": {
        "type": "boolean"
      },
      "formIds": {
        "type": "array"
      },
      "requirementIds": {
        "type": "array"
      },
      "profileIds": {
        "type": "array"
      }
    },
    "exportFlag": {
      "type": "boolean"
    },
    "statusExport": {
      "type": "integer"
    },
    "statusStornoExport": {
      "type": "integer"
    },
    "comment": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "isCancelable": {
      "type": "boolean"
    },
    "actuallyPrintedMaterials": {
      "type": "string"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    }
  },
  "users": {
    "type": "array"
  },
  "starred": {
    "type": "boolean"
  },
  "last_message_read_by": {
    "type": "integer"
  }
};

export const MessageSchema = {
  "id": {
    "type": "integer"
  },
  "thread_id": {
    "type": "integer"
  },
  "text": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  },
  "is_read": {
    "type": "boolean"
  },
  "readBy": {
    "type": "array"
  },
  "sender_id": {
    "type": "number"
  },
  "sender_avatar": {
    "type": "string"
  },
  "appointment": {
    "id": {
      "type": "integer"
    },
    "message_id": {
      "type": "integer"
    },
    "date": {
      "type": "string"
    },
    "time": {
      "type": "string"
    },
    "recipient_id": {
      "type": "number"
    },
    "is_confirmed": {},
    "created_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    }
  },
  "context_read_by": {
    "type": "array"
  }
};

export const UserMessageSchema = {
  "id": {
    "type": "integer"
  },
  "user_id": {
    "type": "integer"
  },
  "message_id": {
    "type": "integer"
  },
  "is_read": {
    "type": "boolean"
  }
};

export const UserThreadSchema = {
  "id": {
    "type": "integer"
  },
  "user_id": {
    "type": "integer"
  },
  "thread_id": {
    "type": "integer"
  },
  "news_id": {
    "type": "integer"
  },
  "is_starred": {
    "type": "boolean"
  },
  "is_deleted": {
    "type": "boolean"
  }
};

export const CaseSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "pid": {
    "type": "integer"
  },
  "caseNumber": {
    "type": "string"
  },
  "diagnosis": {
    "type": "string"
  },
  "room": {
    "type": "string"
  },
  "status": {},
  "msh3": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "msh4": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "created_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "patient": {
    "pid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "maxLength": 200,
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "suffix": {
      "maxLength": 100,
      "type": "string"
    },
    "title": {
      "maxLength": 50,
      "type": "string"
    },
    "gender": {},
    "birthday": {
      "maxLength": 50,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 50,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "addressAdditional": {
      "maxLength": 50,
      "type": "string"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "phone": {
      "maxLength": 50,
      "type": "string"
    },
    "costUnit": {
      "type": "string"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "sonstiges1": {
      "maxLength": 50,
      "type": "string"
    },
    "sonstiges2": {
      "maxLength": 50,
      "type": "string"
    },
    "patientIdentification": {
      "maxLength": 20,
      "type": "string"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "smsNotificationEnabled": {
      "type": "boolean"
    },
    "smsNumber": {
      "type": "string"
    },
    "canViewPartialResults": {
      "type": "boolean"
    },
    "canViewPreliminaryResults": {
      "type": "boolean"
    },
    "resultLanguage": {
      "maxLength": 5,
      "type": "string"
    },
    "oa": {
      "type": "boolean"
    },
    "aids": {
      "type": "array"
    },
    "latest_report_date": {
      "type": "string"
    },
    "dateScore": {
      "type": "number"
    },
    "passportNumber": {
      "type": "string"
    },
    "idCardNumber": {
      "type": "string"
    }
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "events": {
    "type": "array"
  },
  "reports": {
    "type": "array"
  },
  "orders": {
    "type": "array"
  }
};

export const CaseEventSchema = {
  "id": {
    "type": "integer"
  },
  "caseId": {
    "type": "integer"
  },
  "caseNumber": {
    "type": "string"
  },
  "type": {},
  "created_at": {
    "type": "string"
  },
  "effective_at": {
    "type": "string"
  }
};

export const DigitalSignatureSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "aufid": {
    "type": "integer"
  },
  "base64": {
    "type": "string"
  },
  "base64signed": {
    "type": "string"
  },
  "hash": {
    "type": "string"
  },
  "hashSigned": {
    "type": "string"
  },
  "status": {},
  "createdAt": {
    "type": "string"
  },
  "updatedAt": {
    "type": "string"
  }
};

export const WorkstationSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "location": {
    "type": "string"
  },
  "default": {
    "type": "boolean"
  },
  "status": {},
  "clientVersion": {
    "type": "string"
  },
  "printers": {
    "type": "array"
  }
};

export const PrinterSchema = {
  "id": {
    "type": "integer"
  },
  "workstationId": {
    "type": "integer"
  },
  "workstationClientId": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "barcodePrinterName": {
    "type": "string"
  },
  "formPrinterName": {
    "type": "string"
  },
  "barcodePrintType": {
    "type": "integer"
  },
  "printType": {
    "type": "integer"
  },
  "socketPrint": {
    "type": "boolean"
  },
  "pageA5ToA4": {
    "type": "boolean"
  },
  "lpInstruction": {
    "type": "string"
  },
  "barcodeAutoPrintOccasion": {
    "type": "boolean"
  },
  "autoPrintOccasion": {
    "type": "boolean"
  }
};

export const ExchangeFileSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "filename": {
    "type": "string"
  },
  "fileType": {},
  "tnr": {
    "type": "string"
  },
  "lg": {
    "type": "string"
  },
  "lanr": {
    "maxLength": 9,
    "type": [
      "string",
      "null"
    ]
  },
  "additionalKey": {
    "type": [
      "string",
      "null"
    ]
  },
  "ldtVersion": {
    "type": [
      "integer",
      "null"
    ]
  },
  "patientName": {
    "type": "string"
  },
  "doctorName": {
    "type": "string"
  },
  "base64": {
    "type": "string"
  },
  "isExported": {
    "type": "boolean"
  },
  "isRepeatedExported": {
    "type": "boolean"
  },
  "insert_by": {
    "type": "string"
  },
  "last_update_by": {
    "type": "string"
  },
  "importedAt": {
    "type": "string"
  },
  "exportedAt": {
    "type": "string"
  },
  "deletedAt": {
    "type": "string"
  }
};

export const ExchangeStorageSchema = {
  "id": {
    "type": "integer"
  },
  "filename": {
    "type": "string"
  },
  "content": {
    "type": "string"
  }
};

export const ExchangeDoctorGroupSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "isOrderGroup": {
    "type": "boolean"
  }
};

export const ExchangeExportSettingsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "aidOwner": {
    "type": "integer"
  },
  "filenameLA": {
    "type": "string"
  },
  "filenameLG": {
    "type": "string"
  },
  "encoding": {},
  "directoryLA": {
    "type": "string"
  },
  "directoryLG": {
    "type": "string"
  },
  "directorySO": {
    "type": "string"
  },
  "directoryPDF": {
    "type": "string"
  }
};

export const ExchangeImportDirectoriesSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "dir": {
    "type": "string"
  }
};

export const ExchangeImportSettingsLdtSchema = {
  "lid": {
    "type": "integer"
  },
  "importDirectory": {
    "type": "string"
  },
  "archiveDirectory": {
    "type": "string"
  },
  "encoding": {},
  "ldt2ModeLaboratoryGroupIdentification": {},
  "ldt2ModeLaboratoryGroupIdentificationValueList": {
    "type": "string"
  },
  "fileUpdateMode": {},
  "tnrFieldIdentification": {
    "maxLength": 4,
    "type": "string"
  },
  "ldt3ModeLaboratoryGroupIdentification": {},
  "ldt3ModeLaboratoryGroupIdentificationValueList": {
    "type": "string"
  },
  "ldt3Mode2ObjectAndField": {
    "maxLength": 30,
    "type": "string"
  }
};

export const ExchangeImportSettingsPdfSchema = {
  "lid": {
    "type": "integer"
  },
  "importDirectory": {
    "type": "string"
  },
  "archiveDirectory": {
    "type": "string"
  },
  "fileUpdateMode": {}
};

export const StoreCategorySchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "parentId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "name": {
    "type": "string"
  },
  "isPublic": {
    "type": "boolean"
  },
  "path": {
    "type": "array"
  },
  "children": {
    "type": "array"
  },
  "doctors": {
    "type": "array"
  }
};

export const StoreCategoryPermissionSchema = {
  "category_id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  }
};

export const StoreManufacturerSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "link": {
    "maxLength": 255,
    "type": [
      "string",
      "null"
    ]
  },
  "isActive": {
    "type": "boolean"
  },
  "createdAt": {
    "type": "string"
  }
};

export const StoreProductSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "name": {
    "maxLength": 255,
    "type": "string"
  },
  "pzn": {
    "maxLength": 13,
    "type": "string"
  },
  "articleNumber": {
    "maxLength": 255,
    "type": "string"
  },
  "introduction": {
    "maxLength": 255,
    "type": "string"
  },
  "description": {
    "type": "string"
  },
  "price": {
    "type": "number"
  },
  "unitAmount": {
    "type": "number"
  },
  "unitType": {},
  "manufacturerId": {
    "type": "integer"
  },
  "manufacturer": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 100,
      "type": "string"
    },
    "link": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "isActive": {
      "type": "boolean"
    },
    "createdAt": {
      "type": "string"
    }
  },
  "categories": {
    "type": "array"
  },
  "images": {
    "type": "array"
  },
  "isPublic": {
    "type": "boolean"
  },
  "isActive": {
    "type": "boolean"
  },
  "isInStock": {
    "type": "boolean"
  },
  "needsDoctorManufacturer": {
    "type": "boolean"
  },
  "createdAt": {
    "type": "string"
  }
};

export const StoreProductImageSchema = {
  "id": {
    "type": "integer"
  },
  "productId": {
    "type": "integer"
  },
  "content": {
    "type": "string"
  },
  "contentFile": {
    "type": "object"
  },
  "thumbnail": {
    "type": "string"
  },
  "order": {
    "type": "integer"
  }
};

export const StoreOrderSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "userId": {
    "type": "integer"
  },
  "user": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "aid": {
    "type": "integer"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "status": {},
  "total": {
    "type": "number"
  },
  "freeText": {
    "maxLength": 250,
    "type": "string"
  },
  "orderNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "items": {
    "type": "array"
  },
  "createdAt": {
    "type": "string"
  }
};

export const StoreOrderProductSchema = {
  "orderId": {
    "type": "integer"
  },
  "productId": {
    "type": "integer"
  },
  "amount": {
    "type": "integer"
  },
  "total": {
    "type": "number"
  },
  "product": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 255,
      "type": "string"
    },
    "pzn": {
      "maxLength": 13,
      "type": "string"
    },
    "articleNumber": {
      "maxLength": 255,
      "type": "string"
    },
    "introduction": {
      "maxLength": 255,
      "type": "string"
    },
    "description": {
      "type": "string"
    },
    "price": {
      "type": "number"
    },
    "unitAmount": {
      "type": "number"
    },
    "unitType": {},
    "manufacturerId": {
      "type": "integer"
    },
    "manufacturer": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 100,
        "type": "string"
      },
      "link": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "isActive": {
        "type": "boolean"
      },
      "createdAt": {
        "type": "string"
      }
    },
    "categories": {
      "type": "array"
    },
    "images": {
      "type": "array"
    },
    "isPublic": {
      "type": "boolean"
    },
    "isActive": {
      "type": "boolean"
    },
    "isInStock": {
      "type": "boolean"
    },
    "needsDoctorManufacturer": {
      "type": "boolean"
    },
    "createdAt": {
      "type": "string"
    }
  }
};

export const SendSmsSettingsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "apiId": {
    "type": "string"
  },
  "apiKey": {
    "type": "integer"
  },
  "url": {
    "type": "string"
  },
  "method": {
    "type": "string"
  }
};

export const NotificationSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "uid": {
    "type": "integer"
  },
  "type": {},
  "title": {
    "type": "string"
  },
  "body": {
    "type": "string"
  },
  "data": {
    "type": "object"
  },
  "status": {},
  "createdAt": {
    "type": "string"
  },
  "readAt": {
    "type": "string"
  },
  "processedAt": {
    "type": "string"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "type": {},
    "entityId": {
      "type": "integer"
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "type": "string"
    },
    "email": {
      "type": "string"
    },
    "locale": {},
    "userDebugEnabled": {
      "type": "boolean"
    },
    "alternative_kennung": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedTocAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "acceptedMaintenanceAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "enabled2fa": {
      "type": "boolean"
    },
    "rejected2faAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "apiDocs": {
      "type": "boolean"
    }
  },
  "sms": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "nid": {
      "type": "integer"
    },
    "number": {
      "type": "string"
    },
    "messente_id": {
      "type": "string"
    },
    "messente_status": {
      "type": "string"
    },
    "text": {
      "type": "string"
    },
    "sender": {
      "type": "string"
    },
    "status": {},
    "createdAt": {
      "type": "string"
    },
    "deliveredAt": {
      "type": [
        "string",
        "null"
      ]
    },
    "failedAt": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "push": {
    "puid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "created_at": {
      "type": "string"
    },
    "processed_at": {
      "type": "string"
    },
    "pushToken": {
      "type": "string"
    },
    "deviceType": {
      "type": "string"
    },
    "sent_at": {
      "type": "string"
    },
    "notification_id": {
      "type": "integer"
    },
    "target": {
      "type": "string"
    },
    "title": {
      "type": "string"
    },
    "body": {
      "type": "string"
    },
    "data": {
      "type": "object"
    },
    "result": {}
  },
  "mail": {
    "smid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "receiverMail": {
      "type": "string"
    },
    "receiverName": {
      "type": "string"
    },
    "subject": {
      "type": "string"
    },
    "body": {
      "type": "string"
    },
    "type": {},
    "encoding": {},
    "attachment": {
      "type": "string"
    },
    "date": {
      "type": "string"
    },
    "sent": {
      "type": "boolean"
    },
    "reply": {
      "type": "string"
    },
    "printId": {
      "type": "number"
    },
    "mailTemplateGroupId": {
      "type": "number"
    },
    "notification_id": {
      "type": [
        "integer",
        "null"
      ]
    }
  }
};

export const SendSmsSchema = {
  "id": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "nid": {
    "type": "integer"
  },
  "number": {
    "type": "string"
  },
  "messente_id": {
    "type": "string"
  },
  "messente_status": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "sender": {
    "type": "string"
  },
  "status": {},
  "createdAt": {
    "type": "string"
  },
  "deliveredAt": {
    "type": [
      "string",
      "null"
    ]
  },
  "failedAt": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const BaseProcessMetricsSchema = {
  "pid": {
    "type": "number"
  },
  "type": {},
  "cpuUsage": {
    "type": "number"
  },
  "memoryUsed": {
    "type": "string"
  }
};

export const DataCacheSchema = {
  "key": {
    "type": "string"
  },
  "keys": {
    "type": "integer"
  },
  "size": {
    "type": "number"
  },
  "items": {
    "type": "array"
  }
};

export const DataStatisticsSchema = {
  "userCount": {
    "type": "integer"
  },
  "patientsCount": {
    "type": "integer"
  },
  "doctorsCount": {
    "type": "integer"
  },
  "orderFormsCount": {
    "type": "integer"
  },
  "samplesCount": {
    "type": "integer"
  },
  "clinicalRequirementCount": {
    "type": "integer"
  },
  "clinicalAnalysesCount": {
    "type": "integer"
  },
  "clinicalMaterialCount": {
    "type": "integer"
  },
  "mibiRequirementCount": {
    "type": "integer"
  },
  "mibiAnalysesCount": {
    "type": "integer"
  },
  "mibiMaterialCount": {
    "type": "integer"
  }
};

export const MetricsSchema = {
  "cpuCount": {
    "type": "number"
  },
  "cpuUsage": {
    "type": "number"
  },
  "memoryTotal": {
    "type": "number"
  },
  "memoryUsed": {
    "type": "number"
  },
  "versions": {
    "node": {
      "type": "string"
    },
    "redis": {
      "type": "string"
    },
    "postgres": {
      "type": "string"
    }
  },
  "processes": {
    "type": "array"
  },
  "statistics": {
    "userCount": {
      "type": "integer"
    },
    "patientsCount": {
      "type": "integer"
    },
    "doctorsCount": {
      "type": "integer"
    },
    "orderFormsCount": {
      "type": "integer"
    },
    "samplesCount": {
      "type": "integer"
    },
    "clinicalRequirementCount": {
      "type": "integer"
    },
    "clinicalAnalysesCount": {
      "type": "integer"
    },
    "clinicalMaterialCount": {
      "type": "integer"
    },
    "mibiRequirementCount": {
      "type": "integer"
    },
    "mibiAnalysesCount": {
      "type": "integer"
    },
    "mibiMaterialCount": {
      "type": "integer"
    }
  },
  "cache": {
    "key": {
      "type": "string"
    },
    "keys": {
      "type": "integer"
    },
    "size": {
      "type": "number"
    },
    "items": {
      "type": "array"
    }
  },
  "redis": {
    "key": {
      "type": "string"
    },
    "keys": {
      "type": "integer"
    },
    "size": {
      "type": "number"
    },
    "items": {
      "type": "array"
    }
  }
};

export const MetricsProcessSchema = {
  "pid": {
    "type": "number"
  },
  "type": {}
};

export const NumberValueSchema = {
  "label": {
    "type": "string"
  },
  "value": {
    "type": "number"
  }
};

export const NumberMinMaxRangeValueSchema = {
  "label": {
    "type": "string"
  },
  "value": {
    "type": "number"
  },
  "min": {
    "type": "number"
  },
  "max": {
    "type": "number"
  }
};

export const SystemMetricsSchema = {
  "cpuUsage": {
    "type": "number"
  },
  "memoryUsed": {
    "type": "number"
  }
};

export const SystemVersionsSchema = {
  "node": {
    "type": "string"
  },
  "redis": {
    "type": "string"
  },
  "postgres": {
    "type": "string"
  }
};

export const TokenUserPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "type": {}
};

export const TokenPropertiesSchema = {
  "iat": {
    "type": "integer"
  },
  "exp": {
    "type": "integer"
  },
  "user": {
    "id": {
      "type": "integer"
    },
    "entityId": {
      "type": "integer"
    },
    "type": {}
  }
};

export const TokenCreationSettingsPropertiesSchema = {
  "expiration": {
    "type": "number"
  }
};

export const UserDetailPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "labors": {
    "type": "array"
  },
  "type": {},
  "kennung": {
    "type": "string"
  },
  "displayName": {
    "type": "string"
  },
  "email": {
    "type": "string"
  },
  "products": {
    "type": "array"
  },
  "features": {
    "type": "array"
  },
  "preferences": {
    "startProduct": {},
    "fontSize": {},
    "contrast": {},
    "newExchangeFileNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotificationSettings": {
      "characteristics": {
        "type": "array"
      },
      "status": {
        "type": "array"
      },
      "custom": {
        "type": "boolean"
      }
    },
    "reportsDefaultOrder": {},
    "reportsPatientNameSorting": {
      "type": "string"
    },
    "reportsPatientNameDisplayEnabled": {
      "type": "boolean"
    },
    "reportsPatientNameDisplay": {},
    "reportsDefaultFilterProfile": {
      "type": "integer"
    },
    "reportsFavoriteAnalyses": {
      "type": "array"
    },
    "reportsPinnedOnTop": {
      "type": "boolean"
    },
    "reportsInitialOpenCumulative": {
      "type": "boolean"
    },
    "reportsCumulativeMergeDays": {
      "type": "boolean"
    },
    "reportsCumulativeSingleReport": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalysesEnabledMode": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalyses": {
      "type": "array"
    },
    "reportsPdfDownloadPathoAnalyses": {
      "type": "array"
    },
    "enableAdvancedAnalysesFilter": {
      "type": "boolean"
    },
    "reportsBarcodeEnableCustomization": {
      "type": "boolean"
    },
    "reportsBarcodePrefixLength": {
      "type": "integer"
    },
    "reportsBarcodeLength": {
      "type": "integer"
    },
    "reportsBarcodeSuffixLength": {
      "type": "integer"
    },
    "reportsPatientsListShowInsuranceNumber": {
      "type": "boolean"
    },
    "reportsPatientsListShowPatientIdentification": {
      "type": "boolean"
    },
    "reportsPatientsListShowEmail": {
      "type": "boolean"
    },
    "reportsPatientsListShowBirthday": {
      "type": "boolean"
    },
    "reportsCustomSortBdwEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeGroupsEnabled": {
      "type": "boolean"
    },
    "reportsDeltaCheckFilter": {
      "type": "boolean"
    },
    "reportsValueFollowsEnabled": {
      "type": "boolean"
    },
    "reportsValueFollowsText": {
      "type": "string"
    },
    "reportsValueFollowsHideNteEmpty": {
      "type": "boolean"
    },
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    },
    "casesOrderListOpened": {
      "type": "boolean"
    },
    "casesDisabledReportLaboratoryGroups": {
      "type": "array"
    },
    "storeOrderNumberCurrent": {
      "type": "number"
    },
    "storeOrderNumberFormat": {
      "type": "string"
    }
  },
  "permissions": {
    "permissionChangePasswordDoctor": {
      "type": "boolean"
    },
    "permissionChangeUsernameDoctor": {
      "type": "boolean"
    },
    "permissionDeleteReport": {
      "type": "boolean"
    },
    "permissionModifyDoctor": {
      "type": "boolean"
    },
    "permissionModifyPatient": {
      "type": "boolean"
    },
    "permissionReactivateDoctor": {
      "type": "boolean"
    },
    "permissionReactivatePatient": {
      "type": "boolean"
    },
    "permissionLockDoctor": {
      "type": "boolean"
    },
    "permissionLockPatient": {
      "type": "boolean"
    },
    "permissionDeactivateDoctor": {
      "type": "boolean"
    },
    "permissionDeactivatePatient": {
      "type": "boolean"
    },
    "permissionPasswordResetMailDoctor": {
      "type": "boolean"
    },
    "permissionPasswordResetMailPatient": {
      "type": "boolean"
    },
    "permissionAccessUserLogPatient": {
      "type": "boolean"
    },
    "permissionAccessUserlogDoctor": {
      "type": "boolean"
    },
    "permissionChangeUsernamePatient": {
      "type": "boolean"
    },
    "permissionChangePasswordPatient": {
      "type": "boolean"
    },
    "permissionDeleteDoctor": {
      "type": "boolean"
    },
    "permissionDeletePatient": {
      "type": "boolean"
    },
    "permissionActivateDoctor": {
      "type": "boolean"
    },
    "permissionActivatePatient": {
      "type": "boolean"
    },
    "permissionViewMails": {
      "type": "boolean"
    },
    "permissionViewasDoctor": {
      "type": "boolean"
    },
    "permissionViewasPatient": {
      "type": "boolean"
    },
    "permissionPushtest": {
      "type": "boolean"
    },
    "permissionViewReportprotocol": {
      "type": "boolean"
    }
  },
  "debugEnabled": {
    "type": "boolean"
  },
  "enabled2fa": {
    "type": "boolean"
  },
  "locale": {}
};

export const CasesConfigSchema = {
  "preferences": {
    "casesOrderListOpened": {
      "type": "boolean"
    },
    "casesDisabledReportLaboratoryGroups": {
      "type": "array"
    }
  }
};

export const CasesGeneralConfigSchema = {
  "preferences": {
    "casesOrderListOpened": {
      "type": "boolean"
    },
    "casesDisabledReportLaboratoryGroups": {
      "type": "array"
    }
  }
};

export const CasesUserConfigSchema = {
  "preferences": {
    "casesOrderListOpened": {
      "type": "boolean"
    },
    "casesDisabledReportLaboratoryGroups": {
      "type": "array"
    }
  }
};

export const GlobalConfigPropertiesSchema = {
  "serverId": {
    "type": "string"
  },
  "privilegeMode": {},
  "filterProfiles": {
    "type": "array"
  },
  "links": {
    "config": {
      "showToc": {
        "type": "boolean"
      },
      "showPrivacy": {
        "type": "boolean"
      },
      "showContact": {
        "type": "boolean"
      },
      "showContactForm": {
        "type": "boolean"
      }
    },
    "entries": {
      "type": "array"
    },
    "hasToc": {
      "type": "boolean"
    },
    "hasPrivacy": {
      "type": "boolean"
    }
  },
  "system": {
    "maintenance": {
      "enabled": {
        "type": "boolean"
      },
      "title": {
        "type": "string"
      },
      "message": {
        "type": "string"
      }
    },
    "banner": {
      "enabled": {
        "type": "boolean"
      },
      "message": {
        "type": "string"
      }
    },
    "acceptedMaintenanceAt": {
      "type": "boolean"
    },
    "search": {
      "autocompleteEntries": {
        "type": "integer"
      }
    },
    "freeBarcodesPolicy": {
      "enabled": {
        "type": "boolean"
      },
      "regex": {
        "type": "string"
      }
    },
    "regexSearch": {
      "tnrEnabled": {
        "type": "boolean"
      },
      "tnrRegex": {
        "type": "string"
      },
      "exttnrEnabled": {
        "type": "boolean"
      },
      "exttnrRegex": {
        "type": "string"
      },
      "caseNumberEnabled": {
        "type": "boolean"
      },
      "caseNumberRegex": {
        "type": "string"
      },
      "insuranceNumberEnabled": {
        "type": "boolean"
      },
      "insuranceNumberRegex": {
        "type": "string"
      },
      "patientNameOrEmailEnabled": {
        "type": "boolean"
      },
      "patientNameOrEmailRegex": {
        "type": "string"
      }
    },
    "formatPriceDecimalType": {},
    "extendedGenderSelection": {
      "type": "boolean"
    }
  },
  "preferences": {
    "startProduct": {},
    "fontSize": {},
    "contrast": {},
    "newExchangeFileNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotificationSettings": {
      "characteristics": {
        "type": "array"
      },
      "status": {
        "type": "array"
      },
      "custom": {
        "type": "boolean"
      }
    },
    "reportsDefaultOrder": {},
    "reportsPatientNameSorting": {
      "type": "string"
    },
    "reportsPatientNameDisplayEnabled": {
      "type": "boolean"
    },
    "reportsPatientNameDisplay": {},
    "reportsDefaultFilterProfile": {
      "type": "integer"
    },
    "reportsFavoriteAnalyses": {
      "type": "array"
    },
    "reportsPinnedOnTop": {
      "type": "boolean"
    },
    "reportsInitialOpenCumulative": {
      "type": "boolean"
    },
    "reportsCumulativeMergeDays": {
      "type": "boolean"
    },
    "reportsCumulativeSingleReport": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalysesEnabledMode": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalyses": {
      "type": "array"
    },
    "reportsPdfDownloadPathoAnalyses": {
      "type": "array"
    },
    "enableAdvancedAnalysesFilter": {
      "type": "boolean"
    },
    "reportsBarcodeEnableCustomization": {
      "type": "boolean"
    },
    "reportsBarcodePrefixLength": {
      "type": "integer"
    },
    "reportsBarcodeLength": {
      "type": "integer"
    },
    "reportsBarcodeSuffixLength": {
      "type": "integer"
    },
    "reportsPatientsListShowInsuranceNumber": {
      "type": "boolean"
    },
    "reportsPatientsListShowPatientIdentification": {
      "type": "boolean"
    },
    "reportsPatientsListShowEmail": {
      "type": "boolean"
    },
    "reportsPatientsListShowBirthday": {
      "type": "boolean"
    },
    "reportsCustomSortBdwEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeGroupsEnabled": {
      "type": "boolean"
    },
    "reportsDeltaCheckFilter": {
      "type": "boolean"
    },
    "reportsValueFollowsEnabled": {
      "type": "boolean"
    },
    "reportsValueFollowsText": {
      "type": "string"
    },
    "reportsValueFollowsHideNteEmpty": {
      "type": "boolean"
    },
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    },
    "casesOrderListOpened": {
      "type": "boolean"
    },
    "casesDisabledReportLaboratoryGroups": {
      "type": "array"
    },
    "storeOrderNumberCurrent": {
      "type": "number"
    },
    "storeOrderNumberFormat": {
      "type": "string"
    }
  },
  "passwordPolicyPattern": {
    "type": "string"
  },
  "quickAccess": {
    "enabled": {
      "type": "boolean"
    },
    "enabledOnOtherDomain": {
      "type": "boolean"
    },
    "fields": {
      "type": "array"
    },
    "path": {
      "type": "string"
    },
    "privacyUrl": {
      "type": "string"
    },
    "multiPathsEnabled": {
      "type": "boolean"
    },
    "paths": {
      "type": "array"
    },
    "reportMaxAge": {
      "type": "integer"
    },
    "reportMaxAgeInterval": {},
    "orderNumberType": {},
    "orderNumberLength": {
      "type": "integer"
    },
    "orderNumberNumericOnly": {
      "type": "boolean"
    },
    "orderNumberPrefix": {
      "type": "string"
    },
    "orderNumberSuffix": {
      "type": "string"
    },
    "useOrderNumberPrefix": {
      "type": "boolean"
    },
    "useOrderNumberSuffix": {
      "type": "boolean"
    },
    "partialReportsEnabled": {
      "type": "boolean"
    },
    "lockedReportsEnabled": {
      "type": "boolean"
    },
    "enableMailNotification": {
      "type": "boolean"
    },
    "multiDownloadEnabled": {
      "type": "boolean"
    },
    "multiDownloadMaximumCount": {
      "type": "integer"
    },
    "levenshtein": {
      "distance": {
        "type": "number"
      },
      "insertCost": {
        "type": "number"
      },
      "deleteCost": {
        "type": "number"
      },
      "substituteCost": {
        "type": "number"
      }
    }
  },
  "quickScan": {
    "enabled": {
      "type": "boolean"
    },
    "multiTargetsEnabled": {
      "type": "boolean"
    },
    "targets": {
      "type": "array"
    },
    "manualEntry": {
      "type": "boolean"
    },
    "partialReportsEnabled": {
      "type": "boolean"
    },
    "highlightPathological": {
      "type": "boolean"
    },
    "reportMaxAge": {
      "type": "integer"
    },
    "reportMaxAgeInterval": {},
    "explicitAnalyses": {
      "type": "array"
    }
  },
  "allowedFileEndings": {
    "type": "string"
  },
  "svnrLogin": {
    "type": "boolean"
  },
  "doctorSmsVerification": {
    "type": "boolean"
  },
  "twoFactorAuthEnabled": {
    "type": "boolean"
  },
  "twoFactorShowInUserList": {
    "type": "boolean"
  },
  "disableUserServerId": {
    "type": "boolean"
  },
  "customUsernamePrefix": {
    "type": "string"
  },
  "orderLocalization": {},
  "canActivatePatients": {
    "type": "boolean"
  },
  "softDeleteOrders": {
    "type": "boolean"
  },
  "lcRegistrationTokenDefaultExpiryTime": {
    "type": "number"
  },
  "hideEmailFields": {
    "type": "boolean"
  },
  "doctorFirstLoginEmail": {
    "type": "boolean"
  },
  "enablePasswordVisibilityToggleButton": {
    "type": "boolean"
  },
  "useExchangeTokenShortForm": {
    "type": "boolean"
  },
  "recoveryUrl": {
    "type": "string"
  }
};

export const ReportsQuickAccessPathsConfigSchema = {
  "path": {
    "type": "string"
  },
  "target": {
    "type": "string"
  },
  "enableMailNotification": {
    "type": "boolean"
  }
};

export const LevenshteinConfigSchema = {
  "distance": {
    "type": "number"
  },
  "insertCost": {
    "type": "number"
  },
  "deleteCost": {
    "type": "number"
  },
  "substituteCost": {
    "type": "number"
  }
};

export const ReportsQuickAccessConfigSchema = {
  "enabled": {
    "type": "boolean"
  },
  "enabledOnOtherDomain": {
    "type": "boolean"
  },
  "fields": {
    "type": "array"
  },
  "path": {
    "type": "string"
  },
  "privacyUrl": {
    "type": "string"
  },
  "multiPathsEnabled": {
    "type": "boolean"
  },
  "paths": {
    "type": "array"
  },
  "reportMaxAge": {
    "type": "integer"
  },
  "reportMaxAgeInterval": {},
  "orderNumberType": {},
  "orderNumberLength": {
    "type": "integer"
  },
  "orderNumberNumericOnly": {
    "type": "boolean"
  },
  "orderNumberPrefix": {
    "type": "string"
  },
  "orderNumberSuffix": {
    "type": "string"
  },
  "useOrderNumberPrefix": {
    "type": "boolean"
  },
  "useOrderNumberSuffix": {
    "type": "boolean"
  },
  "partialReportsEnabled": {
    "type": "boolean"
  },
  "lockedReportsEnabled": {
    "type": "boolean"
  },
  "enableMailNotification": {
    "type": "boolean"
  },
  "multiDownloadEnabled": {
    "type": "boolean"
  },
  "multiDownloadMaximumCount": {
    "type": "integer"
  },
  "levenshtein": {
    "distance": {
      "type": "number"
    },
    "insertCost": {
      "type": "number"
    },
    "deleteCost": {
      "type": "number"
    },
    "substituteCost": {
      "type": "number"
    }
  }
};

export const ReportsQuickScanTargetConfigSchema = {
  "target": {
    "type": "string"
  },
  "manualEntry": {
    "type": "boolean"
  },
  "partialReportsEnabled": {
    "type": "boolean"
  }
};

export const ReportsQuickScanExplicitAnalysesConfigSchema = {
  "analysis": {
    "type": "string"
  },
  "value": {
    "type": "string"
  },
  "highlight": {}
};

export const ReportsQuickScanConfigSchema = {
  "enabled": {
    "type": "boolean"
  },
  "multiTargetsEnabled": {
    "type": "boolean"
  },
  "targets": {
    "type": "array"
  },
  "manualEntry": {
    "type": "boolean"
  },
  "partialReportsEnabled": {
    "type": "boolean"
  },
  "highlightPathological": {
    "type": "boolean"
  },
  "reportMaxAge": {
    "type": "integer"
  },
  "reportMaxAgeInterval": {},
  "explicitAnalyses": {
    "type": "array"
  }
};

export const HandlerLogEntrySchema = {
  "baseUrl": {
    "type": "string"
  },
  "path": {
    "type": "string"
  },
  "url": {
    "type": "string"
  },
  "method": {
    "type": "string"
  },
  "params": {
    "type": "object"
  },
  "query": {
    "type": "object"
  },
  "body": {
    "type": "object"
  },
  "requestHeaders": {
    "type": "object"
  },
  "responseHeaders": {
    "type": "object"
  },
  "statusCode": {
    "type": "number"
  },
  "slowDown": {
    "type": "object"
  }
};

export const JobLogEntrySchema = {
  "name": {
    "type": "string"
  },
  "params": {
    "type": "object"
  }
};

export const LogEntrySchema = {
  "id": {
    "type": "string"
  },
  "pid": {
    "type": "number"
  },
  "timestamp": {
    "type": "string"
  },
  "level": {},
  "message": {
    "type": "string"
  },
  "duration": {
    "type": "integer"
  },
  "auth": {
    "type": "object"
  },
  "meta": {
    "type": "string"
  },
  "stack": {
    "type": "string"
  },
  "tags": {
    "type": "array"
  },
  "handler": {
    "baseUrl": {
      "type": "string"
    },
    "path": {
      "type": "string"
    },
    "url": {
      "type": "string"
    },
    "method": {
      "type": "string"
    },
    "params": {
      "type": "object"
    },
    "query": {
      "type": "object"
    },
    "body": {
      "type": "object"
    },
    "requestHeaders": {
      "type": "object"
    },
    "responseHeaders": {
      "type": "object"
    },
    "statusCode": {
      "type": "number"
    },
    "slowDown": {
      "type": "object"
    }
  },
  "job": {
    "name": {
      "type": "string"
    },
    "params": {
      "type": "object"
    }
  },
  "subEntries": {
    "type": "array"
  }
};

export const LinkSettingPropertiesSchema = {
  "config": {
    "showToc": {
      "type": "boolean"
    },
    "showPrivacy": {
      "type": "boolean"
    },
    "showContact": {
      "type": "boolean"
    },
    "showContactForm": {
      "type": "boolean"
    }
  },
  "entries": {
    "type": "array"
  },
  "hasToc": {
    "type": "boolean"
  },
  "hasPrivacy": {
    "type": "boolean"
  }
};

export const GlobalSearchConfigPropertiesSchema = {
  "autocompleteEntries": {
    "type": "integer"
  }
};

export const RegexSearchConfigPropertiesSchema = {
  "tnrEnabled": {
    "type": "boolean"
  },
  "tnrRegex": {
    "type": "string"
  },
  "exttnrEnabled": {
    "type": "boolean"
  },
  "exttnrRegex": {
    "type": "string"
  },
  "caseNumberEnabled": {
    "type": "boolean"
  },
  "caseNumberRegex": {
    "type": "string"
  },
  "insuranceNumberEnabled": {
    "type": "boolean"
  },
  "insuranceNumberRegex": {
    "type": "string"
  },
  "patientNameOrEmailEnabled": {
    "type": "boolean"
  },
  "patientNameOrEmailRegex": {
    "type": "string"
  }
};

export const FreeBarcodesPolicyPropertiesSchema = {
  "enabled": {
    "type": "boolean"
  },
  "regex": {
    "type": "string"
  }
};

export const SystemSettingPropertiesSchema = {
  "maintenance": {
    "enabled": {
      "type": "boolean"
    },
    "title": {
      "type": "string"
    },
    "message": {
      "type": "string"
    }
  },
  "banner": {
    "enabled": {
      "type": "boolean"
    },
    "message": {
      "type": "string"
    }
  },
  "acceptedMaintenanceAt": {
    "type": "boolean"
  },
  "search": {
    "autocompleteEntries": {
      "type": "integer"
    }
  },
  "freeBarcodesPolicy": {
    "enabled": {
      "type": "boolean"
    },
    "regex": {
      "type": "string"
    }
  },
  "regexSearch": {
    "tnrEnabled": {
      "type": "boolean"
    },
    "tnrRegex": {
      "type": "string"
    },
    "exttnrEnabled": {
      "type": "boolean"
    },
    "exttnrRegex": {
      "type": "string"
    },
    "caseNumberEnabled": {
      "type": "boolean"
    },
    "caseNumberRegex": {
      "type": "string"
    },
    "insuranceNumberEnabled": {
      "type": "boolean"
    },
    "insuranceNumberRegex": {
      "type": "string"
    },
    "patientNameOrEmailEnabled": {
      "type": "boolean"
    },
    "patientNameOrEmailRegex": {
      "type": "string"
    }
  },
  "formatPriceDecimalType": {},
  "extendedGenderSelection": {
    "type": "boolean"
  }
};

export const DistinctAnalysisPropertiesSchema = {
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  }
};

export const UserShortInfoPropertiesSchema = {
  "xuser_id": {
    "type": "integer"
  },
  "displayName": {
    "type": "string"
  },
  "avatar": {
    "type": "string"
  },
  "userType": {}
};

export const SharedSamplePropertiesSchema = {
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "img": {
    "type": "string"
  },
  "imageLarge": {
    "type": "string"
  },
  "imageSmall": {
    "type": "string"
  },
  "unit": {
    "maxLength": 10,
    "type": "string"
  },
  "quantity": {
    "type": "integer"
  },
  "sortingOrder": {
    "type": "integer"
  },
  "laboratoryGroupShortCode": {
    "maxLength": 50,
    "type": "string"
  },
  "gboidentifier": {
    "type": "string"
  },
  "sampleType": {
    "maxLength": 20,
    "type": "string"
  },
  "sampleTypeLabel": {
    "maxLength": 4,
    "type": "string"
  }
};

export const OrderAnimalPropertiesSchema = {
  "name": {
    "maxLength": 50,
    "type": "string"
  },
  "birthday": {
    "maxLength": 8,
    "type": "string"
  },
  "species": {
    "maxLength": 2,
    "type": "string"
  },
  "speciesManual": {
    "maxLength": 50,
    "type": "string"
  },
  "chipId": {
    "maxLength": 50,
    "type": "string"
  },
  "height": {
    "maxLength": 3,
    "type": "string"
  },
  "weight": {
    "maxLength": 3,
    "type": "string"
  },
  "sex": {
    "type": "number"
  },
  "breed": {
    "maxLength": 50,
    "type": "string"
  },
  "ownerName": {
    "maxLength": 100,
    "type": "string"
  },
  "ownerAmendment": {
    "maxLength": 50,
    "type": "string"
  },
  "ownerStreet": {
    "maxLength": 50,
    "type": "string"
  },
  "ownerHouseNumber": {
    "maxLength": 10,
    "type": "string"
  },
  "ownerZipCode": {
    "maxLength": 10,
    "type": "string"
  },
  "ownerCity": {
    "maxLength": 50,
    "type": "string"
  }
};

export const OrderSwitzerlandPropertiesSchema = {
  "billToOther1": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "billToOther2": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "billToOther3": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyTo": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToEmail": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToSalutation": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToFirstName": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToLastName": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToStreet": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToZip": {
    "maxLength": 5,
    "type": [
      "string",
      "null"
    ]
  },
  "reportCopyToCity": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "notes": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "chTreatmentReason": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "accidentDate": {
    "maxLength": 8,
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderHungaryPropertiesSchema = {
  "motherLastName": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "motherFirstName": {
    "maxLength": 15,
    "type": [
      "string",
      "null"
    ]
  },
  "treatmentReason": {
    "maxLength": 1,
    "type": [
      "string",
      "null"
    ]
  },
  "invoice": {
    "maxLength": 2,
    "type": [
      "string",
      "null"
    ]
  },
  "icd1": {
    "maxLength": 6,
    "type": [
      "string",
      "null"
    ]
  },
  "icd2": {
    "maxLength": 6,
    "type": [
      "string",
      "null"
    ]
  },
  "icd3": {
    "maxLength": 6,
    "type": [
      "string",
      "null"
    ]
  },
  "insuranceNumber": {
    "maxLength": 18,
    "type": [
      "string",
      "null"
    ]
  },
  "citizen": {
    "maxLength": 3,
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderMetaPropertiesSchema = {
  "freeText": {
    "maxLength": 60,
    "type": "string"
  },
  "message": {
    "maxLength": 100,
    "type": "string"
  },
  "infectious": {
    "type": "boolean"
  },
  "urgent": {
    "type": "string"
  },
  "pregnancy": {
    "type": "boolean"
  },
  "checkup": {
    "type": "boolean"
  },
  "costUnit": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "controlCheckupKnownInfection": {
    "type": "boolean"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "dz16b": {
    "type": "boolean"
  },
  "accident": {
    "type": "boolean"
  },
  "accidentTime": {
    "maxLength": 20,
    "type": "string"
  },
  "documentReferenceNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "anamnesticInfo": {
    "maxLength": 60,
    "type": "string"
  },
  "printedMaterials": {
    "type": [
      "string",
      "null"
    ]
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "urgentNotificationPhone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationFax": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationMail": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  }
};

export const ComposedOrderStringsPropertiesSchema = {
  "materials": {
    "type": "string"
  },
  "requirements": {
    "type": "string"
  },
  "journal": {
    "type": "string"
  },
  "costUnits": {
    "type": "string"
  },
  "orderFormNames": {
    "type": "string"
  }
};

export const ComposedOrderPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "isPool": {
    "type": "boolean"
  },
  "isPoct": {
    "type": "boolean"
  },
  "isSoftDeleted": {
    "type": "boolean"
  },
  "orders": {
    "type": "array"
  },
  "displayName": {
    "type": "string"
  },
  "status": {
    "type": "array"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "selectedDoctor": {
    "type": "string"
  },
  "tnr": {
    "type": "string"
  },
  "materials": {
    "type": "array"
  },
  "digitalSignature": {
    "type": "array"
  },
  "reportStatus": {
    "type": "array"
  },
  "reportPathological": {
    "type": "array"
  },
  "created_at": {
    "type": "string"
  },
  "orderCount": {
    "type": "integer"
  },
  "scheduled_at": {
    "type": "array"
  },
  "executed_at": {
    "type": "string"
  },
  "sampled_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "meta_strings": {
    "materials": {
      "type": "string"
    },
    "requirements": {
      "type": "string"
    },
    "journal": {
      "type": "string"
    },
    "costUnits": {
      "type": "string"
    },
    "orderFormNames": {
      "type": "string"
    }
  },
  "sample_in_lab": {
    "type": "boolean"
  },
  "hospitalRoomNumber": {
    "type": "string"
  },
  "hospitalStation": {
    "type": "string"
  },
  "urgent": {
    "type": "boolean"
  },
  "emergency": {
    "type": "boolean"
  },
  "hasMissingAggregation": {
    "type": "boolean"
  },
  "hasReportCopyInfo": {
    "type": "boolean"
  },
  "switzerland": {
    "billToOther1": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther2": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyTo": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToEmail": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToSalutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToFirstName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToLastName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToStreet": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToZip": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToCity": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "notes": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "accidentDate": {
      "maxLength": 8,
      "type": [
        "string",
        "null"
      ]
    }
  }
};

export const ComposedOrderRulesPropertiesSchema = {
  "order": {
    "id": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": "boolean"
    },
    "orders": {
      "type": "array"
    },
    "displayName": {
      "type": "string"
    },
    "status": {
      "type": "array"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "selectedDoctor": {
      "type": "string"
    },
    "tnr": {
      "type": "string"
    },
    "materials": {
      "type": "array"
    },
    "digitalSignature": {
      "type": "array"
    },
    "reportStatus": {
      "type": "array"
    },
    "reportPathological": {
      "type": "array"
    },
    "created_at": {
      "type": "string"
    },
    "orderCount": {
      "type": "integer"
    },
    "scheduled_at": {
      "type": "array"
    },
    "executed_at": {
      "type": "string"
    },
    "sampled_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "hospitalRoomNumber": {
      "type": "string"
    },
    "hospitalStation": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "hasMissingAggregation": {
      "type": "boolean"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    }
  },
  "rules": {
    "type": "array"
  }
};

export const ReRequestOrderPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "isPool": {
    "type": "boolean"
  },
  "isPoct": {
    "type": "boolean"
  },
  "isSoftDeleted": {
    "type": "boolean"
  },
  "orders": {
    "type": "array"
  },
  "displayName": {
    "type": "string"
  },
  "status": {
    "type": "array"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "selectedDoctor": {
    "type": "string"
  },
  "tnr": {
    "type": "string"
  },
  "materials": {
    "type": "array"
  },
  "digitalSignature": {
    "type": "array"
  },
  "reportStatus": {
    "type": "array"
  },
  "reportPathological": {
    "type": "array"
  },
  "created_at": {
    "type": "string"
  },
  "orderCount": {
    "type": "integer"
  },
  "scheduled_at": {
    "type": "array"
  },
  "executed_at": {
    "type": "string"
  },
  "sampled_at": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "meta_strings": {
    "materials": {
      "type": "string"
    },
    "requirements": {
      "type": "string"
    },
    "journal": {
      "type": "string"
    },
    "costUnits": {
      "type": "string"
    },
    "orderFormNames": {
      "type": "string"
    }
  },
  "sample_in_lab": {
    "type": "boolean"
  },
  "hospitalRoomNumber": {
    "type": "string"
  },
  "hospitalStation": {
    "type": "string"
  },
  "urgent": {
    "type": "boolean"
  },
  "emergency": {
    "type": "boolean"
  },
  "hasMissingAggregation": {
    "type": "boolean"
  },
  "hasReportCopyInfo": {
    "type": "boolean"
  },
  "switzerland": {
    "billToOther1": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther2": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyTo": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToEmail": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToSalutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToFirstName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToLastName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToStreet": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToZip": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToCity": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "notes": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "accidentDate": {
      "maxLength": 8,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "reRequestedRequirements": {
    "type": "array"
  },
  "reRequestHistory": {
    "id": {
      "type": "integer"
    },
    "openedAt": {
      "type": "string"
    },
    "reRequestInfo": {
      "type": "string"
    },
    "requirementInfo": {
      "type": "string"
    },
    "closedAt": {
      "type": "string"
    },
    "appliedAt": {
      "type": "string"
    },
    "order": {
      "lid": {
        "type": "integer"
      },
      "isPool": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isSoftDeleted": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "freeText": {
        "maxLength": 60,
        "type": "string"
      },
      "message": {
        "maxLength": 100,
        "type": "string"
      },
      "infectious": {
        "type": "boolean"
      },
      "urgent": {
        "type": "string"
      },
      "pregnancy": {
        "type": "boolean"
      },
      "checkup": {
        "type": "boolean"
      },
      "costUnit": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "controlCheckupKnownInfection": {
        "type": "boolean"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "dz16b": {
        "type": "boolean"
      },
      "accident": {
        "type": "boolean"
      },
      "accidentTime": {
        "maxLength": 20,
        "type": "string"
      },
      "documentReferenceNumber": {
        "maxLength": 20,
        "type": "string"
      },
      "anamnesticInfo": {
        "maxLength": 60,
        "type": "string"
      },
      "printedMaterials": {
        "type": [
          "string",
          "null"
        ]
      },
      "printQuickReportBarcode": {
        "type": "boolean"
      },
      "urgentNotificationPhone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationFax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationMail": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "id": {
        "type": "integer"
      },
      "status": {},
      "executionStatus": {},
      "poolId": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "type": "string"
      },
      "bsnr": {
        "type": "string"
      },
      "selectedDoctor": {
        "type": "string"
      },
      "apid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "patient": {
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 50,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 150,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "costUnit": {
          "type": "string"
        },
        "medication": {
          "maxLength": 100,
          "type": "string"
        },
        "cycleDay": {
          "maxLength": 10,
          "type": "string"
        },
        "weight": {
          "maxLength": 5,
          "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 150,
          "type": "string"
        },
        "exttnr": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "primaryCarePhysicianLanr": {
          "maxLength": 20,
          "type": "string"
        },
        "primaryCarePhysicianBsnr": {
          "maxLength": 20,
          "type": "string"
        },
        "diseaseManagementProgram": {
          "maxLength": 50,
          "type": "string"
        },
        "dmpSpecialGroup": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceStart": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceEnd": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceType": {
          "maxLength": 50,
          "type": "string"
        },
        "exceptionalIndication": {
          "maxLength": 20,
          "type": "string"
        },
        "sktIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "wopIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "accountingArea": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "hvcode": {
          "maxLength": 20,
          "type": "string"
        },
        "vkat": {
          "maxLength": 20,
          "type": "string"
        },
        "secondName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "canton": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "ahvNr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "aisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "insurance": {
          "type": [
            "number",
            "null"
          ]
        },
        "bag": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "cardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "globalLocationNumber": {
          "maxLength": 13,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "hospitalBedNumber": {
          "type": "string"
        },
        "hospitalRoomNumber": {
          "type": "string"
        },
        "hospitalProfession": {
          "type": [
            "string",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "invoiceTo": {
          "maxLength": 25,
          "type": "string"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "autInsuranceTitle": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceFirstName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceLastName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceInsuranceNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceBirthday": {
          "type": "string"
        },
        "autInsuranceAddress": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceHouseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCountry": {
          "maxLength": 10,
          "type": "string"
        },
        "autInsuranceZip": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCity": {
          "maxLength": 50,
          "type": "string"
        },
        "pid": {
          "type": "integer"
        },
        "apgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "displayName": {
          "type": "string"
        },
        "prefix": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 5,
          "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "pregnancyWeek": {
          "maxLength": 2,
          "pattern": "^(\\d{1,2})?$",
          "type": "string"
        },
        "pregnancyWeekDay": {
          "maxLength": 1,
          "pattern": "^(\\d{1})?$",
          "type": "string"
        },
        "cycleLength": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        },
        "dayOfCycle": {
          "maxLength": 2,
          "type": "string"
        },
        "fetalCrownRumpLength": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "fetalNuchalTranslucency": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "hasInsuranceNumber": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "sampleAmount": {
          "maxLength": 10,
          "type": "string"
        },
        "samplePeriod": {
          "maxLength": 10,
          "type": "string"
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "passportNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "idcardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "orderReason": {
          "maxLength": 1,
          "type": "string"
        },
        "costUnitIdentification": {
          "maxLength": 25,
          "type": "string"
        },
        "autInsuranceEmployer": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceEmployerCity": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceAddressAdditional": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "requirements": {
        "type": "array"
      },
      "anamnesis": {
        "type": "array"
      },
      "animal": {
        "name": {
          "maxLength": 50,
          "type": "string"
        },
        "birthday": {
          "maxLength": 8,
          "type": "string"
        },
        "species": {
          "maxLength": 2,
          "type": "string"
        },
        "speciesManual": {
          "maxLength": 50,
          "type": "string"
        },
        "chipId": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 3,
          "type": "string"
        },
        "weight": {
          "maxLength": 3,
          "type": "string"
        },
        "sex": {
          "type": "number"
        },
        "breed": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerName": {
          "maxLength": 100,
          "type": "string"
        },
        "ownerAmendment": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerStreet": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerHouseNumber": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerZipCode": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerCity": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "switzerland": {
        "billToOther1": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther2": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther3": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyTo": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToEmail": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToSalutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToFirstName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToLastName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToStreet": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToZip": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToCity": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "notes": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "accidentDate": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "hungary": {
        "motherLastName": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "motherFirstName": {
          "maxLength": 15,
          "type": [
            "string",
            "null"
          ]
        },
        "treatmentReason": {
          "maxLength": 1,
          "type": [
            "string",
            "null"
          ]
        },
        "invoice": {
          "maxLength": 2,
          "type": [
            "string",
            "null"
          ]
        },
        "icd1": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd2": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd3": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "insuranceNumber": {
          "maxLength": 18,
          "type": [
            "string",
            "null"
          ]
        },
        "citizen": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "executed_at": {
        "type": "string"
      },
      "scheduled_at": {
        "type": [
          "string",
          "null"
        ]
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "covid": {
        "testNumber": {},
        "testReason": {},
        "agreed": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "riskCharacteristicsLocation": {},
        "riskCharacteristicsLocationType": {},
        "legalReason": {},
        "healthServiceZipCode": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "healthService": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "legalOrderBasis": {},
        "kvSpecial": {
          "type": [
            "string",
            "null"
          ]
        },
        "confirmationDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "variantDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "oegdInvoiceTo": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "covidWarnAppBarcode": {
          "type": [
            "boolean",
            "null"
          ]
        }
      },
      "mustValidate": {
        "type": "boolean"
      },
      "hospitalStation": {
        "type": "string"
      },
      "requirementDiagnosisFreeText": {
        "maxLength": 150,
        "type": "string"
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "selectedDiagnoses": {
        "type": "object"
      },
      "selectedDiagnosesChecked": {
        "type": "boolean"
      },
      "workstationChecked": {
        "type": "boolean"
      },
      "splitProperties": {
        "type": "array"
      },
      "sample_in_lab": {
        "type": "boolean"
      },
      "clientname": {
        "type": "string"
      },
      "printerAliasName": {
        "type": "string"
      },
      "overridePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "overrideBarcodePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "beforeAfterDialysis": {},
      "reportCopyAid": {
        "type": [
          "number",
          "null"
        ]
      },
      "reportCopyDoctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "aisIdentifier": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "patientAisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "bloodCollectionType": {},
      "transported_at": {
        "type": "string"
      },
      "created_at": {
        "type": "string"
      },
      "digitalSignature": {},
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "report": {
        "bid": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "parent_bid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "status": {},
        "tnr": {
          "type": "string"
        },
        "pathological": {},
        "mibi": {},
        "created_at": {
          "type": "string"
        },
        "imported_at": {
          "type": "string"
        },
        "registered_at": {
          "type": "string"
        },
        "validated_at": {
          "type": "string"
        },
        "sampled_at": {
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctor": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctorAddition": {
          "maxLength": 100,
          "type": "string"
        },
        "patientName": {
          "type": "string"
        },
        "is_locked": {
          "type": "boolean"
        },
        "is_unread": {
          "type": "boolean"
        },
        "is_pinned": {
          "type": "boolean"
        },
        "has_base64": {
          "type": "boolean"
        },
        "has_elpho": {
          "type": "boolean"
        },
        "has_subreports": {
          "type": "boolean"
        },
        "has_history": {
          "type": "boolean"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "patient": {
          "pid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 200,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 100,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 50,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "phone": {
            "maxLength": 50,
            "type": "string"
          },
          "costUnit": {
            "type": "string"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "sonstiges1": {
            "maxLength": 50,
            "type": "string"
          },
          "sonstiges2": {
            "maxLength": 50,
            "type": "string"
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "smsNotificationEnabled": {
            "type": "boolean"
          },
          "smsNumber": {
            "type": "string"
          },
          "canViewPartialResults": {
            "type": "boolean"
          },
          "canViewPreliminaryResults": {
            "type": "boolean"
          },
          "resultLanguage": {
            "maxLength": 5,
            "type": "string"
          },
          "oa": {
            "type": "boolean"
          },
          "aids": {
            "type": "array"
          },
          "latest_report_date": {
            "type": "string"
          },
          "dateScore": {
            "type": "number"
          },
          "passportNumber": {
            "type": "string"
          },
          "idCardNumber": {
            "type": "string"
          }
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "laboratoryGroup": {
          "grpid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "kurz": {
            "maxLength": 20,
            "pattern": "^(?![Ll][Aa]$).*$",
            "type": "string"
          },
          "name": {
            "maxLength": 200,
            "type": "string"
          },
          "telefon": {
            "maxLength": 20,
            "type": "string"
          },
          "fax": {
            "maxLength": 20,
            "type": "string"
          },
          "website": {
            "maxLength": 50,
            "type": "string"
          },
          "adresse": {
            "maxLength": 50,
            "type": "string"
          },
          "stadt": {
            "maxLength": 50,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": "string"
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": "string"
          },
          "zusatz1": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz2": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz3": {
            "maxLength": 200,
            "type": "string"
          },
          "plz": {
            "maxLength": 25,
            "type": "string"
          },
          "showPdfExport": {
            "type": "boolean"
          },
          "laboratoryGroupBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPrefix": {
              "maxLength": 10,
              "type": "string"
            },
            "printPosition": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMin": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMax": {
              "maxLength": 40,
              "type": "string"
            }
          },
          "advancedAlphanumericBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPosition1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax1": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax2": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax3": {
              "maxLength": 3,
              "type": "string"
            },
            "useState": {
              "type": "object"
            }
          }
        },
        "laboratoryGroupFilter": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "maxLength": 32,
            "type": "string"
          },
          "color": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          },
          "group": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "values": {
          "type": "array"
        },
        "subreports": {
          "type": "array"
        },
        "attachments": {
          "type": "array"
        },
        "attachment_count": {
          "type": "integer"
        },
        "comments": {
          "type": "array"
        },
        "comment_count": {
          "type": "integer"
        },
        "arzname": {
          "maxLength": 300,
          "type": "string"
        },
        "labname": {
          "type": "string"
        },
        "accountingType": {
          "type": "string"
        },
        "laborgruppe": {
          "type": "string"
        },
        "exttnr": {
          "type": "string"
        },
        "lebensnummer": {
          "type": "string"
        },
        "zimmer": {
          "type": "string"
        },
        "lastupdate": {
          "type": "string"
        },
        "orderComment": {
          "type": "string"
        },
        "overwritecolor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "pdfSrc": {
          "type": "string"
        },
        "material": {
          "type": "string"
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceNumber": {
          "type": "string"
        },
        "showBdw": {
          "type": "boolean"
        },
        "showCumulative": {
          "type": "boolean"
        },
        "showPdf": {
          "type": "boolean"
        },
        "showOriginal": {
          "type": "boolean"
        },
        "showOriginalExclusive": {
          "type": "boolean"
        },
        "showCumulativePdf": {
          "type": "boolean"
        },
        "showCumulativeAllPdf": {
          "type": "boolean"
        },
        "showSend": {
          "type": "boolean"
        },
        "showRerequest": {
          "type": "boolean"
        },
        "originalPdfIds": {
          "type": "array"
        },
        "guid": {
          "type": "string"
        },
        "externalGuid": {
          "type": "string"
        },
        "manualCode": {
          "type": "string"
        },
        "uuid": {
          "type": "string"
        },
        "settlementType": {}
      },
      "pool": {
        "id": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "displayName": {
          "type": "string"
        },
        "status": {
          "type": "array"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "orders": {
          "type": "array"
        },
        "logs": {
          "type": "array"
        },
        "created_at": {
          "type": "string"
        },
        "updated_at": {
          "type": "string"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isSoftDeleted": {
          "type": "boolean"
        }
      },
      "samples": {
        "type": "array"
      },
      "workstation": {
        "id": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "location": {
          "type": "string"
        },
        "default": {
          "type": "boolean"
        },
        "status": {},
        "clientVersion": {
          "type": "string"
        },
        "printers": {
          "type": "array"
        }
      },
      "manufacturer": {
        "type": "string"
      },
      "initialWorkplaceId": {
        "type": "number"
      },
      "lastWorkplaceId": {
        "type": "number"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "splitId": {
        "type": "integer"
      },
      "quickScanPdfPassword": {
        "type": [
          "string",
          "null"
        ]
      },
      "hasAttachment": {
        "type": "boolean"
      },
      "case_id": {
        "type": "integer"
      },
      "caseNumber": {
        "type": "string"
      },
      "realReRequestConditions": {
        "allowed": {
          "type": "boolean"
        },
        "formIds": {
          "type": "array"
        },
        "requirementIds": {
          "type": "array"
        },
        "profileIds": {
          "type": "array"
        }
      },
      "exportFlag": {
        "type": "boolean"
      },
      "statusExport": {
        "type": "integer"
      },
      "statusStornoExport": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isCancelable": {
        "type": "boolean"
      },
      "actuallyPrintedMaterials": {
        "type": "string"
      },
      "hasReportCopyInfo": {
        "type": "boolean"
      }
    }
  }
};

export const ComposedOrderPoolWithRulesPropertiesSchema = {
  "order": {
    "id": {
      "type": "integer"
    },
    "isPool": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isSoftDeleted": {
      "type": "boolean"
    },
    "orders": {
      "type": "array"
    },
    "displayName": {
      "type": "string"
    },
    "status": {
      "type": "array"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "selectedDoctor": {
      "type": "string"
    },
    "tnr": {
      "type": "string"
    },
    "materials": {
      "type": "array"
    },
    "digitalSignature": {
      "type": "array"
    },
    "reportStatus": {
      "type": "array"
    },
    "reportPathological": {
      "type": "array"
    },
    "created_at": {
      "type": "string"
    },
    "orderCount": {
      "type": "integer"
    },
    "scheduled_at": {
      "type": "array"
    },
    "executed_at": {
      "type": "string"
    },
    "sampled_at": {
      "type": "string"
    },
    "updated_at": {
      "type": "string"
    },
    "meta_strings": {
      "materials": {
        "type": "string"
      },
      "requirements": {
        "type": "string"
      },
      "journal": {
        "type": "string"
      },
      "costUnits": {
        "type": "string"
      },
      "orderFormNames": {
        "type": "string"
      }
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "hospitalRoomNumber": {
      "type": "string"
    },
    "hospitalStation": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "hasMissingAggregation": {
      "type": "boolean"
    },
    "hasReportCopyInfo": {
      "type": "boolean"
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    }
  },
  "actions": {
    "type": "array"
  }
};

export const OrderFrontendLogEntrySchema = {
  "action": {},
  "data": {
    "type": "object"
  },
  "info": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  }
};

export const WriteableOrderPropertiesSchema = {
  "freeText": {
    "maxLength": 60,
    "type": "string"
  },
  "message": {
    "maxLength": 100,
    "type": "string"
  },
  "infectious": {
    "type": "boolean"
  },
  "urgent": {
    "type": "string"
  },
  "pregnancy": {
    "type": "boolean"
  },
  "checkup": {
    "type": "boolean"
  },
  "costUnit": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "controlCheckupKnownInfection": {
    "type": "boolean"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "dz16b": {
    "type": "boolean"
  },
  "accident": {
    "type": "boolean"
  },
  "accidentTime": {
    "maxLength": 20,
    "type": "string"
  },
  "documentReferenceNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "anamnesticInfo": {
    "maxLength": 60,
    "type": "string"
  },
  "printedMaterials": {
    "type": [
      "string",
      "null"
    ]
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "urgentNotificationPhone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationFax": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationMail": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "id": {
    "type": "integer"
  },
  "status": {},
  "executionStatus": {},
  "poolId": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "tnr": {
    "type": "string"
  },
  "externalId2": {
    "type": "string"
  },
  "lanr": {
    "type": "string"
  },
  "bsnr": {
    "type": "string"
  },
  "selectedDoctor": {
    "type": "string"
  },
  "apid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "patient": {
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "suffix": {
      "maxLength": 50,
      "type": "string"
    },
    "title": {
      "maxLength": 50,
      "type": "string"
    },
    "gender": {},
    "birthday": {
      "maxLength": 50,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 150,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "addressAdditional": {
      "maxLength": 50,
      "type": "string"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "phone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "costUnit": {
      "type": "string"
    },
    "medication": {
      "maxLength": 100,
      "type": "string"
    },
    "cycleDay": {
      "maxLength": 10,
      "type": "string"
    },
    "weight": {
      "maxLength": 5,
      "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "diagnosis": {
      "maxLength": 150,
      "type": "string"
    },
    "exttnr": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "primaryCarePhysicianLanr": {
      "maxLength": 20,
      "type": "string"
    },
    "primaryCarePhysicianBsnr": {
      "maxLength": 20,
      "type": "string"
    },
    "diseaseManagementProgram": {
      "maxLength": 50,
      "type": "string"
    },
    "dmpSpecialGroup": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceStart": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceEnd": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceType": {
      "maxLength": 50,
      "type": "string"
    },
    "exceptionalIndication": {
      "maxLength": 20,
      "type": "string"
    },
    "sktIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "wopIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "accountingArea": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "hvcode": {
      "maxLength": 20,
      "type": "string"
    },
    "vkat": {
      "maxLength": 20,
      "type": "string"
    },
    "secondName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "canton": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "ahvNr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "aisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "insurance": {
      "type": [
        "number",
        "null"
      ]
    },
    "bag": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "cardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "patientIdentification": {
      "maxLength": 20,
      "type": "string"
    },
    "globalLocationNumber": {
      "maxLength": 13,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "hospitalBedNumber": {
      "type": "string"
    },
    "hospitalRoomNumber": {
      "type": "string"
    },
    "hospitalProfession": {
      "type": [
        "string",
        "null"
      ]
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseNumber": {
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "invoiceTo": {
      "maxLength": 25,
      "type": "string"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "autInsuranceTitle": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceFirstName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceLastName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceInsuranceNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceBirthday": {
      "type": "string"
    },
    "autInsuranceAddress": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceHouseNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCountry": {
      "maxLength": 10,
      "type": "string"
    },
    "autInsuranceZip": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCity": {
      "maxLength": 50,
      "type": "string"
    },
    "pid": {
      "type": "integer"
    },
    "apgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "displayName": {
      "type": "string"
    },
    "prefix": {
      "maxLength": 50,
      "type": "string"
    },
    "height": {
      "maxLength": 5,
      "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "pregnancyWeek": {
      "maxLength": 2,
      "pattern": "^(\\d{1,2})?$",
      "type": "string"
    },
    "pregnancyWeekDay": {
      "maxLength": 1,
      "pattern": "^(\\d{1})?$",
      "type": "string"
    },
    "cycleLength": {
      "maxLength": 3,
      "type": [
        "string",
        "null"
      ]
    },
    "dayOfCycle": {
      "maxLength": 2,
      "type": "string"
    },
    "fetalCrownRumpLength": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "fetalNuchalTranslucency": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "hasInsuranceNumber": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "sampleAmount": {
      "maxLength": 10,
      "type": "string"
    },
    "samplePeriod": {
      "maxLength": 10,
      "type": "string"
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "passportNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "idcardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "orderReason": {
      "maxLength": 1,
      "type": "string"
    },
    "costUnitIdentification": {
      "maxLength": 25,
      "type": "string"
    },
    "autInsuranceEmployer": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceEmployerCity": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceAddressAdditional": {
      "maxLength": 50,
      "type": "string"
    }
  },
  "requirements": {
    "type": "array"
  },
  "anamnesis": {
    "type": "array"
  },
  "animal": {
    "name": {
      "maxLength": 50,
      "type": "string"
    },
    "birthday": {
      "maxLength": 8,
      "type": "string"
    },
    "species": {
      "maxLength": 2,
      "type": "string"
    },
    "speciesManual": {
      "maxLength": 50,
      "type": "string"
    },
    "chipId": {
      "maxLength": 50,
      "type": "string"
    },
    "height": {
      "maxLength": 3,
      "type": "string"
    },
    "weight": {
      "maxLength": 3,
      "type": "string"
    },
    "sex": {
      "type": "number"
    },
    "breed": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerName": {
      "maxLength": 100,
      "type": "string"
    },
    "ownerAmendment": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerStreet": {
      "maxLength": 50,
      "type": "string"
    },
    "ownerHouseNumber": {
      "maxLength": 10,
      "type": "string"
    },
    "ownerZipCode": {
      "maxLength": 10,
      "type": "string"
    },
    "ownerCity": {
      "maxLength": 50,
      "type": "string"
    }
  },
  "switzerland": {
    "billToOther1": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther2": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "billToOther3": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyTo": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToEmail": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToSalutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToFirstName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToLastName": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToStreet": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToZip": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "reportCopyToCity": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "notes": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "accidentDate": {
      "maxLength": 8,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "hungary": {
    "motherLastName": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "motherFirstName": {
      "maxLength": 15,
      "type": [
        "string",
        "null"
      ]
    },
    "treatmentReason": {
      "maxLength": 1,
      "type": [
        "string",
        "null"
      ]
    },
    "invoice": {
      "maxLength": 2,
      "type": [
        "string",
        "null"
      ]
    },
    "icd1": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "icd2": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "icd3": {
      "maxLength": 6,
      "type": [
        "string",
        "null"
      ]
    },
    "insuranceNumber": {
      "maxLength": 18,
      "type": [
        "string",
        "null"
      ]
    },
    "citizen": {
      "maxLength": 3,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "executed_at": {
    "type": "string"
  },
  "scheduled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "sampleDate": {
    "type": "string"
  },
  "sampleTime": {
    "maxLength": 8,
    "type": "string"
  },
  "covid": {
    "testNumber": {},
    "testReason": {},
    "agreed": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "riskCharacteristicsLocation": {},
    "riskCharacteristicsLocationType": {},
    "legalReason": {},
    "healthServiceZipCode": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "healthService": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "legalOrderBasis": {},
    "kvSpecial": {
      "type": [
        "string",
        "null"
      ]
    },
    "confirmationDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "variantDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "oegdInvoiceTo": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "covidWarnAppBarcode": {
      "type": [
        "boolean",
        "null"
      ]
    }
  },
  "mustValidate": {
    "type": "boolean"
  },
  "hospitalStation": {
    "type": "string"
  },
  "requirementDiagnosisFreeText": {
    "maxLength": 150,
    "type": "string"
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "selectedDiagnoses": {
    "type": "object"
  },
  "selectedDiagnosesChecked": {
    "type": "boolean"
  },
  "workstationChecked": {
    "type": "boolean"
  },
  "splitProperties": {
    "type": "array"
  },
  "sample_in_lab": {
    "type": "boolean"
  },
  "clientname": {
    "type": "string"
  },
  "printerAliasName": {
    "type": "string"
  },
  "overridePrinterName": {
    "type": [
      "string",
      "null"
    ]
  },
  "overrideBarcodePrinterName": {
    "type": [
      "string",
      "null"
    ]
  },
  "beforeAfterDialysis": {},
  "reportCopyAid": {
    "type": [
      "number",
      "null"
    ]
  },
  "reportCopyDoctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  },
  "aisIdentifier": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "patientAisNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "bloodCollectionType": {}
};

export const OrderBasketFormPropertiesSchema = {
  "formId": {
    "type": "number"
  },
  "splitIndex": {
    "type": "number"
  },
  "tnr": {
    "type": "string"
  },
  "isPoolSplit": {
    "type": "boolean"
  },
  "requirements": {
    "type": "array"
  }
};

export const OrderExportSettingsPropertiesSchema = {
  "settings": {
    "path": {
      "type": "string"
    },
    "delay": {
      "type": "number"
    },
    "shortCodeMode": {},
    "shortCodeOAAnswerMode": {},
    "exportTrigger": {},
    "ch_bags": {
      "type": "object"
    }
  },
  "furtherFields": {
    "patientPhone": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "patientEmail": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "crownRumpLength": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "nuchalTranslucency": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "cycleLength": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "anamnesticInfo": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "bmi": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "cycleDay": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    }
  },
  "austriaFields": {
    "title": {
      "maxLength": 4,
      "type": "string"
    },
    "firstName": {
      "maxLength": 4,
      "type": "string"
    },
    "lastName": {
      "maxLength": 4,
      "type": "string"
    },
    "birthday": {
      "maxLength": 4,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 4,
      "type": "string"
    },
    "country": {
      "maxLength": 4,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 4,
      "type": "string"
    },
    "city": {
      "maxLength": 4,
      "type": "string"
    },
    "street": {
      "maxLength": 4,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 4,
      "type": "string"
    },
    "addressAdditional": {
      "maxLength": 4,
      "type": "string"
    },
    "employer": {
      "maxLength": 4,
      "type": "string"
    },
    "employerPlace": {
      "maxLength": 4,
      "type": "string"
    },
    "chargeType": {
      "maxLength": 4,
      "type": "string"
    }
  },
  "microbiologyFields": {
    "leftRight": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "operative": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "notes": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "examination": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "examinationId": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "examinationText": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "material": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "materialId": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "materialText": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "origin": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "originText": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    },
    "originSite": {
      "fieldId": {
        "maxLength": 4,
        "type": "string"
      },
      "preValue": {
        "maxLength": 100,
        "type": "string"
      }
    }
  },
  "optionalFields": {
    "type": "array"
  },
  "freeTextFields": {
    "fieldId": {
      "maxLength": 4,
      "type": "string"
    },
    "preValue": {
      "maxLength": 100,
      "type": "string"
    },
    "defaultRequest": {
      "type": "string"
    }
  },
  "che_bags": {
    "type": "array"
  },
  "che_bag_to_glns": {
    "type": "array"
  }
};

export const BarcodeSettingsPropertiesSchema = {
  "printPosition": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMin": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMax": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printPrefix": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "overwrite": {
    "type": "boolean"
  },
  "resetCentralBarcodes": {
    "type": "boolean"
  }
};

export const AdvancedAlphanumericBarcodeSettingPropertiesSchema = {
  "printPosition1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax1": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax2": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax3": {
    "maxLength": 3,
    "type": "string"
  },
  "overwrite1": {
    "type": "boolean"
  },
  "overwrite2": {
    "type": "boolean"
  },
  "overwrite3": {
    "type": "boolean"
  },
  "useState": {}
};

export const LaboratoryGroupBarcodeSettingsPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "groupName": {
    "type": "string"
  },
  "groupShortname": {
    "type": "string"
  },
  "printPosition": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMin": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMax": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printPrefix": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "overwrite": {
    "type": "boolean"
  },
  "resetCentralBarcodes": {
    "type": "boolean"
  }
};

export const AdvancedAlphanumericBarcodePropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "groupName": {
    "type": "string"
  },
  "groupShortname": {
    "type": "string"
  },
  "printPosition1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin1": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax1": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin2": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax2": {
    "maxLength": 3,
    "type": "string"
  },
  "printPosition3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMin3": {
    "maxLength": 3,
    "type": "string"
  },
  "printRangeMax3": {
    "maxLength": 3,
    "type": "string"
  },
  "overwrite1": {
    "type": "boolean"
  },
  "overwrite2": {
    "type": "boolean"
  },
  "overwrite3": {
    "type": "boolean"
  },
  "useState": {}
};

export const OrderFieldWithPreValuePropertiesSchema = {
  "fieldId": {
    "maxLength": 4,
    "type": "string"
  },
  "preValue": {
    "maxLength": 100,
    "type": "string"
  }
};

export const RealReRequestSettingsPropertiesSchema = {
  "mtlMail": {
    "maxLength": 250,
    "type": "string"
  },
  "useLgMail": {
    "type": "boolean"
  },
  "orderTimeframe": {
    "type": "integer"
  },
  "reRequestLimit": {
    "type": "integer"
  },
  "reRequestBuffer": {
    "type": "integer"
  },
  "reRequestApprove": {
    "type": "boolean"
  },
  "reRequestNoExport": {
    "type": "boolean"
  },
  "reRequestLimitSampleDate": {
    "type": "boolean"
  },
  "blockRealReRequestMadeMails": {
    "type": "boolean"
  }
};

export const OrderRealReRequestConditionsPropertiesSchema = {
  "allowed": {
    "type": "boolean"
  },
  "formIds": {
    "type": "array"
  },
  "requirementIds": {
    "type": "array"
  },
  "profileIds": {
    "type": "array"
  }
};

export const RealReRequestInformationPropertiesSchema = {
  "appliedRequirementInfo": {
    "type": "string"
  },
  "appliedRequirementInfoWithFreeText": {
    "type": "string"
  },
  "appliedRequirementShortInfo": {
    "type": "string"
  },
  "appliedRequirementExternalInfo": {
    "type": "string"
  },
  "appliedRequirementInfoWithForm": {
    "type": "string"
  },
  "appliedRequirementExternalInfoWithForm": {
    "type": "string"
  },
  "acceptedRequirementInfo": {
    "type": "string"
  },
  "acceptedRequirementInfoWithFreeText": {
    "type": "string"
  },
  "acceptedRequirementShortInfo": {
    "type": "string"
  },
  "acceptedRequirementExternalInfo": {
    "type": "string"
  },
  "acceptedRequirementInfoWithForm": {
    "type": "string"
  },
  "acceptedRequirementExternalInfoWithForm": {
    "type": "string"
  },
  "approvedRequirementInfo": {
    "type": "string"
  },
  "approvedRequirementInfoWithFreeText": {
    "type": "string"
  },
  "approvedRequirementShortInfo": {
    "type": "string"
  },
  "approvedRequirementExternalInfo": {
    "type": "string"
  },
  "approvedRequirementInfoWithForm": {
    "type": "string"
  },
  "approvedRequirementExternalInfoWithForm": {
    "type": "string"
  },
  "rejectedRequirementInfo": {
    "type": "string"
  },
  "rejectedRequirementInfoWithFreeText": {
    "type": "string"
  },
  "rejectedRequirementShortInfo": {
    "type": "string"
  },
  "rejectedRequirementExternalInfo": {
    "type": "string"
  },
  "rejectedRequirementInfoWithForm": {
    "type": "string"
  },
  "rejectedRequirementExternalInfoWithForm": {
    "type": "string"
  }
};

export const OrderPatientGroupPropsSchema = {
  "displayName": {
    "type": "string"
  },
  "updated_at": {
    "type": "string"
  },
  "apgids": {
    "type": "array"
  },
  "patients": {
    "type": "array"
  }
};

export const RequirementLaboratoryGroupsPropertiesSchema = {
  "laboratoryGroup": {
    "type": "string"
  }
};

export const RequirementSubmittersPropertiesSchema = {
  "submitter": {
    "type": "string"
  }
};

export const PoolOrOrderIdsSchema = {
  "poolId": {
    "type": "integer"
  },
  "poolIds": {
    "type": "array"
  },
  "orderId": {
    "type": "integer"
  },
  "orderIds": {
    "type": "array"
  }
};

export const OrderWizardSwitchesSchema = {
  "urgent": {
    "type": "boolean"
  },
  "emergency": {
    "type": "boolean"
  },
  "infectious": {
    "type": "boolean"
  },
  "hzv": {
    "type": "boolean"
  },
  "pregnancy": {
    "type": "boolean"
  },
  "checkup": {
    "type": "boolean"
  },
  "quickReportBarcode": {
    "type": "boolean"
  },
  "accident": {
    "type": "boolean"
  }
};

export const OrderWizardSettingsSchema = {
  "costUnits": {
    "type": "array"
  },
  "forms": {
    "type": "array"
  },
  "profiles": {
    "type": "array"
  },
  "superRequirements": {
    "type": "array"
  },
  "manufacturer": {
    "type": "string"
  },
  "groupForms": {
    "type": "array"
  },
  "splitBak": {
    "type": "boolean"
  },
  "filters": {
    "type": "array"
  },
  "anamnesis": {
    "type": "array"
  },
  "localisation": {},
  "preferences": {
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    }
  },
  "switches": {
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "hzv": {
      "type": "boolean"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "quickReportBarcode": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    }
  },
  "che_bags": {
    "type": "array"
  },
  "che_bag_to_glns": {
    "type": "array"
  }
};

export const OrderWizardSplitPropertiesSchema = {
  "diagnosis": {
    "maxLength": 150,
    "type": "string"
  },
  "freeText": {
    "maxLength": 60,
    "type": "string"
  },
  "message": {
    "maxLength": 100,
    "type": "string"
  },
  "anamnesticInfo": {
    "maxLength": 60,
    "type": "string"
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "printedMaterials": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const FormNotFoundRequirementPropertiesSchema = {
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  }
};

export const FormNotFoundPropertiesSchema = {
  "requirement": {
    "longName": {
      "type": "string"
    },
    "shortName": {
      "type": "string"
    }
  },
  "costUnit": {
    "type": "string"
  }
};

export const ProfileNameCollisionPropertiesSchema = {
  "profileName": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "newName": {
    "type": "string"
  }
};

export const MappingConfirmPropertiesSchema = {
  "formNotFound": {
    "type": "array"
  },
  "nameCollision": {
    "type": "array"
  }
};

export const ProfileNameConfirmPropertiesSchema = {
  "profileName": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "replace": {
    "type": "boolean"
  },
  "newName": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const CopyProfilesConfirmPropertiesSchema = {
  "confirmedNames": {
    "type": "array"
  }
};

export const UnifiedMicrobiologicalMaterialSchema = {
  "main": {
    "matid": {
      "type": "string"
    },
    "externalMaterialId": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "name": {
      "type": "string"
    },
    "origin": {
      "type": "string"
    },
    "order": {
      "type": "integer"
    },
    "sampleType": {
      "type": "string"
    },
    "barcodeCount": {
      "type": "integer"
    },
    "askIntraoperative": {
      "type": "boolean"
    },
    "askLeftRight": {
      "type": "boolean"
    },
    "askOrigin": {
      "type": "integer"
    },
    "analyses": {
      "type": "array"
    },
    "form": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "array"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 20,
        "type": "string"
      },
      "costUnit": {
        "maxLength": 20,
        "type": "string"
      },
      "invoiceToChangeable": {
        "type": "boolean"
      },
      "defaultInvoiceTo": {},
      "isPrivate": {
        "type": "boolean"
      },
      "isDynamicMaterial": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isDefault": {
        "type": "boolean"
      },
      "isFilterHidden": {
        "type": "boolean"
      },
      "formNote": {
        "maxLength": 150,
        "type": "string"
      },
      "isGraphic": {
        "type": "boolean"
      },
      "barcodeComment": {
        "maxLength": 15,
        "type": "string"
      },
      "exportInfo": {
        "maxLength": 150,
        "type": "string"
      },
      "isBak": {
        "type": "boolean"
      },
      "satzart": {
        "maxLength": 4,
        "type": "string"
      },
      "directscheindruckmode": {
        "type": "integer"
      },
      "lastChanged": {
        "maxLength": 14,
        "type": "string"
      },
      "isPool": {
        "type": "boolean"
      },
      "maximumRequestCount": {
        "type": [
          "integer",
          "null"
        ]
      },
      "datamatrix": {
        "maxLength": 20,
        "type": "string"
      },
      "splitMode": {},
      "basePrice": {
        "type": "string"
      },
      "hasDynProbeType": {
        "type": "boolean"
      },
      "isDeleted": {
        "type": "boolean"
      },
      "priceFactor": {
        "type": "string"
      },
      "formGroupID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "bcPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "exportAsFile": {
        "maxLength": 10,
        "type": "string"
      },
      "digitalSignatureMode": {},
      "exportType": {
        "type": "integer"
      },
      "laboratoryGroupShortCode": {
        "maxLength": 50,
        "type": "string"
      },
      "zplID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "formType": {},
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "requirements": {
        "type": "array"
      }
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "localizations": {
      "type": "array"
    },
    "additionalText": {
      "type": [
        "string",
        "null"
      ]
    },
    "hint": {
      "type": [
        "string",
        "null"
      ]
    },
    "infoUrl": {
      "type": [
        "string",
        "null"
      ]
    },
    "alias": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    }
  },
  "subs": {
    "type": "array"
  }
};

export const UnifiedMicrobiologicalAnalysisSchema = {
  "main": {
    "asid": {
      "type": "integer"
    },
    "shortName": {
      "type": "string"
    },
    "longName": {
      "type": "string"
    },
    "externalId": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "order": {
      "type": "integer"
    },
    "hint": {
      "type": [
        "string",
        "null"
      ]
    },
    "form": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "array"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 20,
        "type": "string"
      },
      "costUnit": {
        "maxLength": 20,
        "type": "string"
      },
      "invoiceToChangeable": {
        "type": "boolean"
      },
      "defaultInvoiceTo": {},
      "isPrivate": {
        "type": "boolean"
      },
      "isDynamicMaterial": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isDefault": {
        "type": "boolean"
      },
      "isFilterHidden": {
        "type": "boolean"
      },
      "formNote": {
        "maxLength": 150,
        "type": "string"
      },
      "isGraphic": {
        "type": "boolean"
      },
      "barcodeComment": {
        "maxLength": 15,
        "type": "string"
      },
      "exportInfo": {
        "maxLength": 150,
        "type": "string"
      },
      "isBak": {
        "type": "boolean"
      },
      "satzart": {
        "maxLength": 4,
        "type": "string"
      },
      "directscheindruckmode": {
        "type": "integer"
      },
      "lastChanged": {
        "maxLength": 14,
        "type": "string"
      },
      "isPool": {
        "type": "boolean"
      },
      "maximumRequestCount": {
        "type": [
          "integer",
          "null"
        ]
      },
      "datamatrix": {
        "maxLength": 20,
        "type": "string"
      },
      "splitMode": {},
      "basePrice": {
        "type": "string"
      },
      "hasDynProbeType": {
        "type": "boolean"
      },
      "isDeleted": {
        "type": "boolean"
      },
      "priceFactor": {
        "type": "string"
      },
      "formGroupID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "bcPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "exportAsFile": {
        "maxLength": 10,
        "type": "string"
      },
      "digitalSignatureMode": {},
      "exportType": {
        "type": "integer"
      },
      "laboratoryGroupShortCode": {
        "maxLength": 50,
        "type": "string"
      },
      "zplID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "formType": {},
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "requirements": {
        "type": "array"
      }
    }
  },
  "subs": {
    "type": "array"
  }
};

export const OrdersConfigSchema = {
  "preferences": {
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    }
  },
  "printSettings": {
    "barcodeMode": {},
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "overwrite": {
      "type": "boolean"
    },
    "resetCentralBarcodes": {
      "type": "boolean"
    }
  },
  "laboratoryGroupBarcodeSettings": {
    "type": "array"
  },
  "advancedAlphanumericBarcodeSettings": {
    "type": "array"
  },
  "realReRequestSettings": {
    "mtlMail": {
      "maxLength": 250,
      "type": "string"
    },
    "useLgMail": {
      "type": "boolean"
    },
    "orderTimeframe": {
      "type": "integer"
    },
    "reRequestLimit": {
      "type": "integer"
    },
    "reRequestBuffer": {
      "type": "integer"
    },
    "reRequestApprove": {
      "type": "boolean"
    },
    "reRequestNoExport": {
      "type": "boolean"
    },
    "reRequestLimitSampleDate": {
      "type": "boolean"
    },
    "blockRealReRequestMadeMails": {
      "type": "boolean"
    }
  },
  "diagnoses": {
    "type": "array"
  },
  "localisation": {},
  "printerMapping": {
    "type": "array"
  },
  "formLabelPrinterMapping": {
    "mapping": {
      "type": "array"
    },
    "forms": {
      "type": "array"
    },
    "workstations": {
      "type": "array"
    }
  },
  "centralBarcodes": {
    "threshold": {
      "type": [
        "integer",
        "null"
      ]
    },
    "availableCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "nextBarcode": {
      "type": [
        "string",
        "null"
      ]
    }
  },
  "costUnits": {
    "type": "array"
  }
};

export const OrdersGeneralConfigSchema = {
  "preferences": {
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    }
  },
  "printSettings": {
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "overwrite": {
      "type": "boolean"
    },
    "resetCentralBarcodes": {
      "type": "boolean"
    }
  },
  "laboratoryGroupBarcodeSettings": {
    "type": "array"
  },
  "advancedAlphanumericBarcodeSettings": {
    "type": "array"
  },
  "realReRequestSettings": {
    "mtlMail": {
      "maxLength": 250,
      "type": "string"
    },
    "useLgMail": {
      "type": "boolean"
    },
    "orderTimeframe": {
      "type": "integer"
    },
    "reRequestLimit": {
      "type": "integer"
    },
    "reRequestBuffer": {
      "type": "integer"
    },
    "reRequestApprove": {
      "type": "boolean"
    },
    "reRequestNoExport": {
      "type": "boolean"
    },
    "reRequestLimitSampleDate": {
      "type": "boolean"
    },
    "blockRealReRequestMadeMails": {
      "type": "boolean"
    }
  },
  "diagnoses": {
    "type": "array"
  },
  "localisation": {}
};

export const OrdersPrintSettingsSchema = {
  "barcodeMode": {},
  "printRangeMin": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printRangeMax": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "printPrefix": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "printPosition": {
    "maxLength": 150,
    "type": [
      "string",
      "null"
    ]
  },
  "useOwnNummernkreis": {
    "type": "boolean"
  },
  "useLgNummernkreis": {
    "type": "boolean"
  },
  "internalPrintUser": {
    "type": "boolean"
  },
  "socketPrint": {
    "type": "boolean"
  },
  "overwrite": {
    "type": "boolean"
  },
  "resetCentralBarcodes": {
    "type": "boolean"
  }
};

export const OrdersUserConfigSchema = {
  "localisation": {},
  "preferences": {
    "ordersDetailDefaultGroupBy": {},
    "ordersDetailDefaultSortBy": {},
    "ordersRealCancellation": {
      "type": "boolean"
    },
    "ordersRealCancellationTimeRange": {
      "type": "integer"
    },
    "ordersRealCancellationTimeRangeInterval": {},
    "ordersShowPrePrint": {
      "type": "boolean"
    },
    "ordersPrePrintIsC2A": {
      "type": "boolean"
    },
    "ordersMaxPoolSize": {
      "type": "integer"
    },
    "ordersShowBeforeAfterDialysis": {
      "type": "boolean"
    },
    "ordersBeforeAfterDialysisMustBeSpecified": {
      "type": "boolean"
    },
    "ordersSplitClinical": {
      "type": "boolean"
    },
    "ordersSplitBak": {
      "type": "boolean"
    },
    "ordersScheduleTimeEnabled": {
      "type": "boolean"
    },
    "ordersScheduleTimeInterval": {
      "type": "integer"
    },
    "ordersScheduleTimeEnableDefault": {
      "type": "boolean"
    },
    "ordersScheduleTimeDefault": {
      "type": "string"
    },
    "ordersMultipleBakBarcodes": {
      "type": "boolean"
    },
    "ordersDefaultOrderMode": {},
    "ordersDefaultOrderModeEnabled": {
      "type": "boolean"
    },
    "ordersBookmarkedPoolMode": {
      "type": "boolean"
    },
    "ordersDefaultStatusInBookmarked": {},
    "ordersDefaultCompletionOrderBy": {},
    "ordersDefaultTreatmentReason": {},
    "ordersShowRoomNumber": {
      "type": "boolean"
    },
    "ordersHideDiagnosisFields": {
      "type": "boolean"
    },
    "ordersPreferScheduleDateMode": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButton": {
      "type": "boolean"
    },
    "ordersSampleInLaboratoryButtonFirst": {
      "type": "boolean"
    },
    "ordersSampleDateTimeButton": {
      "type": "boolean"
    },
    "ordersAnamnesisShrinkMode": {
      "type": "boolean"
    },
    "ordersDynamicProbeTypeStaticReprint": {
      "type": "boolean"
    },
    "orderWizardStartFilters": {
      "type": "array"
    },
    "orderWizardCompactView": {
      "type": "boolean"
    },
    "orderWizardUltraCompactView": {
      "type": "boolean"
    },
    "orderWizardDisablePopovers": {
      "type": "boolean"
    },
    "orderWizardDisableTopRequests": {
      "type": "boolean"
    },
    "orderWizardEnableBasketPopovers": {
      "type": "boolean"
    },
    "orderWizardDisableAllPopovers": {
      "type": "boolean"
    },
    "orderWizardStartGroupForm": {
      "type": "array"
    },
    "orderWizardDefaultGroupForm": {
      "type": "object"
    },
    "orderWizardProfilesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardTopRequestsInGroupForm": {
      "type": "boolean"
    },
    "orderWizardFavoritesInGroupForm": {
      "type": "boolean"
    },
    "orderWizardWrapPopoverHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapHeader": {
      "type": "boolean"
    },
    "orderWizardUnwrapListTitle": {
      "type": "boolean"
    },
    "orderWizardWiderColumns": {
      "type": "boolean"
    },
    "orderAutoRequirementHintRule": {
      "type": "boolean"
    },
    "orderWizardHideAdditionalInfo": {
      "type": "boolean"
    },
    "orderWizardPatientHideFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardPatientTopBarFindingsMedications": {
      "type": "boolean"
    },
    "orderWizardNewOrderShowSelectPatient": {
      "type": "boolean"
    },
    "orderWizardShowCostUnitSelect": {
      "type": "boolean"
    },
    "orderWizardDisableMasonryView": {
      "type": "boolean"
    },
    "orderWizardHideInsuranceInInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardDefaultReportCopySetting": {
      "type": [
        "string",
        "null"
      ]
    },
    "orderWizardDefaultInvoiceTo": {
      "type": "string"
    },
    "orderWizardPopoverInvoiceTo": {
      "type": "boolean"
    },
    "orderWizardPopoverHideAnalyses": {
      "type": "boolean"
    },
    "orderWizardReportsTimeRange": {
      "type": "integer"
    },
    "orderWizardReportsTimeRangeInterval": {},
    "orderWizardSearchDirectInput": {
      "type": "boolean"
    },
    "orderWizardBakModalBlockButtons": {
      "type": "boolean"
    },
    "orderWizardDisableHelgaImage": {
      "type": "boolean"
    },
    "orderWizardShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardHideOfflineWorkstations": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowWorkstationSelect": {
      "type": "boolean"
    },
    "orderWizardRememberLastWorkstation": {
      "type": "boolean"
    },
    "orderWizardLastWorkstationId": {
      "type": "integer"
    },
    "orderWizardAutocompleteEntries": {
      "type": "integer"
    },
    "orderWizardBakColor": {
      "type": "string"
    },
    "orderWizardBakFontColor": {
      "type": "string"
    },
    "orderWizardHideBakMaterialGroupInfo": {
      "type": "boolean"
    },
    "orderWizardAlwaysShowBlocks": {
      "type": "boolean"
    },
    "orderWizardBlockSize": {
      "type": "integer"
    },
    "orderWizardDifferentBlockSizeOnHome": {
      "type": "boolean"
    },
    "orderWizardBlockSizeOnHome": {
      "type": "integer"
    },
    "orderWizardCopyToPatientActivationSwitch": {
      "type": "boolean"
    },
    "orderWizardSeparateInvoiceTo": {
      "type": "boolean"
    },
    "orderBarcodeFactorDefault": {
      "type": "number"
    },
    "orderBarcodeMaterialFactor": {
      "type": "array"
    },
    "orderBarcodeMaterialFactorShowOriginalCount": {
      "type": "boolean"
    },
    "orderSortPoolPatientsByName": {
      "type": "boolean"
    },
    "orderSortPatientsDropdownByName": {
      "type": "boolean"
    },
    "orderWizardSubRequirementsInBasket": {
      "type": "boolean"
    },
    "orderWizardInfectiousSwitch": {
      "type": "boolean"
    },
    "orderWizardPregnancySwitch": {
      "type": "boolean"
    },
    "orderWizardCheckupSwitch": {
      "type": "boolean"
    },
    "orderWizardEnableCreateEditProfiles": {
      "type": "boolean"
    },
    "orderWizardProfilesDefaultToAllOthers": {
      "type": "boolean"
    },
    "orderWizardProfilesSetDiagnoseText": {
      "type": "boolean"
    },
    "orderWizardProfilesSetFreeText": {
      "type": "boolean"
    },
    "orderWizardShowExttnr": {
      "type": "boolean"
    },
    "orderWizardShowExttnrLastUnderline": {
      "type": "boolean"
    },
    "orderWizardShowUrgentSwitch": {
      "type": "boolean"
    },
    "orderWizardShowEmergencySwitch": {
      "type": "boolean"
    },
    "orderWizardShowAccidentSwitch": {
      "type": "boolean"
    },
    "orderWizardFillSampleDateFromScheduleDate": {
      "type": "boolean"
    },
    "orderWizardOpenDuplicatePatientOrdersModal": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeCall": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeFax": {
      "type": "boolean"
    },
    "orderWizardUrgentNotificationModeEmail": {
      "type": "boolean"
    },
    "orderWizardChTreatmentReasonDropdown": {
      "type": "boolean"
    },
    "orderWizardInsuranceNumberMask": {
      "type": "string"
    },
    "orderWizardShowProfileAlreadyInBasketWarning": {
      "type": "boolean"
    },
    "orderWizardReportValueAllAnalyses": {
      "type": "boolean"
    },
    "orderMultiHeaderMoreInfo": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInPopover": {
      "type": "boolean"
    },
    "orderWizardAdditionalTextInCompactView": {
      "type": "boolean"
    }
  },
  "printSettings": {
    "barcodeMode": {},
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "overwrite": {
      "type": "boolean"
    },
    "resetCentralBarcodes": {
      "type": "boolean"
    }
  },
  "printerMapping": {
    "type": "array"
  },
  "formLabelPrinterMapping": {
    "mapping": {
      "type": "array"
    },
    "forms": {
      "type": "array"
    },
    "workstations": {
      "type": "array"
    }
  },
  "centralBarcodes": {
    "threshold": {
      "type": [
        "integer",
        "null"
      ]
    },
    "availableCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "nextBarcode": {
      "type": [
        "string",
        "null"
      ]
    }
  }
};

export const OrdersCentralBarcodeStatusSchema = {
  "threshold": {
    "type": [
      "integer",
      "null"
    ]
  },
  "availableCount": {
    "type": [
      "integer",
      "null"
    ]
  },
  "nextBarcode": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrdersFormLabelPrinterMappingSchema = {
  "mapping": {
    "type": "array"
  },
  "forms": {
    "type": "array"
  },
  "workstations": {
    "type": "array"
  }
};

export const KbvCovidFormPropertiesSchema = {
  "testNumber": {},
  "testReason": {},
  "agreed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "riskCharacteristicsLocation": {},
  "riskCharacteristicsLocationType": {},
  "legalReason": {},
  "healthServiceZipCode": {
    "maxLength": 255,
    "type": [
      "string",
      "null"
    ]
  },
  "healthService": {
    "maxLength": 255,
    "type": [
      "string",
      "null"
    ]
  },
  "legalOrderBasis": {},
  "kvSpecial": {
    "type": [
      "string",
      "null"
    ]
  },
  "confirmationDiagnostics": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "variantDiagnostics": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "oegdInvoiceTo": {
    "maxLength": 25,
    "type": [
      "string",
      "null"
    ]
  },
  "covidWarnAppBarcode": {
    "type": [
      "boolean",
      "null"
    ]
  }
};

export const MaterialPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "sampleId": {
    "type": "integer"
  },
  "sampleType": {
    "type": "string"
  },
  "barcodeCount": {
    "type": "integer"
  },
  "manufacturer": {
    "type": "string"
  },
  "name": {
    "type": "string"
  },
  "materialId": {
    "type": "string"
  },
  "externalMaterialId": {
    "type": [
      "string",
      "null"
    ]
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "substitutes": {
    "type": "array"
  }
};

export const SubstituteMaterialPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "sampleId": {
    "type": "integer"
  },
  "sampleType": {
    "type": "string"
  },
  "manufacturer": {
    "type": "string"
  },
  "name": {
    "type": "string"
  },
  "materialId": {
    "type": "string"
  },
  "externalMaterialId": {
    "type": [
      "string",
      "null"
    ]
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  }
};

export const AnalysisPropertiesSchema = {
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "id": {
    "type": "integer"
  },
  "externalId": {
    "maxLength": 10,
    "type": [
      "string",
      "null"
    ]
  },
  "materialId": {
    "type": "string"
  },
  "sampleType": {
    "type": "string"
  },
  "name": {
    "type": "string"
  },
  "amount": {
    "type": "number"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const OrderRequirementsFilterSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "count": {
    "type": "integer"
  },
  "type": {},
  "background": {
    "type": "string"
  },
  "doubleWidth": {
    "type": "boolean"
  }
};

export const AggregatedFormPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "array"
  },
  "name": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "invoiceToChangeable": {
    "type": "boolean"
  },
  "defaultInvoiceTo": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "isPool": {
    "type": "boolean"
  },
  "requirementPrice": {
    "type": "number"
  }
};

export const AggregatedProfileIdsSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "isMicrobiological": {
    "type": "boolean"
  }
};

export const AggregatedProfilePropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "isMicrobiological": {
    "type": "boolean"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "formId": {
    "type": "integer"
  },
  "order": {
    "type": "integer"
  },
  "color": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "diagnosis": {
    "type": "string"
  },
  "freeText": {
    "type": "string"
  },
  "orderReason": {
    "type": "string"
  },
  "requirements": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "filter": {
    "id": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "count": {
      "type": "integer"
    },
    "type": {},
    "background": {
      "type": "string"
    },
    "doubleWidth": {
      "type": "boolean"
    }
  },
  "selectedDiagnoses": {
    "type": "object"
  },
  "dynamicMaterials": {
    "type": "array"
  },
  "showInProfileAggregation": {
    "type": "boolean"
  }
};

export const AggregatedCategoryPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const AggregatedRequirementCollectionSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  }
};

export const AggregatedSuperRequirementPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "isMicrobiological": {
    "type": "boolean"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "formId": {
    "type": "integer"
  },
  "order": {
    "type": "integer"
  },
  "color": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "diagnosis": {
    "type": "string"
  },
  "freeText": {
    "type": "string"
  },
  "orderReason": {
    "type": "string"
  },
  "requirements": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "filter": {
    "id": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "count": {
      "type": "integer"
    },
    "type": {},
    "background": {
      "type": "string"
    },
    "doubleWidth": {
      "type": "boolean"
    }
  },
  "selectedDiagnoses": {
    "type": "object"
  },
  "dynamicMaterials": {
    "type": "array"
  },
  "showInProfileAggregation": {
    "type": "boolean"
  }
};

export const AggregatedIndicationPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const AggregatedNamePropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const AggregatedDiagnosisPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "type": "string"
  },
  "text": {
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  },
  "onlyAdultFemale": {
    "type": "boolean"
  }
};

export const DoctorTopRequirementsPropertiesSchema = {
  "aid": {
    "type": "integer"
  },
  "requirements": {
    "type": "array"
  }
};

export const OrderRequirementsIncompatiblePropertiesSchema = {
  "costUnit": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "incompatible": {
    "type": "string"
  },
  "makeCostUnitNot": {
    "type": "boolean"
  }
};

export const AggregatedRequirementIdsSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "submitter": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const AggregatedMaterialIdsSchema = {
  "id": {
    "type": "integer"
  },
  "materialId": {
    "type": "string"
  }
};

export const AggregatedAnalyseIdsSchema = {
  "id": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  }
};

export const FormLevelPriceSchema = {
  "formId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "price": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const AlternateFormCostUnitSchema = {
  "costUnit": {
    "type": "string"
  },
  "formId": {
    "type": "integer"
  },
  "isPool": {
    "type": "boolean"
  }
};

export const AlternateMibiFormCostUnitSchema = {
  "costUnit": {
    "type": "string"
  },
  "formId": {
    "type": "integer"
  },
  "isPool": {
    "type": "boolean"
  },
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  }
};

export const BaseOrderRequirementPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "submitter": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "externalName": {
    "type": "string"
  },
  "entityKey": {
    "type": "string"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "alias": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "price": {
    "type": "string"
  },
  "rank": {
    "type": "number"
  },
  "ruleInfoText": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "freeText": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextDate": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextAllowed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextMandatory": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextAsDate": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "analyses": {
    "type": "array"
  },
  "materials": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "diagnoses": {
    "type": "array"
  },
  "localizations": {
    "type": "array"
  },
  "requirementCollections": {
    "type": "array"
  },
  "alternateGroupFormCostUnits": {
    "type": "array"
  },
  "alternateMicrobiologicalFormCostUnits": {
    "type": "array"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "materialLimitOverrule": {
    "type": [
      "integer",
      "null"
    ]
  },
  "superRequirement": {
    "type": "boolean"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "duplicateRequirements": {
    "type": "array"
  }
};

export const OrderRequirementDynamicMaterialSchema = {
  "text": {
    "type": "string"
  },
  "index": {
    "type": "integer"
  },
  "shortName": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  }
};

export const OrderRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "submitter": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "externalName": {
    "type": "string"
  },
  "entityKey": {
    "type": "string"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "alias": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "price": {
    "type": "string"
  },
  "rank": {
    "type": "number"
  },
  "ruleInfoText": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "freeText": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextDate": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextAllowed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextMandatory": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextAsDate": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "analyses": {
    "type": "array"
  },
  "materials": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "diagnoses": {
    "type": "array"
  },
  "localizations": {
    "type": "array"
  },
  "requirementCollections": {
    "type": "array"
  },
  "alternateGroupFormCostUnits": {
    "type": "array"
  },
  "alternateMicrobiologicalFormCostUnits": {
    "type": "array"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "materialLimitOverrule": {
    "type": [
      "integer",
      "null"
    ]
  },
  "superRequirement": {
    "type": "boolean"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "duplicateRequirements": {
    "type": "array"
  },
  "profileId": {
    "type": "integer"
  },
  "profileName": {
    "type": "string"
  },
  "formLevelPrices": {
    "type": "array"
  },
  "formId": {
    "type": "integer"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "selectedAnalyses": {
    "type": "array"
  },
  "leftRight": {},
  "intraoperative": {
    "type": "boolean"
  },
  "origin": {
    "type": "string"
  },
  "selectedLocalizations": {
    "type": "array"
  },
  "dynamicMaterials": {
    "type": "array"
  },
  "reRequested": {
    "id": {
      "type": "integer"
    },
    "orderId": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    },
    "requirementId": {
      "type": "integer"
    },
    "formName": {
      "type": "string"
    },
    "shortName": {
      "type": "string"
    },
    "externalName": {
      "maxLength": 100,
      "type": "string"
    },
    "longName": {
      "type": "string"
    },
    "insurance": {
      "type": "string"
    },
    "isPrivate": {
      "type": "boolean"
    },
    "price": {
      "type": [
        "string",
        "null"
      ]
    },
    "freeText": {
      "type": "string"
    },
    "freeTextAllowed": {
      "type": "boolean"
    },
    "freeTextMandatory": {
      "type": "boolean"
    },
    "state": {},
    "data": {
      "type": "string"
    },
    "comment": {
      "type": "string"
    },
    "historyId": {
      "type": "integer"
    },
    "accept": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "order": {
      "lid": {
        "type": "integer"
      },
      "isPool": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isSoftDeleted": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "freeText": {
        "maxLength": 60,
        "type": "string"
      },
      "message": {
        "maxLength": 100,
        "type": "string"
      },
      "infectious": {
        "type": "boolean"
      },
      "urgent": {
        "type": "string"
      },
      "pregnancy": {
        "type": "boolean"
      },
      "checkup": {
        "type": "boolean"
      },
      "costUnit": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "controlCheckupKnownInfection": {
        "type": "boolean"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "dz16b": {
        "type": "boolean"
      },
      "accident": {
        "type": "boolean"
      },
      "accidentTime": {
        "maxLength": 20,
        "type": "string"
      },
      "documentReferenceNumber": {
        "maxLength": 20,
        "type": "string"
      },
      "anamnesticInfo": {
        "maxLength": 60,
        "type": "string"
      },
      "printedMaterials": {
        "type": [
          "string",
          "null"
        ]
      },
      "printQuickReportBarcode": {
        "type": "boolean"
      },
      "urgentNotificationPhone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationFax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationMail": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "id": {
        "type": "integer"
      },
      "status": {},
      "executionStatus": {},
      "poolId": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "type": "string"
      },
      "bsnr": {
        "type": "string"
      },
      "selectedDoctor": {
        "type": "string"
      },
      "apid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "patient": {
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 50,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 150,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "costUnit": {
          "type": "string"
        },
        "medication": {
          "maxLength": 100,
          "type": "string"
        },
        "cycleDay": {
          "maxLength": 10,
          "type": "string"
        },
        "weight": {
          "maxLength": 5,
          "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 150,
          "type": "string"
        },
        "exttnr": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "primaryCarePhysicianLanr": {
          "maxLength": 20,
          "type": "string"
        },
        "primaryCarePhysicianBsnr": {
          "maxLength": 20,
          "type": "string"
        },
        "diseaseManagementProgram": {
          "maxLength": 50,
          "type": "string"
        },
        "dmpSpecialGroup": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceStart": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceEnd": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceType": {
          "maxLength": 50,
          "type": "string"
        },
        "exceptionalIndication": {
          "maxLength": 20,
          "type": "string"
        },
        "sktIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "wopIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "accountingArea": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "hvcode": {
          "maxLength": 20,
          "type": "string"
        },
        "vkat": {
          "maxLength": 20,
          "type": "string"
        },
        "secondName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "canton": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "ahvNr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "aisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "insurance": {
          "type": [
            "number",
            "null"
          ]
        },
        "bag": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "cardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "globalLocationNumber": {
          "maxLength": 13,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "hospitalBedNumber": {
          "type": "string"
        },
        "hospitalRoomNumber": {
          "type": "string"
        },
        "hospitalProfession": {
          "type": [
            "string",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "invoiceTo": {
          "maxLength": 25,
          "type": "string"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "autInsuranceTitle": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceFirstName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceLastName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceInsuranceNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceBirthday": {
          "type": "string"
        },
        "autInsuranceAddress": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceHouseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCountry": {
          "maxLength": 10,
          "type": "string"
        },
        "autInsuranceZip": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCity": {
          "maxLength": 50,
          "type": "string"
        },
        "pid": {
          "type": "integer"
        },
        "apgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "displayName": {
          "type": "string"
        },
        "prefix": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 5,
          "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "pregnancyWeek": {
          "maxLength": 2,
          "pattern": "^(\\d{1,2})?$",
          "type": "string"
        },
        "pregnancyWeekDay": {
          "maxLength": 1,
          "pattern": "^(\\d{1})?$",
          "type": "string"
        },
        "cycleLength": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        },
        "dayOfCycle": {
          "maxLength": 2,
          "type": "string"
        },
        "fetalCrownRumpLength": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "fetalNuchalTranslucency": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "hasInsuranceNumber": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "sampleAmount": {
          "maxLength": 10,
          "type": "string"
        },
        "samplePeriod": {
          "maxLength": 10,
          "type": "string"
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "passportNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "idcardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "orderReason": {
          "maxLength": 1,
          "type": "string"
        },
        "costUnitIdentification": {
          "maxLength": 25,
          "type": "string"
        },
        "autInsuranceEmployer": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceEmployerCity": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceAddressAdditional": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "requirements": {
        "type": "array"
      },
      "anamnesis": {
        "type": "array"
      },
      "animal": {
        "name": {
          "maxLength": 50,
          "type": "string"
        },
        "birthday": {
          "maxLength": 8,
          "type": "string"
        },
        "species": {
          "maxLength": 2,
          "type": "string"
        },
        "speciesManual": {
          "maxLength": 50,
          "type": "string"
        },
        "chipId": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 3,
          "type": "string"
        },
        "weight": {
          "maxLength": 3,
          "type": "string"
        },
        "sex": {
          "type": "number"
        },
        "breed": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerName": {
          "maxLength": 100,
          "type": "string"
        },
        "ownerAmendment": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerStreet": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerHouseNumber": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerZipCode": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerCity": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "switzerland": {
        "billToOther1": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther2": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther3": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyTo": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToEmail": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToSalutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToFirstName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToLastName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToStreet": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToZip": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToCity": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "notes": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "accidentDate": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "hungary": {
        "motherLastName": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "motherFirstName": {
          "maxLength": 15,
          "type": [
            "string",
            "null"
          ]
        },
        "treatmentReason": {
          "maxLength": 1,
          "type": [
            "string",
            "null"
          ]
        },
        "invoice": {
          "maxLength": 2,
          "type": [
            "string",
            "null"
          ]
        },
        "icd1": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd2": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd3": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "insuranceNumber": {
          "maxLength": 18,
          "type": [
            "string",
            "null"
          ]
        },
        "citizen": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "executed_at": {
        "type": "string"
      },
      "scheduled_at": {
        "type": [
          "string",
          "null"
        ]
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "covid": {
        "testNumber": {},
        "testReason": {},
        "agreed": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "riskCharacteristicsLocation": {},
        "riskCharacteristicsLocationType": {},
        "legalReason": {},
        "healthServiceZipCode": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "healthService": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "legalOrderBasis": {},
        "kvSpecial": {
          "type": [
            "string",
            "null"
          ]
        },
        "confirmationDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "variantDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "oegdInvoiceTo": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "covidWarnAppBarcode": {
          "type": [
            "boolean",
            "null"
          ]
        }
      },
      "mustValidate": {
        "type": "boolean"
      },
      "hospitalStation": {
        "type": "string"
      },
      "requirementDiagnosisFreeText": {
        "maxLength": 150,
        "type": "string"
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "selectedDiagnoses": {
        "type": "object"
      },
      "selectedDiagnosesChecked": {
        "type": "boolean"
      },
      "workstationChecked": {
        "type": "boolean"
      },
      "splitProperties": {
        "type": "array"
      },
      "sample_in_lab": {
        "type": "boolean"
      },
      "clientname": {
        "type": "string"
      },
      "printerAliasName": {
        "type": "string"
      },
      "overridePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "overrideBarcodePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "beforeAfterDialysis": {},
      "reportCopyAid": {
        "type": [
          "number",
          "null"
        ]
      },
      "reportCopyDoctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "aisIdentifier": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "patientAisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "bloodCollectionType": {},
      "transported_at": {
        "type": "string"
      },
      "created_at": {
        "type": "string"
      },
      "digitalSignature": {},
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "report": {
        "bid": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "parent_bid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "status": {},
        "tnr": {
          "type": "string"
        },
        "pathological": {},
        "mibi": {},
        "created_at": {
          "type": "string"
        },
        "imported_at": {
          "type": "string"
        },
        "registered_at": {
          "type": "string"
        },
        "validated_at": {
          "type": "string"
        },
        "sampled_at": {
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctor": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctorAddition": {
          "maxLength": 100,
          "type": "string"
        },
        "patientName": {
          "type": "string"
        },
        "is_locked": {
          "type": "boolean"
        },
        "is_unread": {
          "type": "boolean"
        },
        "is_pinned": {
          "type": "boolean"
        },
        "has_base64": {
          "type": "boolean"
        },
        "has_elpho": {
          "type": "boolean"
        },
        "has_subreports": {
          "type": "boolean"
        },
        "has_history": {
          "type": "boolean"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "patient": {
          "pid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 200,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 100,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 50,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "phone": {
            "maxLength": 50,
            "type": "string"
          },
          "costUnit": {
            "type": "string"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "sonstiges1": {
            "maxLength": 50,
            "type": "string"
          },
          "sonstiges2": {
            "maxLength": 50,
            "type": "string"
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "smsNotificationEnabled": {
            "type": "boolean"
          },
          "smsNumber": {
            "type": "string"
          },
          "canViewPartialResults": {
            "type": "boolean"
          },
          "canViewPreliminaryResults": {
            "type": "boolean"
          },
          "resultLanguage": {
            "maxLength": 5,
            "type": "string"
          },
          "oa": {
            "type": "boolean"
          },
          "aids": {
            "type": "array"
          },
          "latest_report_date": {
            "type": "string"
          },
          "dateScore": {
            "type": "number"
          },
          "passportNumber": {
            "type": "string"
          },
          "idCardNumber": {
            "type": "string"
          }
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "laboratoryGroup": {
          "grpid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "kurz": {
            "maxLength": 20,
            "pattern": "^(?![Ll][Aa]$).*$",
            "type": "string"
          },
          "name": {
            "maxLength": 200,
            "type": "string"
          },
          "telefon": {
            "maxLength": 20,
            "type": "string"
          },
          "fax": {
            "maxLength": 20,
            "type": "string"
          },
          "website": {
            "maxLength": 50,
            "type": "string"
          },
          "adresse": {
            "maxLength": 50,
            "type": "string"
          },
          "stadt": {
            "maxLength": 50,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": "string"
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": "string"
          },
          "zusatz1": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz2": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz3": {
            "maxLength": 200,
            "type": "string"
          },
          "plz": {
            "maxLength": 25,
            "type": "string"
          },
          "showPdfExport": {
            "type": "boolean"
          },
          "laboratoryGroupBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPrefix": {
              "maxLength": 10,
              "type": "string"
            },
            "printPosition": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMin": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMax": {
              "maxLength": 40,
              "type": "string"
            }
          },
          "advancedAlphanumericBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPosition1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax1": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax2": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax3": {
              "maxLength": 3,
              "type": "string"
            },
            "useState": {
              "type": "object"
            }
          }
        },
        "laboratoryGroupFilter": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "maxLength": 32,
            "type": "string"
          },
          "color": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          },
          "group": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "values": {
          "type": "array"
        },
        "subreports": {
          "type": "array"
        },
        "attachments": {
          "type": "array"
        },
        "attachment_count": {
          "type": "integer"
        },
        "comments": {
          "type": "array"
        },
        "comment_count": {
          "type": "integer"
        },
        "arzname": {
          "maxLength": 300,
          "type": "string"
        },
        "labname": {
          "type": "string"
        },
        "accountingType": {
          "type": "string"
        },
        "laborgruppe": {
          "type": "string"
        },
        "exttnr": {
          "type": "string"
        },
        "lebensnummer": {
          "type": "string"
        },
        "zimmer": {
          "type": "string"
        },
        "lastupdate": {
          "type": "string"
        },
        "orderComment": {
          "type": "string"
        },
        "overwritecolor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "pdfSrc": {
          "type": "string"
        },
        "material": {
          "type": "string"
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceNumber": {
          "type": "string"
        },
        "showBdw": {
          "type": "boolean"
        },
        "showCumulative": {
          "type": "boolean"
        },
        "showPdf": {
          "type": "boolean"
        },
        "showOriginal": {
          "type": "boolean"
        },
        "showOriginalExclusive": {
          "type": "boolean"
        },
        "showCumulativePdf": {
          "type": "boolean"
        },
        "showCumulativeAllPdf": {
          "type": "boolean"
        },
        "showSend": {
          "type": "boolean"
        },
        "showRerequest": {
          "type": "boolean"
        },
        "originalPdfIds": {
          "type": "array"
        },
        "guid": {
          "type": "string"
        },
        "externalGuid": {
          "type": "string"
        },
        "manualCode": {
          "type": "string"
        },
        "uuid": {
          "type": "string"
        },
        "settlementType": {}
      },
      "pool": {
        "id": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "displayName": {
          "type": "string"
        },
        "status": {
          "type": "array"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "orders": {
          "type": "array"
        },
        "logs": {
          "type": "array"
        },
        "created_at": {
          "type": "string"
        },
        "updated_at": {
          "type": "string"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isSoftDeleted": {
          "type": "boolean"
        }
      },
      "samples": {
        "type": "array"
      },
      "workstation": {
        "id": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "location": {
          "type": "string"
        },
        "default": {
          "type": "boolean"
        },
        "status": {},
        "clientVersion": {
          "type": "string"
        },
        "printers": {
          "type": "array"
        }
      },
      "manufacturer": {
        "type": "string"
      },
      "initialWorkplaceId": {
        "type": "number"
      },
      "lastWorkplaceId": {
        "type": "number"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "splitId": {
        "type": "integer"
      },
      "quickScanPdfPassword": {
        "type": [
          "string",
          "null"
        ]
      },
      "hasAttachment": {
        "type": "boolean"
      },
      "case_id": {
        "type": "integer"
      },
      "caseNumber": {
        "type": "string"
      },
      "realReRequestConditions": {
        "allowed": {
          "type": "boolean"
        },
        "formIds": {
          "type": "array"
        },
        "requirementIds": {
          "type": "array"
        },
        "profileIds": {
          "type": "array"
        }
      },
      "exportFlag": {
        "type": "boolean"
      },
      "statusExport": {
        "type": "integer"
      },
      "statusStornoExport": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isCancelable": {
        "type": "boolean"
      },
      "actuallyPrintedMaterials": {
        "type": "string"
      },
      "hasReportCopyInfo": {
        "type": "boolean"
      }
    },
    "form": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "array"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 20,
        "type": "string"
      },
      "costUnit": {
        "maxLength": 20,
        "type": "string"
      },
      "invoiceToChangeable": {
        "type": "boolean"
      },
      "defaultInvoiceTo": {},
      "isPrivate": {
        "type": "boolean"
      },
      "isDynamicMaterial": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isDefault": {
        "type": "boolean"
      },
      "isFilterHidden": {
        "type": "boolean"
      },
      "formNote": {
        "maxLength": 150,
        "type": "string"
      },
      "isGraphic": {
        "type": "boolean"
      },
      "barcodeComment": {
        "maxLength": 15,
        "type": "string"
      },
      "exportInfo": {
        "maxLength": 150,
        "type": "string"
      },
      "isBak": {
        "type": "boolean"
      },
      "satzart": {
        "maxLength": 4,
        "type": "string"
      },
      "directscheindruckmode": {
        "type": "integer"
      },
      "lastChanged": {
        "maxLength": 14,
        "type": "string"
      },
      "isPool": {
        "type": "boolean"
      },
      "maximumRequestCount": {
        "type": [
          "integer",
          "null"
        ]
      },
      "datamatrix": {
        "maxLength": 20,
        "type": "string"
      },
      "splitMode": {},
      "basePrice": {
        "type": "string"
      },
      "hasDynProbeType": {
        "type": "boolean"
      },
      "isDeleted": {
        "type": "boolean"
      },
      "priceFactor": {
        "type": "string"
      },
      "formGroupID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "bcPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "exportAsFile": {
        "maxLength": 10,
        "type": "string"
      },
      "digitalSignatureMode": {},
      "exportType": {
        "type": "integer"
      },
      "laboratoryGroupShortCode": {
        "maxLength": 50,
        "type": "string"
      },
      "zplID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "formType": {},
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "requirements": {
        "type": "array"
      }
    },
    "history": {
      "id": {
        "type": "integer"
      },
      "openedAt": {
        "type": "string"
      },
      "reRequestInfo": {
        "type": "string"
      },
      "requirementInfo": {
        "type": "string"
      },
      "closedAt": {
        "type": "string"
      },
      "appliedAt": {
        "type": "string"
      },
      "order": {
        "lid": {
          "type": "integer"
        },
        "isPool": {
          "type": "boolean"
        },
        "isPoct": {
          "type": "boolean"
        },
        "isSoftDeleted": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "freeText": {
          "maxLength": 60,
          "type": "string"
        },
        "message": {
          "maxLength": 100,
          "type": "string"
        },
        "infectious": {
          "type": "boolean"
        },
        "urgent": {
          "type": "string"
        },
        "pregnancy": {
          "type": "boolean"
        },
        "checkup": {
          "type": "boolean"
        },
        "costUnit": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "controlCheckupKnownInfection": {
          "type": "boolean"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "dz16b": {
          "type": "boolean"
        },
        "accident": {
          "type": "boolean"
        },
        "accidentTime": {
          "maxLength": 20,
          "type": "string"
        },
        "documentReferenceNumber": {
          "maxLength": 20,
          "type": "string"
        },
        "anamnesticInfo": {
          "maxLength": 60,
          "type": "string"
        },
        "printedMaterials": {
          "type": [
            "string",
            "null"
          ]
        },
        "printQuickReportBarcode": {
          "type": "boolean"
        },
        "urgentNotificationPhone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "urgentNotificationFax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "urgentNotificationMail": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "id": {
          "type": "integer"
        },
        "status": {},
        "executionStatus": {},
        "poolId": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "type": "string"
        },
        "bsnr": {
          "type": "string"
        },
        "selectedDoctor": {
          "type": "string"
        },
        "apid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "patient": {
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 50,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 150,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "costUnit": {
            "type": "string"
          },
          "medication": {
            "maxLength": 100,
            "type": "string"
          },
          "cycleDay": {
            "maxLength": 10,
            "type": "string"
          },
          "weight": {
            "maxLength": 5,
            "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
            "type": "string"
          },
          "diagnosis": {
            "maxLength": 150,
            "type": "string"
          },
          "exttnr": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "primaryCarePhysicianLanr": {
            "maxLength": 20,
            "type": "string"
          },
          "primaryCarePhysicianBsnr": {
            "maxLength": 20,
            "type": "string"
          },
          "diseaseManagementProgram": {
            "maxLength": 50,
            "type": "string"
          },
          "dmpSpecialGroup": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceStart": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceEnd": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceType": {
            "maxLength": 50,
            "type": "string"
          },
          "exceptionalIndication": {
            "maxLength": 20,
            "type": "string"
          },
          "sktIdentifier": {
            "maxLength": 50,
            "type": "string"
          },
          "wopIdentifier": {
            "maxLength": 50,
            "type": "string"
          },
          "accountingArea": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "hvcode": {
            "maxLength": 20,
            "type": "string"
          },
          "vkat": {
            "maxLength": 20,
            "type": "string"
          },
          "secondName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "canton": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "ahvNr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "aisNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "insurance": {
            "type": [
              "number",
              "null"
            ]
          },
          "bag": {
            "maxLength": 5,
            "type": [
              "string",
              "null"
            ]
          },
          "cardNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "globalLocationNumber": {
            "maxLength": 13,
            "type": [
              "string",
              "null"
            ]
          },
          "chTreatmentReason": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "externalOrderInfo": {
            "maxLength": 256,
            "type": [
              "string",
              "null"
            ]
          },
          "hospitalBedNumber": {
            "type": "string"
          },
          "hospitalRoomNumber": {
            "type": "string"
          },
          "hospitalProfession": {
            "type": [
              "string",
              "null"
            ]
          },
          "caseId": {
            "type": [
              "integer",
              "null"
            ]
          },
          "caseNumber": {
            "type": "string"
          },
          "insuranceName": {
            "maxLength": 250,
            "type": "string"
          },
          "invoiceTo": {
            "maxLength": 25,
            "type": "string"
          },
          "dz116b": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "autInsuranceTitle": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceFirstName": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceLastName": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceInsuranceNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceBirthday": {
            "type": "string"
          },
          "autInsuranceAddress": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceHouseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceCountry": {
            "maxLength": 10,
            "type": "string"
          },
          "autInsuranceZip": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceCity": {
            "maxLength": 50,
            "type": "string"
          },
          "pid": {
            "type": "integer"
          },
          "apgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "displayName": {
            "type": "string"
          },
          "prefix": {
            "maxLength": 50,
            "type": "string"
          },
          "height": {
            "maxLength": 5,
            "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
            "type": "string"
          },
          "pregnancyWeek": {
            "maxLength": 2,
            "pattern": "^(\\d{1,2})?$",
            "type": "string"
          },
          "pregnancyWeekDay": {
            "maxLength": 1,
            "pattern": "^(\\d{1})?$",
            "type": "string"
          },
          "cycleLength": {
            "maxLength": 3,
            "type": [
              "string",
              "null"
            ]
          },
          "dayOfCycle": {
            "maxLength": 2,
            "type": "string"
          },
          "fetalCrownRumpLength": {
            "maxLength": 7,
            "type": [
              "string",
              "null"
            ]
          },
          "fetalNuchalTranslucency": {
            "maxLength": 7,
            "type": [
              "string",
              "null"
            ]
          },
          "hasInsuranceNumber": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "sampleAmount": {
            "maxLength": 10,
            "type": "string"
          },
          "samplePeriod": {
            "maxLength": 10,
            "type": "string"
          },
          "sampleDate": {
            "type": "string"
          },
          "sampleTime": {
            "maxLength": 8,
            "type": "string"
          },
          "passportNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "idcardNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "orderReason": {
            "maxLength": 1,
            "type": "string"
          },
          "costUnitIdentification": {
            "maxLength": 25,
            "type": "string"
          },
          "autInsuranceEmployer": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceEmployerCity": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceAddressAdditional": {
            "maxLength": 50,
            "type": "string"
          }
        },
        "requirements": {
          "type": "array"
        },
        "anamnesis": {
          "type": "array"
        },
        "animal": {
          "name": {
            "maxLength": 50,
            "type": "string"
          },
          "birthday": {
            "maxLength": 8,
            "type": "string"
          },
          "species": {
            "maxLength": 2,
            "type": "string"
          },
          "speciesManual": {
            "maxLength": 50,
            "type": "string"
          },
          "chipId": {
            "maxLength": 50,
            "type": "string"
          },
          "height": {
            "maxLength": 3,
            "type": "string"
          },
          "weight": {
            "maxLength": 3,
            "type": "string"
          },
          "sex": {
            "type": "number"
          },
          "breed": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerName": {
            "maxLength": 100,
            "type": "string"
          },
          "ownerAmendment": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerStreet": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerHouseNumber": {
            "maxLength": 10,
            "type": "string"
          },
          "ownerZipCode": {
            "maxLength": 10,
            "type": "string"
          },
          "ownerCity": {
            "maxLength": 50,
            "type": "string"
          }
        },
        "switzerland": {
          "billToOther1": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "billToOther2": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "billToOther3": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyTo": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToEmail": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToSalutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToFirstName": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToLastName": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToStreet": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToZip": {
            "maxLength": 5,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToCity": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "notes": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "chTreatmentReason": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "accidentDate": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "hungary": {
          "motherLastName": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "motherFirstName": {
            "maxLength": 15,
            "type": [
              "string",
              "null"
            ]
          },
          "treatmentReason": {
            "maxLength": 1,
            "type": [
              "string",
              "null"
            ]
          },
          "invoice": {
            "maxLength": 2,
            "type": [
              "string",
              "null"
            ]
          },
          "icd1": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "icd2": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "icd3": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "insuranceNumber": {
            "maxLength": 18,
            "type": [
              "string",
              "null"
            ]
          },
          "citizen": {
            "maxLength": 3,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "executed_at": {
          "type": "string"
        },
        "scheduled_at": {
          "type": [
            "string",
            "null"
          ]
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "covid": {
          "testNumber": {},
          "testReason": {},
          "agreed": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "riskCharacteristicsLocation": {},
          "riskCharacteristicsLocationType": {},
          "legalReason": {},
          "healthServiceZipCode": {
            "maxLength": 255,
            "type": [
              "string",
              "null"
            ]
          },
          "healthService": {
            "maxLength": 255,
            "type": [
              "string",
              "null"
            ]
          },
          "legalOrderBasis": {},
          "kvSpecial": {
            "type": [
              "string",
              "null"
            ]
          },
          "confirmationDiagnostics": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "variantDiagnostics": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "oegdInvoiceTo": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "covidWarnAppBarcode": {
            "type": [
              "boolean",
              "null"
            ]
          }
        },
        "mustValidate": {
          "type": "boolean"
        },
        "hospitalStation": {
          "type": "string"
        },
        "requirementDiagnosisFreeText": {
          "maxLength": 150,
          "type": "string"
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "selectedDiagnoses": {
          "type": "object"
        },
        "selectedDiagnosesChecked": {
          "type": "boolean"
        },
        "workstationChecked": {
          "type": "boolean"
        },
        "splitProperties": {
          "type": "array"
        },
        "sample_in_lab": {
          "type": "boolean"
        },
        "clientname": {
          "type": "string"
        },
        "printerAliasName": {
          "type": "string"
        },
        "overridePrinterName": {
          "type": [
            "string",
            "null"
          ]
        },
        "overrideBarcodePrinterName": {
          "type": [
            "string",
            "null"
          ]
        },
        "beforeAfterDialysis": {},
        "reportCopyAid": {
          "type": [
            "number",
            "null"
          ]
        },
        "reportCopyDoctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "aisIdentifier": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "patientAisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "bloodCollectionType": {},
        "transported_at": {
          "type": "string"
        },
        "created_at": {
          "type": "string"
        },
        "digitalSignature": {},
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "report": {
          "bid": {
            "type": "integer"
          },
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "parent_bid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "caseId": {
            "type": [
              "integer",
              "null"
            ]
          },
          "status": {},
          "tnr": {
            "type": "string"
          },
          "pathological": {},
          "mibi": {},
          "created_at": {
            "type": "string"
          },
          "imported_at": {
            "type": "string"
          },
          "registered_at": {
            "type": "string"
          },
          "validated_at": {
            "type": "string"
          },
          "sampled_at": {
            "type": "string"
          },
          "diagnosis": {
            "maxLength": 100,
            "type": "string"
          },
          "validatingDoctor": {
            "maxLength": 100,
            "type": "string"
          },
          "validatingDoctorAddition": {
            "maxLength": 100,
            "type": "string"
          },
          "patientName": {
            "type": "string"
          },
          "is_locked": {
            "type": "boolean"
          },
          "is_unread": {
            "type": "boolean"
          },
          "is_pinned": {
            "type": "boolean"
          },
          "has_base64": {
            "type": "boolean"
          },
          "has_elpho": {
            "type": "boolean"
          },
          "has_subreports": {
            "type": "boolean"
          },
          "has_history": {
            "type": "boolean"
          },
          "doctor": {
            "aid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": [
                "integer",
                "null"
              ]
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "externalId": {
              "maxLength": 60,
              "type": [
                "string",
                "null"
              ]
            },
            "externalId2": {
              "type": "string"
            },
            "lanr": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "lastName": {
              "maxLength": 250,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "maxLength": 25,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "houseNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "countryCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "phoneNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "faxNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "smsNumber": {
              "maxLength": 20,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "welcomeLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "logoutLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "hierarchyStatus": {
              "type": "object"
            },
            "hierarchyAssignments": {
              "type": "array"
            },
            "hierarchyTree": {
              "type": "array"
            },
            "canActivatePatients": {
              "type": "boolean"
            },
            "isSuperDoctor": {
              "type": "boolean"
            },
            "disableViewDiagnosis": {
              "type": "boolean"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "emailNotificationsCharacteristics": {
              "type": "string"
            },
            "emailNotificationsStatus": {
              "type": "string"
            },
            "emailReports": {
              "type": "boolean"
            },
            "officeName": {
              "maxLength": 200,
              "type": "string"
            },
            "bsnr": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "localisation": {
              "type": "object"
            },
            "laboratoryLocalisation": {
              "type": "object"
            },
            "manufacturer": {
              "type": "string"
            },
            "urgent": {
              "type": "boolean"
            },
            "emergency": {
              "type": "boolean"
            },
            "infectious": {
              "type": "boolean"
            },
            "oaanswertrigger": {
              "type": "string"
            },
            "oaSoftDelete": {
              "type": "boolean"
            },
            "printMode": {
              "type": "integer"
            },
            "barcodePrintSettings": {
              "type": "integer"
            },
            "printername": {
              "maxLength": 200,
              "type": "string"
            },
            "scheinPrintSettings": {
              "type": "integer"
            },
            "printernameSchein": {
              "maxLength": 200,
              "type": "string"
            },
            "socketPrint": {
              "type": "boolean"
            },
            "internalPrintUser": {
              "type": "boolean"
            },
            "customDoctorHeading": {
              "type": "boolean"
            },
            "customDoctorHeadingLine1": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine2": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine3": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine4": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine5": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine6": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine7": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine8": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine9": {
              "maxLength": 50,
              "type": "string"
            },
            "graphicalRequestChooser": {
              "type": "boolean"
            },
            "ipConstraint": {
              "type": "boolean"
            },
            "autoprint": {
              "type": "boolean"
            },
            "speciality": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "reportsLanguageSetting": {
              "type": "object"
            },
            "reportCount": {
              "type": "integer"
            },
            "forms": {
              "type": "array"
            },
            "profiles": {
              "type": "array"
            },
            "globalProfiles": {
              "type": "array"
            },
            "groups": {
              "type": "array"
            },
            "groupForms": {
              "type": "array"
            },
            "devices": {
              "type": "array"
            },
            "doctorGroupOffices": {
              "type": "array"
            },
            "doctorGroupOfficeName": {
              "type": "string"
            },
            "pid": {
              "type": "number"
            },
            "editKisMode": {
              "type": "boolean"
            },
            "laboratory": {
              "type": "object"
            },
            "barcodeMode": {
              "type": "object"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "useOwnNummernkreis": {
              "type": "boolean"
            },
            "useLgNummernkreis": {
              "type": "boolean"
            },
            "exportMe": {
              "type": "boolean"
            },
            "exportMeReceiver": {
              "type": [
                "string",
                "null"
              ]
            },
            "exportMeStatusString": {
              "type": "string"
            },
            "exportMeCharacteristicString": {
              "type": "string"
            },
            "exportMeSettingsStatusFlags": {
              "type": "object"
            },
            "exportMeSettingsCharacteristicFlags": {
              "type": "object"
            },
            "pinnedRequirements": {
              "type": "array"
            }
          },
          "patient": {
            "pid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 200,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "user": {
              "type": "object"
            },
            "lastName": {
              "maxLength": 50,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": "string"
            },
            "suffix": {
              "maxLength": 100,
              "type": "string"
            },
            "title": {
              "maxLength": 50,
              "type": "string"
            },
            "gender": {
              "maxLength": 10,
              "type": "string"
            },
            "birthday": {
              "maxLength": 50,
              "type": "string"
            },
            "city": {
              "maxLength": 50,
              "type": "string"
            },
            "zipCode": {
              "maxLength": 50,
              "type": "string"
            },
            "address": {
              "maxLength": 50,
              "type": "string"
            },
            "houseNumber": {
              "maxLength": 50,
              "type": "string"
            },
            "addressAdditional": {
              "maxLength": 50,
              "type": "string"
            },
            "country": {
              "maxLength": 50,
              "type": "string"
            },
            "insuranceNumber": {
              "maxLength": 100,
              "type": "string"
            },
            "phone": {
              "maxLength": 50,
              "type": "string"
            },
            "costUnit": {
              "type": "string"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "sonstiges1": {
              "maxLength": 50,
              "type": "string"
            },
            "sonstiges2": {
              "maxLength": 50,
              "type": "string"
            },
            "patientIdentification": {
              "maxLength": 20,
              "type": "string"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "smsNotificationEnabled": {
              "type": "boolean"
            },
            "smsNumber": {
              "type": "string"
            },
            "canViewPartialResults": {
              "type": "boolean"
            },
            "canViewPreliminaryResults": {
              "type": "boolean"
            },
            "resultLanguage": {
              "maxLength": 5,
              "type": "string"
            },
            "oa": {
              "type": "boolean"
            },
            "aids": {
              "type": "array"
            },
            "latest_report_date": {
              "type": "string"
            },
            "dateScore": {
              "type": "number"
            },
            "passportNumber": {
              "type": "string"
            },
            "idCardNumber": {
              "type": "string"
            }
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "laboratoryGroup": {
            "grpid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "kurz": {
              "maxLength": 20,
              "pattern": "^(?![Ll][Aa]$).*$",
              "type": "string"
            },
            "name": {
              "maxLength": 200,
              "type": "string"
            },
            "telefon": {
              "maxLength": 20,
              "type": "string"
            },
            "fax": {
              "maxLength": 20,
              "type": "string"
            },
            "website": {
              "maxLength": 50,
              "type": "string"
            },
            "adresse": {
              "maxLength": 50,
              "type": "string"
            },
            "stadt": {
              "maxLength": 50,
              "type": "string"
            },
            "email": {
              "maxLength": 50,
              "type": "string"
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": "string"
            },
            "zusatz1": {
              "maxLength": 200,
              "type": "string"
            },
            "zusatz2": {
              "maxLength": 200,
              "type": "string"
            },
            "zusatz3": {
              "maxLength": 200,
              "type": "string"
            },
            "plz": {
              "maxLength": 25,
              "type": "string"
            },
            "showPdfExport": {
              "type": "boolean"
            },
            "laboratoryGroupBarcodeSettings": {
              "type": "object"
            },
            "advancedAlphanumericBarcodeSettings": {
              "type": "object"
            }
          },
          "laboratoryGroupFilter": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "maxLength": 32,
              "type": "string"
            },
            "color": {
              "maxLength": 8,
              "type": [
                "string",
                "null"
              ]
            },
            "group": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            }
          },
          "values": {
            "type": "array"
          },
          "subreports": {
            "type": "array"
          },
          "attachments": {
            "type": "array"
          },
          "attachment_count": {
            "type": "integer"
          },
          "comments": {
            "type": "array"
          },
          "comment_count": {
            "type": "integer"
          },
          "arzname": {
            "maxLength": 300,
            "type": "string"
          },
          "labname": {
            "type": "string"
          },
          "accountingType": {
            "type": "string"
          },
          "laborgruppe": {
            "type": "string"
          },
          "exttnr": {
            "type": "string"
          },
          "lebensnummer": {
            "type": "string"
          },
          "zimmer": {
            "type": "string"
          },
          "lastupdate": {
            "type": "string"
          },
          "orderComment": {
            "type": "string"
          },
          "overwritecolor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "pdfSrc": {
            "type": "string"
          },
          "material": {
            "type": "string"
          },
          "caseNumber": {
            "type": "string"
          },
          "insuranceNumber": {
            "type": "string"
          },
          "showBdw": {
            "type": "boolean"
          },
          "showCumulative": {
            "type": "boolean"
          },
          "showPdf": {
            "type": "boolean"
          },
          "showOriginal": {
            "type": "boolean"
          },
          "showOriginalExclusive": {
            "type": "boolean"
          },
          "showCumulativePdf": {
            "type": "boolean"
          },
          "showCumulativeAllPdf": {
            "type": "boolean"
          },
          "showSend": {
            "type": "boolean"
          },
          "showRerequest": {
            "type": "boolean"
          },
          "originalPdfIds": {
            "type": "array"
          },
          "guid": {
            "type": "string"
          },
          "externalGuid": {
            "type": "string"
          },
          "manualCode": {
            "type": "string"
          },
          "uuid": {
            "type": "string"
          },
          "settlementType": {}
        },
        "pool": {
          "id": {
            "type": "integer"
          },
          "tnr": {
            "type": "string"
          },
          "displayName": {
            "type": "string"
          },
          "status": {
            "type": "array"
          },
          "doctor": {
            "aid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": [
                "integer",
                "null"
              ]
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "externalId": {
              "maxLength": 60,
              "type": [
                "string",
                "null"
              ]
            },
            "externalId2": {
              "type": "string"
            },
            "lanr": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "lastName": {
              "maxLength": 250,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "maxLength": 25,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "houseNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "countryCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "phoneNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "faxNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "smsNumber": {
              "maxLength": 20,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "welcomeLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "logoutLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "hierarchyStatus": {
              "type": "object"
            },
            "hierarchyAssignments": {
              "type": "array"
            },
            "hierarchyTree": {
              "type": "array"
            },
            "canActivatePatients": {
              "type": "boolean"
            },
            "isSuperDoctor": {
              "type": "boolean"
            },
            "disableViewDiagnosis": {
              "type": "boolean"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "emailNotificationsCharacteristics": {
              "type": "string"
            },
            "emailNotificationsStatus": {
              "type": "string"
            },
            "emailReports": {
              "type": "boolean"
            },
            "officeName": {
              "maxLength": 200,
              "type": "string"
            },
            "bsnr": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "localisation": {
              "type": "object"
            },
            "laboratoryLocalisation": {
              "type": "object"
            },
            "manufacturer": {
              "type": "string"
            },
            "urgent": {
              "type": "boolean"
            },
            "emergency": {
              "type": "boolean"
            },
            "infectious": {
              "type": "boolean"
            },
            "oaanswertrigger": {
              "type": "string"
            },
            "oaSoftDelete": {
              "type": "boolean"
            },
            "printMode": {
              "type": "integer"
            },
            "barcodePrintSettings": {
              "type": "integer"
            },
            "printername": {
              "maxLength": 200,
              "type": "string"
            },
            "scheinPrintSettings": {
              "type": "integer"
            },
            "printernameSchein": {
              "maxLength": 200,
              "type": "string"
            },
            "socketPrint": {
              "type": "boolean"
            },
            "internalPrintUser": {
              "type": "boolean"
            },
            "customDoctorHeading": {
              "type": "boolean"
            },
            "customDoctorHeadingLine1": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine2": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine3": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine4": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine5": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine6": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine7": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine8": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine9": {
              "maxLength": 50,
              "type": "string"
            },
            "graphicalRequestChooser": {
              "type": "boolean"
            },
            "ipConstraint": {
              "type": "boolean"
            },
            "autoprint": {
              "type": "boolean"
            },
            "speciality": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "reportsLanguageSetting": {
              "type": "object"
            },
            "reportCount": {
              "type": "integer"
            },
            "forms": {
              "type": "array"
            },
            "profiles": {
              "type": "array"
            },
            "globalProfiles": {
              "type": "array"
            },
            "groups": {
              "type": "array"
            },
            "groupForms": {
              "type": "array"
            },
            "devices": {
              "type": "array"
            },
            "doctorGroupOffices": {
              "type": "array"
            },
            "doctorGroupOfficeName": {
              "type": "string"
            },
            "pid": {
              "type": "number"
            },
            "editKisMode": {
              "type": "boolean"
            },
            "laboratory": {
              "type": "object"
            },
            "barcodeMode": {
              "type": "object"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "useOwnNummernkreis": {
              "type": "boolean"
            },
            "useLgNummernkreis": {
              "type": "boolean"
            },
            "exportMe": {
              "type": "boolean"
            },
            "exportMeReceiver": {
              "type": [
                "string",
                "null"
              ]
            },
            "exportMeStatusString": {
              "type": "string"
            },
            "exportMeCharacteristicString": {
              "type": "string"
            },
            "exportMeSettingsStatusFlags": {
              "type": "object"
            },
            "exportMeSettingsCharacteristicFlags": {
              "type": "object"
            },
            "pinnedRequirements": {
              "type": "array"
            }
          },
          "orders": {
            "type": "array"
          },
          "logs": {
            "type": "array"
          },
          "created_at": {
            "type": "string"
          },
          "updated_at": {
            "type": "string"
          },
          "materialsString": {
            "type": "string"
          },
          "requirementsString": {
            "type": "string"
          },
          "costUnitsString": {
            "type": "string"
          },
          "journalString": {
            "type": "string"
          },
          "orderFormNamesString": {
            "type": "string"
          },
          "meta_strings": {
            "materials": {
              "type": "string"
            },
            "requirements": {
              "type": "string"
            },
            "journal": {
              "type": "string"
            },
            "costUnits": {
              "type": "string"
            },
            "orderFormNames": {
              "type": "string"
            }
          },
          "isSoftDeleted": {
            "type": "boolean"
          }
        },
        "samples": {
          "type": "array"
        },
        "workstation": {
          "id": {
            "type": "integer"
          },
          "aid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "location": {
            "type": "string"
          },
          "default": {
            "type": "boolean"
          },
          "status": {},
          "clientVersion": {
            "type": "string"
          },
          "printers": {
            "type": "array"
          }
        },
        "manufacturer": {
          "type": "string"
        },
        "initialWorkplaceId": {
          "type": "number"
        },
        "lastWorkplaceId": {
          "type": "number"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "splitId": {
          "type": "integer"
        },
        "quickScanPdfPassword": {
          "type": [
            "string",
            "null"
          ]
        },
        "hasAttachment": {
          "type": "boolean"
        },
        "case_id": {
          "type": "integer"
        },
        "caseNumber": {
          "type": "string"
        },
        "realReRequestConditions": {
          "allowed": {
            "type": "boolean"
          },
          "formIds": {
            "type": "array"
          },
          "requirementIds": {
            "type": "array"
          },
          "profileIds": {
            "type": "array"
          }
        },
        "exportFlag": {
          "type": "boolean"
        },
        "statusExport": {
          "type": "integer"
        },
        "statusStornoExport": {
          "type": "integer"
        },
        "comment": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isCancelable": {
          "type": "boolean"
        },
        "actuallyPrintedMaterials": {
          "type": "string"
        },
        "hasReportCopyInfo": {
          "type": "boolean"
        }
      }
    }
  },
  "cancelled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "invoiceTo": {
    "type": [
      "string",
      "null"
    ]
  },
  "externalMaterialId": {
    "type": "string"
  },
  "missingAggregation": {
    "type": "boolean"
  }
};

export const AggregatedRequirementPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "submitter": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "externalName": {
    "type": "string"
  },
  "entityKey": {
    "type": "string"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "alias": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "price": {
    "type": "string"
  },
  "rank": {
    "type": "number"
  },
  "ruleInfoText": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "freeText": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextDate": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextAllowed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextMandatory": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextAsDate": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "analyses": {
    "type": "array"
  },
  "materials": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "diagnoses": {
    "type": "array"
  },
  "localizations": {
    "type": "array"
  },
  "requirementCollections": {
    "type": "array"
  },
  "alternateGroupFormCostUnits": {
    "type": "array"
  },
  "alternateMicrobiologicalFormCostUnits": {
    "type": "array"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "materialLimitOverrule": {
    "type": [
      "integer",
      "null"
    ]
  },
  "superRequirement": {
    "type": "boolean"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "duplicateRequirements": {
    "type": "array"
  },
  "lid": {
    "type": "integer"
  },
  "occurrence": {
    "type": "integer"
  },
  "profiles": {
    "type": "array"
  },
  "categories": {
    "type": "array"
  },
  "indications": {
    "type": "array"
  },
  "name": {
    "type": "array"
  },
  "forms": {
    "type": "array"
  },
  "costUnits": {
    "type": "array"
  },
  "pinnedByDoctors": {
    "type": "array"
  },
  "topFromDoctors": {
    "type": "array"
  },
  "blacklist": {
    "type": "array"
  },
  "whitelist": {
    "type": "array"
  },
  "blocked": {
    "type": "boolean"
  },
  "order": {
    "type": "integer"
  }
};

export const OrderWizardRequirementSchema = {
  "id": {
    "type": "integer"
  },
  "entityId": {
    "type": "integer"
  },
  "entityType": {},
  "submitter": {
    "type": "string"
  },
  "longName": {
    "type": "string"
  },
  "shortName": {
    "type": "string"
  },
  "examinationDuration": {
    "type": "integer"
  },
  "examinationDurationInterval": {},
  "externalName": {
    "type": "string"
  },
  "entityKey": {
    "type": "string"
  },
  "hint": {
    "type": [
      "string",
      "null"
    ]
  },
  "alias": {
    "type": "string"
  },
  "isPrivate": {
    "type": "boolean"
  },
  "isCharged": {
    "type": "boolean"
  },
  "price": {
    "type": "string"
  },
  "rank": {
    "type": "number"
  },
  "ruleInfoText": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  },
  "freeText": {
    "maxLength": 100,
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextDate": {
    "type": [
      "string",
      "null"
    ]
  },
  "freeTextAllowed": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextMandatory": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "freeTextAsDate": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "analyses": {
    "type": "array"
  },
  "materials": {
    "type": "array"
  },
  "flags": {
    "type": "array"
  },
  "diagnoses": {
    "type": "array"
  },
  "localizations": {
    "type": "array"
  },
  "requirementCollections": {
    "type": "array"
  },
  "alternateGroupFormCostUnits": {
    "type": "array"
  },
  "alternateMicrobiologicalFormCostUnits": {
    "type": "array"
  },
  "limit": {
    "type": [
      "integer",
      "null"
    ]
  },
  "materialLimitOverrule": {
    "type": [
      "integer",
      "null"
    ]
  },
  "superRequirement": {
    "type": "boolean"
  },
  "infoUrl": {
    "type": [
      "string",
      "null"
    ]
  },
  "additionalText": {
    "type": [
      "string",
      "null"
    ]
  },
  "duplicateRequirements": {
    "type": "array"
  },
  "profileId": {
    "type": "integer"
  },
  "profileName": {
    "type": "string"
  },
  "formLevelPrices": {
    "type": "array"
  },
  "formId": {
    "type": "integer"
  },
  "form": {
    "id": {
      "type": "integer"
    },
    "aid": {
      "type": "array"
    },
    "lid": {
      "type": "integer"
    },
    "name": {
      "maxLength": 20,
      "type": "string"
    },
    "costUnit": {
      "maxLength": 20,
      "type": "string"
    },
    "invoiceToChangeable": {
      "type": "boolean"
    },
    "defaultInvoiceTo": {},
    "isPrivate": {
      "type": "boolean"
    },
    "isDynamicMaterial": {
      "type": "boolean"
    },
    "isPoct": {
      "type": "boolean"
    },
    "isDefault": {
      "type": "boolean"
    },
    "isFilterHidden": {
      "type": "boolean"
    },
    "formNote": {
      "maxLength": 150,
      "type": "string"
    },
    "isGraphic": {
      "type": "boolean"
    },
    "barcodeComment": {
      "maxLength": 15,
      "type": "string"
    },
    "exportInfo": {
      "maxLength": 150,
      "type": "string"
    },
    "isBak": {
      "type": "boolean"
    },
    "satzart": {
      "maxLength": 4,
      "type": "string"
    },
    "directscheindruckmode": {
      "type": "integer"
    },
    "lastChanged": {
      "maxLength": 14,
      "type": "string"
    },
    "isPool": {
      "type": "boolean"
    },
    "maximumRequestCount": {
      "type": [
        "integer",
        "null"
      ]
    },
    "datamatrix": {
      "maxLength": 20,
      "type": "string"
    },
    "splitMode": {},
    "basePrice": {
      "type": "string"
    },
    "hasDynProbeType": {
      "type": "boolean"
    },
    "isDeleted": {
      "type": "boolean"
    },
    "priceFactor": {
      "type": "string"
    },
    "formGroupID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "bcPrefix": {
      "maxLength": 10,
      "type": "string"
    },
    "exportAsFile": {
      "maxLength": 10,
      "type": "string"
    },
    "digitalSignatureMode": {},
    "exportType": {
      "type": "integer"
    },
    "laboratoryGroupShortCode": {
      "maxLength": 50,
      "type": "string"
    },
    "zplID": {
      "type": [
        "integer",
        "null"
      ]
    },
    "formType": {},
    "laboratoryGroup": {
      "grpid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "kurz": {
        "maxLength": 20,
        "pattern": "^(?![Ll][Aa]$).*$",
        "type": "string"
      },
      "name": {
        "maxLength": 200,
        "type": "string"
      },
      "telefon": {
        "maxLength": 20,
        "type": "string"
      },
      "fax": {
        "maxLength": 20,
        "type": "string"
      },
      "website": {
        "maxLength": 50,
        "type": "string"
      },
      "adresse": {
        "maxLength": 50,
        "type": "string"
      },
      "stadt": {
        "maxLength": 50,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": "string"
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": "string"
      },
      "zusatz1": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz2": {
        "maxLength": 200,
        "type": "string"
      },
      "zusatz3": {
        "maxLength": 200,
        "type": "string"
      },
      "plz": {
        "maxLength": 25,
        "type": "string"
      },
      "showPdfExport": {
        "type": "boolean"
      },
      "laboratoryGroupBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPrefix": {
          "maxLength": 10,
          "type": "string"
        },
        "printPosition": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMin": {
          "maxLength": 40,
          "type": "string"
        },
        "printRangeMax": {
          "maxLength": 40,
          "type": "string"
        }
      },
      "advancedAlphanumericBarcodeSettings": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "grpid": {
          "type": "integer"
        },
        "group": {
          "maxLength": 20,
          "type": "string"
        },
        "printPosition1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin1": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax1": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin2": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax2": {
          "maxLength": 3,
          "type": "string"
        },
        "printPosition3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMin3": {
          "maxLength": 3,
          "type": "string"
        },
        "printRangeMax3": {
          "maxLength": 3,
          "type": "string"
        },
        "useState": {}
      }
    },
    "requirements": {
      "type": "array"
    }
  },
  "selectedAnalyses": {
    "type": "array"
  },
  "leftRight": {},
  "intraoperative": {
    "type": "boolean"
  },
  "origin": {
    "type": "string"
  },
  "selectedLocalizations": {
    "type": "array"
  },
  "dynamicMaterials": {
    "type": "array"
  },
  "reRequested": {
    "id": {
      "type": "integer"
    },
    "orderId": {
      "type": "integer"
    },
    "formId": {
      "type": "integer"
    },
    "requirementId": {
      "type": "integer"
    },
    "formName": {
      "type": "string"
    },
    "shortName": {
      "type": "string"
    },
    "externalName": {
      "maxLength": 100,
      "type": "string"
    },
    "longName": {
      "type": "string"
    },
    "insurance": {
      "type": "string"
    },
    "isPrivate": {
      "type": "boolean"
    },
    "price": {
      "type": [
        "string",
        "null"
      ]
    },
    "freeText": {
      "type": "string"
    },
    "freeTextAllowed": {
      "type": "boolean"
    },
    "freeTextMandatory": {
      "type": "boolean"
    },
    "state": {},
    "data": {
      "type": "string"
    },
    "comment": {
      "type": "string"
    },
    "historyId": {
      "type": "integer"
    },
    "accept": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "order": {
      "lid": {
        "type": "integer"
      },
      "isPool": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isSoftDeleted": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "freeText": {
        "maxLength": 60,
        "type": "string"
      },
      "message": {
        "maxLength": 100,
        "type": "string"
      },
      "infectious": {
        "type": "boolean"
      },
      "urgent": {
        "type": "string"
      },
      "pregnancy": {
        "type": "boolean"
      },
      "checkup": {
        "type": "boolean"
      },
      "costUnit": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "controlCheckupKnownInfection": {
        "type": "boolean"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "dz16b": {
        "type": "boolean"
      },
      "accident": {
        "type": "boolean"
      },
      "accidentTime": {
        "maxLength": 20,
        "type": "string"
      },
      "documentReferenceNumber": {
        "maxLength": 20,
        "type": "string"
      },
      "anamnesticInfo": {
        "maxLength": 60,
        "type": "string"
      },
      "printedMaterials": {
        "type": [
          "string",
          "null"
        ]
      },
      "printQuickReportBarcode": {
        "type": "boolean"
      },
      "urgentNotificationPhone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationFax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationMail": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "id": {
        "type": "integer"
      },
      "status": {},
      "executionStatus": {},
      "poolId": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "type": "string"
      },
      "bsnr": {
        "type": "string"
      },
      "selectedDoctor": {
        "type": "string"
      },
      "apid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "patient": {
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 50,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 150,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "costUnit": {
          "type": "string"
        },
        "medication": {
          "maxLength": 100,
          "type": "string"
        },
        "cycleDay": {
          "maxLength": 10,
          "type": "string"
        },
        "weight": {
          "maxLength": 5,
          "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 150,
          "type": "string"
        },
        "exttnr": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "primaryCarePhysicianLanr": {
          "maxLength": 20,
          "type": "string"
        },
        "primaryCarePhysicianBsnr": {
          "maxLength": 20,
          "type": "string"
        },
        "diseaseManagementProgram": {
          "maxLength": 50,
          "type": "string"
        },
        "dmpSpecialGroup": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceStart": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceEnd": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceType": {
          "maxLength": 50,
          "type": "string"
        },
        "exceptionalIndication": {
          "maxLength": 20,
          "type": "string"
        },
        "sktIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "wopIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "accountingArea": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "hvcode": {
          "maxLength": 20,
          "type": "string"
        },
        "vkat": {
          "maxLength": 20,
          "type": "string"
        },
        "secondName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "canton": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "ahvNr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "aisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "insurance": {
          "type": [
            "number",
            "null"
          ]
        },
        "bag": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "cardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "globalLocationNumber": {
          "maxLength": 13,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "hospitalBedNumber": {
          "type": "string"
        },
        "hospitalRoomNumber": {
          "type": "string"
        },
        "hospitalProfession": {
          "type": [
            "string",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "invoiceTo": {
          "maxLength": 25,
          "type": "string"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "autInsuranceTitle": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceFirstName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceLastName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceInsuranceNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceBirthday": {
          "type": "string"
        },
        "autInsuranceAddress": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceHouseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCountry": {
          "maxLength": 10,
          "type": "string"
        },
        "autInsuranceZip": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCity": {
          "maxLength": 50,
          "type": "string"
        },
        "pid": {
          "type": "integer"
        },
        "apgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "displayName": {
          "type": "string"
        },
        "prefix": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 5,
          "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "pregnancyWeek": {
          "maxLength": 2,
          "pattern": "^(\\d{1,2})?$",
          "type": "string"
        },
        "pregnancyWeekDay": {
          "maxLength": 1,
          "pattern": "^(\\d{1})?$",
          "type": "string"
        },
        "cycleLength": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        },
        "dayOfCycle": {
          "maxLength": 2,
          "type": "string"
        },
        "fetalCrownRumpLength": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "fetalNuchalTranslucency": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "hasInsuranceNumber": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "sampleAmount": {
          "maxLength": 10,
          "type": "string"
        },
        "samplePeriod": {
          "maxLength": 10,
          "type": "string"
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "passportNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "idcardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "orderReason": {
          "maxLength": 1,
          "type": "string"
        },
        "costUnitIdentification": {
          "maxLength": 25,
          "type": "string"
        },
        "autInsuranceEmployer": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceEmployerCity": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceAddressAdditional": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "requirements": {
        "type": "array"
      },
      "anamnesis": {
        "type": "array"
      },
      "animal": {
        "name": {
          "maxLength": 50,
          "type": "string"
        },
        "birthday": {
          "maxLength": 8,
          "type": "string"
        },
        "species": {
          "maxLength": 2,
          "type": "string"
        },
        "speciesManual": {
          "maxLength": 50,
          "type": "string"
        },
        "chipId": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 3,
          "type": "string"
        },
        "weight": {
          "maxLength": 3,
          "type": "string"
        },
        "sex": {
          "type": "number"
        },
        "breed": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerName": {
          "maxLength": 100,
          "type": "string"
        },
        "ownerAmendment": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerStreet": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerHouseNumber": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerZipCode": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerCity": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "switzerland": {
        "billToOther1": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther2": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther3": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyTo": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToEmail": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToSalutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToFirstName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToLastName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToStreet": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToZip": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToCity": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "notes": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "accidentDate": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "hungary": {
        "motherLastName": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "motherFirstName": {
          "maxLength": 15,
          "type": [
            "string",
            "null"
          ]
        },
        "treatmentReason": {
          "maxLength": 1,
          "type": [
            "string",
            "null"
          ]
        },
        "invoice": {
          "maxLength": 2,
          "type": [
            "string",
            "null"
          ]
        },
        "icd1": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd2": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd3": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "insuranceNumber": {
          "maxLength": 18,
          "type": [
            "string",
            "null"
          ]
        },
        "citizen": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "executed_at": {
        "type": "string"
      },
      "scheduled_at": {
        "type": [
          "string",
          "null"
        ]
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "covid": {
        "testNumber": {},
        "testReason": {},
        "agreed": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "riskCharacteristicsLocation": {},
        "riskCharacteristicsLocationType": {},
        "legalReason": {},
        "healthServiceZipCode": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "healthService": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "legalOrderBasis": {},
        "kvSpecial": {
          "type": [
            "string",
            "null"
          ]
        },
        "confirmationDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "variantDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "oegdInvoiceTo": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "covidWarnAppBarcode": {
          "type": [
            "boolean",
            "null"
          ]
        }
      },
      "mustValidate": {
        "type": "boolean"
      },
      "hospitalStation": {
        "type": "string"
      },
      "requirementDiagnosisFreeText": {
        "maxLength": 150,
        "type": "string"
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "selectedDiagnoses": {
        "type": "object"
      },
      "selectedDiagnosesChecked": {
        "type": "boolean"
      },
      "workstationChecked": {
        "type": "boolean"
      },
      "splitProperties": {
        "type": "array"
      },
      "sample_in_lab": {
        "type": "boolean"
      },
      "clientname": {
        "type": "string"
      },
      "printerAliasName": {
        "type": "string"
      },
      "overridePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "overrideBarcodePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "beforeAfterDialysis": {},
      "reportCopyAid": {
        "type": [
          "number",
          "null"
        ]
      },
      "reportCopyDoctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "aisIdentifier": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "patientAisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "bloodCollectionType": {},
      "transported_at": {
        "type": "string"
      },
      "created_at": {
        "type": "string"
      },
      "digitalSignature": {},
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "report": {
        "bid": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "parent_bid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "status": {},
        "tnr": {
          "type": "string"
        },
        "pathological": {},
        "mibi": {},
        "created_at": {
          "type": "string"
        },
        "imported_at": {
          "type": "string"
        },
        "registered_at": {
          "type": "string"
        },
        "validated_at": {
          "type": "string"
        },
        "sampled_at": {
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctor": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctorAddition": {
          "maxLength": 100,
          "type": "string"
        },
        "patientName": {
          "type": "string"
        },
        "is_locked": {
          "type": "boolean"
        },
        "is_unread": {
          "type": "boolean"
        },
        "is_pinned": {
          "type": "boolean"
        },
        "has_base64": {
          "type": "boolean"
        },
        "has_elpho": {
          "type": "boolean"
        },
        "has_subreports": {
          "type": "boolean"
        },
        "has_history": {
          "type": "boolean"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "patient": {
          "pid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 200,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 100,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 50,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "phone": {
            "maxLength": 50,
            "type": "string"
          },
          "costUnit": {
            "type": "string"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "sonstiges1": {
            "maxLength": 50,
            "type": "string"
          },
          "sonstiges2": {
            "maxLength": 50,
            "type": "string"
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "smsNotificationEnabled": {
            "type": "boolean"
          },
          "smsNumber": {
            "type": "string"
          },
          "canViewPartialResults": {
            "type": "boolean"
          },
          "canViewPreliminaryResults": {
            "type": "boolean"
          },
          "resultLanguage": {
            "maxLength": 5,
            "type": "string"
          },
          "oa": {
            "type": "boolean"
          },
          "aids": {
            "type": "array"
          },
          "latest_report_date": {
            "type": "string"
          },
          "dateScore": {
            "type": "number"
          },
          "passportNumber": {
            "type": "string"
          },
          "idCardNumber": {
            "type": "string"
          }
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "laboratoryGroup": {
          "grpid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "kurz": {
            "maxLength": 20,
            "pattern": "^(?![Ll][Aa]$).*$",
            "type": "string"
          },
          "name": {
            "maxLength": 200,
            "type": "string"
          },
          "telefon": {
            "maxLength": 20,
            "type": "string"
          },
          "fax": {
            "maxLength": 20,
            "type": "string"
          },
          "website": {
            "maxLength": 50,
            "type": "string"
          },
          "adresse": {
            "maxLength": 50,
            "type": "string"
          },
          "stadt": {
            "maxLength": 50,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": "string"
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": "string"
          },
          "zusatz1": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz2": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz3": {
            "maxLength": 200,
            "type": "string"
          },
          "plz": {
            "maxLength": 25,
            "type": "string"
          },
          "showPdfExport": {
            "type": "boolean"
          },
          "laboratoryGroupBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPrefix": {
              "maxLength": 10,
              "type": "string"
            },
            "printPosition": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMin": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMax": {
              "maxLength": 40,
              "type": "string"
            }
          },
          "advancedAlphanumericBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPosition1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax1": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax2": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax3": {
              "maxLength": 3,
              "type": "string"
            },
            "useState": {
              "type": "object"
            }
          }
        },
        "laboratoryGroupFilter": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "maxLength": 32,
            "type": "string"
          },
          "color": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          },
          "group": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "values": {
          "type": "array"
        },
        "subreports": {
          "type": "array"
        },
        "attachments": {
          "type": "array"
        },
        "attachment_count": {
          "type": "integer"
        },
        "comments": {
          "type": "array"
        },
        "comment_count": {
          "type": "integer"
        },
        "arzname": {
          "maxLength": 300,
          "type": "string"
        },
        "labname": {
          "type": "string"
        },
        "accountingType": {
          "type": "string"
        },
        "laborgruppe": {
          "type": "string"
        },
        "exttnr": {
          "type": "string"
        },
        "lebensnummer": {
          "type": "string"
        },
        "zimmer": {
          "type": "string"
        },
        "lastupdate": {
          "type": "string"
        },
        "orderComment": {
          "type": "string"
        },
        "overwritecolor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "pdfSrc": {
          "type": "string"
        },
        "material": {
          "type": "string"
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceNumber": {
          "type": "string"
        },
        "showBdw": {
          "type": "boolean"
        },
        "showCumulative": {
          "type": "boolean"
        },
        "showPdf": {
          "type": "boolean"
        },
        "showOriginal": {
          "type": "boolean"
        },
        "showOriginalExclusive": {
          "type": "boolean"
        },
        "showCumulativePdf": {
          "type": "boolean"
        },
        "showCumulativeAllPdf": {
          "type": "boolean"
        },
        "showSend": {
          "type": "boolean"
        },
        "showRerequest": {
          "type": "boolean"
        },
        "originalPdfIds": {
          "type": "array"
        },
        "guid": {
          "type": "string"
        },
        "externalGuid": {
          "type": "string"
        },
        "manualCode": {
          "type": "string"
        },
        "uuid": {
          "type": "string"
        },
        "settlementType": {}
      },
      "pool": {
        "id": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "displayName": {
          "type": "string"
        },
        "status": {
          "type": "array"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "orders": {
          "type": "array"
        },
        "logs": {
          "type": "array"
        },
        "created_at": {
          "type": "string"
        },
        "updated_at": {
          "type": "string"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isSoftDeleted": {
          "type": "boolean"
        }
      },
      "samples": {
        "type": "array"
      },
      "workstation": {
        "id": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "location": {
          "type": "string"
        },
        "default": {
          "type": "boolean"
        },
        "status": {},
        "clientVersion": {
          "type": "string"
        },
        "printers": {
          "type": "array"
        }
      },
      "manufacturer": {
        "type": "string"
      },
      "initialWorkplaceId": {
        "type": "number"
      },
      "lastWorkplaceId": {
        "type": "number"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "splitId": {
        "type": "integer"
      },
      "quickScanPdfPassword": {
        "type": [
          "string",
          "null"
        ]
      },
      "hasAttachment": {
        "type": "boolean"
      },
      "case_id": {
        "type": "integer"
      },
      "caseNumber": {
        "type": "string"
      },
      "realReRequestConditions": {
        "allowed": {
          "type": "boolean"
        },
        "formIds": {
          "type": "array"
        },
        "requirementIds": {
          "type": "array"
        },
        "profileIds": {
          "type": "array"
        }
      },
      "exportFlag": {
        "type": "boolean"
      },
      "statusExport": {
        "type": "integer"
      },
      "statusStornoExport": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isCancelable": {
        "type": "boolean"
      },
      "actuallyPrintedMaterials": {
        "type": "string"
      },
      "hasReportCopyInfo": {
        "type": "boolean"
      }
    },
    "form": {
      "id": {
        "type": "integer"
      },
      "aid": {
        "type": "array"
      },
      "lid": {
        "type": "integer"
      },
      "name": {
        "maxLength": 20,
        "type": "string"
      },
      "costUnit": {
        "maxLength": 20,
        "type": "string"
      },
      "invoiceToChangeable": {
        "type": "boolean"
      },
      "defaultInvoiceTo": {},
      "isPrivate": {
        "type": "boolean"
      },
      "isDynamicMaterial": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isDefault": {
        "type": "boolean"
      },
      "isFilterHidden": {
        "type": "boolean"
      },
      "formNote": {
        "maxLength": 150,
        "type": "string"
      },
      "isGraphic": {
        "type": "boolean"
      },
      "barcodeComment": {
        "maxLength": 15,
        "type": "string"
      },
      "exportInfo": {
        "maxLength": 150,
        "type": "string"
      },
      "isBak": {
        "type": "boolean"
      },
      "satzart": {
        "maxLength": 4,
        "type": "string"
      },
      "directscheindruckmode": {
        "type": "integer"
      },
      "lastChanged": {
        "maxLength": 14,
        "type": "string"
      },
      "isPool": {
        "type": "boolean"
      },
      "maximumRequestCount": {
        "type": [
          "integer",
          "null"
        ]
      },
      "datamatrix": {
        "maxLength": 20,
        "type": "string"
      },
      "splitMode": {},
      "basePrice": {
        "type": "string"
      },
      "hasDynProbeType": {
        "type": "boolean"
      },
      "isDeleted": {
        "type": "boolean"
      },
      "priceFactor": {
        "type": "string"
      },
      "formGroupID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "bcPrefix": {
        "maxLength": 10,
        "type": "string"
      },
      "exportAsFile": {
        "maxLength": 10,
        "type": "string"
      },
      "digitalSignatureMode": {},
      "exportType": {
        "type": "integer"
      },
      "laboratoryGroupShortCode": {
        "maxLength": 50,
        "type": "string"
      },
      "zplID": {
        "type": [
          "integer",
          "null"
        ]
      },
      "formType": {},
      "laboratoryGroup": {
        "grpid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "kurz": {
          "maxLength": 20,
          "pattern": "^(?![Ll][Aa]$).*$",
          "type": "string"
        },
        "name": {
          "maxLength": 200,
          "type": "string"
        },
        "telefon": {
          "maxLength": 20,
          "type": "string"
        },
        "fax": {
          "maxLength": 20,
          "type": "string"
        },
        "website": {
          "maxLength": 50,
          "type": "string"
        },
        "adresse": {
          "maxLength": 50,
          "type": "string"
        },
        "stadt": {
          "maxLength": 50,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": "string"
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": "string"
        },
        "zusatz1": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz2": {
          "maxLength": 200,
          "type": "string"
        },
        "zusatz3": {
          "maxLength": 200,
          "type": "string"
        },
        "plz": {
          "maxLength": 25,
          "type": "string"
        },
        "showPdfExport": {
          "type": "boolean"
        },
        "laboratoryGroupBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPrefix": {
            "maxLength": 10,
            "type": "string"
          },
          "printPosition": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMin": {
            "maxLength": 40,
            "type": "string"
          },
          "printRangeMax": {
            "maxLength": 40,
            "type": "string"
          }
        },
        "advancedAlphanumericBarcodeSettings": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "grpid": {
            "type": "integer"
          },
          "group": {
            "maxLength": 20,
            "type": "string"
          },
          "printPosition1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin1": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax1": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin2": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax2": {
            "maxLength": 3,
            "type": "string"
          },
          "printPosition3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMin3": {
            "maxLength": 3,
            "type": "string"
          },
          "printRangeMax3": {
            "maxLength": 3,
            "type": "string"
          },
          "useState": {}
        }
      },
      "requirements": {
        "type": "array"
      }
    },
    "history": {
      "id": {
        "type": "integer"
      },
      "openedAt": {
        "type": "string"
      },
      "reRequestInfo": {
        "type": "string"
      },
      "requirementInfo": {
        "type": "string"
      },
      "closedAt": {
        "type": "string"
      },
      "appliedAt": {
        "type": "string"
      },
      "order": {
        "lid": {
          "type": "integer"
        },
        "isPool": {
          "type": "boolean"
        },
        "isPoct": {
          "type": "boolean"
        },
        "isSoftDeleted": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "freeText": {
          "maxLength": 60,
          "type": "string"
        },
        "message": {
          "maxLength": 100,
          "type": "string"
        },
        "infectious": {
          "type": "boolean"
        },
        "urgent": {
          "type": "string"
        },
        "pregnancy": {
          "type": "boolean"
        },
        "checkup": {
          "type": "boolean"
        },
        "costUnit": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "controlCheckupKnownInfection": {
          "type": "boolean"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "dz16b": {
          "type": "boolean"
        },
        "accident": {
          "type": "boolean"
        },
        "accidentTime": {
          "maxLength": 20,
          "type": "string"
        },
        "documentReferenceNumber": {
          "maxLength": 20,
          "type": "string"
        },
        "anamnesticInfo": {
          "maxLength": 60,
          "type": "string"
        },
        "printedMaterials": {
          "type": [
            "string",
            "null"
          ]
        },
        "printQuickReportBarcode": {
          "type": "boolean"
        },
        "urgentNotificationPhone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "urgentNotificationFax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "urgentNotificationMail": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "id": {
          "type": "integer"
        },
        "status": {},
        "executionStatus": {},
        "poolId": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "type": "string"
        },
        "bsnr": {
          "type": "string"
        },
        "selectedDoctor": {
          "type": "string"
        },
        "apid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "patient": {
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 50,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 150,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "costUnit": {
            "type": "string"
          },
          "medication": {
            "maxLength": 100,
            "type": "string"
          },
          "cycleDay": {
            "maxLength": 10,
            "type": "string"
          },
          "weight": {
            "maxLength": 5,
            "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
            "type": "string"
          },
          "diagnosis": {
            "maxLength": 150,
            "type": "string"
          },
          "exttnr": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "primaryCarePhysicianLanr": {
            "maxLength": 20,
            "type": "string"
          },
          "primaryCarePhysicianBsnr": {
            "maxLength": 20,
            "type": "string"
          },
          "diseaseManagementProgram": {
            "maxLength": 50,
            "type": "string"
          },
          "dmpSpecialGroup": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceStart": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceEnd": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceType": {
            "maxLength": 50,
            "type": "string"
          },
          "exceptionalIndication": {
            "maxLength": 20,
            "type": "string"
          },
          "sktIdentifier": {
            "maxLength": 50,
            "type": "string"
          },
          "wopIdentifier": {
            "maxLength": 50,
            "type": "string"
          },
          "accountingArea": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "hvcode": {
            "maxLength": 20,
            "type": "string"
          },
          "vkat": {
            "maxLength": 20,
            "type": "string"
          },
          "secondName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "canton": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "ahvNr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "aisNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "insurance": {
            "type": [
              "number",
              "null"
            ]
          },
          "bag": {
            "maxLength": 5,
            "type": [
              "string",
              "null"
            ]
          },
          "cardNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "globalLocationNumber": {
            "maxLength": 13,
            "type": [
              "string",
              "null"
            ]
          },
          "chTreatmentReason": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "externalOrderInfo": {
            "maxLength": 256,
            "type": [
              "string",
              "null"
            ]
          },
          "hospitalBedNumber": {
            "type": "string"
          },
          "hospitalRoomNumber": {
            "type": "string"
          },
          "hospitalProfession": {
            "type": [
              "string",
              "null"
            ]
          },
          "caseId": {
            "type": [
              "integer",
              "null"
            ]
          },
          "caseNumber": {
            "type": "string"
          },
          "insuranceName": {
            "maxLength": 250,
            "type": "string"
          },
          "invoiceTo": {
            "maxLength": 25,
            "type": "string"
          },
          "dz116b": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "autInsuranceTitle": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceFirstName": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceLastName": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceInsuranceNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceBirthday": {
            "type": "string"
          },
          "autInsuranceAddress": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceHouseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceCountry": {
            "maxLength": 10,
            "type": "string"
          },
          "autInsuranceZip": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceCity": {
            "maxLength": 50,
            "type": "string"
          },
          "pid": {
            "type": "integer"
          },
          "apgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "displayName": {
            "type": "string"
          },
          "prefix": {
            "maxLength": 50,
            "type": "string"
          },
          "height": {
            "maxLength": 5,
            "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
            "type": "string"
          },
          "pregnancyWeek": {
            "maxLength": 2,
            "pattern": "^(\\d{1,2})?$",
            "type": "string"
          },
          "pregnancyWeekDay": {
            "maxLength": 1,
            "pattern": "^(\\d{1})?$",
            "type": "string"
          },
          "cycleLength": {
            "maxLength": 3,
            "type": [
              "string",
              "null"
            ]
          },
          "dayOfCycle": {
            "maxLength": 2,
            "type": "string"
          },
          "fetalCrownRumpLength": {
            "maxLength": 7,
            "type": [
              "string",
              "null"
            ]
          },
          "fetalNuchalTranslucency": {
            "maxLength": 7,
            "type": [
              "string",
              "null"
            ]
          },
          "hasInsuranceNumber": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "sampleAmount": {
            "maxLength": 10,
            "type": "string"
          },
          "samplePeriod": {
            "maxLength": 10,
            "type": "string"
          },
          "sampleDate": {
            "type": "string"
          },
          "sampleTime": {
            "maxLength": 8,
            "type": "string"
          },
          "passportNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "idcardNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "orderReason": {
            "maxLength": 1,
            "type": "string"
          },
          "costUnitIdentification": {
            "maxLength": 25,
            "type": "string"
          },
          "autInsuranceEmployer": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceEmployerCity": {
            "maxLength": 50,
            "type": "string"
          },
          "autInsuranceAddressAdditional": {
            "maxLength": 50,
            "type": "string"
          }
        },
        "requirements": {
          "type": "array"
        },
        "anamnesis": {
          "type": "array"
        },
        "animal": {
          "name": {
            "maxLength": 50,
            "type": "string"
          },
          "birthday": {
            "maxLength": 8,
            "type": "string"
          },
          "species": {
            "maxLength": 2,
            "type": "string"
          },
          "speciesManual": {
            "maxLength": 50,
            "type": "string"
          },
          "chipId": {
            "maxLength": 50,
            "type": "string"
          },
          "height": {
            "maxLength": 3,
            "type": "string"
          },
          "weight": {
            "maxLength": 3,
            "type": "string"
          },
          "sex": {
            "type": "number"
          },
          "breed": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerName": {
            "maxLength": 100,
            "type": "string"
          },
          "ownerAmendment": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerStreet": {
            "maxLength": 50,
            "type": "string"
          },
          "ownerHouseNumber": {
            "maxLength": 10,
            "type": "string"
          },
          "ownerZipCode": {
            "maxLength": 10,
            "type": "string"
          },
          "ownerCity": {
            "maxLength": 50,
            "type": "string"
          }
        },
        "switzerland": {
          "billToOther1": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "billToOther2": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "billToOther3": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyTo": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToEmail": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToSalutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToFirstName": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToLastName": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToStreet": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToZip": {
            "maxLength": 5,
            "type": [
              "string",
              "null"
            ]
          },
          "reportCopyToCity": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "notes": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "chTreatmentReason": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "accidentDate": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "hungary": {
          "motherLastName": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "motherFirstName": {
            "maxLength": 15,
            "type": [
              "string",
              "null"
            ]
          },
          "treatmentReason": {
            "maxLength": 1,
            "type": [
              "string",
              "null"
            ]
          },
          "invoice": {
            "maxLength": 2,
            "type": [
              "string",
              "null"
            ]
          },
          "icd1": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "icd2": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "icd3": {
            "maxLength": 6,
            "type": [
              "string",
              "null"
            ]
          },
          "insuranceNumber": {
            "maxLength": 18,
            "type": [
              "string",
              "null"
            ]
          },
          "citizen": {
            "maxLength": 3,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "executed_at": {
          "type": "string"
        },
        "scheduled_at": {
          "type": [
            "string",
            "null"
          ]
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "covid": {
          "testNumber": {},
          "testReason": {},
          "agreed": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "riskCharacteristicsLocation": {},
          "riskCharacteristicsLocationType": {},
          "legalReason": {},
          "healthServiceZipCode": {
            "maxLength": 255,
            "type": [
              "string",
              "null"
            ]
          },
          "healthService": {
            "maxLength": 255,
            "type": [
              "string",
              "null"
            ]
          },
          "legalOrderBasis": {},
          "kvSpecial": {
            "type": [
              "string",
              "null"
            ]
          },
          "confirmationDiagnostics": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "variantDiagnostics": {
            "type": [
              "boolean",
              "null"
            ]
          },
          "oegdInvoiceTo": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "covidWarnAppBarcode": {
            "type": [
              "boolean",
              "null"
            ]
          }
        },
        "mustValidate": {
          "type": "boolean"
        },
        "hospitalStation": {
          "type": "string"
        },
        "requirementDiagnosisFreeText": {
          "maxLength": 150,
          "type": "string"
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "selectedDiagnoses": {
          "type": "object"
        },
        "selectedDiagnosesChecked": {
          "type": "boolean"
        },
        "workstationChecked": {
          "type": "boolean"
        },
        "splitProperties": {
          "type": "array"
        },
        "sample_in_lab": {
          "type": "boolean"
        },
        "clientname": {
          "type": "string"
        },
        "printerAliasName": {
          "type": "string"
        },
        "overridePrinterName": {
          "type": [
            "string",
            "null"
          ]
        },
        "overrideBarcodePrinterName": {
          "type": [
            "string",
            "null"
          ]
        },
        "beforeAfterDialysis": {},
        "reportCopyAid": {
          "type": [
            "number",
            "null"
          ]
        },
        "reportCopyDoctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "aisIdentifier": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "patientAisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "bloodCollectionType": {},
        "transported_at": {
          "type": "string"
        },
        "created_at": {
          "type": "string"
        },
        "digitalSignature": {},
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "report": {
          "bid": {
            "type": "integer"
          },
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "parent_bid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "caseId": {
            "type": [
              "integer",
              "null"
            ]
          },
          "status": {},
          "tnr": {
            "type": "string"
          },
          "pathological": {},
          "mibi": {},
          "created_at": {
            "type": "string"
          },
          "imported_at": {
            "type": "string"
          },
          "registered_at": {
            "type": "string"
          },
          "validated_at": {
            "type": "string"
          },
          "sampled_at": {
            "type": "string"
          },
          "diagnosis": {
            "maxLength": 100,
            "type": "string"
          },
          "validatingDoctor": {
            "maxLength": 100,
            "type": "string"
          },
          "validatingDoctorAddition": {
            "maxLength": 100,
            "type": "string"
          },
          "patientName": {
            "type": "string"
          },
          "is_locked": {
            "type": "boolean"
          },
          "is_unread": {
            "type": "boolean"
          },
          "is_pinned": {
            "type": "boolean"
          },
          "has_base64": {
            "type": "boolean"
          },
          "has_elpho": {
            "type": "boolean"
          },
          "has_subreports": {
            "type": "boolean"
          },
          "has_history": {
            "type": "boolean"
          },
          "doctor": {
            "aid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": [
                "integer",
                "null"
              ]
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "externalId": {
              "maxLength": 60,
              "type": [
                "string",
                "null"
              ]
            },
            "externalId2": {
              "type": "string"
            },
            "lanr": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "lastName": {
              "maxLength": 250,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "maxLength": 25,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "houseNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "countryCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "phoneNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "faxNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "smsNumber": {
              "maxLength": 20,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "welcomeLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "logoutLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "hierarchyStatus": {
              "type": "object"
            },
            "hierarchyAssignments": {
              "type": "array"
            },
            "hierarchyTree": {
              "type": "array"
            },
            "canActivatePatients": {
              "type": "boolean"
            },
            "isSuperDoctor": {
              "type": "boolean"
            },
            "disableViewDiagnosis": {
              "type": "boolean"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "emailNotificationsCharacteristics": {
              "type": "string"
            },
            "emailNotificationsStatus": {
              "type": "string"
            },
            "emailReports": {
              "type": "boolean"
            },
            "officeName": {
              "maxLength": 200,
              "type": "string"
            },
            "bsnr": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "localisation": {
              "type": "object"
            },
            "laboratoryLocalisation": {
              "type": "object"
            },
            "manufacturer": {
              "type": "string"
            },
            "urgent": {
              "type": "boolean"
            },
            "emergency": {
              "type": "boolean"
            },
            "infectious": {
              "type": "boolean"
            },
            "oaanswertrigger": {
              "type": "string"
            },
            "oaSoftDelete": {
              "type": "boolean"
            },
            "printMode": {
              "type": "integer"
            },
            "barcodePrintSettings": {
              "type": "integer"
            },
            "printername": {
              "maxLength": 200,
              "type": "string"
            },
            "scheinPrintSettings": {
              "type": "integer"
            },
            "printernameSchein": {
              "maxLength": 200,
              "type": "string"
            },
            "socketPrint": {
              "type": "boolean"
            },
            "internalPrintUser": {
              "type": "boolean"
            },
            "customDoctorHeading": {
              "type": "boolean"
            },
            "customDoctorHeadingLine1": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine2": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine3": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine4": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine5": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine6": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine7": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine8": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine9": {
              "maxLength": 50,
              "type": "string"
            },
            "graphicalRequestChooser": {
              "type": "boolean"
            },
            "ipConstraint": {
              "type": "boolean"
            },
            "autoprint": {
              "type": "boolean"
            },
            "speciality": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "reportsLanguageSetting": {
              "type": "object"
            },
            "reportCount": {
              "type": "integer"
            },
            "forms": {
              "type": "array"
            },
            "profiles": {
              "type": "array"
            },
            "globalProfiles": {
              "type": "array"
            },
            "groups": {
              "type": "array"
            },
            "groupForms": {
              "type": "array"
            },
            "devices": {
              "type": "array"
            },
            "doctorGroupOffices": {
              "type": "array"
            },
            "doctorGroupOfficeName": {
              "type": "string"
            },
            "pid": {
              "type": "number"
            },
            "editKisMode": {
              "type": "boolean"
            },
            "laboratory": {
              "type": "object"
            },
            "barcodeMode": {
              "type": "object"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "useOwnNummernkreis": {
              "type": "boolean"
            },
            "useLgNummernkreis": {
              "type": "boolean"
            },
            "exportMe": {
              "type": "boolean"
            },
            "exportMeReceiver": {
              "type": [
                "string",
                "null"
              ]
            },
            "exportMeStatusString": {
              "type": "string"
            },
            "exportMeCharacteristicString": {
              "type": "string"
            },
            "exportMeSettingsStatusFlags": {
              "type": "object"
            },
            "exportMeSettingsCharacteristicFlags": {
              "type": "object"
            },
            "pinnedRequirements": {
              "type": "array"
            }
          },
          "patient": {
            "pid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 200,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "user": {
              "type": "object"
            },
            "lastName": {
              "maxLength": 50,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": "string"
            },
            "suffix": {
              "maxLength": 100,
              "type": "string"
            },
            "title": {
              "maxLength": 50,
              "type": "string"
            },
            "gender": {
              "maxLength": 10,
              "type": "string"
            },
            "birthday": {
              "maxLength": 50,
              "type": "string"
            },
            "city": {
              "maxLength": 50,
              "type": "string"
            },
            "zipCode": {
              "maxLength": 50,
              "type": "string"
            },
            "address": {
              "maxLength": 50,
              "type": "string"
            },
            "houseNumber": {
              "maxLength": 50,
              "type": "string"
            },
            "addressAdditional": {
              "maxLength": 50,
              "type": "string"
            },
            "country": {
              "maxLength": 50,
              "type": "string"
            },
            "insuranceNumber": {
              "maxLength": 100,
              "type": "string"
            },
            "phone": {
              "maxLength": 50,
              "type": "string"
            },
            "costUnit": {
              "type": "string"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "sonstiges1": {
              "maxLength": 50,
              "type": "string"
            },
            "sonstiges2": {
              "maxLength": 50,
              "type": "string"
            },
            "patientIdentification": {
              "maxLength": 20,
              "type": "string"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "smsNotificationEnabled": {
              "type": "boolean"
            },
            "smsNumber": {
              "type": "string"
            },
            "canViewPartialResults": {
              "type": "boolean"
            },
            "canViewPreliminaryResults": {
              "type": "boolean"
            },
            "resultLanguage": {
              "maxLength": 5,
              "type": "string"
            },
            "oa": {
              "type": "boolean"
            },
            "aids": {
              "type": "array"
            },
            "latest_report_date": {
              "type": "string"
            },
            "dateScore": {
              "type": "number"
            },
            "passportNumber": {
              "type": "string"
            },
            "idCardNumber": {
              "type": "string"
            }
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "laboratoryGroup": {
            "grpid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "kurz": {
              "maxLength": 20,
              "pattern": "^(?![Ll][Aa]$).*$",
              "type": "string"
            },
            "name": {
              "maxLength": 200,
              "type": "string"
            },
            "telefon": {
              "maxLength": 20,
              "type": "string"
            },
            "fax": {
              "maxLength": 20,
              "type": "string"
            },
            "website": {
              "maxLength": 50,
              "type": "string"
            },
            "adresse": {
              "maxLength": 50,
              "type": "string"
            },
            "stadt": {
              "maxLength": 50,
              "type": "string"
            },
            "email": {
              "maxLength": 50,
              "type": "string"
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": "string"
            },
            "zusatz1": {
              "maxLength": 200,
              "type": "string"
            },
            "zusatz2": {
              "maxLength": 200,
              "type": "string"
            },
            "zusatz3": {
              "maxLength": 200,
              "type": "string"
            },
            "plz": {
              "maxLength": 25,
              "type": "string"
            },
            "showPdfExport": {
              "type": "boolean"
            },
            "laboratoryGroupBarcodeSettings": {
              "type": "object"
            },
            "advancedAlphanumericBarcodeSettings": {
              "type": "object"
            }
          },
          "laboratoryGroupFilter": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "maxLength": 32,
              "type": "string"
            },
            "color": {
              "maxLength": 8,
              "type": [
                "string",
                "null"
              ]
            },
            "group": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            }
          },
          "values": {
            "type": "array"
          },
          "subreports": {
            "type": "array"
          },
          "attachments": {
            "type": "array"
          },
          "attachment_count": {
            "type": "integer"
          },
          "comments": {
            "type": "array"
          },
          "comment_count": {
            "type": "integer"
          },
          "arzname": {
            "maxLength": 300,
            "type": "string"
          },
          "labname": {
            "type": "string"
          },
          "accountingType": {
            "type": "string"
          },
          "laborgruppe": {
            "type": "string"
          },
          "exttnr": {
            "type": "string"
          },
          "lebensnummer": {
            "type": "string"
          },
          "zimmer": {
            "type": "string"
          },
          "lastupdate": {
            "type": "string"
          },
          "orderComment": {
            "type": "string"
          },
          "overwritecolor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "pdfSrc": {
            "type": "string"
          },
          "material": {
            "type": "string"
          },
          "caseNumber": {
            "type": "string"
          },
          "insuranceNumber": {
            "type": "string"
          },
          "showBdw": {
            "type": "boolean"
          },
          "showCumulative": {
            "type": "boolean"
          },
          "showPdf": {
            "type": "boolean"
          },
          "showOriginal": {
            "type": "boolean"
          },
          "showOriginalExclusive": {
            "type": "boolean"
          },
          "showCumulativePdf": {
            "type": "boolean"
          },
          "showCumulativeAllPdf": {
            "type": "boolean"
          },
          "showSend": {
            "type": "boolean"
          },
          "showRerequest": {
            "type": "boolean"
          },
          "originalPdfIds": {
            "type": "array"
          },
          "guid": {
            "type": "string"
          },
          "externalGuid": {
            "type": "string"
          },
          "manualCode": {
            "type": "string"
          },
          "uuid": {
            "type": "string"
          },
          "settlementType": {}
        },
        "pool": {
          "id": {
            "type": "integer"
          },
          "tnr": {
            "type": "string"
          },
          "displayName": {
            "type": "string"
          },
          "status": {
            "type": "array"
          },
          "doctor": {
            "aid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "mtgid": {
              "type": [
                "integer",
                "null"
              ]
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "externalId": {
              "maxLength": 60,
              "type": [
                "string",
                "null"
              ]
            },
            "externalId2": {
              "type": "string"
            },
            "lanr": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "lastName": {
              "maxLength": 250,
              "type": "string"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "maxLength": 25,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "houseNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "countryCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "phoneNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "faxNumber": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "smsNumber": {
              "maxLength": 20,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "welcomeLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "logoutLabel": {
              "maxLength": 200,
              "type": [
                "string",
                "null"
              ]
            },
            "hierarchyStatus": {
              "type": "object"
            },
            "hierarchyAssignments": {
              "type": "array"
            },
            "hierarchyTree": {
              "type": "array"
            },
            "canActivatePatients": {
              "type": "boolean"
            },
            "isSuperDoctor": {
              "type": "boolean"
            },
            "disableViewDiagnosis": {
              "type": "boolean"
            },
            "emailNotificationEnabled": {
              "type": "boolean"
            },
            "emailNotificationsCharacteristics": {
              "type": "string"
            },
            "emailNotificationsStatus": {
              "type": "string"
            },
            "emailReports": {
              "type": "boolean"
            },
            "officeName": {
              "maxLength": 200,
              "type": "string"
            },
            "bsnr": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "localisation": {
              "type": "object"
            },
            "laboratoryLocalisation": {
              "type": "object"
            },
            "manufacturer": {
              "type": "string"
            },
            "urgent": {
              "type": "boolean"
            },
            "emergency": {
              "type": "boolean"
            },
            "infectious": {
              "type": "boolean"
            },
            "oaanswertrigger": {
              "type": "string"
            },
            "oaSoftDelete": {
              "type": "boolean"
            },
            "printMode": {
              "type": "integer"
            },
            "barcodePrintSettings": {
              "type": "integer"
            },
            "printername": {
              "maxLength": 200,
              "type": "string"
            },
            "scheinPrintSettings": {
              "type": "integer"
            },
            "printernameSchein": {
              "maxLength": 200,
              "type": "string"
            },
            "socketPrint": {
              "type": "boolean"
            },
            "internalPrintUser": {
              "type": "boolean"
            },
            "customDoctorHeading": {
              "type": "boolean"
            },
            "customDoctorHeadingLine1": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine2": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine3": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine4": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine5": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine6": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine7": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine8": {
              "maxLength": 50,
              "type": "string"
            },
            "customDoctorHeadingLine9": {
              "maxLength": 50,
              "type": "string"
            },
            "graphicalRequestChooser": {
              "type": "boolean"
            },
            "ipConstraint": {
              "type": "boolean"
            },
            "autoprint": {
              "type": "boolean"
            },
            "speciality": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "reportsLanguageSetting": {
              "type": "object"
            },
            "reportCount": {
              "type": "integer"
            },
            "forms": {
              "type": "array"
            },
            "profiles": {
              "type": "array"
            },
            "globalProfiles": {
              "type": "array"
            },
            "groups": {
              "type": "array"
            },
            "groupForms": {
              "type": "array"
            },
            "devices": {
              "type": "array"
            },
            "doctorGroupOffices": {
              "type": "array"
            },
            "doctorGroupOfficeName": {
              "type": "string"
            },
            "pid": {
              "type": "number"
            },
            "editKisMode": {
              "type": "boolean"
            },
            "laboratory": {
              "type": "object"
            },
            "barcodeMode": {
              "type": "object"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "useOwnNummernkreis": {
              "type": "boolean"
            },
            "useLgNummernkreis": {
              "type": "boolean"
            },
            "exportMe": {
              "type": "boolean"
            },
            "exportMeReceiver": {
              "type": [
                "string",
                "null"
              ]
            },
            "exportMeStatusString": {
              "type": "string"
            },
            "exportMeCharacteristicString": {
              "type": "string"
            },
            "exportMeSettingsStatusFlags": {
              "type": "object"
            },
            "exportMeSettingsCharacteristicFlags": {
              "type": "object"
            },
            "pinnedRequirements": {
              "type": "array"
            }
          },
          "orders": {
            "type": "array"
          },
          "logs": {
            "type": "array"
          },
          "created_at": {
            "type": "string"
          },
          "updated_at": {
            "type": "string"
          },
          "materialsString": {
            "type": "string"
          },
          "requirementsString": {
            "type": "string"
          },
          "costUnitsString": {
            "type": "string"
          },
          "journalString": {
            "type": "string"
          },
          "orderFormNamesString": {
            "type": "string"
          },
          "meta_strings": {
            "materials": {
              "type": "string"
            },
            "requirements": {
              "type": "string"
            },
            "journal": {
              "type": "string"
            },
            "costUnits": {
              "type": "string"
            },
            "orderFormNames": {
              "type": "string"
            }
          },
          "isSoftDeleted": {
            "type": "boolean"
          }
        },
        "samples": {
          "type": "array"
        },
        "workstation": {
          "id": {
            "type": "integer"
          },
          "aid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "location": {
            "type": "string"
          },
          "default": {
            "type": "boolean"
          },
          "status": {},
          "clientVersion": {
            "type": "string"
          },
          "printers": {
            "type": "array"
          }
        },
        "manufacturer": {
          "type": "string"
        },
        "initialWorkplaceId": {
          "type": "number"
        },
        "lastWorkplaceId": {
          "type": "number"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "splitId": {
          "type": "integer"
        },
        "quickScanPdfPassword": {
          "type": [
            "string",
            "null"
          ]
        },
        "hasAttachment": {
          "type": "boolean"
        },
        "case_id": {
          "type": "integer"
        },
        "caseNumber": {
          "type": "string"
        },
        "realReRequestConditions": {
          "allowed": {
            "type": "boolean"
          },
          "formIds": {
            "type": "array"
          },
          "requirementIds": {
            "type": "array"
          },
          "profileIds": {
            "type": "array"
          }
        },
        "exportFlag": {
          "type": "boolean"
        },
        "statusExport": {
          "type": "integer"
        },
        "statusStornoExport": {
          "type": "integer"
        },
        "comment": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isCancelable": {
          "type": "boolean"
        },
        "actuallyPrintedMaterials": {
          "type": "string"
        },
        "hasReportCopyInfo": {
          "type": "boolean"
        }
      }
    }
  },
  "cancelled_at": {
    "type": [
      "string",
      "null"
    ]
  },
  "invoiceTo": {
    "type": [
      "string",
      "null"
    ]
  },
  "externalMaterialId": {
    "type": "string"
  },
  "missingAggregation": {
    "type": "boolean"
  },
  "filter": {
    "id": {
      "type": "integer"
    },
    "name": {
      "type": "string"
    },
    "count": {
      "type": "integer"
    },
    "type": {},
    "background": {
      "type": "string"
    },
    "doubleWidth": {
      "type": "boolean"
    }
  },
  "filters": {
    "type": "array"
  },
  "forms": {
    "type": "array"
  }
};

export const OrderPatientMetaPropertiesSchema = {
  "lastName": {
    "maxLength": 50,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "suffix": {
    "maxLength": 50,
    "type": "string"
  },
  "title": {
    "maxLength": 50,
    "type": "string"
  },
  "gender": {},
  "birthday": {
    "maxLength": 50,
    "type": "string"
  },
  "city": {
    "maxLength": 50,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 50,
    "type": "string"
  },
  "address": {
    "maxLength": 150,
    "type": "string"
  },
  "houseNumber": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "addressAdditional": {
    "maxLength": 50,
    "type": "string"
  },
  "country": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "email": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "phone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "costUnit": {
    "type": "string"
  },
  "medication": {
    "maxLength": 100,
    "type": "string"
  },
  "cycleDay": {
    "maxLength": 10,
    "type": "string"
  },
  "weight": {
    "maxLength": 5,
    "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
    "type": "string"
  },
  "diagnosis": {
    "maxLength": 150,
    "type": "string"
  },
  "exttnr": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "primaryCarePhysicianLanr": {
    "maxLength": 20,
    "type": "string"
  },
  "primaryCarePhysicianBsnr": {
    "maxLength": 20,
    "type": "string"
  },
  "diseaseManagementProgram": {
    "maxLength": 50,
    "type": "string"
  },
  "dmpSpecialGroup": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceStart": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceEnd": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceType": {
    "maxLength": 50,
    "type": "string"
  },
  "exceptionalIndication": {
    "maxLength": 20,
    "type": "string"
  },
  "sktIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "wopIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "accountingArea": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "hvcode": {
    "maxLength": 20,
    "type": "string"
  },
  "vkat": {
    "maxLength": 20,
    "type": "string"
  },
  "secondName": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "canton": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "ahvNr": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "aisNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "insurance": {
    "type": [
      "number",
      "null"
    ]
  },
  "bag": {
    "maxLength": 5,
    "type": [
      "string",
      "null"
    ]
  },
  "cardNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "patientIdentification": {
    "maxLength": 20,
    "type": "string"
  },
  "globalLocationNumber": {
    "maxLength": 13,
    "type": [
      "string",
      "null"
    ]
  },
  "chTreatmentReason": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "hospitalBedNumber": {
    "type": "string"
  },
  "hospitalRoomNumber": {
    "type": "string"
  },
  "hospitalProfession": {
    "type": [
      "string",
      "null"
    ]
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "caseNumber": {
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "invoiceTo": {
    "maxLength": 25,
    "type": "string"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "autInsuranceTitle": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceFirstName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceLastName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceInsuranceNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceBirthday": {
    "type": "string"
  },
  "autInsuranceAddress": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceHouseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCountry": {
    "maxLength": 10,
    "type": "string"
  },
  "autInsuranceZip": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCity": {
    "maxLength": 50,
    "type": "string"
  },
  "pid": {
    "type": "integer"
  },
  "apgid": {
    "type": [
      "integer",
      "null"
    ]
  },
  "displayName": {
    "type": "string"
  },
  "prefix": {
    "maxLength": 50,
    "type": "string"
  },
  "height": {
    "maxLength": 5,
    "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
    "type": "string"
  },
  "pregnancyWeek": {
    "maxLength": 2,
    "pattern": "^(\\d{1,2})?$",
    "type": "string"
  },
  "pregnancyWeekDay": {
    "maxLength": 1,
    "pattern": "^(\\d{1})?$",
    "type": "string"
  },
  "cycleLength": {
    "maxLength": 3,
    "type": [
      "string",
      "null"
    ]
  },
  "dayOfCycle": {
    "maxLength": 2,
    "type": "string"
  },
  "fetalCrownRumpLength": {
    "maxLength": 7,
    "type": [
      "string",
      "null"
    ]
  },
  "fetalNuchalTranslucency": {
    "maxLength": 7,
    "type": [
      "string",
      "null"
    ]
  },
  "hasInsuranceNumber": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "sampleAmount": {
    "maxLength": 10,
    "type": "string"
  },
  "samplePeriod": {
    "maxLength": 10,
    "type": "string"
  },
  "sampleDate": {
    "type": "string"
  },
  "sampleTime": {
    "maxLength": 8,
    "type": "string"
  },
  "passportNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "idcardNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "orderReason": {
    "maxLength": 1,
    "type": "string"
  },
  "costUnitIdentification": {
    "maxLength": 25,
    "type": "string"
  },
  "autInsuranceEmployer": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceEmployerCity": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceAddressAdditional": {
    "maxLength": 50,
    "type": "string"
  }
};

export const OrderPatientGDTSharedPropertiesSchema = {
  "medication": {
    "maxLength": 100,
    "type": "string"
  },
  "cycleDay": {
    "maxLength": 10,
    "type": "string"
  },
  "weight": {
    "maxLength": 5,
    "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
    "type": "string"
  },
  "diagnosis": {
    "maxLength": 150,
    "type": "string"
  },
  "exttnr": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "primaryCarePhysicianLanr": {
    "maxLength": 20,
    "type": "string"
  },
  "primaryCarePhysicianBsnr": {
    "maxLength": 20,
    "type": "string"
  },
  "diseaseManagementProgram": {
    "maxLength": 50,
    "type": "string"
  },
  "dmpSpecialGroup": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceStart": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceEnd": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceType": {
    "maxLength": 50,
    "type": "string"
  },
  "exceptionalIndication": {
    "maxLength": 20,
    "type": "string"
  },
  "sktIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "wopIdentifier": {
    "maxLength": 50,
    "type": "string"
  },
  "accountingArea": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "hvcode": {
    "maxLength": 20,
    "type": "string"
  },
  "vkat": {
    "maxLength": 20,
    "type": "string"
  },
  "secondName": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "canton": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "ahvNr": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "aisNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "insurance": {
    "type": [
      "number",
      "null"
    ]
  },
  "bag": {
    "maxLength": 5,
    "type": [
      "string",
      "null"
    ]
  },
  "cardNumber": {
    "maxLength": 20,
    "type": [
      "string",
      "null"
    ]
  },
  "patientIdentification": {
    "maxLength": 20,
    "type": "string"
  },
  "globalLocationNumber": {
    "maxLength": 13,
    "type": [
      "string",
      "null"
    ]
  },
  "chTreatmentReason": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "externalOrderInfo": {
    "maxLength": 256,
    "type": [
      "string",
      "null"
    ]
  },
  "hospitalBedNumber": {
    "type": "string"
  },
  "hospitalRoomNumber": {
    "type": "string"
  },
  "hospitalProfession": {
    "type": [
      "string",
      "null"
    ]
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "caseNumber": {
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "invoiceTo": {
    "maxLength": 25,
    "type": "string"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "autInsuranceTitle": {
    "maxLength": 100,
    "type": "string"
  },
  "autInsuranceFirstName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceLastName": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceInsuranceNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceBirthday": {
    "type": "string"
  },
  "autInsuranceAddress": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceHouseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCountry": {
    "maxLength": 10,
    "type": "string"
  },
  "autInsuranceZip": {
    "maxLength": 50,
    "type": "string"
  },
  "autInsuranceCity": {
    "maxLength": 50,
    "type": "string"
  }
};

export const OrderRuleActionPropertiesSchema = {
  "type": {},
  "options": {
    "type": [
      "object",
      "null"
    ]
  }
};

export const OrderRuleConditionPropertiesSchema = {
  "type": {},
  "notOperator": {
    "type": "boolean"
  },
  "options": {
    "type": [
      "object",
      "null"
    ]
  }
};

export const OrderRulesListQueryPropertiesSchema = {
  "query": {
    "type": "string"
  },
  "autoGenerated": {
    "type": "boolean"
  }
};

export const OrderRulesResultPropertiesSchema = {
  "order": {
    "freeText": {
      "maxLength": 60,
      "type": "string"
    },
    "message": {
      "maxLength": 100,
      "type": "string"
    },
    "infectious": {
      "type": "boolean"
    },
    "urgent": {
      "type": "string"
    },
    "pregnancy": {
      "type": "boolean"
    },
    "checkup": {
      "type": "boolean"
    },
    "costUnit": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "controlCheckupKnownInfection": {
      "type": "boolean"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "dz16b": {
      "type": "boolean"
    },
    "accident": {
      "type": "boolean"
    },
    "accidentTime": {
      "maxLength": 20,
      "type": "string"
    },
    "documentReferenceNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "anamnesticInfo": {
      "maxLength": 60,
      "type": "string"
    },
    "printedMaterials": {
      "type": [
        "string",
        "null"
      ]
    },
    "printQuickReportBarcode": {
      "type": "boolean"
    },
    "urgentNotificationPhone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationFax": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "urgentNotificationMail": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "id": {
      "type": "integer"
    },
    "status": {},
    "executionStatus": {},
    "poolId": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "tnr": {
      "type": "string"
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "type": "string"
    },
    "bsnr": {
      "type": "string"
    },
    "selectedDoctor": {
      "type": "string"
    },
    "apid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "patient": {
      "lastName": {
        "maxLength": 50,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": "string"
      },
      "suffix": {
        "maxLength": 50,
        "type": "string"
      },
      "title": {
        "maxLength": 50,
        "type": "string"
      },
      "gender": {},
      "birthday": {
        "maxLength": 50,
        "type": "string"
      },
      "city": {
        "maxLength": 50,
        "type": "string"
      },
      "zipCode": {
        "maxLength": 50,
        "type": "string"
      },
      "address": {
        "maxLength": 150,
        "type": "string"
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "addressAdditional": {
        "maxLength": 50,
        "type": "string"
      },
      "country": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "email": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "costUnit": {
        "type": "string"
      },
      "medication": {
        "maxLength": 100,
        "type": "string"
      },
      "cycleDay": {
        "maxLength": 10,
        "type": "string"
      },
      "weight": {
        "maxLength": 5,
        "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "diagnosis": {
        "maxLength": 150,
        "type": "string"
      },
      "exttnr": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "primaryCarePhysicianLanr": {
        "maxLength": 20,
        "type": "string"
      },
      "primaryCarePhysicianBsnr": {
        "maxLength": 20,
        "type": "string"
      },
      "diseaseManagementProgram": {
        "maxLength": 50,
        "type": "string"
      },
      "dmpSpecialGroup": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceStart": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceEnd": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceType": {
        "maxLength": 50,
        "type": "string"
      },
      "exceptionalIndication": {
        "maxLength": 20,
        "type": "string"
      },
      "sktIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "wopIdentifier": {
        "maxLength": 50,
        "type": "string"
      },
      "accountingArea": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "hvcode": {
        "maxLength": 20,
        "type": "string"
      },
      "vkat": {
        "maxLength": 20,
        "type": "string"
      },
      "secondName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "canton": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "ahvNr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "aisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "insurance": {
        "type": [
          "number",
          "null"
        ]
      },
      "bag": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "cardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "patientIdentification": {
        "maxLength": 20,
        "type": "string"
      },
      "globalLocationNumber": {
        "maxLength": 13,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "hospitalBedNumber": {
        "type": "string"
      },
      "hospitalRoomNumber": {
        "type": "string"
      },
      "hospitalProfession": {
        "type": [
          "string",
          "null"
        ]
      },
      "caseId": {
        "type": [
          "integer",
          "null"
        ]
      },
      "caseNumber": {
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "invoiceTo": {
        "maxLength": 25,
        "type": "string"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "autInsuranceTitle": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceFirstName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceLastName": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceInsuranceNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceBirthday": {
        "type": "string"
      },
      "autInsuranceAddress": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceHouseNumber": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCountry": {
        "maxLength": 10,
        "type": "string"
      },
      "autInsuranceZip": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceCity": {
        "maxLength": 50,
        "type": "string"
      },
      "pid": {
        "type": "integer"
      },
      "apgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "displayName": {
        "type": "string"
      },
      "prefix": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 5,
        "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
        "type": "string"
      },
      "pregnancyWeek": {
        "maxLength": 2,
        "pattern": "^(\\d{1,2})?$",
        "type": "string"
      },
      "pregnancyWeekDay": {
        "maxLength": 1,
        "pattern": "^(\\d{1})?$",
        "type": "string"
      },
      "cycleLength": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      },
      "dayOfCycle": {
        "maxLength": 2,
        "type": "string"
      },
      "fetalCrownRumpLength": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "fetalNuchalTranslucency": {
        "maxLength": 7,
        "type": [
          "string",
          "null"
        ]
      },
      "hasInsuranceNumber": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "sampleAmount": {
        "maxLength": 10,
        "type": "string"
      },
      "samplePeriod": {
        "maxLength": 10,
        "type": "string"
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "passportNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "idcardNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "orderReason": {
        "maxLength": 1,
        "type": "string"
      },
      "costUnitIdentification": {
        "maxLength": 25,
        "type": "string"
      },
      "autInsuranceEmployer": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceEmployerCity": {
        "maxLength": 50,
        "type": "string"
      },
      "autInsuranceAddressAdditional": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "requirements": {
      "type": "array"
    },
    "anamnesis": {
      "type": "array"
    },
    "animal": {
      "name": {
        "maxLength": 50,
        "type": "string"
      },
      "birthday": {
        "maxLength": 8,
        "type": "string"
      },
      "species": {
        "maxLength": 2,
        "type": "string"
      },
      "speciesManual": {
        "maxLength": 50,
        "type": "string"
      },
      "chipId": {
        "maxLength": 50,
        "type": "string"
      },
      "height": {
        "maxLength": 3,
        "type": "string"
      },
      "weight": {
        "maxLength": 3,
        "type": "string"
      },
      "sex": {
        "type": "number"
      },
      "breed": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerName": {
        "maxLength": 100,
        "type": "string"
      },
      "ownerAmendment": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerStreet": {
        "maxLength": 50,
        "type": "string"
      },
      "ownerHouseNumber": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerZipCode": {
        "maxLength": 10,
        "type": "string"
      },
      "ownerCity": {
        "maxLength": 50,
        "type": "string"
      }
    },
    "switzerland": {
      "billToOther1": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther2": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "billToOther3": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyTo": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToEmail": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToSalutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToFirstName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToLastName": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToStreet": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToZip": {
        "maxLength": 5,
        "type": [
          "string",
          "null"
        ]
      },
      "reportCopyToCity": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "notes": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "chTreatmentReason": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "accidentDate": {
        "maxLength": 8,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "hungary": {
      "motherLastName": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "motherFirstName": {
        "maxLength": 15,
        "type": [
          "string",
          "null"
        ]
      },
      "treatmentReason": {
        "maxLength": 1,
        "type": [
          "string",
          "null"
        ]
      },
      "invoice": {
        "maxLength": 2,
        "type": [
          "string",
          "null"
        ]
      },
      "icd1": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd2": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "icd3": {
        "maxLength": 6,
        "type": [
          "string",
          "null"
        ]
      },
      "insuranceNumber": {
        "maxLength": 18,
        "type": [
          "string",
          "null"
        ]
      },
      "citizen": {
        "maxLength": 3,
        "type": [
          "string",
          "null"
        ]
      }
    },
    "executed_at": {
      "type": "string"
    },
    "scheduled_at": {
      "type": [
        "string",
        "null"
      ]
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "covid": {
      "testNumber": {},
      "testReason": {},
      "agreed": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "riskCharacteristicsLocation": {},
      "riskCharacteristicsLocationType": {},
      "legalReason": {},
      "healthServiceZipCode": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "healthService": {
        "maxLength": 255,
        "type": [
          "string",
          "null"
        ]
      },
      "legalOrderBasis": {},
      "kvSpecial": {
        "type": [
          "string",
          "null"
        ]
      },
      "confirmationDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "variantDiagnostics": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "oegdInvoiceTo": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "covidWarnAppBarcode": {
        "type": [
          "boolean",
          "null"
        ]
      }
    },
    "mustValidate": {
      "type": "boolean"
    },
    "hospitalStation": {
      "type": "string"
    },
    "requirementDiagnosisFreeText": {
      "maxLength": 150,
      "type": "string"
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "selectedDiagnoses": {
      "type": "object"
    },
    "selectedDiagnosesChecked": {
      "type": "boolean"
    },
    "workstationChecked": {
      "type": "boolean"
    },
    "splitProperties": {
      "type": "array"
    },
    "sample_in_lab": {
      "type": "boolean"
    },
    "clientname": {
      "type": "string"
    },
    "printerAliasName": {
      "type": "string"
    },
    "overridePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "overrideBarcodePrinterName": {
      "type": [
        "string",
        "null"
      ]
    },
    "beforeAfterDialysis": {},
    "reportCopyAid": {
      "type": [
        "number",
        "null"
      ]
    },
    "reportCopyDoctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aisIdentifier": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "patientAisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "bloodCollectionType": {}
  },
  "verifiedRules": {
    "type": "array"
  },
  "errors": {
    "type": "array"
  }
};

export const OrderRulesAddRequirementActionOptionsSchema = {
  "shortName": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  }
};

export const OrderRulesAnamnesisActionOptionsSchema = {
  "questionId": {
    "type": "integer"
  }
};

export const OrderRulesDiagnosisMandatoryActionOptionsSchema = {
  "enabled": {
    "type": "boolean"
  }
};

export const OrderRulesFreetextMandatoryActionOptionsSchema = {
  "shortName": {
    "type": "string"
  }
};

export const OrderRulesIncompatibleRequirementActionOptionsSchema = {
  "shortName": {
    "type": "string"
  }
};

export const OrderRulesMandatoryFieldActionOptionsSchema = {
  "field": {}
};

export const OrderRulesRemoveRequirementActionOptionsSchema = {
  "shortName": {
    "type": "string"
  }
};

export const OrderRulesShowHintActionOptionsSchema = {
  "title": {
    "type": "string"
  },
  "text": {
    "type": "string"
  }
};

export const OrderRulesShowTextActionOptionsSchema = {
  "text": {
    "type": "string"
  },
  "type": {}
};

export const OrderRulesStatusPreventionActionOptionsSchema = {
  "status": {}
};

export const OrderRulesWorkstationMandatoryActionOptionsSchema = {
  "enabled": {
    "type": "boolean"
  }
};

export const OrderRulesDialysisMandatoryActionOptionsSchema = {
  "enabled": {
    "type": "boolean"
  }
};

export const OrderRulesCostUnitConditionOptionsSchema = {
  "costUnit": {
    "type": "string"
  }
};

export const OrderRulesDoctorGroupsConditionOptionsSchema = {
  "doctorGroups": {
    "type": "array"
  }
};

export const OrderRulesDoctorsConditionOptionsSchema = {
  "doctors": {
    "type": "array"
  }
};

export const OrderRulesFeatureConditionOptionsSchema = {
  "key": {}
};

export const OrderRulesFormConditionOptionsSchema = {
  "formId": {
    "type": "integer"
  }
};

export const OrderRulesFormTypeConditionOptionsSchema = {
  "type": {}
};

export const OrderRulesGenderConditionOptionsSchema = {
  "gender": {}
};

export const OrderRulesLocalizationConditionOptionsSchema = {
  "localisation": {}
};

export const OrderRulesRequirementConditionOptionsSchema = {
  "shortName": {
    "type": "string"
  },
  "laboratoryGroup": {
    "type": "string"
  }
};

export const OrderRulesProductConditionOptionsSchema = {
  "key": {}
};

export const OrderRulesStatusConditionOptionsSchema = {
  "status": {}
};

export const OrderRulesUserTypeConditionOptionsSchema = {
  "type": {}
};

export const LoadAisImportPropertiesSchema = {
  "loadAisFirstname": {
    "type": "string"
  },
  "loadAisLastname": {
    "type": "string"
  },
  "loadAisBirthdate": {
    "type": "string"
  },
  "loadAisShowReport": {
    "type": "boolean"
  }
};

export const OriginalReportPropertiesSchema = {
  "f_ob_opt1": {
    "type": "number"
  },
  "f_ob_opt2": {
    "type": "number"
  },
  "f_ob_opt3": {
    "type": "number"
  },
  "f_ob_opt4": {
    "type": "number"
  }
};

export const OriginalReportSettingsSchema = {
  "settings": {
    "f_ob_opt1": {
      "type": "number"
    },
    "f_ob_opt2": {
      "type": "number"
    },
    "f_ob_opt3": {
      "type": "number"
    },
    "f_ob_opt4": {
      "type": "number"
    }
  }
};

export const GuidPdfConfigSchema = {
  "available": {
    "type": "boolean"
  },
  "protection": {},
  "askFirstName": {
    "type": "boolean"
  },
  "askPassport": {
    "type": "boolean"
  },
  "askIdCard": {
    "type": "boolean"
  }
};

export const ReportsPdfDownloadAnalyseSettingSchema = {
  "name": {
    "type": "string"
  },
  "pathological": {
    "type": "boolean"
  }
};

export const ReportsPdfDownloadAnalysesSettingsSchema = {
  "analyses": {
    "type": "array"
  },
  "enabledMode": {
    "type": "boolean"
  }
};

export const ReportBasePropertiesSchema = {
  "material": {
    "type": "string"
  }
};

export const ReportAccessStatsSchema = {
  "total": {
    "type": "integer"
  },
  "today": {
    "type": "integer"
  },
  "last7": {
    "type": "integer"
  },
  "last30": {
    "type": "integer"
  }
};

export const ExportMeSettingsStatusFlagsSchema = {
  "preliminary": {
    "type": "boolean"
  },
  "partial": {
    "type": "boolean"
  },
  "final": {
    "type": "boolean"
  }
};

export const ExportMeSettingsCharacteristicFlagsSchema = {
  "notPathological": {
    "type": "boolean"
  },
  "pathological": {
    "type": "boolean"
  },
  "extreme": {
    "type": "boolean"
  },
  "deltaCheck": {
    "type": "boolean"
  }
};

export const ReportsDoctorSettingsSchema = {
  "canActivatePatients": {
    "type": "boolean"
  },
  "isSuperDoctor": {
    "type": "boolean"
  },
  "disableViewDiagnosis": {
    "type": "boolean"
  },
  "emailReports": {
    "type": "boolean"
  },
  "speciality": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "reportsLanguageSetting": {},
  "exportMe": {
    "type": "boolean"
  },
  "exportMeReceiver": {
    "type": [
      "string",
      "null"
    ]
  },
  "exportMeSettingsStatusFlags": {
    "preliminary": {
      "type": "boolean"
    },
    "partial": {
      "type": "boolean"
    },
    "final": {
      "type": "boolean"
    }
  },
  "exportMeSettingsCharacteristicFlags": {
    "notPathological": {
      "type": "boolean"
    },
    "pathological": {
      "type": "boolean"
    },
    "extreme": {
      "type": "boolean"
    },
    "deltaCheck": {
      "type": "boolean"
    }
  }
};

export const ReportsGeneralConfigSchema = {
  "preferences": {
    "reportsDefaultOrder": {},
    "reportsPatientNameSorting": {
      "type": "string"
    },
    "reportsPatientNameDisplayEnabled": {
      "type": "boolean"
    },
    "reportsPatientNameDisplay": {},
    "reportsDefaultFilterProfile": {
      "type": "integer"
    },
    "reportsFavoriteAnalyses": {
      "type": "array"
    },
    "reportsPinnedOnTop": {
      "type": "boolean"
    },
    "reportsInitialOpenCumulative": {
      "type": "boolean"
    },
    "reportsCumulativeMergeDays": {
      "type": "boolean"
    },
    "reportsCumulativeSingleReport": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalysesEnabledMode": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalyses": {
      "type": "array"
    },
    "reportsPdfDownloadPathoAnalyses": {
      "type": "array"
    },
    "enableAdvancedAnalysesFilter": {
      "type": "boolean"
    },
    "reportsBarcodeEnableCustomization": {
      "type": "boolean"
    },
    "reportsBarcodePrefixLength": {
      "type": "integer"
    },
    "reportsBarcodeLength": {
      "type": "integer"
    },
    "reportsBarcodeSuffixLength": {
      "type": "integer"
    },
    "reportsPatientsListShowInsuranceNumber": {
      "type": "boolean"
    },
    "reportsPatientsListShowPatientIdentification": {
      "type": "boolean"
    },
    "reportsPatientsListShowEmail": {
      "type": "boolean"
    },
    "reportsPatientsListShowBirthday": {
      "type": "boolean"
    },
    "reportsCustomSortBdwEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeGroupsEnabled": {
      "type": "boolean"
    },
    "reportsDeltaCheckFilter": {
      "type": "boolean"
    },
    "reportsValueFollowsEnabled": {
      "type": "boolean"
    },
    "reportsValueFollowsText": {
      "type": "string"
    },
    "reportsValueFollowsHideNteEmpty": {
      "type": "boolean"
    }
  },
  "reportDisplay": {
    "lid": {
      "type": "integer"
    },
    "emailReports": {
      "type": "boolean"
    },
    "exportReports": {
      "type": "boolean"
    },
    "contactLaboratory": {
      "type": "boolean"
    },
    "reportAttachments": {
      "type": "boolean"
    },
    "rerequests": {
      "type": "boolean"
    },
    "printOrders": {
      "type": "boolean"
    },
    "showPathoColumn": {
      "type": "boolean"
    },
    "hidePathoColumnPat": {
      "type": "boolean"
    },
    "pathoHighlightType": {},
    "showLaboratoryGroup": {
      "type": "boolean"
    },
    "rerequestTimeFrame": {
      "type": "number"
    },
    "alwaysShowDateAndTimeInCumulativeDisplay": {
      "type": "boolean"
    }
  },
  "allergyClasses": {
    "type": "array"
  },
  "patients": {
    "defaultPatientCanViewPartialResults": {
      "type": "boolean"
    },
    "defaultPatientCanViewPreliminaryResults": {
      "type": "boolean"
    }
  }
};

export const ReportsUserConfigSchema = {
  "preferences": {
    "reportsDefaultOrder": {},
    "reportsPatientNameSorting": {
      "type": "string"
    },
    "reportsPatientNameDisplayEnabled": {
      "type": "boolean"
    },
    "reportsPatientNameDisplay": {},
    "reportsDefaultFilterProfile": {
      "type": "integer"
    },
    "reportsFavoriteAnalyses": {
      "type": "array"
    },
    "reportsPinnedOnTop": {
      "type": "boolean"
    },
    "reportsInitialOpenCumulative": {
      "type": "boolean"
    },
    "reportsCumulativeMergeDays": {
      "type": "boolean"
    },
    "reportsCumulativeSingleReport": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalysesEnabledMode": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalyses": {
      "type": "array"
    },
    "reportsPdfDownloadPathoAnalyses": {
      "type": "array"
    },
    "enableAdvancedAnalysesFilter": {
      "type": "boolean"
    },
    "reportsBarcodeEnableCustomization": {
      "type": "boolean"
    },
    "reportsBarcodePrefixLength": {
      "type": "integer"
    },
    "reportsBarcodeLength": {
      "type": "integer"
    },
    "reportsBarcodeSuffixLength": {
      "type": "integer"
    },
    "reportsPatientsListShowInsuranceNumber": {
      "type": "boolean"
    },
    "reportsPatientsListShowPatientIdentification": {
      "type": "boolean"
    },
    "reportsPatientsListShowEmail": {
      "type": "boolean"
    },
    "reportsPatientsListShowBirthday": {
      "type": "boolean"
    },
    "reportsCustomSortBdwEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeGroupsEnabled": {
      "type": "boolean"
    },
    "reportsDeltaCheckFilter": {
      "type": "boolean"
    },
    "reportsValueFollowsEnabled": {
      "type": "boolean"
    },
    "reportsValueFollowsText": {
      "type": "string"
    },
    "reportsValueFollowsHideNteEmpty": {
      "type": "boolean"
    }
  },
  "lanr": {
    "enabled": {
      "type": "boolean"
    },
    "doctors": {
      "type": "array"
    },
    "defaultLanr": {
      "type": "string"
    }
  },
  "doctor": {
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailReports": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    }
  }
};

export const ReportsGeneralPatientsConfigSchema = {
  "defaultPatientCanViewPartialResults": {
    "type": "boolean"
  },
  "defaultPatientCanViewPreliminaryResults": {
    "type": "boolean"
  }
};

export const ReportsNotificationSettingsSchema = {
  "characteristics": {
    "type": "array"
  },
  "status": {
    "type": "array"
  },
  "custom": {
    "type": "boolean"
  }
};

export const ReportsConfigSchema = {
  "preferences": {
    "reportsDefaultOrder": {},
    "reportsPatientNameSorting": {
      "type": "string"
    },
    "reportsPatientNameDisplayEnabled": {
      "type": "boolean"
    },
    "reportsPatientNameDisplay": {},
    "reportsDefaultFilterProfile": {
      "type": "integer"
    },
    "reportsFavoriteAnalyses": {
      "type": "array"
    },
    "reportsPinnedOnTop": {
      "type": "boolean"
    },
    "reportsInitialOpenCumulative": {
      "type": "boolean"
    },
    "reportsCumulativeMergeDays": {
      "type": "boolean"
    },
    "reportsCumulativeSingleReport": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalysesEnabledMode": {
      "type": "boolean"
    },
    "reportsPdfDownloadAnalyses": {
      "type": "array"
    },
    "reportsPdfDownloadPathoAnalyses": {
      "type": "array"
    },
    "enableAdvancedAnalysesFilter": {
      "type": "boolean"
    },
    "reportsBarcodeEnableCustomization": {
      "type": "boolean"
    },
    "reportsBarcodePrefixLength": {
      "type": "integer"
    },
    "reportsBarcodeLength": {
      "type": "integer"
    },
    "reportsBarcodeSuffixLength": {
      "type": "integer"
    },
    "reportsPatientsListShowInsuranceNumber": {
      "type": "boolean"
    },
    "reportsPatientsListShowPatientIdentification": {
      "type": "boolean"
    },
    "reportsPatientsListShowEmail": {
      "type": "boolean"
    },
    "reportsPatientsListShowBirthday": {
      "type": "boolean"
    },
    "reportsCustomSortBdwEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeEnabled": {
      "type": "boolean"
    },
    "reportsCustomSortCumulativeGroupsEnabled": {
      "type": "boolean"
    },
    "reportsDeltaCheckFilter": {
      "type": "boolean"
    },
    "reportsValueFollowsEnabled": {
      "type": "boolean"
    },
    "reportsValueFollowsText": {
      "type": "string"
    },
    "reportsValueFollowsHideNteEmpty": {
      "type": "boolean"
    }
  },
  "reportDisplay": {
    "lid": {
      "type": "integer"
    },
    "emailReports": {
      "type": "boolean"
    },
    "exportReports": {
      "type": "boolean"
    },
    "contactLaboratory": {
      "type": "boolean"
    },
    "reportAttachments": {
      "type": "boolean"
    },
    "rerequests": {
      "type": "boolean"
    },
    "printOrders": {
      "type": "boolean"
    },
    "showPathoColumn": {
      "type": "boolean"
    },
    "hidePathoColumnPat": {
      "type": "boolean"
    },
    "pathoHighlightType": {},
    "showLaboratoryGroup": {
      "type": "boolean"
    },
    "rerequestTimeFrame": {
      "type": "number"
    },
    "alwaysShowDateAndTimeInCumulativeDisplay": {
      "type": "boolean"
    }
  },
  "allergyClasses": {
    "type": "array"
  },
  "patients": {
    "defaultPatientCanViewPartialResults": {
      "type": "boolean"
    },
    "defaultPatientCanViewPreliminaryResults": {
      "type": "boolean"
    }
  },
  "lanr": {
    "enabled": {
      "type": "boolean"
    },
    "doctors": {
      "type": "array"
    },
    "defaultLanr": {
      "type": "string"
    }
  },
  "doctor": {
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailReports": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    }
  }
};

export const AdvancedAnalysisFilterSchema = {
  "name": {
    "type": "string"
  },
  "filterType": {},
  "value": {
    "type": "string"
  }
};

export const FilterProfilePropertiesSchema = {
  "status": {},
  "gender": {},
  "characteristic": {},
  "reportType": {},
  "advancedAnalysisFilter": {
    "type": "array"
  },
  "locked": {
    "type": "boolean"
  },
  "dateFrom": {
    "type": "string"
  },
  "dateTo": {
    "type": "string"
  },
  "unread": {},
  "doctorName": {
    "type": "string"
  }
};

export const ReportHistoryValueSchema = {
  "name": {
    "type": "string"
  },
  "description": {
    "type": "string"
  },
  "group": {
    "type": "string"
  },
  "poct": {
    "type": [
      "string",
      "null"
    ]
  },
  "values": {
    "type": "array"
  }
};

export const ReportValuePropertiesSchema = {
  "bid": {
    "type": "integer"
  },
  "caseId": {
    "type": [
      "integer",
      "null"
    ]
  },
  "caseNumber": {
    "type": [
      "string",
      "null"
    ]
  },
  "btnr": {
    "type": "string"
  },
  "blg": {
    "type": "string"
  },
  "date": {
    "type": "string"
  },
  "result": {
    "type": "string"
  },
  "textResult": {
    "maxLength": 150,
    "type": "string"
  },
  "unit": {
    "type": "string"
  },
  "reference": {
    "from": {
      "type": "string"
    },
    "to": {
      "type": "string"
    },
    "text": {
      "type": "string"
    }
  },
  "pathological": {
    "type": "string"
  },
  "poct": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "graphicFormat": {},
  "laboratoryGroup": {
    "type": "string"
  }
};

export const ReportValueReferenceSchema = {
  "from": {
    "type": "string"
  },
  "to": {
    "type": "string"
  },
  "text": {
    "type": "string"
  }
};

export const ReportCreateDoctorPropertiesSchema = {
  "short_form": {
    "maxLength": 60,
    "type": "string"
  },
  "name": {
    "maxLength": 100,
    "type": "string"
  },
  "first_name": {
    "maxLength": 50,
    "type": "string"
  },
  "salutation": {
    "maxLength": 100,
    "type": "string"
  },
  "phone_number": {
    "maxLength": 50,
    "type": "string"
  },
  "fax_number": {
    "maxLength": 50,
    "type": "string"
  },
  "address": {
    "maxLength": 100,
    "type": "string"
  },
  "city": {
    "maxLength": 50,
    "type": "string"
  },
  "zip": {
    "maxLength": 25,
    "type": "string"
  },
  "country_code": {
    "maxLength": 50,
    "type": "string"
  },
  "office_name": {
    "type": "string"
  }
};

export const ReportIdexxCreatePatientPropertiesSchema = {
  "owner_name": {
    "maxLength": 50,
    "type": "string"
  },
  "owner_first_name": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_breed": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_species": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_name": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_birth_date": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_age": {
    "maxLength": 50,
    "type": "string"
  },
  "animal_age_unit": {},
  "animal_sex": {},
  "animal_picture": {}
};

export const ReportIdexxCreateResultPropertiesSchema = {
  "result_group": {
    "maxLength": 150,
    "type": "string"
  },
  "result_name": {
    "maxLength": 150,
    "type": "string"
  },
  "methodology": {
    "type": "string"
  },
  "sub_category": {
    "type": "string"
  },
  "add_on": {
    "type": "boolean"
  },
  "cancel": {
    "type": "boolean"
  },
  "result": {
    "maxLength": 17,
    "type": "string"
  },
  "text_result": {
    "maxLength": 80,
    "type": "string"
  },
  "from": {
    "maxLength": 30,
    "type": "string"
  },
  "to": {
    "maxLength": 30,
    "type": "string"
  },
  "unit": {
    "maxLength": 30,
    "type": "string"
  },
  "from_to_display": {
    "maxLength": 50,
    "type": "string"
  },
  "pathologic_code": {
    "maxLength": 10,
    "type": "string"
  },
  "comment": {
    "maxLength": 100,
    "type": "string"
  },
  "flag_for_microbiologic": {},
  "shortcode": {
    "maxLength": 30,
    "type": "string"
  },
  "anti_tab_counter": {
    "type": "integer"
  },
  "comment_reference_result": {
    "maxLength": 15,
    "type": "string"
  },
  "comment_reference_result_group": {
    "maxLength": 15,
    "type": "string"
  },
  "textcomment_for_result": {
    "type": "array"
  },
  "status": {},
  "sample_material_id": {
    "maxLength": 50,
    "type": "string"
  }
};

export const ReportIdexxCreateTextCommentForResultPropertiesSchema = {
  "comment_type": {},
  "textline": {
    "type": "string"
  }
};

export const ReportIdexxCreateCommentsPropertiesSchema = {
  "textline": {
    "type": "string"
  },
  "type": {},
  "reference": {
    "maxLength": 50,
    "type": "string"
  }
};

export const ReportIdexxCreateElphoPropertiesSchema = {
  "x_value": {
    "type": "number"
  },
  "y_value": {
    "type": "number"
  },
  "line": {},
  "type": {}
};

export const ReportIdexxCreateBudgetPropertiesSchema = {
  "position_number": {
    "type": "number"
  },
  "billing_position_id": {
    "maxLength": 20,
    "type": "string"
  },
  "billing_position_name": {
    "maxLength": 100,
    "type": "string"
  },
  "billing_position_price": {
    "maxLength": 50,
    "type": "string"
  },
  "vat_percentage": {
    "maxLength": 20,
    "type": "string"
  },
  "billing_sum_excl_vat": {
    "maxLength": 50,
    "type": "string"
  },
  "billing_sum_incl_vat": {
    "maxLength": 100,
    "type": "string"
  },
  "billing_vat_sum": {
    "maxLength": 20,
    "type": "string"
  },
  "billing_currency": {
    "maxLength": 20,
    "type": "string"
  }
};

export const ReportIdexxCreateReportCommentPropertiesSchema = {
  "textline": {
    "type": "string"
  }
};

export const ReportIdexxCreatePropertiesSchema = {
  "transaction_uuid": {
    "type": "string"
  },
  "order_uuid": {
    "type": "string"
  },
  "order_created_date": {
    "maxLength": 14,
    "type": "string"
  },
  "order_import_date": {
    "maxLength": 14,
    "type": "string"
  },
  "validating_doctor": {
    "maxLength": 100,
    "type": "string"
  },
  "validating_doctor_addition": {
    "maxLength": 100,
    "type": "string"
  },
  "sample_names": {
    "maxLength": 150,
    "type": "string"
  },
  "lab_accessionNumber": {
    "maxLength": 15,
    "type": "string"
  },
  "lab_barcodeId": {
    "maxLength": 50,
    "type": "string"
  },
  "order_status": {},
  "laboratory_group": {},
  "customer_language_code": {},
  "legal_entity": {},
  "create_lis_date": {
    "maxLength": 14,
    "type": "string"
  },
  "doctor": {
    "short_form": {
      "maxLength": 60,
      "type": "string"
    },
    "name": {
      "maxLength": 100,
      "type": "string"
    },
    "first_name": {
      "maxLength": 50,
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": "string"
    },
    "phone_number": {
      "maxLength": 50,
      "type": "string"
    },
    "fax_number": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 100,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zip": {
      "maxLength": 25,
      "type": "string"
    },
    "country_code": {
      "maxLength": 50,
      "type": "string"
    },
    "office_name": {
      "type": "string"
    }
  },
  "patient": {
    "owner_name": {
      "maxLength": 50,
      "type": "string"
    },
    "owner_first_name": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_breed": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_species": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_name": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_birth_date": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_age": {
      "maxLength": 50,
      "type": "string"
    },
    "animal_age_unit": {},
    "animal_sex": {},
    "animal_picture": {}
  },
  "results": {
    "type": "array"
  },
  "comments": {
    "type": "array"
  },
  "electrophoresis": {
    "type": "array"
  },
  "budget": {
    "type": "array"
  },
  "general_report_comments": {
    "type": "array"
  },
  "pdf_attachment": {},
  "create_as_pdf": {
    "type": "boolean"
  },
  "create_as_ldt": {
    "type": "boolean"
  },
  "settlement_type": {}
};

export const AuthConfigPropertiesSchema = {
  "authTokenExpiryTime": {
    "type": "number"
  },
  "authTokenExpiryTimeInterval": {},
  "resetTokenExpiryTime": {
    "type": "number"
  },
  "resetTokenExpiryTimeInterval": {},
  "smsVerificationTokenExpiryTime": {
    "type": "number"
  },
  "smsVerificationTokenExpiryTimeInterval": {},
  "lcRegistrationTokenDefaultExpiryTime": {
    "type": "number"
  },
  "lcRegistrationTokenDefaultExpiryTimeInterval": {},
  "throttleLoginCount": {
    "type": "number"
  },
  "throttleLoginTime": {
    "type": "number"
  },
  "throttleLoginTimeInterval": {},
  "passwordPolicyPattern": {
    "type": "string"
  },
  "enableAlternateLogin": {
    "type": "boolean"
  },
  "disableUserServerId": {
    "type": "boolean"
  },
  "customUsernamePrefix": {
    "type": "string"
  },
  "logWrongPasswordCleartext": {
    "type": "boolean"
  },
  "deviceApproveBrowsers": {
    "type": "boolean"
  },
  "enableAcceptToc": {
    "type": "boolean"
  },
  "hideEmailFields": {
    "type": "boolean"
  },
  "doctorFirstLoginEmail": {
    "type": "boolean"
  },
  "identifierLength": {
    "type": "integer"
  },
  "twoFactorAuthEnabled": {
    "type": "boolean"
  },
  "twoFactorAppDisabled": {
    "type": "boolean"
  },
  "twoFactorAuthIdentifier": {
    "type": "string"
  },
  "twoFactorAuthMandatory": {
    "type": "boolean"
  },
  "twoFactorShowInUserList": {
    "type": "boolean"
  },
  "enablePasswordVisibilityToggleButton": {
    "type": "boolean"
  },
  "recoveryUrl": {
    "type": "string"
  },
  "recoveryUrlActive": {
    "type": "boolean"
  }
};

export const LinksConfigPropertiesSchema = {
  "showToc": {
    "type": "boolean"
  },
  "showPrivacy": {
    "type": "boolean"
  },
  "showContact": {
    "type": "boolean"
  },
  "showContactForm": {
    "type": "boolean"
  }
};

export const DoctorGroupMemberPropertiesSchema = {
  "aid": {
    "type": "integer"
  },
  "kurzform": {
    "type": "string"
  },
  "displayName": {
    "type": "string"
  },
  "isAdmin": {
    "type": "boolean"
  },
  "isMember": {
    "type": "boolean"
  },
  "bengrp": {
    "type": "boolean"
  },
  "doctor": {
    "aid": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "mtgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "kennung": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "status": {},
    "displayName": {
      "type": "string"
    },
    "salutation": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "email": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "userDebugEnabled": {
      "type": "boolean"
    },
    "oa": {
      "type": "boolean"
    },
    "ob": {
      "type": "boolean"
    },
    "of": {
      "type": "boolean"
    },
    "ll": {
      "type": "boolean"
    },
    "le": {
      "type": "boolean"
    },
    "ls": {
      "type": "boolean"
    },
    "lc": {
      "type": "boolean"
    },
    "dd": {
      "type": "boolean"
    },
    "mc": {
      "type": "boolean"
    },
    "nc": {
      "type": "boolean"
    },
    "lw": {
      "type": "boolean"
    },
    "admin": {
      "type": "boolean"
    },
    "dashboard": {
      "type": "boolean"
    },
    "features": {
      "type": "object"
    },
    "user": {
      "id": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "type": {},
      "entityId": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "alternative_kennung": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedTocAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "acceptedMaintenanceAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "enabled2fa": {
        "type": "boolean"
      },
      "rejected2faAt": {
        "type": [
          "string",
          "null"
        ]
      },
      "apiDocs": {
        "type": "boolean"
      }
    },
    "externalId": {
      "maxLength": 60,
      "type": [
        "string",
        "null"
      ]
    },
    "externalId2": {
      "type": "string"
    },
    "lanr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "title": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "lastName": {
      "maxLength": 250,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "address": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "zipCode": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "city": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "countryCode": {
      "type": [
        "string",
        "null"
      ]
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "faxNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "smsNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "www": {
      "maxLength": 100,
      "type": [
        "string",
        "null"
      ]
    },
    "welcomeLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "logoutLabel": {
      "maxLength": 200,
      "type": [
        "string",
        "null"
      ]
    },
    "hierarchyStatus": {},
    "hierarchyAssignments": {
      "type": "array"
    },
    "hierarchyTree": {
      "type": "array"
    },
    "canActivatePatients": {
      "type": "boolean"
    },
    "isSuperDoctor": {
      "type": "boolean"
    },
    "disableViewDiagnosis": {
      "type": "boolean"
    },
    "emailNotificationEnabled": {
      "type": "boolean"
    },
    "emailNotificationsCharacteristics": {
      "type": "string"
    },
    "emailNotificationsStatus": {
      "type": "string"
    },
    "emailReports": {
      "type": "boolean"
    },
    "officeName": {
      "maxLength": 200,
      "type": "string"
    },
    "bsnr": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "localisation": {},
    "laboratoryLocalisation": {},
    "manufacturer": {
      "type": "string"
    },
    "urgent": {
      "type": "boolean"
    },
    "emergency": {
      "type": "boolean"
    },
    "infectious": {
      "type": "boolean"
    },
    "oaanswertrigger": {
      "type": "string"
    },
    "oaSoftDelete": {
      "type": "boolean"
    },
    "printMode": {
      "type": "integer"
    },
    "barcodePrintSettings": {
      "type": "integer"
    },
    "printername": {
      "maxLength": 200,
      "type": "string"
    },
    "scheinPrintSettings": {
      "type": "integer"
    },
    "printernameSchein": {
      "maxLength": 200,
      "type": "string"
    },
    "socketPrint": {
      "type": "boolean"
    },
    "internalPrintUser": {
      "type": "boolean"
    },
    "customDoctorHeading": {
      "type": "boolean"
    },
    "customDoctorHeadingLine1": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine2": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine3": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine4": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine5": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine6": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine7": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine8": {
      "maxLength": 50,
      "type": "string"
    },
    "customDoctorHeadingLine9": {
      "maxLength": 50,
      "type": "string"
    },
    "graphicalRequestChooser": {
      "type": "boolean"
    },
    "ipConstraint": {
      "type": "boolean"
    },
    "autoprint": {
      "type": "boolean"
    },
    "speciality": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "reportsLanguageSetting": {},
    "reportCount": {
      "type": "integer"
    },
    "forms": {
      "type": "array"
    },
    "profiles": {
      "type": "array"
    },
    "globalProfiles": {
      "type": "array"
    },
    "groups": {
      "type": "array"
    },
    "groupForms": {
      "type": "array"
    },
    "devices": {
      "type": "array"
    },
    "doctorGroupOffices": {
      "type": "array"
    },
    "doctorGroupOfficeName": {
      "type": "string"
    },
    "pid": {
      "type": "number"
    },
    "editKisMode": {
      "type": "boolean"
    },
    "laboratory": {
      "lid": {
        "type": "integer"
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "maxLength": 50,
        "type": "string"
      },
      "salutation": {
        "type": "string"
      },
      "email": {
        "maxLength": 100,
        "type": "string"
      },
      "locale": {},
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "hl7Name": {
        "type": "string"
      },
      "target": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "companyName": {
        "type": "string"
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "phone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "whatsapp": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "fax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "rerequestMail": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "autoLockReport": {
        "type": "boolean"
      },
      "class0": {
        "type": "number"
      },
      "class1": {
        "type": "number"
      },
      "class2": {
        "type": "number"
      },
      "class3": {
        "type": "number"
      },
      "class4": {
        "type": "number"
      },
      "class5": {
        "type": "number"
      },
      "showPdf": {},
      "showOriginal": {},
      "showBdw": {},
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "type": [
          "string",
          "null"
        ]
      },
      "suffix": {
        "type": [
          "string",
          "null"
        ]
      },
      "loginSite": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "additional1": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional2": {
        "type": [
          "string",
          "null"
        ]
      },
      "additional3": {
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "contactInfo": {
        "type": [
          "string",
          "null"
        ]
      },
      "verifyIKs": {
        "type": "boolean"
      },
      "displayText": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "concurrentLoginLimit": {
        "type": "integer"
      },
      "barcodeAlgorithm": {
        "maxLength": 10,
        "type": "string"
      },
      "barcodeLength": {
        "type": "integer"
      },
      "labelWidth": {
        "type": "integer"
      },
      "labelHeight": {
        "type": "integer"
      },
      "barcodeWidth": {
        "type": "integer"
      },
      "barcodeHeight": {
        "type": "integer"
      },
      "dynamicProbeTypeBase": {
        "type": "integer"
      },
      "reportDisplay": {
        "lid": {
          "type": "integer"
        },
        "emailReports": {
          "type": "boolean"
        },
        "exportReports": {
          "type": "boolean"
        },
        "contactLaboratory": {
          "type": "boolean"
        },
        "reportAttachments": {
          "type": "boolean"
        },
        "rerequests": {
          "type": "boolean"
        },
        "printOrders": {
          "type": "boolean"
        },
        "showPathoColumn": {
          "type": "boolean"
        },
        "hidePathoColumnPat": {
          "type": "boolean"
        },
        "pathoHighlightType": {},
        "showLaboratoryGroup": {
          "type": "boolean"
        },
        "rerequestTimeFrame": {
          "type": "number"
        },
        "alwaysShowDateAndTimeInCumulativeDisplay": {
          "type": "boolean"
        }
      },
      "mailTemplateGroup": {
        "mtgid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "reply": {
          "type": "string"
        },
        "target": {
          "type": [
            "string",
            "null"
          ]
        },
        "www_url": {
          "maxLength": 50,
          "pattern": "^https?://",
          "type": [
            "string",
            "null"
          ]
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "defaultPatientCanViewPartialResults": {
        "type": "boolean"
      },
      "defaultPatientCanViewPreliminaryResults": {
        "type": "boolean"
      },
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "overwrite": {
        "type": "boolean"
      },
      "resetCentralBarcodes": {
        "type": "boolean"
      },
      "mtlMail": {
        "maxLength": 250,
        "type": "string"
      },
      "useLgMail": {
        "type": "boolean"
      },
      "orderTimeframe": {
        "type": "integer"
      },
      "reRequestLimit": {
        "type": "integer"
      },
      "reRequestBuffer": {
        "type": "integer"
      },
      "reRequestApprove": {
        "type": "boolean"
      },
      "reRequestNoExport": {
        "type": "boolean"
      },
      "reRequestLimitSampleDate": {
        "type": "boolean"
      },
      "emailsBlock": {
        "type": "array"
      },
      "orderLocalization": {},
      "quickScanPdfMode": {
        "type": "integer"
      },
      "quickScanWalletMode": {
        "type": "integer"
      },
      "quickScanAskFirstName": {
        "type": "boolean"
      },
      "quickScanAskPassport": {
        "type": "boolean"
      },
      "logo": {
        "type": [
          "string",
          "null"
        ]
      },
      "fontColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "backgroundColor": {
        "type": [
          "string",
          "null"
        ]
      },
      "highlightColor": {
        "type": [
          "string",
          "null"
        ]
      }
    },
    "barcodeMode": {},
    "printPosition": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMin": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printRangeMax": {
      "maxLength": 150,
      "type": [
        "string",
        "null"
      ]
    },
    "printPrefix": {
      "maxLength": 10,
      "type": [
        "string",
        "null"
      ]
    },
    "useOwnNummernkreis": {
      "type": "boolean"
    },
    "useLgNummernkreis": {
      "type": "boolean"
    },
    "exportMe": {
      "type": "boolean"
    },
    "exportMeReceiver": {
      "type": [
        "string",
        "null"
      ]
    },
    "exportMeStatusString": {
      "type": "string"
    },
    "exportMeCharacteristicString": {
      "type": "string"
    },
    "exportMeSettingsStatusFlags": {
      "preliminary": {
        "type": "boolean"
      },
      "partial": {
        "type": "boolean"
      },
      "final": {
        "type": "boolean"
      }
    },
    "exportMeSettingsCharacteristicFlags": {
      "notPathological": {
        "type": "boolean"
      },
      "pathological": {
        "type": "boolean"
      },
      "extreme": {
        "type": "boolean"
      },
      "deltaCheck": {
        "type": "boolean"
      }
    },
    "pinnedRequirements": {
      "type": "array"
    }
  }
};

export const ProductPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "key": {
    "type": "string"
  },
  "forDoctors": {
    "type": "boolean"
  },
  "forPatients": {
    "type": "boolean"
  },
  "enabled": {
    "type": "boolean"
  },
  "doctorsEnabled": {
    "type": "boolean"
  },
  "patientsEnabled": {
    "type": "boolean"
  },
  "appEnabled": {
    "type": "boolean"
  },
  "explicitLock": {
    "type": "boolean"
  }
};

export const FeaturePropertiesSchema = {
  "key": {},
  "product": {},
  "forDoctors": {
    "type": "boolean"
  },
  "forPatients": {
    "type": "boolean"
  },
  "enabled": {
    "type": "boolean"
  },
  "doctorsSetting": {},
  "patientsSetting": {}
};

export const LanrPreferencePropertiesSchema = {
  "enabled": {
    "type": "boolean"
  },
  "doctors": {
    "type": "array"
  },
  "defaultLanr": {
    "type": "string"
  }
};

export const AidToGroupOfficeSchema = {
  "aid": {
    "type": "integer"
  },
  "assignment": {
    "type": "array"
  }
};

export const MaintenanceConfigPropertiesSchema = {
  "enabled": {
    "type": "boolean"
  },
  "title": {
    "type": "string"
  },
  "message": {
    "type": "string"
  }
};

export const BannerConfigPropertiesSchema = {
  "enabled": {
    "type": "boolean"
  },
  "message": {
    "type": "string"
  }
};

export const SharedPreferencePropertiesSchema = {
  "pref_name": {
    "type": "string"
  },
  "pref_value": {
    "type": [
      "string",
      "null"
    ]
  }
};

export const ReportsFavoriteAnalysesSchema = {
  "shortName": {
    "type": "string"
  }
};

export const OrderBarcodeMaterialFactorSchema = {
  "materialId": {
    "type": "integer"
  },
  "factor": {
    "type": "number"
  }
};

export const NotificationPreferencesPropertiesSchema = {
  "enabled": {
    "type": "boolean"
  },
  "mail": {
    "type": "boolean"
  },
  "push": {
    "type": "boolean"
  },
  "sms": {
    "type": "boolean"
  }
};

export const UserPreferencesPropertiesSchema = {
  "startProduct": {},
  "fontSize": {},
  "contrast": {}
};

export const NotificationsPreferencesPropertiesSchema = {
  "newExchangeFileNotification": {
    "enabled": {
      "type": "boolean"
    },
    "mail": {
      "type": "boolean"
    },
    "push": {
      "type": "boolean"
    },
    "sms": {
      "type": "boolean"
    }
  },
  "newReportNotification": {
    "enabled": {
      "type": "boolean"
    },
    "mail": {
      "type": "boolean"
    },
    "push": {
      "type": "boolean"
    },
    "sms": {
      "type": "boolean"
    }
  },
  "newReportNotificationSettings": {
    "characteristics": {
      "type": "array"
    },
    "status": {
      "type": "array"
    },
    "custom": {
      "type": "boolean"
    }
  }
};

export const ReportsPreferencesPropertiesSchema = {
  "reportsDefaultOrder": {},
  "reportsPatientNameSorting": {
    "type": "string"
  },
  "reportsPatientNameDisplayEnabled": {
    "type": "boolean"
  },
  "reportsPatientNameDisplay": {},
  "reportsDefaultFilterProfile": {
    "type": "integer"
  },
  "reportsFavoriteAnalyses": {
    "type": "array"
  },
  "reportsPinnedOnTop": {
    "type": "boolean"
  },
  "reportsInitialOpenCumulative": {
    "type": "boolean"
  },
  "reportsCumulativeMergeDays": {
    "type": "boolean"
  },
  "reportsCumulativeSingleReport": {
    "type": "boolean"
  },
  "reportsPdfDownloadAnalysesEnabledMode": {
    "type": "boolean"
  },
  "reportsPdfDownloadAnalyses": {
    "type": "array"
  },
  "reportsPdfDownloadPathoAnalyses": {
    "type": "array"
  },
  "enableAdvancedAnalysesFilter": {
    "type": "boolean"
  },
  "reportsBarcodeEnableCustomization": {
    "type": "boolean"
  },
  "reportsBarcodePrefixLength": {
    "type": "integer"
  },
  "reportsBarcodeLength": {
    "type": "integer"
  },
  "reportsBarcodeSuffixLength": {
    "type": "integer"
  },
  "reportsPatientsListShowInsuranceNumber": {
    "type": "boolean"
  },
  "reportsPatientsListShowPatientIdentification": {
    "type": "boolean"
  },
  "reportsPatientsListShowEmail": {
    "type": "boolean"
  },
  "reportsPatientsListShowBirthday": {
    "type": "boolean"
  },
  "reportsCustomSortBdwEnabled": {
    "type": "boolean"
  },
  "reportsCustomSortCumulativeEnabled": {
    "type": "boolean"
  },
  "reportsCustomSortCumulativeGroupsEnabled": {
    "type": "boolean"
  },
  "reportsDeltaCheckFilter": {
    "type": "boolean"
  },
  "reportsValueFollowsEnabled": {
    "type": "boolean"
  },
  "reportsValueFollowsText": {
    "type": "string"
  },
  "reportsValueFollowsHideNteEmpty": {
    "type": "boolean"
  }
};

export const OrdersPreferencesPropertiesSchema = {
  "ordersDetailDefaultGroupBy": {},
  "ordersDetailDefaultSortBy": {},
  "ordersRealCancellation": {
    "type": "boolean"
  },
  "ordersRealCancellationTimeRange": {
    "type": "integer"
  },
  "ordersRealCancellationTimeRangeInterval": {},
  "ordersShowPrePrint": {
    "type": "boolean"
  },
  "ordersPrePrintIsC2A": {
    "type": "boolean"
  },
  "ordersMaxPoolSize": {
    "type": "integer"
  },
  "ordersShowBeforeAfterDialysis": {
    "type": "boolean"
  },
  "ordersBeforeAfterDialysisMustBeSpecified": {
    "type": "boolean"
  },
  "ordersSplitClinical": {
    "type": "boolean"
  },
  "ordersSplitBak": {
    "type": "boolean"
  },
  "ordersScheduleTimeEnabled": {
    "type": "boolean"
  },
  "ordersScheduleTimeInterval": {
    "type": "integer"
  },
  "ordersScheduleTimeEnableDefault": {
    "type": "boolean"
  },
  "ordersScheduleTimeDefault": {
    "type": "string"
  },
  "ordersMultipleBakBarcodes": {
    "type": "boolean"
  },
  "ordersDefaultOrderMode": {},
  "ordersDefaultOrderModeEnabled": {
    "type": "boolean"
  },
  "ordersBookmarkedPoolMode": {
    "type": "boolean"
  },
  "ordersDefaultStatusInBookmarked": {},
  "ordersDefaultCompletionOrderBy": {},
  "ordersDefaultTreatmentReason": {},
  "ordersShowRoomNumber": {
    "type": "boolean"
  },
  "ordersHideDiagnosisFields": {
    "type": "boolean"
  },
  "ordersPreferScheduleDateMode": {
    "type": "boolean"
  },
  "ordersSampleInLaboratoryButton": {
    "type": "boolean"
  },
  "ordersSampleInLaboratoryButtonFirst": {
    "type": "boolean"
  },
  "ordersSampleDateTimeButton": {
    "type": "boolean"
  },
  "ordersAnamnesisShrinkMode": {
    "type": "boolean"
  },
  "ordersDynamicProbeTypeStaticReprint": {
    "type": "boolean"
  },
  "orderWizardStartFilters": {
    "type": "array"
  },
  "orderWizardCompactView": {
    "type": "boolean"
  },
  "orderWizardUltraCompactView": {
    "type": "boolean"
  },
  "orderWizardDisablePopovers": {
    "type": "boolean"
  },
  "orderWizardDisableTopRequests": {
    "type": "boolean"
  },
  "orderWizardEnableBasketPopovers": {
    "type": "boolean"
  },
  "orderWizardDisableAllPopovers": {
    "type": "boolean"
  },
  "orderWizardStartGroupForm": {
    "type": "array"
  },
  "orderWizardDefaultGroupForm": {
    "type": "object"
  },
  "orderWizardProfilesInGroupForm": {
    "type": "boolean"
  },
  "orderWizardTopRequestsInGroupForm": {
    "type": "boolean"
  },
  "orderWizardFavoritesInGroupForm": {
    "type": "boolean"
  },
  "orderWizardWrapPopoverHeader": {
    "type": "boolean"
  },
  "orderWizardUnwrapHeader": {
    "type": "boolean"
  },
  "orderWizardUnwrapListTitle": {
    "type": "boolean"
  },
  "orderWizardWiderColumns": {
    "type": "boolean"
  },
  "orderAutoRequirementHintRule": {
    "type": "boolean"
  },
  "orderWizardHideAdditionalInfo": {
    "type": "boolean"
  },
  "orderWizardPatientHideFindingsMedications": {
    "type": "boolean"
  },
  "orderWizardPatientTopBarFindingsMedications": {
    "type": "boolean"
  },
  "orderWizardNewOrderShowSelectPatient": {
    "type": "boolean"
  },
  "orderWizardShowCostUnitSelect": {
    "type": "boolean"
  },
  "orderWizardDisableMasonryView": {
    "type": "boolean"
  },
  "orderWizardHideInsuranceInInvoiceTo": {
    "type": "boolean"
  },
  "orderWizardDefaultReportCopySetting": {
    "type": [
      "string",
      "null"
    ]
  },
  "orderWizardDefaultInvoiceTo": {
    "type": "string"
  },
  "orderWizardPopoverInvoiceTo": {
    "type": "boolean"
  },
  "orderWizardPopoverHideAnalyses": {
    "type": "boolean"
  },
  "orderWizardReportsTimeRange": {
    "type": "integer"
  },
  "orderWizardReportsTimeRangeInterval": {},
  "orderWizardSearchDirectInput": {
    "type": "boolean"
  },
  "orderWizardBakModalBlockButtons": {
    "type": "boolean"
  },
  "orderWizardDisableHelgaImage": {
    "type": "boolean"
  },
  "orderWizardShowWorkstationSelect": {
    "type": "boolean"
  },
  "orderWizardHideOfflineWorkstations": {
    "type": "boolean"
  },
  "orderWizardAlwaysShowWorkstationSelect": {
    "type": "boolean"
  },
  "orderWizardRememberLastWorkstation": {
    "type": "boolean"
  },
  "orderWizardLastWorkstationId": {
    "type": "integer"
  },
  "orderWizardAutocompleteEntries": {
    "type": "integer"
  },
  "orderWizardBakColor": {
    "type": "string"
  },
  "orderWizardBakFontColor": {
    "type": "string"
  },
  "orderWizardHideBakMaterialGroupInfo": {
    "type": "boolean"
  },
  "orderWizardAlwaysShowBlocks": {
    "type": "boolean"
  },
  "orderWizardBlockSize": {
    "type": "integer"
  },
  "orderWizardDifferentBlockSizeOnHome": {
    "type": "boolean"
  },
  "orderWizardBlockSizeOnHome": {
    "type": "integer"
  },
  "orderWizardCopyToPatientActivationSwitch": {
    "type": "boolean"
  },
  "orderWizardSeparateInvoiceTo": {
    "type": "boolean"
  },
  "orderBarcodeFactorDefault": {
    "type": "number"
  },
  "orderBarcodeMaterialFactor": {
    "type": "array"
  },
  "orderBarcodeMaterialFactorShowOriginalCount": {
    "type": "boolean"
  },
  "orderSortPoolPatientsByName": {
    "type": "boolean"
  },
  "orderSortPatientsDropdownByName": {
    "type": "boolean"
  },
  "orderWizardSubRequirementsInBasket": {
    "type": "boolean"
  },
  "orderWizardInfectiousSwitch": {
    "type": "boolean"
  },
  "orderWizardPregnancySwitch": {
    "type": "boolean"
  },
  "orderWizardCheckupSwitch": {
    "type": "boolean"
  },
  "orderWizardEnableCreateEditProfiles": {
    "type": "boolean"
  },
  "orderWizardProfilesDefaultToAllOthers": {
    "type": "boolean"
  },
  "orderWizardProfilesSetDiagnoseText": {
    "type": "boolean"
  },
  "orderWizardProfilesSetFreeText": {
    "type": "boolean"
  },
  "orderWizardShowExttnr": {
    "type": "boolean"
  },
  "orderWizardShowExttnrLastUnderline": {
    "type": "boolean"
  },
  "orderWizardShowUrgentSwitch": {
    "type": "boolean"
  },
  "orderWizardShowEmergencySwitch": {
    "type": "boolean"
  },
  "orderWizardShowAccidentSwitch": {
    "type": "boolean"
  },
  "orderWizardFillSampleDateFromScheduleDate": {
    "type": "boolean"
  },
  "orderWizardOpenDuplicatePatientOrdersModal": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeCall": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeFax": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeEmail": {
    "type": "boolean"
  },
  "orderWizardChTreatmentReasonDropdown": {
    "type": "boolean"
  },
  "orderWizardInsuranceNumberMask": {
    "type": "string"
  },
  "orderWizardShowProfileAlreadyInBasketWarning": {
    "type": "boolean"
  },
  "orderWizardReportValueAllAnalyses": {
    "type": "boolean"
  },
  "orderMultiHeaderMoreInfo": {
    "type": "boolean"
  },
  "orderWizardAdditionalTextInPopover": {
    "type": "boolean"
  },
  "orderWizardAdditionalTextInCompactView": {
    "type": "boolean"
  }
};

export const CasesPreferencesPropertiesSchema = {
  "casesOrderListOpened": {
    "type": "boolean"
  },
  "casesDisabledReportLaboratoryGroups": {
    "type": "array"
  }
};

export const StorePreferencesPropertiesSchema = {
  "storeOrderNumberCurrent": {
    "type": "number"
  },
  "storeOrderNumberFormat": {
    "type": "string"
  }
};

export const PreferencesPropertiesSchema = {
  "startProduct": {},
  "fontSize": {},
  "contrast": {},
  "newExchangeFileNotification": {
    "enabled": {
      "type": "boolean"
    },
    "mail": {
      "type": "boolean"
    },
    "push": {
      "type": "boolean"
    },
    "sms": {
      "type": "boolean"
    }
  },
  "newReportNotification": {
    "enabled": {
      "type": "boolean"
    },
    "mail": {
      "type": "boolean"
    },
    "push": {
      "type": "boolean"
    },
    "sms": {
      "type": "boolean"
    }
  },
  "newReportNotificationSettings": {
    "characteristics": {
      "type": "array"
    },
    "status": {
      "type": "array"
    },
    "custom": {
      "type": "boolean"
    }
  },
  "reportsDefaultOrder": {},
  "reportsPatientNameSorting": {
    "type": "string"
  },
  "reportsPatientNameDisplayEnabled": {
    "type": "boolean"
  },
  "reportsPatientNameDisplay": {},
  "reportsDefaultFilterProfile": {
    "type": "integer"
  },
  "reportsFavoriteAnalyses": {
    "type": "array"
  },
  "reportsPinnedOnTop": {
    "type": "boolean"
  },
  "reportsInitialOpenCumulative": {
    "type": "boolean"
  },
  "reportsCumulativeMergeDays": {
    "type": "boolean"
  },
  "reportsCumulativeSingleReport": {
    "type": "boolean"
  },
  "reportsPdfDownloadAnalysesEnabledMode": {
    "type": "boolean"
  },
  "reportsPdfDownloadAnalyses": {
    "type": "array"
  },
  "reportsPdfDownloadPathoAnalyses": {
    "type": "array"
  },
  "enableAdvancedAnalysesFilter": {
    "type": "boolean"
  },
  "reportsBarcodeEnableCustomization": {
    "type": "boolean"
  },
  "reportsBarcodePrefixLength": {
    "type": "integer"
  },
  "reportsBarcodeLength": {
    "type": "integer"
  },
  "reportsBarcodeSuffixLength": {
    "type": "integer"
  },
  "reportsPatientsListShowInsuranceNumber": {
    "type": "boolean"
  },
  "reportsPatientsListShowPatientIdentification": {
    "type": "boolean"
  },
  "reportsPatientsListShowEmail": {
    "type": "boolean"
  },
  "reportsPatientsListShowBirthday": {
    "type": "boolean"
  },
  "reportsCustomSortBdwEnabled": {
    "type": "boolean"
  },
  "reportsCustomSortCumulativeEnabled": {
    "type": "boolean"
  },
  "reportsCustomSortCumulativeGroupsEnabled": {
    "type": "boolean"
  },
  "reportsDeltaCheckFilter": {
    "type": "boolean"
  },
  "reportsValueFollowsEnabled": {
    "type": "boolean"
  },
  "reportsValueFollowsText": {
    "type": "string"
  },
  "reportsValueFollowsHideNteEmpty": {
    "type": "boolean"
  },
  "ordersDetailDefaultGroupBy": {},
  "ordersDetailDefaultSortBy": {},
  "ordersRealCancellation": {
    "type": "boolean"
  },
  "ordersRealCancellationTimeRange": {
    "type": "integer"
  },
  "ordersRealCancellationTimeRangeInterval": {},
  "ordersShowPrePrint": {
    "type": "boolean"
  },
  "ordersPrePrintIsC2A": {
    "type": "boolean"
  },
  "ordersMaxPoolSize": {
    "type": "integer"
  },
  "ordersShowBeforeAfterDialysis": {
    "type": "boolean"
  },
  "ordersBeforeAfterDialysisMustBeSpecified": {
    "type": "boolean"
  },
  "ordersSplitClinical": {
    "type": "boolean"
  },
  "ordersSplitBak": {
    "type": "boolean"
  },
  "ordersScheduleTimeEnabled": {
    "type": "boolean"
  },
  "ordersScheduleTimeInterval": {
    "type": "integer"
  },
  "ordersScheduleTimeEnableDefault": {
    "type": "boolean"
  },
  "ordersScheduleTimeDefault": {
    "type": "string"
  },
  "ordersMultipleBakBarcodes": {
    "type": "boolean"
  },
  "ordersDefaultOrderMode": {},
  "ordersDefaultOrderModeEnabled": {
    "type": "boolean"
  },
  "ordersBookmarkedPoolMode": {
    "type": "boolean"
  },
  "ordersDefaultStatusInBookmarked": {},
  "ordersDefaultCompletionOrderBy": {},
  "ordersDefaultTreatmentReason": {},
  "ordersShowRoomNumber": {
    "type": "boolean"
  },
  "ordersHideDiagnosisFields": {
    "type": "boolean"
  },
  "ordersPreferScheduleDateMode": {
    "type": "boolean"
  },
  "ordersSampleInLaboratoryButton": {
    "type": "boolean"
  },
  "ordersSampleInLaboratoryButtonFirst": {
    "type": "boolean"
  },
  "ordersSampleDateTimeButton": {
    "type": "boolean"
  },
  "ordersAnamnesisShrinkMode": {
    "type": "boolean"
  },
  "ordersDynamicProbeTypeStaticReprint": {
    "type": "boolean"
  },
  "orderWizardStartFilters": {
    "type": "array"
  },
  "orderWizardCompactView": {
    "type": "boolean"
  },
  "orderWizardUltraCompactView": {
    "type": "boolean"
  },
  "orderWizardDisablePopovers": {
    "type": "boolean"
  },
  "orderWizardDisableTopRequests": {
    "type": "boolean"
  },
  "orderWizardEnableBasketPopovers": {
    "type": "boolean"
  },
  "orderWizardDisableAllPopovers": {
    "type": "boolean"
  },
  "orderWizardStartGroupForm": {
    "type": "array"
  },
  "orderWizardDefaultGroupForm": {
    "type": "object"
  },
  "orderWizardProfilesInGroupForm": {
    "type": "boolean"
  },
  "orderWizardTopRequestsInGroupForm": {
    "type": "boolean"
  },
  "orderWizardFavoritesInGroupForm": {
    "type": "boolean"
  },
  "orderWizardWrapPopoverHeader": {
    "type": "boolean"
  },
  "orderWizardUnwrapHeader": {
    "type": "boolean"
  },
  "orderWizardUnwrapListTitle": {
    "type": "boolean"
  },
  "orderWizardWiderColumns": {
    "type": "boolean"
  },
  "orderAutoRequirementHintRule": {
    "type": "boolean"
  },
  "orderWizardHideAdditionalInfo": {
    "type": "boolean"
  },
  "orderWizardPatientHideFindingsMedications": {
    "type": "boolean"
  },
  "orderWizardPatientTopBarFindingsMedications": {
    "type": "boolean"
  },
  "orderWizardNewOrderShowSelectPatient": {
    "type": "boolean"
  },
  "orderWizardShowCostUnitSelect": {
    "type": "boolean"
  },
  "orderWizardDisableMasonryView": {
    "type": "boolean"
  },
  "orderWizardHideInsuranceInInvoiceTo": {
    "type": "boolean"
  },
  "orderWizardDefaultReportCopySetting": {
    "type": [
      "string",
      "null"
    ]
  },
  "orderWizardDefaultInvoiceTo": {
    "type": "string"
  },
  "orderWizardPopoverInvoiceTo": {
    "type": "boolean"
  },
  "orderWizardPopoverHideAnalyses": {
    "type": "boolean"
  },
  "orderWizardReportsTimeRange": {
    "type": "integer"
  },
  "orderWizardReportsTimeRangeInterval": {},
  "orderWizardSearchDirectInput": {
    "type": "boolean"
  },
  "orderWizardBakModalBlockButtons": {
    "type": "boolean"
  },
  "orderWizardDisableHelgaImage": {
    "type": "boolean"
  },
  "orderWizardShowWorkstationSelect": {
    "type": "boolean"
  },
  "orderWizardHideOfflineWorkstations": {
    "type": "boolean"
  },
  "orderWizardAlwaysShowWorkstationSelect": {
    "type": "boolean"
  },
  "orderWizardRememberLastWorkstation": {
    "type": "boolean"
  },
  "orderWizardLastWorkstationId": {
    "type": "integer"
  },
  "orderWizardAutocompleteEntries": {
    "type": "integer"
  },
  "orderWizardBakColor": {
    "type": "string"
  },
  "orderWizardBakFontColor": {
    "type": "string"
  },
  "orderWizardHideBakMaterialGroupInfo": {
    "type": "boolean"
  },
  "orderWizardAlwaysShowBlocks": {
    "type": "boolean"
  },
  "orderWizardBlockSize": {
    "type": "integer"
  },
  "orderWizardDifferentBlockSizeOnHome": {
    "type": "boolean"
  },
  "orderWizardBlockSizeOnHome": {
    "type": "integer"
  },
  "orderWizardCopyToPatientActivationSwitch": {
    "type": "boolean"
  },
  "orderWizardSeparateInvoiceTo": {
    "type": "boolean"
  },
  "orderBarcodeFactorDefault": {
    "type": "number"
  },
  "orderBarcodeMaterialFactor": {
    "type": "array"
  },
  "orderBarcodeMaterialFactorShowOriginalCount": {
    "type": "boolean"
  },
  "orderSortPoolPatientsByName": {
    "type": "boolean"
  },
  "orderSortPatientsDropdownByName": {
    "type": "boolean"
  },
  "orderWizardSubRequirementsInBasket": {
    "type": "boolean"
  },
  "orderWizardInfectiousSwitch": {
    "type": "boolean"
  },
  "orderWizardPregnancySwitch": {
    "type": "boolean"
  },
  "orderWizardCheckupSwitch": {
    "type": "boolean"
  },
  "orderWizardEnableCreateEditProfiles": {
    "type": "boolean"
  },
  "orderWizardProfilesDefaultToAllOthers": {
    "type": "boolean"
  },
  "orderWizardProfilesSetDiagnoseText": {
    "type": "boolean"
  },
  "orderWizardProfilesSetFreeText": {
    "type": "boolean"
  },
  "orderWizardShowExttnr": {
    "type": "boolean"
  },
  "orderWizardShowExttnrLastUnderline": {
    "type": "boolean"
  },
  "orderWizardShowUrgentSwitch": {
    "type": "boolean"
  },
  "orderWizardShowEmergencySwitch": {
    "type": "boolean"
  },
  "orderWizardShowAccidentSwitch": {
    "type": "boolean"
  },
  "orderWizardFillSampleDateFromScheduleDate": {
    "type": "boolean"
  },
  "orderWizardOpenDuplicatePatientOrdersModal": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeCall": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeFax": {
    "type": "boolean"
  },
  "orderWizardUrgentNotificationModeEmail": {
    "type": "boolean"
  },
  "orderWizardChTreatmentReasonDropdown": {
    "type": "boolean"
  },
  "orderWizardInsuranceNumberMask": {
    "type": "string"
  },
  "orderWizardShowProfileAlreadyInBasketWarning": {
    "type": "boolean"
  },
  "orderWizardReportValueAllAnalyses": {
    "type": "boolean"
  },
  "orderMultiHeaderMoreInfo": {
    "type": "boolean"
  },
  "orderWizardAdditionalTextInPopover": {
    "type": "boolean"
  },
  "orderWizardAdditionalTextInCompactView": {
    "type": "boolean"
  },
  "casesOrderListOpened": {
    "type": "boolean"
  },
  "casesDisabledReportLaboratoryGroups": {
    "type": "array"
  },
  "storeOrderNumberCurrent": {
    "type": "number"
  },
  "storeOrderNumberFormat": {
    "type": "string"
  }
};

export const DoctorCreatePropertiesSchema = {
  "email": {
    "maxLength": 200,
    "type": "string"
  },
  "externalId": {
    "maxLength": 60,
    "type": "string"
  },
  "salutation": {
    "maxLength": 100,
    "type": "string"
  },
  "title": {
    "maxLength": 100,
    "type": "string"
  },
  "lastName": {
    "maxLength": 100,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "smsNumber": {
    "maxLength": 20,
    "type": "string"
  }
};

export const LaboratoryPermissionsPropertiesSchema = {
  "permissionChangePasswordDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernameDoctor": {
    "type": "boolean"
  },
  "permissionDeleteReport": {
    "type": "boolean"
  },
  "permissionModifyDoctor": {
    "type": "boolean"
  },
  "permissionModifyPatient": {
    "type": "boolean"
  },
  "permissionReactivateDoctor": {
    "type": "boolean"
  },
  "permissionReactivatePatient": {
    "type": "boolean"
  },
  "permissionLockDoctor": {
    "type": "boolean"
  },
  "permissionLockPatient": {
    "type": "boolean"
  },
  "permissionDeactivateDoctor": {
    "type": "boolean"
  },
  "permissionDeactivatePatient": {
    "type": "boolean"
  },
  "permissionPasswordResetMailDoctor": {
    "type": "boolean"
  },
  "permissionPasswordResetMailPatient": {
    "type": "boolean"
  },
  "permissionAccessUserLogPatient": {
    "type": "boolean"
  },
  "permissionAccessUserlogDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernamePatient": {
    "type": "boolean"
  },
  "permissionChangePasswordPatient": {
    "type": "boolean"
  },
  "permissionDeleteDoctor": {
    "type": "boolean"
  },
  "permissionDeletePatient": {
    "type": "boolean"
  },
  "permissionActivateDoctor": {
    "type": "boolean"
  },
  "permissionActivatePatient": {
    "type": "boolean"
  },
  "permissionViewMails": {
    "type": "boolean"
  },
  "permissionViewasDoctor": {
    "type": "boolean"
  },
  "permissionViewasPatient": {
    "type": "boolean"
  },
  "permissionPushtest": {
    "type": "boolean"
  },
  "permissionViewReportprotocol": {
    "type": "boolean"
  }
};

export const LaboratoryUserPermissionsPropertiesSchema = {
  "permissionChangePasswordDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernameDoctor": {
    "type": "boolean"
  },
  "permissionDeleteReport": {
    "type": "boolean"
  },
  "permissionModifyDoctor": {
    "type": "boolean"
  },
  "permissionModifyPatient": {
    "type": "boolean"
  },
  "permissionReactivateDoctor": {
    "type": "boolean"
  },
  "permissionReactivatePatient": {
    "type": "boolean"
  },
  "permissionLockDoctor": {
    "type": "boolean"
  },
  "permissionLockPatient": {
    "type": "boolean"
  },
  "permissionDeactivateDoctor": {
    "type": "boolean"
  },
  "permissionDeactivatePatient": {
    "type": "boolean"
  },
  "permissionPasswordResetMailDoctor": {
    "type": "boolean"
  },
  "permissionPasswordResetMailPatient": {
    "type": "boolean"
  },
  "permissionAccessUserLogPatient": {
    "type": "boolean"
  },
  "permissionAccessUserlogDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernamePatient": {
    "type": "boolean"
  },
  "permissionChangePasswordPatient": {
    "type": "boolean"
  },
  "permissionDeleteDoctor": {
    "type": "boolean"
  },
  "permissionDeletePatient": {
    "type": "boolean"
  },
  "permissionActivateDoctor": {
    "type": "boolean"
  },
  "permissionActivatePatient": {
    "type": "boolean"
  },
  "permissionViewMails": {
    "type": "boolean"
  },
  "permissionViewasDoctor": {
    "type": "boolean"
  },
  "permissionViewasPatient": {
    "type": "boolean"
  },
  "permissionPushtest": {
    "type": "boolean"
  },
  "permissionViewReportprotocol": {
    "type": "boolean"
  }
};

export const UserPermissionsPropertiesSchema = {
  "permissionChangePasswordDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernameDoctor": {
    "type": "boolean"
  },
  "permissionDeleteReport": {
    "type": "boolean"
  },
  "permissionModifyDoctor": {
    "type": "boolean"
  },
  "permissionModifyPatient": {
    "type": "boolean"
  },
  "permissionReactivateDoctor": {
    "type": "boolean"
  },
  "permissionReactivatePatient": {
    "type": "boolean"
  },
  "permissionLockDoctor": {
    "type": "boolean"
  },
  "permissionLockPatient": {
    "type": "boolean"
  },
  "permissionDeactivateDoctor": {
    "type": "boolean"
  },
  "permissionDeactivatePatient": {
    "type": "boolean"
  },
  "permissionPasswordResetMailDoctor": {
    "type": "boolean"
  },
  "permissionPasswordResetMailPatient": {
    "type": "boolean"
  },
  "permissionAccessUserLogPatient": {
    "type": "boolean"
  },
  "permissionAccessUserlogDoctor": {
    "type": "boolean"
  },
  "permissionChangeUsernamePatient": {
    "type": "boolean"
  },
  "permissionChangePasswordPatient": {
    "type": "boolean"
  },
  "permissionDeleteDoctor": {
    "type": "boolean"
  },
  "permissionDeletePatient": {
    "type": "boolean"
  },
  "permissionActivateDoctor": {
    "type": "boolean"
  },
  "permissionActivatePatient": {
    "type": "boolean"
  },
  "permissionViewMails": {
    "type": "boolean"
  },
  "permissionViewasDoctor": {
    "type": "boolean"
  },
  "permissionViewasPatient": {
    "type": "boolean"
  },
  "permissionPushtest": {
    "type": "boolean"
  },
  "permissionViewReportprotocol": {
    "type": "boolean"
  }
};

export const DoctorGroupMembershipPropertiesSchema = {
  "name": {
    "maxLength": 200,
    "type": "string"
  },
  "aid": {
    "type": "integer"
  },
  "gid": {
    "type": "integer"
  },
  "lid": {
    "type": "integer"
  },
  "isAdmin": {
    "type": "boolean"
  },
  "isMember": {
    "type": "boolean"
  },
  "bengrp": {
    "type": "boolean"
  }
};

export const DoctorHierarchyGroupPropertiesSchema = {
  "owner": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "parent": {
      "type": [
        "integer",
        "null"
      ]
    },
    "type": {},
    "created_at": {
      "type": "string"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "owner": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "name": {
      "type": "string"
    }
  },
  "descendants": {
    "type": "array"
  }
};

export const DoctorHierarchyPathDetailPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "type": {},
  "name": {
    "type": "string"
  }
};

export const DoctorHierarchyPathPropertiesSchema = {
  "path": {
    "type": "array"
  }
};

export const PatientMetaPropertiesSchema = {
  "lastName": {
    "maxLength": 50,
    "type": "string"
  },
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "suffix": {
    "maxLength": 100,
    "type": "string"
  },
  "title": {
    "maxLength": 50,
    "type": "string"
  },
  "gender": {},
  "birthday": {
    "maxLength": 50,
    "type": "string"
  },
  "city": {
    "maxLength": 50,
    "type": "string"
  },
  "zipCode": {
    "maxLength": 50,
    "type": "string"
  },
  "address": {
    "maxLength": 50,
    "type": "string"
  },
  "houseNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "addressAdditional": {
    "maxLength": 50,
    "type": "string"
  },
  "country": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 100,
    "type": "string"
  },
  "email": {
    "maxLength": 200,
    "type": "string"
  },
  "phone": {
    "maxLength": 50,
    "type": "string"
  },
  "costUnit": {
    "type": "string"
  }
};

export const UserEntityPropertiesSchema = {
  "kennung": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "passwort": {
    "type": [
      "string",
      "null"
    ]
  },
  "status": {},
  "displayName": {
    "type": "string"
  },
  "salutation": {
    "type": "string"
  },
  "email": {
    "type": "string"
  },
  "lastlang": {
    "type": "string"
  },
  "locale": {},
  "userDebugEnabled": {
    "type": "boolean"
  }
};

export const UserLogPropertiesSchema = {
  "id": {
    "type": "integer"
  },
  "xuser_id": {
    "type": "integer"
  },
  "user_agent_id": {
    "type": "integer"
  },
  "user_agent": {
    "type": "string"
  },
  "user_name": {
    "type": "string"
  },
  "lid": {
    "type": "integer"
  },
  "info": {
    "type": "string"
  },
  "ip": {
    "type": "string"
  },
  "created_at": {
    "type": "string"
  }
};

export const UserProductsPropertiesSchema = {
  "oa": {
    "type": "boolean"
  },
  "ob": {
    "type": "boolean"
  },
  "of": {
    "type": "boolean"
  },
  "ll": {
    "type": "boolean"
  },
  "le": {
    "type": "boolean"
  },
  "ls": {
    "type": "boolean"
  },
  "lc": {
    "type": "boolean"
  },
  "dd": {
    "type": "boolean"
  },
  "mc": {
    "type": "boolean"
  },
  "nc": {
    "type": "boolean"
  },
  "lw": {
    "type": "boolean"
  },
  "admin": {
    "type": "boolean"
  },
  "dashboard": {
    "type": "boolean"
  },
  "features": {
    "type": "object"
  }
};

export const UserProfilePropertiesSchema = {
  "email": {
    "type": "string"
  },
  "smsNumber": {
    "type": "string"
  },
  "www": {
    "type": "string"
  }
};

export const MvzMartinsriedHealthCertificateApiVoucherCreatePropertiesSchema = {
  "vouchers": {
    "type": "array"
  }
};

export const MvzMartinsriedVoucherSchema = {
  "voucherCode": {
    "maxLength": 64,
    "type": "string"
  },
  "purpose": {},
  "operator": {
    "maxLength": 16,
    "type": "string"
  },
  "functionGroup": {
    "maxLength": 16,
    "type": "string"
  }
};

export const KbvFormContentSchema = {
  "freeText": {
    "maxLength": 60,
    "type": "string"
  },
  "message": {
    "maxLength": 100,
    "type": "string"
  },
  "infectious": {
    "type": "boolean"
  },
  "urgent": {
    "type": "string"
  },
  "pregnancy": {
    "type": "boolean"
  },
  "checkup": {
    "type": "boolean"
  },
  "costUnit": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceName": {
    "maxLength": 250,
    "type": "string"
  },
  "controlCheckupKnownInfection": {
    "type": "boolean"
  },
  "dz116b": {
    "type": [
      "boolean",
      "null"
    ]
  },
  "dz16b": {
    "type": "boolean"
  },
  "accident": {
    "type": "boolean"
  },
  "accidentTime": {
    "maxLength": 20,
    "type": "string"
  },
  "documentReferenceNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "anamnesticInfo": {
    "maxLength": 60,
    "type": "string"
  },
  "printedMaterials": {
    "type": [
      "string",
      "null"
    ]
  },
  "printQuickReportBarcode": {
    "type": "boolean"
  },
  "urgentNotificationPhone": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationFax": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "urgentNotificationMail": {
    "maxLength": 50,
    "type": [
      "string",
      "null"
    ]
  },
  "patient": {
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "suffix": {
      "maxLength": 50,
      "type": "string"
    },
    "title": {
      "maxLength": 50,
      "type": "string"
    },
    "gender": {},
    "birthday": {
      "maxLength": 50,
      "type": "string"
    },
    "city": {
      "maxLength": 50,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 50,
      "type": "string"
    },
    "address": {
      "maxLength": 150,
      "type": "string"
    },
    "houseNumber": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "addressAdditional": {
      "maxLength": 50,
      "type": "string"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "email": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "phone": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "costUnit": {
      "type": "string"
    },
    "medication": {
      "maxLength": 100,
      "type": "string"
    },
    "cycleDay": {
      "maxLength": 10,
      "type": "string"
    },
    "weight": {
      "maxLength": 5,
      "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "diagnosis": {
      "maxLength": 150,
      "type": "string"
    },
    "exttnr": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "primaryCarePhysicianLanr": {
      "maxLength": 20,
      "type": "string"
    },
    "primaryCarePhysicianBsnr": {
      "maxLength": 20,
      "type": "string"
    },
    "diseaseManagementProgram": {
      "maxLength": 50,
      "type": "string"
    },
    "dmpSpecialGroup": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceStart": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceEnd": {
      "maxLength": 50,
      "type": "string"
    },
    "insuranceType": {
      "maxLength": 50,
      "type": "string"
    },
    "exceptionalIndication": {
      "maxLength": 20,
      "type": "string"
    },
    "sktIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "wopIdentifier": {
      "maxLength": 50,
      "type": "string"
    },
    "accountingArea": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "hvcode": {
      "maxLength": 20,
      "type": "string"
    },
    "vkat": {
      "maxLength": 20,
      "type": "string"
    },
    "secondName": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "canton": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "ahvNr": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "aisNumber": {
      "maxLength": 100,
      "type": "string"
    },
    "insurance": {
      "type": [
        "number",
        "null"
      ]
    },
    "bag": {
      "maxLength": 5,
      "type": [
        "string",
        "null"
      ]
    },
    "cardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "patientIdentification": {
      "maxLength": 20,
      "type": "string"
    },
    "globalLocationNumber": {
      "maxLength": 13,
      "type": [
        "string",
        "null"
      ]
    },
    "chTreatmentReason": {
      "maxLength": 50,
      "type": [
        "string",
        "null"
      ]
    },
    "externalOrderInfo": {
      "maxLength": 256,
      "type": [
        "string",
        "null"
      ]
    },
    "hospitalBedNumber": {
      "type": "string"
    },
    "hospitalRoomNumber": {
      "type": "string"
    },
    "hospitalProfession": {
      "type": [
        "string",
        "null"
      ]
    },
    "caseId": {
      "type": [
        "integer",
        "null"
      ]
    },
    "caseNumber": {
      "type": "string"
    },
    "insuranceName": {
      "maxLength": 250,
      "type": "string"
    },
    "invoiceTo": {
      "maxLength": 25,
      "type": "string"
    },
    "dz116b": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "autInsuranceTitle": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceFirstName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceLastName": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceInsuranceNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceBirthday": {
      "type": "string"
    },
    "autInsuranceAddress": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceHouseNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCountry": {
      "maxLength": 10,
      "type": "string"
    },
    "autInsuranceZip": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceCity": {
      "maxLength": 50,
      "type": "string"
    },
    "pid": {
      "type": "integer"
    },
    "apgid": {
      "type": [
        "integer",
        "null"
      ]
    },
    "displayName": {
      "type": "string"
    },
    "prefix": {
      "maxLength": 50,
      "type": "string"
    },
    "height": {
      "maxLength": 5,
      "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
      "type": "string"
    },
    "pregnancyWeek": {
      "maxLength": 2,
      "pattern": "^(\\d{1,2})?$",
      "type": "string"
    },
    "pregnancyWeekDay": {
      "maxLength": 1,
      "pattern": "^(\\d{1})?$",
      "type": "string"
    },
    "cycleLength": {
      "maxLength": 3,
      "type": [
        "string",
        "null"
      ]
    },
    "dayOfCycle": {
      "maxLength": 2,
      "type": "string"
    },
    "fetalCrownRumpLength": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "fetalNuchalTranslucency": {
      "maxLength": 7,
      "type": [
        "string",
        "null"
      ]
    },
    "hasInsuranceNumber": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "sampleAmount": {
      "maxLength": 10,
      "type": "string"
    },
    "samplePeriod": {
      "maxLength": 10,
      "type": "string"
    },
    "sampleDate": {
      "type": "string"
    },
    "sampleTime": {
      "maxLength": 8,
      "type": "string"
    },
    "passportNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "idcardNumber": {
      "maxLength": 20,
      "type": [
        "string",
        "null"
      ]
    },
    "orderReason": {
      "maxLength": 1,
      "type": "string"
    },
    "costUnitIdentification": {
      "maxLength": 25,
      "type": "string"
    },
    "autInsuranceEmployer": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceEmployerCity": {
      "maxLength": 50,
      "type": "string"
    },
    "autInsuranceAddressAdditional": {
      "maxLength": 50,
      "type": "string"
    }
  },
  "covid": {
    "testNumber": {},
    "testReason": {},
    "agreed": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "riskCharacteristicsLocation": {},
    "riskCharacteristicsLocationType": {},
    "legalReason": {},
    "healthServiceZipCode": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "healthService": {
      "maxLength": 255,
      "type": [
        "string",
        "null"
      ]
    },
    "legalOrderBasis": {},
    "kvSpecial": {
      "type": [
        "string",
        "null"
      ]
    },
    "confirmationDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "variantDiagnostics": {
      "type": [
        "boolean",
        "null"
      ]
    },
    "oegdInvoiceTo": {
      "maxLength": 25,
      "type": [
        "string",
        "null"
      ]
    },
    "covidWarnAppBarcode": {
      "type": [
        "boolean",
        "null"
      ]
    }
  },
  "kbvFormType": {}
};

export const PaymentInstructionsSchema = {
  "sessionId": {
    "type": "string"
  },
  "publicKey": {
    "type": "string"
  }
};

export const PreparePaymentPropertiesSchema = {
  "paymentInstructions": {
    "sessionId": {
      "type": "string"
    },
    "publicKey": {
      "type": "string"
    }
  },
  "status": {}
};

export const VoucherPaymentInputSchema = {
  "voucherCode": {
    "maxLength": 255,
    "type": "string"
  }
};

export const StripePaymentInputSchema = {
  "sessionId": {
    "type": "string"
  }
};

export const PaymentSpecificInputSchema = {
  "sessionId": {
    "type": "string"
  },
  "voucherCode": {
    "maxLength": 255,
    "type": "string"
  }
};

export const AdditionalPersonSchema = {
  "firstName": {
    "maxLength": 50,
    "type": "string"
  },
  "lastName": {
    "maxLength": 50,
    "type": "string"
  },
  "dob": {
    "maxLength": 8,
    "type": "string"
  },
  "sex": {
    "type": "integer"
  }
};

export const WebshopOrderPropertiesSchema = {
  "basicOrder": {
    "id": {
      "type": "integer"
    },
    "email": {
      "maxLength": 50,
      "type": "string"
    },
    "firstName": {
      "maxLength": 50,
      "type": "string"
    },
    "lastName": {
      "maxLength": 50,
      "type": "string"
    },
    "dob": {
      "maxLength": 8,
      "type": "string"
    },
    "address": {
      "maxLength": 150,
      "type": "string"
    },
    "zipCode": {
      "maxLength": 5,
      "type": "string"
    },
    "city": {
      "maxLength": 150,
      "type": "string"
    },
    "sex": {
      "type": "integer"
    },
    "order": {
      "lid": {
        "type": "integer"
      },
      "isPool": {
        "type": "boolean"
      },
      "isPoct": {
        "type": "boolean"
      },
      "isSoftDeleted": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "freeText": {
        "maxLength": 60,
        "type": "string"
      },
      "message": {
        "maxLength": 100,
        "type": "string"
      },
      "infectious": {
        "type": "boolean"
      },
      "urgent": {
        "type": "string"
      },
      "pregnancy": {
        "type": "boolean"
      },
      "checkup": {
        "type": "boolean"
      },
      "costUnit": {
        "maxLength": 50,
        "type": "string"
      },
      "insuranceName": {
        "maxLength": 250,
        "type": "string"
      },
      "controlCheckupKnownInfection": {
        "type": "boolean"
      },
      "dz116b": {
        "type": [
          "boolean",
          "null"
        ]
      },
      "dz16b": {
        "type": "boolean"
      },
      "accident": {
        "type": "boolean"
      },
      "accidentTime": {
        "maxLength": 20,
        "type": "string"
      },
      "documentReferenceNumber": {
        "maxLength": 20,
        "type": "string"
      },
      "anamnesticInfo": {
        "maxLength": 60,
        "type": "string"
      },
      "printedMaterials": {
        "type": [
          "string",
          "null"
        ]
      },
      "printQuickReportBarcode": {
        "type": "boolean"
      },
      "urgentNotificationPhone": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationFax": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "urgentNotificationMail": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "id": {
        "type": "integer"
      },
      "status": {},
      "executionStatus": {},
      "poolId": {
        "type": "integer"
      },
      "aid": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "type": "string"
      },
      "bsnr": {
        "type": "string"
      },
      "selectedDoctor": {
        "type": "string"
      },
      "apid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "patient": {
        "lastName": {
          "maxLength": 50,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": "string"
        },
        "suffix": {
          "maxLength": 50,
          "type": "string"
        },
        "title": {
          "maxLength": 50,
          "type": "string"
        },
        "gender": {},
        "birthday": {
          "maxLength": 50,
          "type": "string"
        },
        "city": {
          "maxLength": 50,
          "type": "string"
        },
        "zipCode": {
          "maxLength": 50,
          "type": "string"
        },
        "address": {
          "maxLength": 150,
          "type": "string"
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "addressAdditional": {
          "maxLength": 50,
          "type": "string"
        },
        "country": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "email": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "costUnit": {
          "type": "string"
        },
        "medication": {
          "maxLength": 100,
          "type": "string"
        },
        "cycleDay": {
          "maxLength": 10,
          "type": "string"
        },
        "weight": {
          "maxLength": 5,
          "pattern": "^(\\d{1,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 150,
          "type": "string"
        },
        "exttnr": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "primaryCarePhysicianLanr": {
          "maxLength": 20,
          "type": "string"
        },
        "primaryCarePhysicianBsnr": {
          "maxLength": 20,
          "type": "string"
        },
        "diseaseManagementProgram": {
          "maxLength": 50,
          "type": "string"
        },
        "dmpSpecialGroup": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceStart": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceEnd": {
          "maxLength": 50,
          "type": "string"
        },
        "insuranceType": {
          "maxLength": 50,
          "type": "string"
        },
        "exceptionalIndication": {
          "maxLength": 20,
          "type": "string"
        },
        "sktIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "wopIdentifier": {
          "maxLength": 50,
          "type": "string"
        },
        "accountingArea": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "hvcode": {
          "maxLength": 20,
          "type": "string"
        },
        "vkat": {
          "maxLength": 20,
          "type": "string"
        },
        "secondName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "canton": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "ahvNr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "aisNumber": {
          "maxLength": 100,
          "type": "string"
        },
        "insurance": {
          "type": [
            "number",
            "null"
          ]
        },
        "bag": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "cardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "patientIdentification": {
          "maxLength": 20,
          "type": "string"
        },
        "globalLocationNumber": {
          "maxLength": 13,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "externalOrderInfo": {
          "maxLength": 256,
          "type": [
            "string",
            "null"
          ]
        },
        "hospitalBedNumber": {
          "type": "string"
        },
        "hospitalRoomNumber": {
          "type": "string"
        },
        "hospitalProfession": {
          "type": [
            "string",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceName": {
          "maxLength": 250,
          "type": "string"
        },
        "invoiceTo": {
          "maxLength": 25,
          "type": "string"
        },
        "dz116b": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "autInsuranceTitle": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceFirstName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceLastName": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceInsuranceNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceBirthday": {
          "type": "string"
        },
        "autInsuranceAddress": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceHouseNumber": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCountry": {
          "maxLength": 10,
          "type": "string"
        },
        "autInsuranceZip": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceCity": {
          "maxLength": 50,
          "type": "string"
        },
        "pid": {
          "type": "integer"
        },
        "apgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "displayName": {
          "type": "string"
        },
        "prefix": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 5,
          "pattern": "^(\\d{2,3}([\\.,]\\d)?)?$",
          "type": "string"
        },
        "pregnancyWeek": {
          "maxLength": 2,
          "pattern": "^(\\d{1,2})?$",
          "type": "string"
        },
        "pregnancyWeekDay": {
          "maxLength": 1,
          "pattern": "^(\\d{1})?$",
          "type": "string"
        },
        "cycleLength": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        },
        "dayOfCycle": {
          "maxLength": 2,
          "type": "string"
        },
        "fetalCrownRumpLength": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "fetalNuchalTranslucency": {
          "maxLength": 7,
          "type": [
            "string",
            "null"
          ]
        },
        "hasInsuranceNumber": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "sampleAmount": {
          "maxLength": 10,
          "type": "string"
        },
        "samplePeriod": {
          "maxLength": 10,
          "type": "string"
        },
        "sampleDate": {
          "type": "string"
        },
        "sampleTime": {
          "maxLength": 8,
          "type": "string"
        },
        "passportNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "idcardNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "orderReason": {
          "maxLength": 1,
          "type": "string"
        },
        "costUnitIdentification": {
          "maxLength": 25,
          "type": "string"
        },
        "autInsuranceEmployer": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceEmployerCity": {
          "maxLength": 50,
          "type": "string"
        },
        "autInsuranceAddressAdditional": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "requirements": {
        "type": "array"
      },
      "anamnesis": {
        "type": "array"
      },
      "animal": {
        "name": {
          "maxLength": 50,
          "type": "string"
        },
        "birthday": {
          "maxLength": 8,
          "type": "string"
        },
        "species": {
          "maxLength": 2,
          "type": "string"
        },
        "speciesManual": {
          "maxLength": 50,
          "type": "string"
        },
        "chipId": {
          "maxLength": 50,
          "type": "string"
        },
        "height": {
          "maxLength": 3,
          "type": "string"
        },
        "weight": {
          "maxLength": 3,
          "type": "string"
        },
        "sex": {
          "type": "number"
        },
        "breed": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerName": {
          "maxLength": 100,
          "type": "string"
        },
        "ownerAmendment": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerStreet": {
          "maxLength": 50,
          "type": "string"
        },
        "ownerHouseNumber": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerZipCode": {
          "maxLength": 10,
          "type": "string"
        },
        "ownerCity": {
          "maxLength": 50,
          "type": "string"
        }
      },
      "switzerland": {
        "billToOther1": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther2": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "billToOther3": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyTo": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToEmail": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToSalutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToFirstName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToLastName": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToStreet": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToZip": {
          "maxLength": 5,
          "type": [
            "string",
            "null"
          ]
        },
        "reportCopyToCity": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "notes": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "chTreatmentReason": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "accidentDate": {
          "maxLength": 8,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "hungary": {
        "motherLastName": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "motherFirstName": {
          "maxLength": 15,
          "type": [
            "string",
            "null"
          ]
        },
        "treatmentReason": {
          "maxLength": 1,
          "type": [
            "string",
            "null"
          ]
        },
        "invoice": {
          "maxLength": 2,
          "type": [
            "string",
            "null"
          ]
        },
        "icd1": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd2": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "icd3": {
          "maxLength": 6,
          "type": [
            "string",
            "null"
          ]
        },
        "insuranceNumber": {
          "maxLength": 18,
          "type": [
            "string",
            "null"
          ]
        },
        "citizen": {
          "maxLength": 3,
          "type": [
            "string",
            "null"
          ]
        }
      },
      "executed_at": {
        "type": "string"
      },
      "scheduled_at": {
        "type": [
          "string",
          "null"
        ]
      },
      "sampleDate": {
        "type": "string"
      },
      "sampleTime": {
        "maxLength": 8,
        "type": "string"
      },
      "covid": {
        "testNumber": {},
        "testReason": {},
        "agreed": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "riskCharacteristicsLocation": {},
        "riskCharacteristicsLocationType": {},
        "legalReason": {},
        "healthServiceZipCode": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "healthService": {
          "maxLength": 255,
          "type": [
            "string",
            "null"
          ]
        },
        "legalOrderBasis": {},
        "kvSpecial": {
          "type": [
            "string",
            "null"
          ]
        },
        "confirmationDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "variantDiagnostics": {
          "type": [
            "boolean",
            "null"
          ]
        },
        "oegdInvoiceTo": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "covidWarnAppBarcode": {
          "type": [
            "boolean",
            "null"
          ]
        }
      },
      "mustValidate": {
        "type": "boolean"
      },
      "hospitalStation": {
        "type": "string"
      },
      "requirementDiagnosisFreeText": {
        "maxLength": 150,
        "type": "string"
      },
      "externalOrderInfo": {
        "maxLength": 256,
        "type": [
          "string",
          "null"
        ]
      },
      "selectedDiagnoses": {
        "type": "object"
      },
      "selectedDiagnosesChecked": {
        "type": "boolean"
      },
      "workstationChecked": {
        "type": "boolean"
      },
      "splitProperties": {
        "type": "array"
      },
      "sample_in_lab": {
        "type": "boolean"
      },
      "clientname": {
        "type": "string"
      },
      "printerAliasName": {
        "type": "string"
      },
      "overridePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "overrideBarcodePrinterName": {
        "type": [
          "string",
          "null"
        ]
      },
      "beforeAfterDialysis": {},
      "reportCopyAid": {
        "type": [
          "number",
          "null"
        ]
      },
      "reportCopyDoctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "aisIdentifier": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "patientAisNumber": {
        "maxLength": 100,
        "type": "string"
      },
      "bloodCollectionType": {},
      "transported_at": {
        "type": "string"
      },
      "created_at": {
        "type": "string"
      },
      "digitalSignature": {},
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "report": {
        "bid": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "parent_bid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "caseId": {
          "type": [
            "integer",
            "null"
          ]
        },
        "status": {},
        "tnr": {
          "type": "string"
        },
        "pathological": {},
        "mibi": {},
        "created_at": {
          "type": "string"
        },
        "imported_at": {
          "type": "string"
        },
        "registered_at": {
          "type": "string"
        },
        "validated_at": {
          "type": "string"
        },
        "sampled_at": {
          "type": "string"
        },
        "diagnosis": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctor": {
          "maxLength": 100,
          "type": "string"
        },
        "validatingDoctorAddition": {
          "maxLength": 100,
          "type": "string"
        },
        "patientName": {
          "type": "string"
        },
        "is_locked": {
          "type": "boolean"
        },
        "is_unread": {
          "type": "boolean"
        },
        "is_pinned": {
          "type": "boolean"
        },
        "has_base64": {
          "type": "boolean"
        },
        "has_elpho": {
          "type": "boolean"
        },
        "has_subreports": {
          "type": "boolean"
        },
        "has_history": {
          "type": "boolean"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "patient": {
          "pid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 200,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "lastName": {
            "maxLength": 50,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": "string"
          },
          "suffix": {
            "maxLength": 100,
            "type": "string"
          },
          "title": {
            "maxLength": 50,
            "type": "string"
          },
          "gender": {},
          "birthday": {
            "maxLength": 50,
            "type": "string"
          },
          "city": {
            "maxLength": 50,
            "type": "string"
          },
          "zipCode": {
            "maxLength": 50,
            "type": "string"
          },
          "address": {
            "maxLength": 50,
            "type": "string"
          },
          "houseNumber": {
            "maxLength": 50,
            "type": "string"
          },
          "addressAdditional": {
            "maxLength": 50,
            "type": "string"
          },
          "country": {
            "maxLength": 50,
            "type": "string"
          },
          "insuranceNumber": {
            "maxLength": 100,
            "type": "string"
          },
          "phone": {
            "maxLength": 50,
            "type": "string"
          },
          "costUnit": {
            "type": "string"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "sonstiges1": {
            "maxLength": 50,
            "type": "string"
          },
          "sonstiges2": {
            "maxLength": 50,
            "type": "string"
          },
          "patientIdentification": {
            "maxLength": 20,
            "type": "string"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "smsNotificationEnabled": {
            "type": "boolean"
          },
          "smsNumber": {
            "type": "string"
          },
          "canViewPartialResults": {
            "type": "boolean"
          },
          "canViewPreliminaryResults": {
            "type": "boolean"
          },
          "resultLanguage": {
            "maxLength": 5,
            "type": "string"
          },
          "oa": {
            "type": "boolean"
          },
          "aids": {
            "type": "array"
          },
          "latest_report_date": {
            "type": "string"
          },
          "dateScore": {
            "type": "number"
          },
          "passportNumber": {
            "type": "string"
          },
          "idCardNumber": {
            "type": "string"
          }
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "laboratoryGroup": {
          "grpid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "kurz": {
            "maxLength": 20,
            "pattern": "^(?![Ll][Aa]$).*$",
            "type": "string"
          },
          "name": {
            "maxLength": 200,
            "type": "string"
          },
          "telefon": {
            "maxLength": 20,
            "type": "string"
          },
          "fax": {
            "maxLength": 20,
            "type": "string"
          },
          "website": {
            "maxLength": 50,
            "type": "string"
          },
          "adresse": {
            "maxLength": 50,
            "type": "string"
          },
          "stadt": {
            "maxLength": 50,
            "type": "string"
          },
          "email": {
            "maxLength": 50,
            "type": "string"
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": "string"
          },
          "zusatz1": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz2": {
            "maxLength": 200,
            "type": "string"
          },
          "zusatz3": {
            "maxLength": 200,
            "type": "string"
          },
          "plz": {
            "maxLength": 25,
            "type": "string"
          },
          "showPdfExport": {
            "type": "boolean"
          },
          "laboratoryGroupBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPrefix": {
              "maxLength": 10,
              "type": "string"
            },
            "printPosition": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMin": {
              "maxLength": 40,
              "type": "string"
            },
            "printRangeMax": {
              "maxLength": 40,
              "type": "string"
            }
          },
          "advancedAlphanumericBarcodeSettings": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "grpid": {
              "type": "integer"
            },
            "group": {
              "maxLength": 20,
              "type": "string"
            },
            "printPosition1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin1": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax1": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin2": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax2": {
              "maxLength": 3,
              "type": "string"
            },
            "printPosition3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMin3": {
              "maxLength": 3,
              "type": "string"
            },
            "printRangeMax3": {
              "maxLength": 3,
              "type": "string"
            },
            "useState": {
              "type": "object"
            }
          }
        },
        "laboratoryGroupFilter": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "maxLength": 32,
            "type": "string"
          },
          "color": {
            "maxLength": 8,
            "type": [
              "string",
              "null"
            ]
          },
          "group": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          }
        },
        "values": {
          "type": "array"
        },
        "subreports": {
          "type": "array"
        },
        "attachments": {
          "type": "array"
        },
        "attachment_count": {
          "type": "integer"
        },
        "comments": {
          "type": "array"
        },
        "comment_count": {
          "type": "integer"
        },
        "arzname": {
          "maxLength": 300,
          "type": "string"
        },
        "labname": {
          "type": "string"
        },
        "accountingType": {
          "type": "string"
        },
        "laborgruppe": {
          "type": "string"
        },
        "exttnr": {
          "type": "string"
        },
        "lebensnummer": {
          "type": "string"
        },
        "zimmer": {
          "type": "string"
        },
        "lastupdate": {
          "type": "string"
        },
        "orderComment": {
          "type": "string"
        },
        "overwritecolor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "pdfSrc": {
          "type": "string"
        },
        "material": {
          "type": "string"
        },
        "caseNumber": {
          "type": "string"
        },
        "insuranceNumber": {
          "type": "string"
        },
        "showBdw": {
          "type": "boolean"
        },
        "showCumulative": {
          "type": "boolean"
        },
        "showPdf": {
          "type": "boolean"
        },
        "showOriginal": {
          "type": "boolean"
        },
        "showOriginalExclusive": {
          "type": "boolean"
        },
        "showCumulativePdf": {
          "type": "boolean"
        },
        "showCumulativeAllPdf": {
          "type": "boolean"
        },
        "showSend": {
          "type": "boolean"
        },
        "showRerequest": {
          "type": "boolean"
        },
        "originalPdfIds": {
          "type": "array"
        },
        "guid": {
          "type": "string"
        },
        "externalGuid": {
          "type": "string"
        },
        "manualCode": {
          "type": "string"
        },
        "uuid": {
          "type": "string"
        },
        "settlementType": {}
      },
      "pool": {
        "id": {
          "type": "integer"
        },
        "tnr": {
          "type": "string"
        },
        "displayName": {
          "type": "string"
        },
        "status": {
          "type": "array"
        },
        "doctor": {
          "aid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "mtgid": {
            "type": [
              "integer",
              "null"
            ]
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "email": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "externalId": {
            "maxLength": 60,
            "type": [
              "string",
              "null"
            ]
          },
          "externalId2": {
            "type": "string"
          },
          "lanr": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "lastName": {
            "maxLength": 250,
            "type": "string"
          },
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "maxLength": 25,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "houseNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "countryCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "phoneNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "faxNumber": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "smsNumber": {
            "maxLength": 20,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "welcomeLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "logoutLabel": {
            "maxLength": 200,
            "type": [
              "string",
              "null"
            ]
          },
          "hierarchyStatus": {},
          "hierarchyAssignments": {
            "type": "array"
          },
          "hierarchyTree": {
            "type": "array"
          },
          "canActivatePatients": {
            "type": "boolean"
          },
          "isSuperDoctor": {
            "type": "boolean"
          },
          "disableViewDiagnosis": {
            "type": "boolean"
          },
          "emailNotificationEnabled": {
            "type": "boolean"
          },
          "emailNotificationsCharacteristics": {
            "type": "string"
          },
          "emailNotificationsStatus": {
            "type": "string"
          },
          "emailReports": {
            "type": "boolean"
          },
          "officeName": {
            "maxLength": 200,
            "type": "string"
          },
          "bsnr": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "localisation": {},
          "laboratoryLocalisation": {},
          "manufacturer": {
            "type": "string"
          },
          "urgent": {
            "type": "boolean"
          },
          "emergency": {
            "type": "boolean"
          },
          "infectious": {
            "type": "boolean"
          },
          "oaanswertrigger": {
            "type": "string"
          },
          "oaSoftDelete": {
            "type": "boolean"
          },
          "printMode": {
            "type": "integer"
          },
          "barcodePrintSettings": {
            "type": "integer"
          },
          "printername": {
            "maxLength": 200,
            "type": "string"
          },
          "scheinPrintSettings": {
            "type": "integer"
          },
          "printernameSchein": {
            "maxLength": 200,
            "type": "string"
          },
          "socketPrint": {
            "type": "boolean"
          },
          "internalPrintUser": {
            "type": "boolean"
          },
          "customDoctorHeading": {
            "type": "boolean"
          },
          "customDoctorHeadingLine1": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine2": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine3": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine4": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine5": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine6": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine7": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine8": {
            "maxLength": 50,
            "type": "string"
          },
          "customDoctorHeadingLine9": {
            "maxLength": 50,
            "type": "string"
          },
          "graphicalRequestChooser": {
            "type": "boolean"
          },
          "ipConstraint": {
            "type": "boolean"
          },
          "autoprint": {
            "type": "boolean"
          },
          "speciality": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "reportsLanguageSetting": {},
          "reportCount": {
            "type": "integer"
          },
          "forms": {
            "type": "array"
          },
          "profiles": {
            "type": "array"
          },
          "globalProfiles": {
            "type": "array"
          },
          "groups": {
            "type": "array"
          },
          "groupForms": {
            "type": "array"
          },
          "devices": {
            "type": "array"
          },
          "doctorGroupOffices": {
            "type": "array"
          },
          "doctorGroupOfficeName": {
            "type": "string"
          },
          "pid": {
            "type": "number"
          },
          "editKisMode": {
            "type": "boolean"
          },
          "laboratory": {
            "lid": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "maxLength": 50,
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "maxLength": 100,
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "oa": {
              "type": "boolean"
            },
            "ob": {
              "type": "boolean"
            },
            "of": {
              "type": "boolean"
            },
            "ll": {
              "type": "boolean"
            },
            "le": {
              "type": "boolean"
            },
            "ls": {
              "type": "boolean"
            },
            "lc": {
              "type": "boolean"
            },
            "dd": {
              "type": "boolean"
            },
            "mc": {
              "type": "boolean"
            },
            "nc": {
              "type": "boolean"
            },
            "lw": {
              "type": "boolean"
            },
            "admin": {
              "type": "boolean"
            },
            "dashboard": {
              "type": "boolean"
            },
            "features": {
              "type": "object"
            },
            "user": {
              "type": "object"
            },
            "hl7Name": {
              "type": "string"
            },
            "target": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "companyName": {
              "type": "string"
            },
            "address": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "city": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "phone": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "whatsapp": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "fax": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "www": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "rerequestMail": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "autoLockReport": {
              "type": "boolean"
            },
            "class0": {
              "type": "number"
            },
            "class1": {
              "type": "number"
            },
            "class2": {
              "type": "number"
            },
            "class3": {
              "type": "number"
            },
            "class4": {
              "type": "number"
            },
            "class5": {
              "type": "number"
            },
            "showPdf": {
              "type": "object"
            },
            "showOriginal": {
              "type": "object"
            },
            "showBdw": {
              "type": "object"
            },
            "firstName": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "title": {
              "type": [
                "string",
                "null"
              ]
            },
            "suffix": {
              "type": [
                "string",
                "null"
              ]
            },
            "loginSite": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "additional1": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional2": {
              "type": [
                "string",
                "null"
              ]
            },
            "additional3": {
              "type": [
                "string",
                "null"
              ]
            },
            "zipCode": {
              "type": [
                "string",
                "null"
              ]
            },
            "contactInfo": {
              "type": [
                "string",
                "null"
              ]
            },
            "verifyIKs": {
              "type": "boolean"
            },
            "displayText": {
              "maxLength": 100,
              "type": [
                "string",
                "null"
              ]
            },
            "concurrentLoginLimit": {
              "type": "integer"
            },
            "barcodeAlgorithm": {
              "maxLength": 10,
              "type": "string"
            },
            "barcodeLength": {
              "type": "integer"
            },
            "labelWidth": {
              "type": "integer"
            },
            "labelHeight": {
              "type": "integer"
            },
            "barcodeWidth": {
              "type": "integer"
            },
            "barcodeHeight": {
              "type": "integer"
            },
            "dynamicProbeTypeBase": {
              "type": "integer"
            },
            "reportDisplay": {
              "type": "object"
            },
            "mailTemplateGroup": {
              "type": "object"
            },
            "defaultPatientCanViewPartialResults": {
              "type": "boolean"
            },
            "defaultPatientCanViewPreliminaryResults": {
              "type": "boolean"
            },
            "printPosition": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMin": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printRangeMax": {
              "maxLength": 150,
              "type": [
                "string",
                "null"
              ]
            },
            "printPrefix": {
              "maxLength": 10,
              "type": [
                "string",
                "null"
              ]
            },
            "overwrite": {
              "type": "boolean"
            },
            "resetCentralBarcodes": {
              "type": "boolean"
            },
            "mtlMail": {
              "maxLength": 250,
              "type": "string"
            },
            "useLgMail": {
              "type": "boolean"
            },
            "orderTimeframe": {
              "type": "integer"
            },
            "reRequestLimit": {
              "type": "integer"
            },
            "reRequestBuffer": {
              "type": "integer"
            },
            "reRequestApprove": {
              "type": "boolean"
            },
            "reRequestNoExport": {
              "type": "boolean"
            },
            "reRequestLimitSampleDate": {
              "type": "boolean"
            },
            "emailsBlock": {
              "type": "array"
            },
            "orderLocalization": {
              "type": "object"
            },
            "quickScanPdfMode": {
              "type": "integer"
            },
            "quickScanWalletMode": {
              "type": "integer"
            },
            "quickScanAskFirstName": {
              "type": "boolean"
            },
            "quickScanAskPassport": {
              "type": "boolean"
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "barcodeMode": {},
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "useOwnNummernkreis": {
            "type": "boolean"
          },
          "useLgNummernkreis": {
            "type": "boolean"
          },
          "exportMe": {
            "type": "boolean"
          },
          "exportMeReceiver": {
            "type": [
              "string",
              "null"
            ]
          },
          "exportMeStatusString": {
            "type": "string"
          },
          "exportMeCharacteristicString": {
            "type": "string"
          },
          "exportMeSettingsStatusFlags": {
            "preliminary": {
              "type": "boolean"
            },
            "partial": {
              "type": "boolean"
            },
            "final": {
              "type": "boolean"
            }
          },
          "exportMeSettingsCharacteristicFlags": {
            "notPathological": {
              "type": "boolean"
            },
            "pathological": {
              "type": "boolean"
            },
            "extreme": {
              "type": "boolean"
            },
            "deltaCheck": {
              "type": "boolean"
            }
          },
          "pinnedRequirements": {
            "type": "array"
          }
        },
        "orders": {
          "type": "array"
        },
        "logs": {
          "type": "array"
        },
        "created_at": {
          "type": "string"
        },
        "updated_at": {
          "type": "string"
        },
        "materialsString": {
          "type": "string"
        },
        "requirementsString": {
          "type": "string"
        },
        "costUnitsString": {
          "type": "string"
        },
        "journalString": {
          "type": "string"
        },
        "orderFormNamesString": {
          "type": "string"
        },
        "meta_strings": {
          "materials": {
            "type": "string"
          },
          "requirements": {
            "type": "string"
          },
          "journal": {
            "type": "string"
          },
          "costUnits": {
            "type": "string"
          },
          "orderFormNames": {
            "type": "string"
          }
        },
        "isSoftDeleted": {
          "type": "boolean"
        }
      },
      "samples": {
        "type": "array"
      },
      "workstation": {
        "id": {
          "type": "integer"
        },
        "aid": {
          "type": "integer"
        },
        "name": {
          "type": "string"
        },
        "location": {
          "type": "string"
        },
        "default": {
          "type": "boolean"
        },
        "status": {},
        "clientVersion": {
          "type": "string"
        },
        "printers": {
          "type": "array"
        }
      },
      "manufacturer": {
        "type": "string"
      },
      "initialWorkplaceId": {
        "type": "number"
      },
      "lastWorkplaceId": {
        "type": "number"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "splitId": {
        "type": "integer"
      },
      "quickScanPdfPassword": {
        "type": [
          "string",
          "null"
        ]
      },
      "hasAttachment": {
        "type": "boolean"
      },
      "case_id": {
        "type": "integer"
      },
      "caseNumber": {
        "type": "string"
      },
      "realReRequestConditions": {
        "allowed": {
          "type": "boolean"
        },
        "formIds": {
          "type": "array"
        },
        "requirementIds": {
          "type": "array"
        },
        "profileIds": {
          "type": "array"
        }
      },
      "exportFlag": {
        "type": "boolean"
      },
      "statusExport": {
        "type": "integer"
      },
      "statusStornoExport": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isCancelable": {
        "type": "boolean"
      },
      "actuallyPrintedMaterials": {
        "type": "string"
      },
      "hasReportCopyInfo": {
        "type": "boolean"
      }
    },
    "orderPool": {
      "id": {
        "type": "integer"
      },
      "tnr": {
        "type": "string"
      },
      "displayName": {
        "type": "string"
      },
      "status": {
        "type": "array"
      },
      "doctor": {
        "aid": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "mtgid": {
          "type": [
            "integer",
            "null"
          ]
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "email": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "externalId": {
          "maxLength": 60,
          "type": [
            "string",
            "null"
          ]
        },
        "externalId2": {
          "type": "string"
        },
        "lanr": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "lastName": {
          "maxLength": 250,
          "type": "string"
        },
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "maxLength": 25,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "houseNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "countryCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "phoneNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "faxNumber": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "smsNumber": {
          "maxLength": 20,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "welcomeLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "logoutLabel": {
          "maxLength": 200,
          "type": [
            "string",
            "null"
          ]
        },
        "hierarchyStatus": {},
        "hierarchyAssignments": {
          "type": "array"
        },
        "hierarchyTree": {
          "type": "array"
        },
        "canActivatePatients": {
          "type": "boolean"
        },
        "isSuperDoctor": {
          "type": "boolean"
        },
        "disableViewDiagnosis": {
          "type": "boolean"
        },
        "emailNotificationEnabled": {
          "type": "boolean"
        },
        "emailNotificationsCharacteristics": {
          "type": "string"
        },
        "emailNotificationsStatus": {
          "type": "string"
        },
        "emailReports": {
          "type": "boolean"
        },
        "officeName": {
          "maxLength": 200,
          "type": "string"
        },
        "bsnr": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "localisation": {},
        "laboratoryLocalisation": {},
        "manufacturer": {
          "type": "string"
        },
        "urgent": {
          "type": "boolean"
        },
        "emergency": {
          "type": "boolean"
        },
        "infectious": {
          "type": "boolean"
        },
        "oaanswertrigger": {
          "type": "string"
        },
        "oaSoftDelete": {
          "type": "boolean"
        },
        "printMode": {
          "type": "integer"
        },
        "barcodePrintSettings": {
          "type": "integer"
        },
        "printername": {
          "maxLength": 200,
          "type": "string"
        },
        "scheinPrintSettings": {
          "type": "integer"
        },
        "printernameSchein": {
          "maxLength": 200,
          "type": "string"
        },
        "socketPrint": {
          "type": "boolean"
        },
        "internalPrintUser": {
          "type": "boolean"
        },
        "customDoctorHeading": {
          "type": "boolean"
        },
        "customDoctorHeadingLine1": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine2": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine3": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine4": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine5": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine6": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine7": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine8": {
          "maxLength": 50,
          "type": "string"
        },
        "customDoctorHeadingLine9": {
          "maxLength": 50,
          "type": "string"
        },
        "graphicalRequestChooser": {
          "type": "boolean"
        },
        "ipConstraint": {
          "type": "boolean"
        },
        "autoprint": {
          "type": "boolean"
        },
        "speciality": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "reportsLanguageSetting": {},
        "reportCount": {
          "type": "integer"
        },
        "forms": {
          "type": "array"
        },
        "profiles": {
          "type": "array"
        },
        "globalProfiles": {
          "type": "array"
        },
        "groups": {
          "type": "array"
        },
        "groupForms": {
          "type": "array"
        },
        "devices": {
          "type": "array"
        },
        "doctorGroupOffices": {
          "type": "array"
        },
        "doctorGroupOfficeName": {
          "type": "string"
        },
        "pid": {
          "type": "number"
        },
        "editKisMode": {
          "type": "boolean"
        },
        "laboratory": {
          "lid": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "maxLength": 50,
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "maxLength": 100,
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "oa": {
            "type": "boolean"
          },
          "ob": {
            "type": "boolean"
          },
          "of": {
            "type": "boolean"
          },
          "ll": {
            "type": "boolean"
          },
          "le": {
            "type": "boolean"
          },
          "ls": {
            "type": "boolean"
          },
          "lc": {
            "type": "boolean"
          },
          "dd": {
            "type": "boolean"
          },
          "mc": {
            "type": "boolean"
          },
          "nc": {
            "type": "boolean"
          },
          "lw": {
            "type": "boolean"
          },
          "admin": {
            "type": "boolean"
          },
          "dashboard": {
            "type": "boolean"
          },
          "features": {
            "type": "object"
          },
          "user": {
            "id": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "type": {
              "type": "integer"
            },
            "entityId": {
              "type": "integer"
            },
            "kennung": {
              "maxLength": 50,
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "integer"
            },
            "displayName": {
              "type": "string"
            },
            "salutation": {
              "type": "string"
            },
            "email": {
              "type": "string"
            },
            "locale": {
              "type": "object"
            },
            "userDebugEnabled": {
              "type": "boolean"
            },
            "alternative_kennung": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedTocAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "acceptedMaintenanceAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "enabled2fa": {
              "type": "boolean"
            },
            "rejected2faAt": {
              "type": [
                "string",
                "null"
              ]
            },
            "apiDocs": {
              "type": "boolean"
            }
          },
          "hl7Name": {
            "type": "string"
          },
          "target": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "companyName": {
            "type": "string"
          },
          "address": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "city": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "phone": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "whatsapp": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "fax": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "www": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "rerequestMail": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "autoLockReport": {
            "type": "boolean"
          },
          "class0": {
            "type": "number"
          },
          "class1": {
            "type": "number"
          },
          "class2": {
            "type": "number"
          },
          "class3": {
            "type": "number"
          },
          "class4": {
            "type": "number"
          },
          "class5": {
            "type": "number"
          },
          "showPdf": {},
          "showOriginal": {},
          "showBdw": {},
          "firstName": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "title": {
            "type": [
              "string",
              "null"
            ]
          },
          "suffix": {
            "type": [
              "string",
              "null"
            ]
          },
          "loginSite": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "additional1": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional2": {
            "type": [
              "string",
              "null"
            ]
          },
          "additional3": {
            "type": [
              "string",
              "null"
            ]
          },
          "zipCode": {
            "type": [
              "string",
              "null"
            ]
          },
          "contactInfo": {
            "type": [
              "string",
              "null"
            ]
          },
          "verifyIKs": {
            "type": "boolean"
          },
          "displayText": {
            "maxLength": 100,
            "type": [
              "string",
              "null"
            ]
          },
          "concurrentLoginLimit": {
            "type": "integer"
          },
          "barcodeAlgorithm": {
            "maxLength": 10,
            "type": "string"
          },
          "barcodeLength": {
            "type": "integer"
          },
          "labelWidth": {
            "type": "integer"
          },
          "labelHeight": {
            "type": "integer"
          },
          "barcodeWidth": {
            "type": "integer"
          },
          "barcodeHeight": {
            "type": "integer"
          },
          "dynamicProbeTypeBase": {
            "type": "integer"
          },
          "reportDisplay": {
            "lid": {
              "type": "integer"
            },
            "emailReports": {
              "type": "boolean"
            },
            "exportReports": {
              "type": "boolean"
            },
            "contactLaboratory": {
              "type": "boolean"
            },
            "reportAttachments": {
              "type": "boolean"
            },
            "rerequests": {
              "type": "boolean"
            },
            "printOrders": {
              "type": "boolean"
            },
            "showPathoColumn": {
              "type": "boolean"
            },
            "hidePathoColumnPat": {
              "type": "boolean"
            },
            "pathoHighlightType": {
              "type": "object"
            },
            "showLaboratoryGroup": {
              "type": "boolean"
            },
            "rerequestTimeFrame": {
              "type": "number"
            },
            "alwaysShowDateAndTimeInCumulativeDisplay": {
              "type": "boolean"
            }
          },
          "mailTemplateGroup": {
            "mtgid": {
              "type": "integer"
            },
            "lid": {
              "type": "integer"
            },
            "name": {
              "type": "string"
            },
            "reply": {
              "type": "string"
            },
            "target": {
              "type": [
                "string",
                "null"
              ]
            },
            "www_url": {
              "maxLength": 50,
              "pattern": "^https?://",
              "type": [
                "string",
                "null"
              ]
            },
            "logo": {
              "type": [
                "string",
                "null"
              ]
            },
            "fontColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "backgroundColor": {
              "type": [
                "string",
                "null"
              ]
            },
            "highlightColor": {
              "type": [
                "string",
                "null"
              ]
            }
          },
          "defaultPatientCanViewPartialResults": {
            "type": "boolean"
          },
          "defaultPatientCanViewPreliminaryResults": {
            "type": "boolean"
          },
          "printPosition": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMin": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printRangeMax": {
            "maxLength": 150,
            "type": [
              "string",
              "null"
            ]
          },
          "printPrefix": {
            "maxLength": 10,
            "type": [
              "string",
              "null"
            ]
          },
          "overwrite": {
            "type": "boolean"
          },
          "resetCentralBarcodes": {
            "type": "boolean"
          },
          "mtlMail": {
            "maxLength": 250,
            "type": "string"
          },
          "useLgMail": {
            "type": "boolean"
          },
          "orderTimeframe": {
            "type": "integer"
          },
          "reRequestLimit": {
            "type": "integer"
          },
          "reRequestBuffer": {
            "type": "integer"
          },
          "reRequestApprove": {
            "type": "boolean"
          },
          "reRequestNoExport": {
            "type": "boolean"
          },
          "reRequestLimitSampleDate": {
            "type": "boolean"
          },
          "emailsBlock": {
            "type": "array"
          },
          "orderLocalization": {},
          "quickScanPdfMode": {
            "type": "integer"
          },
          "quickScanWalletMode": {
            "type": "integer"
          },
          "quickScanAskFirstName": {
            "type": "boolean"
          },
          "quickScanAskPassport": {
            "type": "boolean"
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "barcodeMode": {},
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "useOwnNummernkreis": {
          "type": "boolean"
        },
        "useLgNummernkreis": {
          "type": "boolean"
        },
        "exportMe": {
          "type": "boolean"
        },
        "exportMeReceiver": {
          "type": [
            "string",
            "null"
          ]
        },
        "exportMeStatusString": {
          "type": "string"
        },
        "exportMeCharacteristicString": {
          "type": "string"
        },
        "exportMeSettingsStatusFlags": {
          "preliminary": {
            "type": "boolean"
          },
          "partial": {
            "type": "boolean"
          },
          "final": {
            "type": "boolean"
          }
        },
        "exportMeSettingsCharacteristicFlags": {
          "notPathological": {
            "type": "boolean"
          },
          "pathological": {
            "type": "boolean"
          },
          "extreme": {
            "type": "boolean"
          },
          "deltaCheck": {
            "type": "boolean"
          }
        },
        "pinnedRequirements": {
          "type": "array"
        }
      },
      "orders": {
        "type": "array"
      },
      "logs": {
        "type": "array"
      },
      "created_at": {
        "type": "string"
      },
      "updated_at": {
        "type": "string"
      },
      "materialsString": {
        "type": "string"
      },
      "requirementsString": {
        "type": "string"
      },
      "costUnitsString": {
        "type": "string"
      },
      "journalString": {
        "type": "string"
      },
      "orderFormNamesString": {
        "type": "string"
      },
      "meta_strings": {
        "materials": {
          "type": "string"
        },
        "requirements": {
          "type": "string"
        },
        "journal": {
          "type": "string"
        },
        "costUnits": {
          "type": "string"
        },
        "orderFormNames": {
          "type": "string"
        }
      },
      "isSoftDeleted": {
        "type": "boolean"
      }
    },
    "phoneNumber": {
      "maxLength": 50,
      "type": "string"
    },
    "paymentMethod": {},
    "linkId": {
      "type": "integer"
    },
    "country": {
      "maxLength": 50,
      "type": "string"
    },
    "billingAddress": {
      "maxLength": 50,
      "type": "string"
    },
    "billingZipCode": {
      "maxLength": 20,
      "type": "string"
    },
    "billingCity": {
      "maxLength": 50,
      "type": "string"
    },
    "billingCountry": {
      "maxLength": 50,
      "type": "string"
    },
    "orderDate": {
      "type": "string"
    },
    "billingNumber": {
      "maxLength": 20,
      "type": "string"
    },
    "deviceId": {
      "maxLength": 255,
      "type": "string"
    },
    "productId": {
      "type": "integer"
    }
  },
  "additionalPersons": {
    "type": "array"
  },
  "houseNr": {
    "maxLength": 50,
    "type": "string"
  },
  "insuranceNumber": {
    "maxLength": 50,
    "type": "string"
  },
  "formImage": {
    "type": "string"
  },
  "healthcardImage": {
    "type": "string"
  },
  "idFrontImage": {
    "type": "string"
  },
  "idBackImage": {
    "type": "string"
  },
  "idCardData": {
    "type": "string"
  },
  "idCardNumber": {
    "maxLength": 20,
    "type": "string"
  },
  "kbvFormPdf417Content": {
    "type": "string"
  }
};

export const WebshopOrderIdcardPropertiesSchema = {
  "address": {
    "maxLength": 255,
    "type": "string"
  },
  "age": {
    "type": "integer"
  },
  "birthDate": {
    "type": "string"
  },
  "expiryDate": {
    "type": "string"
  },
  "issuingDate": {
    "type": "string"
  },
  "cardNumber": {
    "maxLength": 255,
    "type": "string"
  },
  "expired": {
    "type": "boolean"
  },
  "faceImage": {
    "type": "object"
  },
  "firstName": {
    "maxLength": 255,
    "type": "string"
  },
  "fullName": {
    "maxLength": 255,
    "type": "string"
  },
  "issuingAuthority": {
    "maxLength": 255,
    "type": "string"
  },
  "lastNamePart": {
    "maxLength": 255,
    "type": "string"
  },
  "lastName": {
    "maxLength": 255,
    "type": "string"
  },
  "nationality": {
    "maxLength": 255,
    "type": "string"
  },
  "placeOfBirth": {
    "maxLength": 255,
    "type": "string"
  },
  "sex": {
    "maxLength": 255,
    "type": "string"
  }
};

export const CrudListQueryDtoSchema = {
  "query": {
    "type": "string"
  }
};

export const CrudPaginatedListQueryDtoSchema = {
  "query": {
    "type": "string"
  },
  "limit": {
    "type": "integer"
  },
  "offset": {
    "type": "integer"
  }
};

export const UserEntityTypeDtoSchema = {
  "entityId": {
    "type": "integer"
  },
  "type": {},
  "preferences": {
    "storeOrderNumberCurrent": {
      "type": "number"
    },
    "storeOrderNumberFormat": {
      "type": "string"
    }
  }
};

export const ExchangeFileIdsDtoSchema = {
  "ids": {
    "type": "array"
  }
};

export const ExchangeListQueryDtoSchema = {
  "query": {
    "type": "string"
  },
  "limit": {
    "type": "integer"
  },
  "offset": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "dateFrom": {
    "type": "string"
  },
  "dateTo": {
    "type": "string"
  },
  "fileType": {},
  "ldtFileVersion": {
    "type": [
      "integer",
      "null"
    ]
  },
  "showDeleted": {
    "type": "boolean"
  }
};

export const ExchangeUploadFileDtoSchema = {
  "aids": {
    "type": "array"
  },
  "file": {
    "type": "object"
  }
};

export const ExchangeExportSettingsDtoSchema = {
  "own": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "aid": {
      "type": "integer"
    },
    "doctor": {
      "aid": {
        "type": "integer"
      },
      "lid": {
        "type": "integer"
      },
      "mtgid": {
        "type": [
          "integer",
          "null"
        ]
      },
      "kennung": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "status": {},
      "displayName": {
        "type": "string"
      },
      "salutation": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "email": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "userDebugEnabled": {
        "type": "boolean"
      },
      "oa": {
        "type": "boolean"
      },
      "ob": {
        "type": "boolean"
      },
      "of": {
        "type": "boolean"
      },
      "ll": {
        "type": "boolean"
      },
      "le": {
        "type": "boolean"
      },
      "ls": {
        "type": "boolean"
      },
      "lc": {
        "type": "boolean"
      },
      "dd": {
        "type": "boolean"
      },
      "mc": {
        "type": "boolean"
      },
      "nc": {
        "type": "boolean"
      },
      "lw": {
        "type": "boolean"
      },
      "admin": {
        "type": "boolean"
      },
      "dashboard": {
        "type": "boolean"
      },
      "features": {
        "type": "object"
      },
      "user": {
        "id": {
          "type": "integer"
        },
        "lid": {
          "type": "integer"
        },
        "type": {},
        "entityId": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "alternative_kennung": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedTocAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "acceptedMaintenanceAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "enabled2fa": {
          "type": "boolean"
        },
        "rejected2faAt": {
          "type": [
            "string",
            "null"
          ]
        },
        "apiDocs": {
          "type": "boolean"
        }
      },
      "externalId": {
        "maxLength": 60,
        "type": [
          "string",
          "null"
        ]
      },
      "externalId2": {
        "type": "string"
      },
      "lanr": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "title": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "lastName": {
        "maxLength": 250,
        "type": "string"
      },
      "firstName": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "address": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "zipCode": {
        "maxLength": 25,
        "type": [
          "string",
          "null"
        ]
      },
      "city": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "houseNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "countryCode": {
        "type": [
          "string",
          "null"
        ]
      },
      "phoneNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "faxNumber": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "smsNumber": {
        "maxLength": 20,
        "type": [
          "string",
          "null"
        ]
      },
      "www": {
        "maxLength": 100,
        "type": [
          "string",
          "null"
        ]
      },
      "welcomeLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "logoutLabel": {
        "maxLength": 200,
        "type": [
          "string",
          "null"
        ]
      },
      "hierarchyStatus": {},
      "hierarchyAssignments": {
        "type": "array"
      },
      "hierarchyTree": {
        "type": "array"
      },
      "canActivatePatients": {
        "type": "boolean"
      },
      "isSuperDoctor": {
        "type": "boolean"
      },
      "disableViewDiagnosis": {
        "type": "boolean"
      },
      "emailNotificationEnabled": {
        "type": "boolean"
      },
      "emailNotificationsCharacteristics": {
        "type": "string"
      },
      "emailNotificationsStatus": {
        "type": "string"
      },
      "emailReports": {
        "type": "boolean"
      },
      "officeName": {
        "maxLength": 200,
        "type": "string"
      },
      "bsnr": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "localisation": {},
      "laboratoryLocalisation": {},
      "manufacturer": {
        "type": "string"
      },
      "urgent": {
        "type": "boolean"
      },
      "emergency": {
        "type": "boolean"
      },
      "infectious": {
        "type": "boolean"
      },
      "oaanswertrigger": {
        "type": "string"
      },
      "oaSoftDelete": {
        "type": "boolean"
      },
      "printMode": {
        "type": "integer"
      },
      "barcodePrintSettings": {
        "type": "integer"
      },
      "printername": {
        "maxLength": 200,
        "type": "string"
      },
      "scheinPrintSettings": {
        "type": "integer"
      },
      "printernameSchein": {
        "maxLength": 200,
        "type": "string"
      },
      "socketPrint": {
        "type": "boolean"
      },
      "internalPrintUser": {
        "type": "boolean"
      },
      "customDoctorHeading": {
        "type": "boolean"
      },
      "customDoctorHeadingLine1": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine2": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine3": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine4": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine5": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine6": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine7": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine8": {
        "maxLength": 50,
        "type": "string"
      },
      "customDoctorHeadingLine9": {
        "maxLength": 50,
        "type": "string"
      },
      "graphicalRequestChooser": {
        "type": "boolean"
      },
      "ipConstraint": {
        "type": "boolean"
      },
      "autoprint": {
        "type": "boolean"
      },
      "speciality": {
        "maxLength": 50,
        "type": [
          "string",
          "null"
        ]
      },
      "reportsLanguageSetting": {},
      "reportCount": {
        "type": "integer"
      },
      "forms": {
        "type": "array"
      },
      "profiles": {
        "type": "array"
      },
      "globalProfiles": {
        "type": "array"
      },
      "groups": {
        "type": "array"
      },
      "groupForms": {
        "type": "array"
      },
      "devices": {
        "type": "array"
      },
      "doctorGroupOffices": {
        "type": "array"
      },
      "doctorGroupOfficeName": {
        "type": "string"
      },
      "pid": {
        "type": "number"
      },
      "editKisMode": {
        "type": "boolean"
      },
      "laboratory": {
        "lid": {
          "type": "integer"
        },
        "kennung": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "status": {},
        "displayName": {
          "maxLength": 50,
          "type": "string"
        },
        "salutation": {
          "type": "string"
        },
        "email": {
          "maxLength": 100,
          "type": "string"
        },
        "locale": {},
        "userDebugEnabled": {
          "type": "boolean"
        },
        "oa": {
          "type": "boolean"
        },
        "ob": {
          "type": "boolean"
        },
        "of": {
          "type": "boolean"
        },
        "ll": {
          "type": "boolean"
        },
        "le": {
          "type": "boolean"
        },
        "ls": {
          "type": "boolean"
        },
        "lc": {
          "type": "boolean"
        },
        "dd": {
          "type": "boolean"
        },
        "mc": {
          "type": "boolean"
        },
        "nc": {
          "type": "boolean"
        },
        "lw": {
          "type": "boolean"
        },
        "admin": {
          "type": "boolean"
        },
        "dashboard": {
          "type": "boolean"
        },
        "features": {
          "type": "object"
        },
        "user": {
          "id": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "type": {},
          "entityId": {
            "type": "integer"
          },
          "kennung": {
            "maxLength": 50,
            "type": [
              "string",
              "null"
            ]
          },
          "status": {},
          "displayName": {
            "type": "string"
          },
          "salutation": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "locale": {},
          "userDebugEnabled": {
            "type": "boolean"
          },
          "alternative_kennung": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedTocAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "acceptedMaintenanceAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "enabled2fa": {
            "type": "boolean"
          },
          "rejected2faAt": {
            "type": [
              "string",
              "null"
            ]
          },
          "apiDocs": {
            "type": "boolean"
          }
        },
        "hl7Name": {
          "type": "string"
        },
        "target": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "companyName": {
          "type": "string"
        },
        "address": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "city": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "phone": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "whatsapp": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "fax": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "www": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "rerequestMail": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "autoLockReport": {
          "type": "boolean"
        },
        "class0": {
          "type": "number"
        },
        "class1": {
          "type": "number"
        },
        "class2": {
          "type": "number"
        },
        "class3": {
          "type": "number"
        },
        "class4": {
          "type": "number"
        },
        "class5": {
          "type": "number"
        },
        "showPdf": {},
        "showOriginal": {},
        "showBdw": {},
        "firstName": {
          "maxLength": 50,
          "type": [
            "string",
            "null"
          ]
        },
        "title": {
          "type": [
            "string",
            "null"
          ]
        },
        "suffix": {
          "type": [
            "string",
            "null"
          ]
        },
        "loginSite": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "additional1": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional2": {
          "type": [
            "string",
            "null"
          ]
        },
        "additional3": {
          "type": [
            "string",
            "null"
          ]
        },
        "zipCode": {
          "type": [
            "string",
            "null"
          ]
        },
        "contactInfo": {
          "type": [
            "string",
            "null"
          ]
        },
        "verifyIKs": {
          "type": "boolean"
        },
        "displayText": {
          "maxLength": 100,
          "type": [
            "string",
            "null"
          ]
        },
        "concurrentLoginLimit": {
          "type": "integer"
        },
        "barcodeAlgorithm": {
          "maxLength": 10,
          "type": "string"
        },
        "barcodeLength": {
          "type": "integer"
        },
        "labelWidth": {
          "type": "integer"
        },
        "labelHeight": {
          "type": "integer"
        },
        "barcodeWidth": {
          "type": "integer"
        },
        "barcodeHeight": {
          "type": "integer"
        },
        "dynamicProbeTypeBase": {
          "type": "integer"
        },
        "reportDisplay": {
          "lid": {
            "type": "integer"
          },
          "emailReports": {
            "type": "boolean"
          },
          "exportReports": {
            "type": "boolean"
          },
          "contactLaboratory": {
            "type": "boolean"
          },
          "reportAttachments": {
            "type": "boolean"
          },
          "rerequests": {
            "type": "boolean"
          },
          "printOrders": {
            "type": "boolean"
          },
          "showPathoColumn": {
            "type": "boolean"
          },
          "hidePathoColumnPat": {
            "type": "boolean"
          },
          "pathoHighlightType": {},
          "showLaboratoryGroup": {
            "type": "boolean"
          },
          "rerequestTimeFrame": {
            "type": "number"
          },
          "alwaysShowDateAndTimeInCumulativeDisplay": {
            "type": "boolean"
          }
        },
        "mailTemplateGroup": {
          "mtgid": {
            "type": "integer"
          },
          "lid": {
            "type": "integer"
          },
          "name": {
            "type": "string"
          },
          "reply": {
            "type": "string"
          },
          "target": {
            "type": [
              "string",
              "null"
            ]
          },
          "www_url": {
            "maxLength": 50,
            "pattern": "^https?://",
            "type": [
              "string",
              "null"
            ]
          },
          "logo": {
            "type": [
              "string",
              "null"
            ]
          },
          "fontColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "backgroundColor": {
            "type": [
              "string",
              "null"
            ]
          },
          "highlightColor": {
            "type": [
              "string",
              "null"
            ]
          }
        },
        "defaultPatientCanViewPartialResults": {
          "type": "boolean"
        },
        "defaultPatientCanViewPreliminaryResults": {
          "type": "boolean"
        },
        "printPosition": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMin": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printRangeMax": {
          "maxLength": 150,
          "type": [
            "string",
            "null"
          ]
        },
        "printPrefix": {
          "maxLength": 10,
          "type": [
            "string",
            "null"
          ]
        },
        "overwrite": {
          "type": "boolean"
        },
        "resetCentralBarcodes": {
          "type": "boolean"
        },
        "mtlMail": {
          "maxLength": 250,
          "type": "string"
        },
        "useLgMail": {
          "type": "boolean"
        },
        "orderTimeframe": {
          "type": "integer"
        },
        "reRequestLimit": {
          "type": "integer"
        },
        "reRequestBuffer": {
          "type": "integer"
        },
        "reRequestApprove": {
          "type": "boolean"
        },
        "reRequestNoExport": {
          "type": "boolean"
        },
        "reRequestLimitSampleDate": {
          "type": "boolean"
        },
        "emailsBlock": {
          "type": "array"
        },
        "orderLocalization": {},
        "quickScanPdfMode": {
          "type": "integer"
        },
        "quickScanWalletMode": {
          "type": "integer"
        },
        "quickScanAskFirstName": {
          "type": "boolean"
        },
        "quickScanAskPassport": {
          "type": "boolean"
        },
        "logo": {
          "type": [
            "string",
            "null"
          ]
        },
        "fontColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "backgroundColor": {
          "type": [
            "string",
            "null"
          ]
        },
        "highlightColor": {
          "type": [
            "string",
            "null"
          ]
        }
      },
      "barcodeMode": {},
      "printPosition": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMin": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printRangeMax": {
        "maxLength": 150,
        "type": [
          "string",
          "null"
        ]
      },
      "printPrefix": {
        "maxLength": 10,
        "type": [
          "string",
          "null"
        ]
      },
      "useOwnNummernkreis": {
        "type": "boolean"
      },
      "useLgNummernkreis": {
        "type": "boolean"
      },
      "exportMe": {
        "type": "boolean"
      },
      "exportMeReceiver": {
        "type": [
          "string",
          "null"
        ]
      },
      "exportMeStatusString": {
        "type": "string"
      },
      "exportMeCharacteristicString": {
        "type": "string"
      },
      "exportMeSettingsStatusFlags": {
        "preliminary": {
          "type": "boolean"
        },
        "partial": {
          "type": "boolean"
        },
        "final": {
          "type": "boolean"
        }
      },
      "exportMeSettingsCharacteristicFlags": {
        "notPathological": {
          "type": "boolean"
        },
        "pathological": {
          "type": "boolean"
        },
        "extreme": {
          "type": "boolean"
        },
        "deltaCheck": {
          "type": "boolean"
        }
      },
      "pinnedRequirements": {
        "type": "array"
      }
    },
    "aidOwner": {
      "type": "integer"
    },
    "filenameLA": {
      "type": "string"
    },
    "filenameLG": {
      "type": "string"
    },
    "encoding": {},
    "directoryLA": {
      "type": "string"
    },
    "directoryLG": {
      "type": "string"
    },
    "directorySO": {
      "type": "string"
    },
    "directoryPDF": {
      "type": "string"
    }
  },
  "others": {
    "type": "array"
  }
};

export const ExchangeImportSettingsResultDtoSchema = {
  "settingsLDT": {
    "lid": {
      "type": "integer"
    },
    "importDirectory": {
      "type": "string"
    },
    "archiveDirectory": {
      "type": "string"
    },
    "encoding": {},
    "ldt2ModeLaboratoryGroupIdentification": {},
    "ldt2ModeLaboratoryGroupIdentificationValueList": {
      "type": "string"
    },
    "fileUpdateMode": {},
    "tnrFieldIdentification": {
      "maxLength": 4,
      "type": "string"
    },
    "ldt3ModeLaboratoryGroupIdentification": {},
    "ldt3ModeLaboratoryGroupIdentificationValueList": {
      "type": "string"
    },
    "ldt3Mode2ObjectAndField": {
      "maxLength": 30,
      "type": "string"
    }
  },
  "settingsPDF": {
    "lid": {
      "type": "integer"
    },
    "importDirectory": {
      "type": "string"
    },
    "archiveDirectory": {
      "type": "string"
    },
    "fileUpdateMode": {}
  },
  "directories": {
    "type": "array"
  }
};

export const StoreCategoryPathReferenceDtoSchema = {
  "id": {
    "type": "integer"
  },
  "name": {
    "type": "string"
  }
};

export const StoreProductUploadImageDtoSchema = {
  "id": {
    "type": "integer"
  },
  "image": {
    "type": "object"
  }
};

export const StoreProductsListQueryDtoSchema = {
  "query": {
    "type": "string"
  },
  "limit": {
    "type": "integer"
  },
  "offset": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "categoryId": {
    "type": "integer"
  },
  "manufacturerId": {
    "type": "integer"
  }
};

export const StoreOrderCreateDtoSchema = {
  "aid": {
    "type": "integer"
  },
  "items": {
    "type": "array"
  },
  "status": {}
};

export const StoreOrdersListQueryDtoSchema = {
  "query": {
    "type": "string"
  },
  "limit": {
    "type": "integer"
  },
  "offset": {
    "type": "integer"
  },
  "aid": {
    "type": "integer"
  },
  "status": {}
};

export const StoreConfigDtoSchema = {
  "preferences": {
    "storeOrderNumberCurrent": {
      "type": "number"
    },
    "storeOrderNumberFormat": {
      "type": "string"
    }
  }
};

export const StoreUserConfigDtoSchema = {
  "entityId": {
    "type": "integer"
  },
  "type": {},
  "preferences": {
    "storeOrderNumberCurrent": {
      "type": "number"
    },
    "storeOrderNumberFormat": {
      "type": "string"
    }
  }
};

export const NotificationGeneralConfigDTOSchema = {
  "preferences": {
    "newExchangeFileNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotificationSettings": {
      "characteristics": {
        "type": "array"
      },
      "status": {
        "type": "array"
      },
      "custom": {
        "type": "boolean"
      }
    }
  },
  "smsSettings": {
    "id": {
      "type": "integer"
    },
    "lid": {
      "type": "integer"
    },
    "apiId": {
      "type": "string"
    },
    "apiKey": {
      "type": "integer"
    },
    "url": {
      "type": "string"
    },
    "method": {
      "type": "string"
    }
  }
};

export const NotificationUserConfigDTOSchema = {
  "preferences": {
    "newExchangeFileNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotification": {
      "enabled": {
        "type": "boolean"
      },
      "mail": {
        "type": "boolean"
      },
      "push": {
        "type": "boolean"
      },
      "sms": {
        "type": "boolean"
      }
    },
    "newReportNotificationSettings": {
      "characteristics": {
        "type": "array"
      },
      "status": {
        "type": "array"
      },
      "custom": {
        "type": "boolean"
      }
    }
  }
};

export const ListNotificationsDtoSchema = {
  "results": {
    "type": "array"
  }
};

export const MessenteDeliveryReportDTOSchema = {
  "stat": {
    "type": "string"
  },
  "err": {
    "type": "string"
  }
};

export const NotificationCountDTOSchema = {
  "count": {
    "type": "integer"
  }
};

export const ListNotificationLogsQueryDtoSchema = {
  "query": {
    "type": "string"
  },
  "limit": {
    "type": "integer"
  },
  "offset": {
    "type": "integer"
  },
  "type": {},
  "method": {},
  "timestampFrom": {
    "type": "string"
  },
  "timestampTo": {
    "type": "string"
  }
};

export const ListNotificationLogsDtoSchema = {
  "id": {
    "type": "integer"
  },
  "type": {},
  "title": {
    "type": "string"
  },
  "body": {
    "type": "string"
  },
  "data": {
    "type": "object"
  }
};
